import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import { ReportType } from '../../models/Report'

export * from './CanceledItem'
export * from './ReportRowView'

export function getFormatDate(date: dayjs.Dayjs, reportType: ReportType) {
  if (reportType === 'daily') {
    // eslint-disable-next-line ar-i18n/require-translation-ja
    return date.format('YYYY年M月D日(ddd)')
  }
  // eslint-disable-next-line ar-i18n/require-translation-ja
  return date.format('YYYY年M月')
}
