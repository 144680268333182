import React from 'react'

import { StyleProp, View, ViewStyle } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { BusinessUserResource_RestaurantResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_resource'

import { Restaurant } from 'models/Restaurant'

type SetState<S> = (value: S | ((prevState: S) => S)) => void

export function PermittedRestaurantsFormGroup({
  style,
  restaurants,
  permittedRestaurantIds,
  setPermittedRestaurantIds,
  isAdmin,
}: {
  style?: StyleProp<ViewStyle>
  restaurants: Restaurant[]
  permittedRestaurantIds: Array<BusinessUserResource_RestaurantResource['id']>
  setPermittedRestaurantIds: SetState<
    Array<BusinessUserResource_RestaurantResource['id']>
  >
  isAdmin: boolean
}) {
  const { width, sm } = useResponsive()
  const onSelectAllRestaurants = () => {
    setPermittedRestaurantIds(restaurants.map(({ id }) => id))
  }

  const onDeselectAllRestaurants = () => {
    setPermittedRestaurantIds([])
  }

  const onChangePermittedRestaurants = (
    restaurantId: Restaurant['id'],
    value: boolean
  ) => {
    if (value) {
      if (!permittedRestaurantIds.includes(restaurantId)) {
        setPermittedRestaurantIds((ids) => [...ids, restaurantId])
      }
    } else {
      setPermittedRestaurantIds((ids) =>
        ids.filter((id) => id !== restaurantId)
      )
    }
  }

  return (
    <FormGroup
      mode={width < sm ? 'vertical' : 'inline-expanded'}
      style={style}
      formLabel={<FormLabel value={t('取扱店舗')} required />}
    >
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: width < sm ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            rowGap: width < sm ? 16 : 0,
            columnGap: width < sm ? 0 : 20,
          }}
        >
          <Button
            mode="outline"
            style={{ width: 220 }}
            onPress={onSelectAllRestaurants}
            disabled={isAdmin}
          >
            {t('すべてを選択')}
          </Button>
          <Button
            mode="outline"
            variant="secondary"
            style={{
              width: 220,
            }}
            onPress={onDeselectAllRestaurants}
            disabled={isAdmin}
          >
            {t('すべての選択を解除')}
          </Button>
        </View>
        <Wrap
          style={{
            marginTop: 24,
          }}
          verticalGap={28}
          horizontalGap={20}
        >
          {restaurants.map((restaurant) => (
            <Checkbox
              key={restaurant.id}
              onChange={(value) =>
                onChangePermittedRestaurants(restaurant.id, value)
              }
              checked={permittedRestaurantIds.includes(restaurant.id)}
              checkboxLabel={<CheckboxLabel value={restaurant.name} />}
              disabled={isAdmin}
            />
          ))}
        </Wrap>
      </View>
    </FormGroup>
  )
}
