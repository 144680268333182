import { assertNever } from '@hello-ai/ar_shared/src/modules/assert'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  TableSeatResource_Category,
  TableSeatResource_SmokingStatus,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

import { CategoryName } from '../../models/TableSeat'

export function getCategoryName(value: TableSeatResource_Category) {
  switch (value) {
    case TableSeatResource_Category.TABLE_SEAT:
      return t('テーブル')
    case TableSeatResource_Category.COUNTER_SEAT:
      return t('カウンター')
    case TableSeatResource_Category.TERRACE:
      return t('テラス')
    case TableSeatResource_Category.SEMI_PRIVATE_ROOM:
      return t('半個室')
    case TableSeatResource_Category.PRIVATE_ROOM:
      return t('個室')
    case TableSeatResource_Category.TATAMI:
      return t('畳')
    default: {
      assertNever(value)
    }
  }
}

export function categoryNameFromString(
  value: string | undefined | null
): CategoryName | undefined {
  switch (value) {
    case 'table_seat':
    case 'counter_seat':
    case 'terrace':
    case 'semi_private_room':
    case 'private_room':
    case 'tatami':
      return value
    default: {
      return undefined
    }
  }
}

export function getCategoryNumber(value: CategoryName) {
  switch (value) {
    case 'table_seat':
      return 0
    case 'counter_seat':
      return 1
    case 'terrace':
      return 2
    case 'semi_private_room':
      return 3
    case 'private_room':
      return 4
    case 'tatami':
      return 5
    default: {
      assertNever(value)
    }
  }
}

export function getCategoryTitle(value: CategoryName) {
  switch (value) {
    case 'table_seat':
      return t('テーブル')
    case 'counter_seat':
      return t('カウンター')
    case 'terrace':
      return t('テラス')
    case 'semi_private_room':
      return t('半個室')
    case 'private_room':
      return t('個室')
    case 'tatami':
      return t('畳')
    default: {
      assertNever(value)
    }
  }
}

export function getSmokingStatusName(value: TableSeatResource_SmokingStatus) {
  switch (value) {
    case TableSeatResource_SmokingStatus.NO_SMOKING:
      return t('禁煙')
    case TableSeatResource_SmokingStatus.SMOKING:
      return t('喫煙')
    default: {
      assertNever(value)
    }
  }
}
