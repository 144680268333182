import React from 'react'
import { useNavigate } from 'react-router-dom'

import { ScrollView, StyleProp, View, ViewStyle } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import {
  useOwnerInvoiceSetting,
  getInvoiceSettingPaymentMethodText,
} from 'models/OwnerInvoiceSetting'

const buttonHeight = 48
const buttonWidth = 248

export default function PaymentIndex() {
  const navigate = useNavigate()
  const { data: invoiceSetting } = useOwnerInvoiceSetting()

  function FormSection({
    style,
    children,
  }: {
    style?: StyleProp<ViewStyle>
    children?: React.ReactNode
  }) {
    return (
      <View style={style}>
        <ShadowBox style={{ marginTop: 22 }}>{children}</ShadowBox>
      </View>
    )
  }

  if (invoiceSetting === undefined) {
    return <Loading />
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      <FormSection>
        <FormGroup
          style={{ alignItems: 'baseline' }}
          formLabel={<FormLabel value={t('支払い方法')} />}
        >
          <Text>
            {getInvoiceSettingPaymentMethodText(invoiceSetting.payment_method)}
          </Text>
          <Text
            style={{
              marginTop: 16,
              fontSize: 14,
              lineHeight: 18,
            }}
          >
            {t('※請求書払いまたはクレジットカード払いを選択いただけます')}
          </Text>
        </FormGroup>
      </FormSection>
      <View
        style={{
          marginTop: 48,
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        <Button
          onPress={() => {
            navigate('/settings/payment/edit')
          }}
          height={buttonHeight}
          width={buttonWidth}
        >
          {t('支払い方法を変更する')}
        </Button>
      </View>
    </ScrollView>
  )
}
