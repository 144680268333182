import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import LanguageScreen from 'pages/Other/language'

const routes: StackRoutes = {
  Index: {
    title: t('言語設定'),
    fullPath: `/restaurants/:id/other/language`,
    relativePath: `/`,
    element: <LanguageScreen />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
}

export default function LanguageStack() {
  return <Stack routes={routes} />
}
