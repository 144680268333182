import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faBahtSign } from '@fortawesome/pro-solid-svg-icons/faBahtSign'
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons/faDollarSign'
import { faFrancSign } from '@fortawesome/pro-solid-svg-icons/faFrancSign'
import { faPesoSign } from '@fortawesome/pro-solid-svg-icons/faPesoSign'
import { faPoundSign } from '@fortawesome/pro-solid-svg-icons/faPoundSign'
import { faWonSign } from '@fortawesome/pro-solid-svg-icons/faWonSign'
import { faYenSign } from '@fortawesome/pro-solid-svg-icons/faYenSign'

import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

import numberWithDelimiter from './numberWithDelimiter'

export const defaultPriceUnit = 'JPY'

type Num = number | string | undefined | null

const currencyInfo: {
  [key: string]: {
    symbol: string
    decimalPlaces: number
    icon: IconDefinition
  }
} = {
  AUD: { symbol: 'A$', decimalPlaces: 2, icon: faDollarSign },
  CAD: { symbol: 'C$', decimalPlaces: 2, icon: faDollarSign },
  CHF: { symbol: 'CHF', decimalPlaces: 2, icon: faFrancSign },
  GBP: { symbol: '£', decimalPlaces: 2, icon: faPoundSign },
  HKD: { symbol: 'HK$', decimalPlaces: 2, icon: faDollarSign },
  NZD: { symbol: 'NZ$', decimalPlaces: 2, icon: faDollarSign },
  PHP: { symbol: '₱', decimalPlaces: 2, icon: faPesoSign },
  SGD: { symbol: 'S$', decimalPlaces: 2, icon: faDollarSign },
  THB: { symbol: '฿', decimalPlaces: 2, icon: faBahtSign },
  TWD: { symbol: 'NT$', decimalPlaces: 2, icon: faDollarSign },
  USD: { symbol: '$', decimalPlaces: 2, icon: faDollarSign },
  JPY: { symbol: '¥', decimalPlaces: 0, icon: faYenSign },
  KRW: { symbol: '₩', decimalPlaces: 0, icon: faWonSign },
}

function getCurrencySymbol(priceUnit: string) {
  if (priceUnit in currencyInfo) {
    return currencyInfo[priceUnit].symbol
  }
  return '¥'
}

function getCurrencyDecimalPlaces(priceUnit: string): number {
  if (priceUnit in currencyInfo) {
    return currencyInfo[priceUnit].decimalPlaces
  }
  return 0
}

function getCurrencyIcon(priceUnit: string) {
  if (priceUnit in currencyInfo) {
    return currencyInfo[priceUnit].icon
  }
  return faYenSign
}

// フォームに入力可能な小数点以下の桁数をdecimalPlacesに合わせ制限する
function formatCurrencyInput(
  decimalPlaces: number,
  value?: string,
  useAbsoluteValue?: boolean
) {
  if (value === undefined || value === null || value === '') return ''

  let number = parseFloat(value)
  if (isNaN(number)) {
    // 数値でない場合、マイナス記号のみ許容
    if (value === '-') {
      return value
    }
    return ''
  }

  if (useAbsoluteValue) {
    number = Math.abs(number)
  }

  const decimalIndex = value.indexOf('.')
  if (decimalIndex !== -1 && value.length - decimalIndex > decimalPlaces + 1) {
    // 小数点以下をdecimalPlaces桁に合わせて残りを切り捨てる
    value = value.substring(0, decimalIndex + decimalPlaces + 1)
  }
  return value
}

// 通貨単位に応じた小数点以下の桁数に数値をキャストする
function currencyWithDelimiter(decimalPlaces: number, num: Num) {
  if (num === undefined || num === null) return '-'
  if (num === '') return num

  num = parseFloat(String(num)).toFixed(decimalPlaces)
  return numberWithDelimiter(num)
}

// 通貨単位に応じ以下どちらかを返す（デフォルトは1）
// 1. 記号+金額 ex)￥1,000
// 2. 国際表記+スペース+金額 ex)JPY 1,000
function formatCurrency(
  priceUnit: string,
  decimalPlaces: number,
  num: Num,
  useSymbol: boolean
) {
  return useSymbol
    ? `${getCurrencySymbol(priceUnit)}${currencyWithDelimiter(
        decimalPlaces,
        num
      )}`
    : `${priceUnit} ${currencyWithDelimiter(decimalPlaces, num)}`
}

export function getRestaurantCurrencyFormat(priceUnit: string) {
  const decimalPlaces = getCurrencyDecimalPlaces(priceUnit)

  return {
    priceUnit,
    decimalPlaces,
    formatCurrencyInput: (value?: string, useAbsoluteValue?: boolean) =>
      formatCurrencyInput(decimalPlaces, value, useAbsoluteValue),
    currencyWithDelimiter: (num: Num) =>
      currencyWithDelimiter(decimalPlaces, num),
    formatCurrency: (num: Num, useSymbol = true) =>
      formatCurrency(priceUnit, decimalPlaces, num, useSymbol),
    currencyIcon: () => getCurrencyIcon(priceUnit),
  }
}

// 価格入力、表示の多通貨対応
export function useRestaurantCurrency(
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
) {
  const priceUnit =
    restaurant != null ? restaurant.price_unit : defaultPriceUnit
  return getRestaurantCurrencyFormat(priceUnit)
}
