import React from 'react'

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import { ScrollView, View } from 'react-native'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { TableSeatConnectionResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat_connection/table_seat_connection_resource'

import { useToken } from 'models/Auth'
import { tableSeatConnectionService } from 'models/TableSeatConnection'
import { onError } from 'modules/swr'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableSeatConnections() {
  const restaurantId = useRestaurantId()
  const token = useToken()
  const { data: { tableSeatConnections } = {}, mutate } =
    tableSeatConnectionService.useList({
      restaurantId,
    })

  if (tableSeatConnections === undefined) {
    return <Loading />
  }

  const alertDestroySeatConenction = async (
    tableSeatConnectionId: TableSeatConnectionResource['id']
  ) => {
    Alert.alert(t('本当に削除しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          const { error } = await tableSeatConnectionService.destroy(token, {
            id: tableSeatConnectionId,
          })

          if (error != null) {
            onError(error)
            return
          }
          mutate()
        },
      },
    ])
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      <View>
        <Text style={{ fontSize: 22, fontWeight: '600' }}>
          {t('接続パターン')}
        </Text>
        <ShadowBox style={[{ marginTop: 24, padding: 0 }]}>
          <View
            style={{
              paddingTop: 16,
              paddingBottom: 12,
              paddingHorizontal: 32,
              flexDirection: 'row',
              alignItems: 'center',
              borderBottomWidth: 0.5,
              borderBottomColor: Colors.border,
            }}
          >
            <Text
              style={{
                flex: 2,
                fontSize: 12,
                fontWeight: '600',
              }}
            >
              {t('席')}
            </Text>
            <Text
              style={{
                marginLeft: 16,
                flex: 1,
                fontSize: 12,
                fontWeight: '600',
              }}
            >
              {t('最小人数')}
            </Text>
            <Text
              style={{
                marginLeft: 16,
                flex: 1,
                fontSize: 12,
                fontWeight: '600',
              }}
            >
              {t('最大人数')}
            </Text>
          </View>
          <View
            style={{
              paddingVertical: 28,
              paddingHorizontal: 32,
            }}
          >
            {tableSeatConnections.length === 0 && (
              <Text
                style={{
                  color: Colors.disabledBlack,
                  fontWeight: '600',
                  textAlign: 'center',
                }}
              >
                {t('接続パターンがありません')}
              </Text>
            )}

            {tableSeatConnections.map((tableSeatConnection, index) => {
              return (
                <View
                  key={tableSeatConnection.id}
                  style={{
                    marginTop: index === 0 ? 0 : 40,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <View style={{ flex: 2 }}>
                    <Text style={{ flex: 1 }}>
                      {tableSeatConnection.tableSeats
                        .map(({ name }) => name)
                        .join(', ')}
                    </Text>
                  </View>
                  <Text style={{ marginLeft: 16, flex: 1 }}>
                    {tableSeatConnection.minPartySize}
                  </Text>
                  <View
                    style={{
                      marginLeft: 16,
                      flex: 1,
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Text style={{ flex: 1 }}>
                      {tableSeatConnection.maxPartySize}
                    </Text>
                    <TouchableOpacity
                      style={[{ marginLeft: 20 }]}
                      onPress={async () =>
                        await alertDestroySeatConenction(tableSeatConnection.id)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        size={20}
                        color={Colors.secondaryBlack}
                        style={{ padding: 2 }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              )
            })}
          </View>
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
