import React from 'react'

import { ScrollView, View } from 'react-native'
import { useNavigate, useParams } from 'react-router'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { BusinessFeatureResource_FeatureKey } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_feature_resource'
import { BusinessUserRolePermissionResource_Level } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_permission_resource'
import { BusinessUserRolePermission } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_service'

import { useToken } from 'models/Auth'
import {
  businessFeatures,
  businessUserRoleClient,
  compareBusinessUserRolePermissionLevels,
  getBusinessUserRolePermission,
  useBusinessUserRole,
} from 'models/BusinessUserRole'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'

function validate({ name }: { name: string }) {
  return name !== ''
}

const initialPermissions = [
  {
    featureKey: BusinessFeatureResource_FeatureKey.TABLE_MENUS,
    level: BusinessUserRolePermissionResource_Level.ALL,
  },
  {
    featureKey: BusinessFeatureResource_FeatureKey.TABLE_PAST_ORDERS,
    level: BusinessUserRolePermissionResource_Level.ALL,
  },
  {
    featureKey: BusinessFeatureResource_FeatureKey.TABLE_REPORTS,
    level: BusinessUserRolePermissionResource_Level.ALL,
  },
  {
    featureKey: BusinessFeatureResource_FeatureKey.TABLE_CUSTOMERS,
    level: BusinessUserRolePermissionResource_Level.READ,
  },
  {
    featureKey: BusinessFeatureResource_FeatureKey.TABLE_SALES,
    level: BusinessUserRolePermissionResource_Level.READ,
  },
]

const FORM_BUTTON_WITH = 278
const FORM_BUTTON_HEIGHT = 48

export default function BusinessUserRolesForm() {
  const token = useToken()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const { businessUserRoleId } = useParams<{ businessUserRoleId?: string }>()
  const { businessUserRole, mutate } = useBusinessUserRole(
    businessUserRoleId === undefined ? undefined : { id: businessUserRoleId }
  )

  const [name, setName] = useFormState(businessUserRole?.name ?? '')
  const [description, setDescription] = useFormState(
    businessUserRole?.description ?? ''
  )

  const [businessUserRolePermissions, setBusinessUserRolePermissions] =
    useFormState<BusinessUserRolePermission[]>(
      businessUserRole?.businessUserRolePermissions &&
        businessUserRole.businessUserRolePermissions.length > 0
        ? businessUserRole.businessUserRolePermissions
        : initialPermissions
    )

  const onSubmit = async () => {
    if (token === null) return

    if (businessUserRoleId === undefined) {
      const { error } = await callRpc(
        businessUserRoleClient.create(
          {
            name,
            businessUserRolePermissions,
            description,
          },
          rpcOptions({ token })
        )
      )
      if (error != null) {
        onError(error)
        return
      }
      displayToastInfo(t('役割を追加しました'))
      navigate('/business_user_roles')
    } else {
      const { error } = await callRpc(
        businessUserRoleClient.update(
          {
            id: businessUserRoleId,
            name,
            businessUserRolePermissions,
            description,
          },
          rpcOptions({ token })
        )
      )
      if (error != null) {
        onError(error)
        return
      }
      displayToastInfo(t('役割を更新しました'))
    }
    mutate()
    navigate('/business_user_roles')
  }

  function onSelectLevel(
    featureKey_: BusinessFeatureResource_FeatureKey,
    level: BusinessUserRolePermissionResource_Level
  ) {
    setBusinessUserRolePermissions((businessUserRolePermissions) => {
      const permission = getBusinessUserRolePermission(
        businessUserRolePermissions,
        featureKey_
      )
      return [
        ...businessUserRolePermissions.filter(
          ({ featureKey }) => featureKey !== featureKey_
        ),
        {
          ...permission,
          level,
        },
      ]
    })
  }

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{
          padding: width < sm ? 24 : 48,
          flexGrow: 1,
        }}
      >
        <ShadowBox
          style={{
            marginTop: 24,
            padding: 32,
          }}
        >
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('役割名')} required />}
          >
            <TextInput
              placeholder={t('役割')}
              onChangeText={setName}
              value={name}
            />
          </FormGroup>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            style={{ marginTop: 28 }}
            formLabel={<FormLabel value={t('説明')} />}
          >
            <TextInput
              placeholder={t('説明')}
              onChangeText={setDescription}
              value={description}
            />
          </FormGroup>

          <FormGroup
            mode="vertical"
            style={{ marginTop: 28 }}
            formLabel={<FormLabel value={t('権限')} />}
          >
            <View>
              {businessFeatures.map(
                ([featureKey, { name, maxLevel }], index) => {
                  const isPermissionExist = businessUserRolePermissions.some(
                    (role) => role.featureKey === featureKey
                  )
                  const targetLevel = isPermissionExist
                    ? getBusinessUserRolePermission(
                        businessUserRolePermissions,
                        featureKey
                      ).level
                    : maxLevel
                  return (
                    <FormGroup
                      key={`${featureKey}`}
                      style={[
                        index > 0 && {
                          marginTop: 28,
                        },
                      ]}
                      mode={width < sm ? 'inline-expanded' : 'inline'}
                      formLabel={<FormLabel mode="secondary" value={name} />}
                    >
                      <RadioGroup
                        mode={width < sm ? 'vertical' : 'inline'}
                        value={targetLevel}
                        onChange={(value) => onSelectLevel(featureKey, value)}
                      >
                        {[
                          {
                            name: t('なし'),
                            level:
                              BusinessUserRolePermissionResource_Level.NONE,
                          },
                          {
                            name: t('閲覧'),
                            level:
                              BusinessUserRolePermissionResource_Level.READ,
                          },
                          {
                            name: t('閲覧・編集'),
                            level: BusinessUserRolePermissionResource_Level.ALL,
                          },
                        ]
                          .filter(
                            ({ level }) =>
                              compareBusinessUserRolePermissionLevels(
                                level,
                                maxLevel
                              ) <= 0
                          )
                          .map(({ name, level }) => (
                            <Radio
                              radioLabel={<RadioLabel value={name} />}
                              key={level}
                              value={level}
                            />
                          ))}
                      </RadioGroup>
                    </FormGroup>
                  )
                }
              )}
            </View>
          </FormGroup>
        </ShadowBox>
      </ScrollView>
      <View
        style={{
          backgroundColor: Colors.white,
          alignItems: 'center',
          paddingVertical: 12,
        }}
      >
        <Button
          style={{
            width: FORM_BUTTON_WITH,
            height: FORM_BUTTON_HEIGHT,
          }}
          disabled={!validate({ name })}
          onPress={onSubmit}
        >
          {businessUserRoleId === undefined ? t('追加する') : t('更新する')}
        </Button>
      </View>
    </View>
  )
}
