import React from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import { BlobProvider } from '@react-pdf/renderer'
import { Linking, ScrollView, View } from 'react-native'
import { useNavigate } from 'react-router'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Grid } from '@hello-ai/ar_shared/src/components/Grid'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { TableSeat } from '@hello-ai/for_r_app/src/components/TableSeats/TableSeat'
import { TableSeatResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

import TableSeatQrDocument from 'components/Restaurant/TableSeatQrDocument'
import { useRestaurant } from 'models/Restaurant'
import { useTableSeats } from 'models/TableSeat'
import { useRestaurantId } from 'modules/useRestaurantId'

function SettingButton({
  toUrl,
  title,
  text,
  isRight = false,
}: {
  toUrl: string
  title: string
  text: string
  isRight?: boolean
}) {
  const { width, sm } = useResponsive()
  const navigate = useNavigate()
  const marginTop = isRight ? (width < sm ? 16 : 0) : 0
  const marginLeft = isRight ? (width < sm ? 0 : 16) : 0
  return (
    <TouchableOpacity
      onPress={() => navigate(toUrl)}
      style={{
        marginTop,
        marginLeft,
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        backgroundColor: 'white',
        flex: 1,
        borderRadius: 8,
        shadowRadius: 4,
        elevation: 4,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        padding: 24,
      }}
    >
      <View>
        <Text style={{ fontSize: 18, fontWeight: '600' }}>{title}</Text>
        <Text
          style={{
            marginTop: 10,
            fontSize: width < sm ? 12 : 14,
            color: Colors.secondaryBlack,
          }}
        >
          {text}
        </Text>
      </View>
      <View
        style={{
          marginLeft: 8,
        }}
      >
        <FontAwesomeIcon size={14} color={Colors.black} icon={faChevronRight} />
      </View>
    </TouchableOpacity>
  )
}

export default function TableSeats() {
  const navigate = useNavigate()
  const { width, sm, md } = useResponsive()
  const restaurantId = useRestaurantId()
  const { restaurant } = useRestaurant(restaurantId)
  const { tableSeats } = useTableSeats(
    restaurantId,
    TableSeatResource_Status.PUBLISHED
  )
  const numOfColumns = width < sm ? 2 : width < md ? 4 : 6
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          mode="contained"
          variant="secondary"
          height={48}
          roundness={24}
          style={{
            flex: 1,
          }}
          onPress={() => {
            navigate(`/restaurants/${restaurantId}/table_seats/new`)
          }}
        >
          <Text
            style={{
              color: Colors.primary,
              fontWeight: '500',
              fontSize: 14,
            }}
          >
            {t('席を追加')}
          </Text>
        </Button>
        {tableSeats !== undefined && restaurant?.order_available && (
          <BlobProvider
            key="print"
            document={<TableSeatQrDocument tableSeats={tableSeats} />}
          >
            {({ url }) => {
              if (url !== null) {
                return (
                  <Button
                    mode="contained"
                    variant="secondary"
                    height={48}
                    roundness={24}
                    style={{
                      marginLeft: 20,
                      flex: 1,
                    }}
                    onPress={() => {
                      Linking.openURL(url)
                    }}
                  >
                    <Text
                      style={{
                        color: Colors.primary,
                        fontWeight: '500',
                        fontSize: 14,
                      }}
                    >
                      {t('QRコードを印刷')}
                    </Text>
                  </Button>
                )
              } else {
                return null
              }
            }}
          </BlobProvider>
        )}
      </View>
      {tableSeats !== undefined && (
        <>
          <View
            style={[
              width >= sm && {
                flexDirection: 'row',
              },
              { marginTop: 24 },
            ]}
          >
            <SettingButton
              toUrl={`/restaurants/${restaurantId}/table_seats/table_seat_charge_policies`}
              title={t('席チャージを設定')}
              text={t('席チャージのパターンを作成することができます')}
            />
            <SettingButton
              isRight
              toUrl={`/restaurants/${restaurantId}/table_seats/table_seat_connections`}
              title={t('接続パターンを設定')}
              text={t('ネット予約のための席の接続パターンを作成できます')}
            />
          </View>
          <Grid
            numOfColumns={numOfColumns}
            style={{ marginTop: 32 }}
            horizontalGap={20}
            verticalGap={20}
          >
            {tableSeats.map((tableSeat) => (
              <TableSeat
                key={tableSeat.id}
                tableSeat={tableSeat}
                onPress={() =>
                  navigate(
                    `/restaurants/${restaurantId}/table_seats/${tableSeat.id}/edit`
                  )
                }
              />
            ))}
          </Grid>
        </>
      )}
    </ScrollView>
  )
}
