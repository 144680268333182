import { StyleSheet } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export const createStyles = (isDesktop: boolean) =>
  StyleSheet.create({
    container: {
      marginHorizontal: isDesktop ? 16 : 0,
      flexDirection: 'row',
      alignItems: isDesktop ? 'center' : 'stretch',
      backgroundColor: Colors.white,
      borderTopLeftRadius: isDesktop ? 4 : 0,
      borderBottomLeftRadius: isDesktop ? 4 : 0,
      borderRadius: isDesktop ? 4 : 0,
      borderWidth: 0.4,
      borderColor: Colors.border,
      borderStyle: 'solid',
    },
    headerWrap: {
      backgroundColor: '#F6F4EE',
      alignItems: 'flex-start',
      borderTopLeftRadius: 4,
      borderBottomLeftRadius: 4,
    },
    header: {
      color: Colors.black,
      display: 'flex',
      flexDirection: 'row',
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 8,
      ...(isDesktop
        ? {
            borderRadius: 4,
            paddingBottom: 8,
            alignItems: 'center',
            justifyContent: 'center',
            borderBottomWidth: 0.4,
            borderBottomColor: Colors.border,
          }
        : {
            height: 34,
            borderRadius: 0,
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 0,
            marginVertical: 0,
            borderVertical: 0,
          }),
    },
    headerText: {
      fontWeight: '600',
      fontSize: 14,
      color: Colors.black,
      marginRight: 4,
    },
    headerCount: {
      fontWeight: '600',
      fontSize: 14,
      color: Colors.black,
      marginRight: 4,
    },
    popoverContent: {
      padding: 16,
      width: isDesktop ? 360 : 304,
      borderRadius: 15,
      shadowColor: '#000000',
      shadowOffset: { width: 0, height: 4 },
      shadowOpacity: 0.15,
      shadowRadius: 16,
      elevation: 5,
    },
    popoverText: {
      fontSize: 14,
      color: Colors.black,
      lineHeight: 21,
    },
    accordionWrap: {
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1,
      paddingLeft: 8,
      paddingRight: 16,
      ...(!isDesktop && { paddingTop: 3, paddingBottom: 0 }),
    },
    itemContainer: {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
    },
    children: {
      flexGrow: 1,
    },
  })
