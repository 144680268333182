import React from 'react'

import { View, Image, Linking } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

export function TakeoutUnavailableView() {
  const { width, md } = useResponsive()
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <View style={{ width: width < md ? '92%' : '80%' }}>
        <Image
          style={{
            width: '100%',
            height: undefined,
            aspectRatio: width < md ? 704 / 908 : 1760 / 1040,
          }}
          resizeMode="cover"
          source={
            width < md
              ? require('../../../assets/images/empty_state_sp.png')
              : require('../../../assets/images/empty_state.png')
          }
        />
        <View
          style={{
            position: 'absolute',
            top: width < md ? 20 : 32,
            left: width < md ? 20 : 32,
            bottom: width < md ? 20 : 32,
            right: width < md ? 20 : 32,
            backgroundColor: 'rgba(255,255,255,0.2)',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Image
            style={{
              width: (2167 / 768) * 45,
              height: 45,
            }}
            source={require('../../../assets/images/logo.png')}
          />
          <Text
            style={{ fontWeight: '600', marginTop: 24, textAlign: 'center' }}
          >
            {t('ご利用には契約が必要です')}
          </Text>
          <Text style={{ marginTop: 12, textAlign: 'center' }}>
            {t('スマートに受け取りできるテイクアウトを利用してみませんか？')}
          </Text>
          <View
            style={[
              { marginTop: 32 },
              width >= md && {
                flexDirection: 'row',
                alignItems: 'center',
              },
            ]}
          >
            <Button
              textStyle={{
                fontSize: 18,
                color: 'white',
              }}
              style={[
                {
                  height: 56,
                  backgroundColor: Colors.primary,
                  paddingHorizontal: 24,
                  marginTop: width < md ? 16 : 0,
                },
              ]}
              onPress={() =>
                Linking.openURL(
                  'https://autoreserve.com/for_restaurants/contact'
                )
              }
            >
              {t('お問い合わせする')}
            </Button>
          </View>
        </View>
      </View>
    </View>
  )
}
