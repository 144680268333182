import React, { useState } from 'react'

import { View, ScrollView } from 'react-native'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import TakeoutProduct from 'components/Restaurant/TakeoutProduct'
import TakeoutView from 'components/Restaurant/TakeoutView'
import { useToken } from 'models/Auth'
import {
  useRestaurantTakeoutProducts,
  updateTakeoutProduct,
} from 'models/TakeoutProduct'
import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TakeoutProductsScreen() {
  const { width, sm } = useResponsive()
  const token = useToken()
  const restaurantId = useRestaurantId()
  const [productsCurrentPage, setProductsCurrentPage] = useState(1)

  const { takeoutProducts, headerData, mutate } = useRestaurantTakeoutProducts({
    restaurantId,
    params: {
      page: productsCurrentPage,
      status: 'archived',
    },
  })

  const isLoading = takeoutProducts == null || headerData == null

  return (
    <TakeoutView>
      <ScrollView
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
      >
        <View style={{ marginTop: 40 }}>
          <ShadowBox
            style={[
              {
                paddingHorizontal: 0,
                paddingVertical: 8,
                marginTop: 24,
              },
            ]}
          >
            {isLoading && (
              <View style={{ padding: 24 }}>
                <Loading />
              </View>
            )}
            {!isLoading &&
              takeoutProducts != null &&
              takeoutProducts?.length > 0 &&
              takeoutProducts.map((takeoutProduct, index) => {
                return (
                  <View
                    key={takeoutProduct.id}
                    style={{
                      borderTopWidth: index === 0 ? 0 : 0.5,
                      borderColor: Colors.border,
                      paddingTop: 16,
                      marginBottom: 16,
                      paddingHorizontal: 24,
                    }}
                  >
                    <TakeoutProduct
                      onChangeStatus={async (takeoutProductId, params) => {
                        if (token == null || headerData == null) {
                          return
                        }
                        await updateTakeoutProduct({
                          token,
                          takeoutProductId,
                          params,
                        })
                        mutate({
                          takeoutProducts: takeoutProducts.filter(
                            (product) => product.id !== takeoutProductId
                          ),
                          headerData,
                        })
                      }}
                      takeoutProduct={takeoutProduct}
                      onPress={() =>
                        history.push(
                          `/restaurants/${restaurantId}/takeout/products/${takeoutProduct.id}/edit`
                        )
                      }
                    />
                  </View>
                )
              })}
            {!isLoading &&
              takeoutProducts != null &&
              takeoutProducts.length === 0 && (
                <Text
                  style={{
                    paddingTop: 16,
                    marginBottom: 24,
                    paddingHorizontal: 24,
                    fontSize: 22,
                    fontWeight: '600',
                    color: Colors.disabledBlack,
                    textAlign: 'center',
                  }}
                >
                  {t('該当する商品はありません')}
                </Text>
              )}
            <View
              style={{
                borderTopWidth: 0.5,
                borderColor: Colors.border,
                paddingTop: 24,
                marginBottom: 16,
              }}
            >
              <Pagination
                currentPage={productsCurrentPage}
                totalPage={headerData?.totalPages ?? 0}
                setPage={(page) => setProductsCurrentPage(page)}
              />
            </View>
          </ShadowBox>
        </View>
      </ScrollView>
    </TakeoutView>
  )
}
