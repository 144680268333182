import type React from 'react'
import { useEffect } from 'react'

import { faArrowsUpDown } from '@fortawesome/pro-solid-svg-icons/faArrowsUpDown'
import { faCircleExclamation } from '@fortawesome/pro-solid-svg-icons/faCircleExclamation'
import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare'
import { faUtensils } from '@fortawesome/pro-solid-svg-icons/faUtensils'
import { Image } from 'expo-image'
import { View, Platform } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import {
  isSiteControllerReservation,
  type RestaurantReservation as RestaurantReservationModel,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import { getTagIcon } from '../../../models/ReservationTag'
import { useIsRestaurantSmartPaymentAvailable } from '../../../models/Restaurant'
import { getFormatTime, toSeconds } from '../../../modules/time'
import { calculateVisitStatusComponentProps } from '../../Restaurant/Reservation/ListView/VisitStatusTag'

import {
  CELL_HEIGHT,
  CELL_PADDING_Y,
  SEAT_PC_HEIGHT,
  SEAT_SP_HEIGHT,
  MIN_DURATION,
} from './const'
import {
  ifScrollToTop,
  getCellWidth,
  shouldDisplayEllipsis,
  calculateIconMaxWidth,
  calculateIconCount,
  getCellHeight,
  getCellX,
  getCellY,
  ifScrollToBottom,
  setIntervalByRequestAnimationFrame,
  getReservationText,
} from './utils'

import type { ViewStyle } from 'react-native'
import type { ReanimatedScrollEvent } from 'react-native-reanimated/lib/typescript/hook/commonTypes'

/**
 * 「連続している席」を1つの予約カードでまとめて表示するときの高さを計算する
 *
 * @param consecutiveCount - 連続している行数
 * @param isSp - スマホサイズかどうか
 */
function getReservationCardHeight(
  consecutiveCount: number,
  isSp: boolean
): number {
  const baseHeight = getCellHeight(isSp)
  if (consecutiveCount === 1) {
    return baseHeight
  }

  // 1つのセルの上下のPaddingを足した値
  const oneCellPadding = CELL_PADDING_Y * 2

  // カードが連続した分だけベースの高さを足す。paddingも連続した分だけ足すが、1カード分は不要なので、-1する
  const totalCellHeights =
    baseHeight * consecutiveCount + oneCellPadding * (consecutiveCount - 1)

  return totalCellHeights
}

// TODO: source={require('../../../assets/images/ar_icon.png')} が危険なので絶対パスに変更する
type Props = {
  onPress: ({
    absoluteX,
    absoluteY,
  }: {
    absoluteX: number
    absoluteY: number
  }) => void
  type: 'default' | 'unselectable'
  index: number
  date: dayjs.Dayjs
  restaurantReservation: Omit<
    RestaurantReservationModel,
    | 'id'
    | 'status'
    | 'cancel_fee'
    | 'table_seats'
    | 'restaurant_crew_member'
    | 'reservation'
  >
  restaurantId: number
  onDragEnd?: (offsetY: number) => void
  isWarning?: boolean
  scrollRootRef?: React.RefObject<Animated.ScrollView>
  latestRootScrollViewEvent?: Animated.SharedValue<ReanimatedScrollEvent | null>
  consecutiveCount?: number
}

function ReservationContainer(props: Props) {
  const { width, sm } = useResponsive()
  const isRestaurantSmartPaymentAvailable =
    useIsRestaurantSmartPaymentAvailable(props.restaurantId)

  return (
    <ReservationPresentational
      {...props}
      width={width}
      sm={sm}
      isRestaurantSmartPaymentAvailable={isRestaurantSmartPaymentAvailable}
    />
  )
}

type PresentationalProps = Omit<Props, 'restaurantId'> & {
  width: number
  sm: number
  isRestaurantSmartPaymentAvailable: boolean
}

export function ReservationPresentational({
  onPress,
  type = 'default',
  index,
  restaurantReservation,
  onDragEnd,
  date,
  isWarning = false,
  scrollRootRef,
  latestRootScrollViewEvent,
  consecutiveCount = 1,
  width,
  sm,
  isRestaurantSmartPaymentAvailable,
}: PresentationalProps) {
  const startAt = dayjs(restaurantReservation.start_at)
  const dayDiff = startAt.diff(date, 'day')
  const startTime = toSeconds(dayDiff * 24 + startAt.hour(), startAt.minute())
  const endAt = dayjs(restaurantReservation.end_at)

  const hours = endAt.diff(startAt, 'minute') / 60
  const text = getReservationText(restaurantReservation)
  // 高さは連続数によって変化
  const height = getReservationCardHeight(consecutiveCount, width < sm)

  // 25時などの場合は 1day ずれる
  const x = getCellX(
    dayDiff * 24 + startAt.hour() + startAt.minute() / 60,
    width < sm
  )
  const startY = useSharedValue(getCellY(index, width < sm))
  const offsetY = useSharedValue(getCellY(index, width < sm))
  const onStartScrollViewOffsetY = useSharedValue(0)

  const pressGesture = Gesture.Tap()
    .runOnJS(true)
    .onEnd((e, success) => {
      if (success) {
        onPress(e)
      }
    })
  pressGesture.enabled(type !== 'unselectable')
  const isDragging = useSharedValue(false)
  let cancelInterval: (() => void) | null = null
  const panGesture = Gesture.Pan()
    //  予約チップのrerender（再フェッチなど）とGestureのactive状態が同時に発生すると画面が固まるため、jsスレッド上で動かす
    .runOnJS(true)
    .enabled(type === 'default')
    .activateAfterLongPress(100)
    .onStart(() => {
      // TODO: react-compiler for react-native-reanimated
      // eslint-disable-next-line react-compiler/react-compiler
      isDragging.value = true
      onStartScrollViewOffsetY.value =
        latestRootScrollViewEvent?.value?.contentOffset.y ?? 0
    })
    .onUpdate((e) => {
      if (!isDragging.value) return
      const absoluteY = e.absoluteY
      const rootScrollViewOffsetYValue =
        latestRootScrollViewEvent?.value?.contentOffset.y ?? 0
      let bias = 0
      const isScrollToTop = ifScrollToTop(latestRootScrollViewEvent?.value)
      const isCloseToBottom = ifScrollToBottom(latestRootScrollViewEvent?.value)
      if (!isScrollToTop && absoluteY < 140) {
        bias = -10
      } else if (!isCloseToBottom && absoluteY > 700) {
        bias = 10
      }
      if (cancelInterval != null) cancelInterval()
      offsetY.value =
        startY.value +
        e.translationY +
        rootScrollViewOffsetYValue -
        onStartScrollViewOffsetY.value
      if (bias !== 0) {
        let loopBias = bias
        let counter = 0
        cancelInterval = setIntervalByRequestAnimationFrame(() => {
          scrollRootRef?.current?.scrollTo({
            y: rootScrollViewOffsetYValue + loopBias,
            animated: false,
          })
          offsetY.value =
            startY.value +
            e.translationY +
            rootScrollViewOffsetYValue -
            onStartScrollViewOffsetY.value +
            loopBias

          counter += 1
          loopBias += (bias / 2) * counter
        }, 10)
      }
    })
    .onEnd(() => {
      startY.value = offsetY.value
      if (onDragEnd) {
        const nextIndex = Math.round(
          (offsetY.value - CELL_HEIGHT - CELL_PADDING_Y) /
            (width < sm ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT)
        )
        onDragEnd(nextIndex)
        // NOTE: 予約が被った際にAlertが表示されるとsuccess=falseになり LongPress().onEndでリセットされないケースがあるため、ここでもリセットしておく
        isDragging.value = false
      }
    })

  const dragGesture = Gesture.Simultaneous(panGesture)
  const gestures = Gesture.Race(pressGesture, dragGesture)

  const dragAnimatedStyle = useAnimatedStyle((): ViewStyle => {
    const translateY = offsetY.value
    const cursor = isDragging.value ? 'grabbing' : 'grab'
    const isWeb = Platform.OS === 'web'
    return {
      transform: [{ translateX: x }, { translateY }],
      // ViewStyleがcursorのgrabbing/grabをサポートしていないため、@ts-ignoreで無理やり設定
      // @ts-ignore
      cursor: isWeb ? cursor : undefined,
    }
  }, [x, offsetY, isDragging])
  const draggingOpacityStyle = useAnimatedStyle(() => ({
    opacity: isDragging.value ? 0.5 : 1,
  }))
  const draggingIconStyle = useAnimatedStyle(() => ({
    display: isDragging.value ? 'flex' : 'none',
  }))
  const draggingFrontStyle = useAnimatedStyle(() => ({
    zIndex: isDragging.value ? 10 : 5,
  }))

  const { backgroundColor, textColor } = calculateVisitStatusComponentProps(
    restaurantReservation.visit_status ?? 'reserved'
  )

  const isReservedByAR =
    restaurantReservation.source === 'autoreserve' &&
    restaurantReservation.source_by === 'auto'

  const prevIndex = usePrevious(index)
  // index変更時は位置を更新する
  useEffect(() => {
    if (prevIndex !== index) {
      offsetY.value = getCellY(index, width < sm)
    }
  }, [prevIndex, index, offsetY, sm, width])

  function TimeAndPartySize() {
    return (
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text
          style={{ color: textColor, fontSize: 12, lineHeight: 18 }}
          numberOfLines={1}
        >
          {width >= sm && `${getFormatTime(startTime)} - `}
          {t('{{party_size}}({{adult_party_size}},{{child_party_size}})', {
            party_size: t('{{count}}人', {
              count: restaurantReservation.party_size,
            }),
            adult_party_size: t('大人{{count}}', {
              count: restaurantReservation.adult_party_size,
            }),
            child_party_size: t('子供{{count}}', {
              count: restaurantReservation.child_party_size,
            }),
          })}
        </Text>
      </View>
    )
  }

  function NameAndIcons() {
    const iconCount = calculateIconCount(restaurantReservation, isReservedByAR)
    return (
      <View
        style={[
          {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        ]}
      >
        {/* name */}
        <Text
          style={{
            fontSize: 14,
            fontWeight: '600',
            color: textColor,
            flex: 1,
          }}
          numberOfLines={1}
        >
          {text}
        </Text>
        {/* icons */}
        <View
          style={[
            {
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2,
              flexShrink: 0,
            },
          ]}
        >
          <View
            style={[
              {
                flexDirection: 'row',
                alignItems: 'center',
                gap: 2,
                flexShrink: 0,
                maxWidth: calculateIconMaxWidth(hours, iconCount),
                overflow: 'hidden',
              },
            ]}
          >
            {restaurantReservation.smart_payment != null &&
              isRestaurantSmartPaymentAvailable && (
                <View
                  style={{
                    width: 16,
                    height: 16,
                    backgroundColor: Colors.primary,
                    borderColor: Colors.white,
                    borderWidth: 1,
                    borderRadius: 16,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                      lineHeight: 14,
                      fontWeight: '600',
                      color: Colors.white,
                    }}
                  >
                    {t('ス')}
                  </Text>
                </View>
              )}
            {!!restaurantReservation.memo && (
              <View style={{ width: 16 }}>
                <FontAwesomeIcon
                  style={{ marginTop: 2 }}
                  icon={faPenToSquare}
                  size={16}
                  color={'white'}
                />
              </View>
            )}
            {isSiteControllerReservation(restaurantReservation) && (
              <View
                style={{
                  backgroundColor: '#FAF9F5',
                  borderRadius: 8,
                  paddingHorizontal: 6,
                  padding: 2,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    lineHeight: 18,
                    fontWeight: '300',
                    color: Colors.primary,
                  }}
                >
                  {
                    GOURMET_SITE_PROVIDER_SOURCE[
                      restaurantReservation
                        .reservation_site_controller_parsed_course
                        .site_controller_parsed_course.site
                    ].label
                  }
                </Text>
              </View>
            )}
            {isReservedByAR && (
              <Image
                style={{
                  height: 16,
                  width: 16,
                }}
                source={require('../../../assets/images/ar_icon.png')}
              />
            )}
            {restaurantReservation.reservation_courses !== undefined &&
              restaurantReservation.reservation_courses.length > 0 && (
                <View style={{ width: 16 }}>
                  <FontAwesomeIcon
                    icon={faUtensils}
                    size={16}
                    color={Colors.white}
                  />
                </View>
              )}
            {restaurantReservation.reservation_tags?.map((tag) => {
              const icon = getTagIcon(tag.name)
              if (icon === undefined) return null
              return (
                <View key={tag.id} style={{ width: 16 }}>
                  <FontAwesomeIcon icon={icon} size={16} color={Colors.white} />
                </View>
              )
            })}
          </View>
          {shouldDisplayEllipsis(hours, iconCount) && (
            <Text
              style={{
                color: Colors.white,
                fontSize: 10,
                fontWeight: '600',
              }}
            >
              ･･･
            </Text>
          )}
        </View>
      </View>
    )
  }

  return (
    <GestureDetector gesture={gestures}>
      <Animated.View
        style={[
          {
            backgroundColor,
            position: 'absolute',
            borderRadius: 4,
            paddingVertical: 4,
            paddingHorizontal: 8,
            width: getCellWidth(hours, width < sm),
            height,
          },
          dragAnimatedStyle,
          draggingFrontStyle,
          isWarning && {
            opacity: 0.4,
          },
        ]}
      >
        <View
          style={{
            top: 2,
            right: 2,
            position: 'absolute',
            flexDirection: 'row',
            gap: 2,
          }}
        >
          {hours > MIN_DURATION && (
            <>
              {isWarning && (
                <FontAwesomeIcon
                  icon={faCircleExclamation}
                  size={16}
                  color={Colors.caution}
                />
              )}
              {restaurantReservation.has_previous_table_order && (
                <FontAwesomeIcon
                  icon={faHistory}
                  size={16}
                  color={Colors.white}
                />
              )}
            </>
          )}
        </View>
        <Animated.View
          style={[
            {
              flexDirection: width < sm ? 'column-reverse' : 'column',
              justifyContent: 'center',
              position: 'relative',
            },
            draggingOpacityStyle,
          ]}
        >
          {hours > MIN_DURATION && (
            <>
              <TimeAndPartySize />
              <NameAndIcons />
            </>
          )}
        </Animated.View>
        <Animated.View
          style={[
            {
              width: 32,
              height: 32,
              borderRadius: 32,
              position: 'absolute',
              top: (height - 32) / 2,
              right: -16,
              alignItems: 'center',
              justifyContent: 'center',
              borderColor: backgroundColor,
              borderWidth: 2,
              backgroundColor: 'white',
            },
            draggingIconStyle,
          ]}
        >
          <FontAwesomeIcon icon={faArrowsUpDown} color={backgroundColor} />
        </Animated.View>
      </Animated.View>
    </GestureDetector>
  )
}

export { ReservationContainer as Reservation }
