import React from 'react'

import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { flatten, range } from 'lodash'
import { ScrollView, View } from 'react-native'
import { useNavigate, useParams } from 'react-router'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Grid } from '@hello-ai/ar_shared/src/components/Grid'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  displayToastError,
  displayToastInfo,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { TableSeatResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

import { useToken } from 'models/Auth'
import { useRestaurantTableMenus } from 'models/TableMenu'
import { useTableSeats } from 'models/TableSeat'
import {
  getChargeUnitName,
  tableSeatChargePolicyClient,
  useTableSeatChargePolicies,
  useTableSeatChargePolicy,
} from 'models/TableSeatChargePolicy'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'
import { useRestaurantId } from 'modules/useRestaurantId'

type ChargeIntervalType = 'use' | 'time'

const chargeIntervalTypes: Array<{
  value: ChargeIntervalType
  label: string
}> = [
  { value: 'use', label: t('利用ごと') },
  { value: 'time', label: t('時間ごと') },
]

export default function TableSeatChargePolicyForm() {
  const restaurantId = useRestaurantId()
  const token = useToken()
  const navigate = useNavigate()

  const { tableSeatChargePolicyId } = useParams<{
    tableSeatChargePolicyId?: string
  }>()

  const params =
    tableSeatChargePolicyId === undefined
      ? undefined
      : {
          restaurantId,
          id: tableSeatChargePolicyId,
        }
  const { tableSeatChargePolicy, mutate } = useTableSeatChargePolicy(params)
  const { tableMenus } = useRestaurantTableMenus({ restaurantId })
  const { tableSeats } = useTableSeats(
    restaurantId,
    TableSeatResource_Status.PUBLISHED
  )
  const { tableSeatChargePolicies } = useTableSeatChargePolicies(restaurantId)

  const [selectedChargeIntervalType, setSelectedChargeIntervalType] =
    useFormState(
      tableSeatChargePolicy?.chargeInterval !== undefined ? 'time' : 'use'
    )
  const [chargeInterval, setChargeInterval] = useFormState(
    tableSeatChargePolicy?.chargeInterval !== undefined
      ? `${tableSeatChargePolicy.chargeInterval.value / 60}`
      : ''
  )
  const [chargeUnit, setChargeUnit] = useFormState(
    tableSeatChargePolicy?.chargeUnit ?? 0
  )
  const [taxIncluded, setTaxIncluded] = useFormState(
    tableSeatChargePolicy?.taxIncluded ?? true
  )
  const [price, setPrice] = useFormState(
    tableSeatChargePolicy?.price?.toString() ?? ''
  )
  const [selectedTableMenuIds, setSelectedTableMenuIds] = useFormState(
    tableSeatChargePolicy?.tableMenus.map((tableMenu) => tableMenu.id) ?? []
  )
  const tableSeatIds =
    tableSeatChargePolicy?.tableSeats.map((tableSeat) => tableSeat.id) ?? []
  const [selectedTableSeatIds, setSelectedTableSeatIds] =
    useFormState(tableSeatIds)
  const usedSeatIdsGroupedByMenuId =
    tableSeatChargePolicies
      ?.filter(
        (tableSeatChargePolicy) =>
          tableSeatChargePolicyId !== tableSeatChargePolicy.id
      )
      ?.reduce((result: { [key: string]: string[] }, policy) => {
        policy.tableMenus.forEach((tableMenu) => {
          result[tableMenu.id] = [
            ...(result[tableMenu.id] ?? []),
            ...policy.tableSeats.map((seat) => seat.id),
          ]
        })
        return result
      }, {}) ?? {}
  const usedSeatIdsBySelectedTableMenu = flatten(
    selectedTableMenuIds?.map(
      (menuId) => usedSeatIdsGroupedByMenuId[menuId] ?? []
    )
  )
  const toggleSelectedTableSeatIds = (tableSeatId: string) => {
    if (selectedTableSeatIds.includes(tableSeatId)) {
      setSelectedTableSeatIds(
        selectedTableSeatIds.filter(
          (selectedTableSeatId) => selectedTableSeatId !== tableSeatId
        )
      )
    } else {
      setSelectedTableSeatIds([...selectedTableSeatIds, tableSeatId])
    }
  }
  const isUsedMenuSeatCombinationExist = () =>
    selectedTableSeatIds.some((selectedTableSeatId) =>
      usedSeatIdsBySelectedTableMenu.includes(selectedTableSeatId)
    )

  const formParams = {
    chargeInterval:
      selectedChargeIntervalType === 'use'
        ? undefined
        : {
            value: parseInt(chargeInterval) * 60,
          },
    chargeUnit,
    price: parseInt(price),
    taxIncluded,
    taxType: 0,
    tableSeatIds: selectedTableSeatIds,
    tableMenuIds: selectedTableMenuIds,
  }

  const onCreate = async () => {
    if (isUsedMenuSeatCombinationExist()) {
      displayToastError(
        t('すでに指定したメニューと席を利用した席チャージが存在します')
      )
      return
    }

    const { error } = await callRpc(
      tableSeatChargePolicyClient.create(
        {
          restaurantId,
          ...formParams,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }
    navigate(
      `/restaurants/${restaurantId}/table_seats/table_seat_charge_policies`
    )
    displayToastInfo(t('追加しました'))
  }

  const onUpdate = async () => {
    if (isUsedMenuSeatCombinationExist()) {
      displayToastError(
        t('すでに指定したメニューと席を利用した席チャージが存在します')
      )
      return
    }

    if (tableSeatChargePolicyId === undefined) return
    const { error } = await callRpc(
      tableSeatChargePolicyClient.update(
        {
          restaurantId,
          id: tableSeatChargePolicyId,
          ...formParams,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }
    navigate(
      `/restaurants/${restaurantId}/table_seats/table_seat_charge_policies`
    )
    displayToastInfo(t('更新しました'))
    mutate()
  }

  const onArchive = async () => {
    if (tableSeatChargePolicyId === undefined) return
    const { error } = await callRpc(
      tableSeatChargePolicyClient.archive(
        {
          restaurantId,
          id: tableSeatChargePolicyId,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }
    navigate(
      `/restaurants/${restaurantId}/table_seats/table_seat_charge_policies`
    )
    displayToastInfo(t('アーカイブしました'))
  }

  const onAlertArchive = () => {
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          onArchive()
        },
      },
    ])
  }

  const disabled =
    (selectedChargeIntervalType === 'time' && chargeInterval === '') ||
    price === '' ||
    selectedTableMenuIds.length === 0

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: 'white' }}
      contentContainerStyle={{
        padding: 40,
      }}
    >
      <FormGroup formLabel={<FormLabel value={t('時間')} required />}>
        <RadioGroup
          value={selectedChargeIntervalType}
          onChange={setSelectedChargeIntervalType}
        >
          {chargeIntervalTypes.map((chargeIntervalType) => (
            <Radio
              key={chargeIntervalType.value}
              value={chargeIntervalType.value}
              radioLabel={
                <RadioLabel
                  style={[
                    chargeIntervalType.value === selectedChargeIntervalType && {
                      fontWeight: '600',
                    },
                  ]}
                  value={chargeIntervalType.label}
                />
              }
            />
          ))}
        </RadioGroup>
        <TextInput
          value={chargeInterval}
          onChangeText={setChargeInterval}
          autoCapitalize="none"
          keyboardType="number-pad"
          placeholder={t('時間（分）')}
          disabled={selectedChargeIntervalType === 'use'}
          style={{ marginTop: 16 }}
        />
      </FormGroup>
      <FormGroup
        formLabel={<FormLabel value={t('価格')} required />}
        style={{ marginTop: 32 }}
      >
        <RadioGroup value={chargeUnit} onChange={setChargeUnit}>
          {range(2).map((i) => (
            <Radio
              key={i}
              value={i}
              radioLabel={
                <RadioLabel
                  style={[
                    i === chargeUnit && {
                      fontWeight: '600',
                    },
                  ]}
                  value={getChargeUnitName(i)}
                />
              }
            />
          ))}
        </RadioGroup>
        <SegmentedControl
          buttons={[
            {
              label: t('税別（10％）'),
              value: false,
            },
            { label: t('税込（10％）'), value: true },
          ].map(({ label, value }) => {
            return {
              text: label,
              selected: value === taxIncluded,
              onPress: () => setTaxIncluded(value),
            }
          })}
          style={{ marginTop: 16 }}
        />
        <TextInput
          value={price}
          onChangeText={setPrice}
          autoCapitalize="none"
          keyboardType="number-pad"
          placeholder={t('価格（円）')}
          style={{ marginTop: 16 }}
        />
      </FormGroup>
      <FormGroup
        formLabel={<FormLabel value={t('適用するメニュー')} />}
        style={{ marginTop: 32 }}
      >
        <CheckboxGroup
          value={selectedTableMenuIds}
          onChange={setSelectedTableMenuIds}
        >
          {tableMenus?.map((tableMenu) => {
            return (
              <Checkbox
                key={tableMenu.id}
                value={tableMenu.id}
                checkboxLabel={<CheckboxLabel value={tableMenu.name} />}
              />
            )
          })}
        </CheckboxGroup>
        <Text
          style={{
            marginTop: 12,
            color: Colors.secondaryBlack,
          }}
        >
          {t(
            'メニューの提供時間中にのみ席チャージ料金が発生するようにできます'
          )}
        </Text>
      </FormGroup>
      <FormGroup
        formLabel={<FormLabel value={t('適用する座席')} />}
        style={{ marginTop: 32 }}
      >
        <Text
          style={{
            marginBottom: 12,
            color: Colors.secondaryBlack,
          }}
        >
          {t('※適用するメニューが被っている席は選択することができません')}
        </Text>
        <Grid numOfColumns={4} horizontalGap={16} verticalGap={16}>
          {tableSeats?.map((tableSeat) => {
            const selected: boolean = selectedTableSeatIds.includes(
              tableSeat.id
            )
            const disabled: boolean = usedSeatIdsBySelectedTableMenu.includes(
              tableSeat.id
            )
            return (
              <TouchableOpacity
                key={tableSeat.id}
                disabled={disabled}
                style={{
                  borderWidth: 1,
                  opacity: disabled ? 0.4 : 1,
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  borderColor: selected ? Colors.accent : Colors.border,
                  borderRadius: 8,
                  padding: 16,
                }}
                onPress={() => toggleSelectedTableSeatIds(tableSeat.id)}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    flex: 1,
                    paddingRight: 10,
                  }}
                  numberOfLines={2}
                >
                  {tableSeat.name}
                </Text>
                {disabled && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    color={Colors.black}
                    size={16}
                  />
                )}
              </TouchableOpacity>
            )
          })}
        </Grid>
      </FormGroup>
      <View style={{ flexDirection: 'row', marginTop: 40 }}>
        {tableSeatChargePolicyId !== undefined && (
          <Button
            mode="outline"
            variant="danger"
            height={48}
            style={{ flex: 1 }}
            onPress={onAlertArchive}
          >
            <Text style={{ color: Colors.caution }}>{t('アーカイブする')}</Text>
          </Button>
        )}
        <Button
          mode="contained"
          variant="primary"
          height={48}
          style={{
            flex: 1,
            marginLeft: 32,
          }}
          onPress={tableSeatChargePolicyId !== undefined ? onUpdate : onCreate}
          disabled={disabled}
        >
          <Text
            style={{
              fontWeight: '600',
              color: 'white',
            }}
          >
            {tableSeatChargePolicyId !== undefined
              ? t('更新する')
              : t('追加する')}
          </Text>
        </Button>
      </View>
    </ScrollView>
  )
}
