import React from 'react'

import { useNavigate, useParams } from 'react-router'

import GourmetSiteSeatSettingEdit from '@hello-ai/for_r_app/src/components/GourmetSiteSiteSeat/GourmetSiteSeatSettingEdit'
import { displayToastSuccess } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function GourmetSiteSeatSettingEditScreen() {
  const { sharedSeatId } = useParams()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  if (restaurantId == null || sharedSeatId == null) return null

  return (
    <GourmetSiteSeatSettingEdit
      restaurantId={restaurantId}
      sharedSeatId={sharedSeatId}
      displayToastSuccess={displayToastSuccess}
      onSubmitSuccess={() => navigate(-1)}
    />
  )
}
