import React from 'react'

import { ScrollView, View } from 'react-native'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import GroupCautionMessage from 'components/Menus/GroupCautionMessage'
import TableMenu from 'components/Restaurant/TableMenu'
import RoundedActionButton from 'components/Shared/RoundedActionButton'
import { useRestaurantTableMenus } from 'models/TableMenu'
import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableMenusScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()

  const { tableMenus, isLoading } = useRestaurantTableMenus({
    restaurantId,
  })

  const hasGroupManagedItem = (tableMenus ?? []).some((item) => {
    return item.group_managed
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      {hasGroupManagedItem && (
        <View
          style={{
            marginBottom: 40,
          }}
        >
          <GroupCautionMessage />
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <RoundedActionButton
          onPress={() => history.push(`/restaurants/${restaurantId}/menus/new`)}
        >
          {t('メニューを追加')}
        </RoundedActionButton>
      </View>
      {tableMenus && tableMenus.length > 0 && (
        <View style={{ marginTop: 40 }}>
          <ShadowBox
            style={[
              {
                paddingHorizontal: 0,
                paddingVertical: 8,
              },
            ]}
          >
            {tableMenus.map((tableMenu, index) => {
              return (
                <View
                  key={tableMenu.id}
                  style={{
                    borderTopWidth: index === 0 ? 0 : 0.5,
                    borderColor: Colors.border,
                    paddingTop: 16,
                    marginBottom: 16,
                    paddingHorizontal: 24,
                  }}
                >
                  <TableMenu
                    locked={tableMenu.group_managed}
                    tableMenu={tableMenu}
                    onPress={() => {
                      history.push(
                        `/restaurants/${restaurantId}/menus/${tableMenu.id}/edit`
                      )
                    }}
                  />
                </View>
              )
            })}
          </ShadowBox>
        </View>
      )}
    </ScrollView>
  )
}
