import { useMemo } from 'react'

import { flatten } from 'lodash'

import { Customer } from '@hello-ai/ar_shared/src/types/ForR/Customer'

import axios, { wrapResponse } from 'modules/axios'
import { fetcher, swrKey, onError } from 'modules/swr'
import { useStickySWRInfinite } from 'modules/swr/infinite'

import { useToken } from './Auth'

export * from '@hello-ai/ar_shared/src/types/ForR/Customer'

export function useCustomers(
  restaurantId: number,
  params: {
    query?: string
  }
) {
  const token = useToken()

  const { data, size, setSize, error, mutate } = useStickySWRInfinite<
    Customer[]
  >(
    (index, prevData) => {
      if (prevData?.length === 0) return null
      return swrKey(token, `/restaurants/${restaurantId}/customers`, {
        ...params,
        page: index + 1,
      })
    },
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string,
    ]) => fetcher<Customer[]>([token, url, JSON.parse(params)])
  )

  const customers = useMemo(() => data && flatten(data), [data])

  return {
    customers,
    size,
    setSize,
    error,
    mutate,
  }
}

export async function sendMessages(
  restaurantId: number,
  params: {
    body: string
    image?: any
  }
) {
  const { response, error } = await wrapResponse(
    axios.post<{}>(
      `/restaurants/${restaurantId}/customers/send_messages`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}
