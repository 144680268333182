import React from 'react'

import {
  View,
  ActivityIndicator,
  StyleSheet,
  StyleProp,
  ViewStyle,
} from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

interface LoadingProps {
  style?: StyleProp<ViewStyle>
}
const Loading = React.memo(function Loading({ style }: LoadingProps) {
  return (
    <View
      style={StyleSheet.compose(
        { flex: 1, justifyContent: 'center', alignItems: 'center' },
        style
      )}
    >
      <ActivityIndicator size="small" color={Colors.primary} />
    </View>
  )
})

export default Loading
