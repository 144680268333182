import React from 'react'

import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

function GroupLabel({
  label,
  totalCount,
}: {
  label: string
  totalCount: number
}) {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Text
        style={{
          fontWeight: '600',
          fontSize: 22,
          lineHeight: 22,
        }}
      >
        {label}
      </Text>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: Colors.bgBlack,
          borderRadius: 8,
          height: 36,
          minWidth: 36,
        }}
      >
        <Text
          style={{
            fontSize: 18,
            fontWeight: '600',
            color: Colors.black,
          }}
        >
          {totalCount}
        </Text>
      </View>
    </View>
  )
}

export default GroupLabel
