import {
  RestaurantReservation,
  RestaurantReservationParams,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { RestaurantReservationClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation/restaurant_reservation_service.client'

import { createRpcService } from 'modules/rpc'
import useSWR, {
  fetcher,
  mutate,
  onError,
  SWRConfiguration,
  swrKey,
} from 'modules/swr'

import axios, { setHeader, wrapResponse } from '../modules/axios'

import { useToken } from './Auth'
export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

export const restaurantReservationService = createRpcService(
  RestaurantReservationClient
)

type UseRestaurantReservationsParams = {
  date: string
  status?: RestaurantReservation['status'] // default: reserved
  future_only?: boolean
  page?: number
  per_page?: number
}

type WithHeaderData<T> = {
  data: T
  headerData: {
    currentPage: number
    totalPages: number
    totalCount: number
  }
}

async function customFetcher<T, P extends {}>(
  token: string | null,
  url: string,
  params?: P
): Promise<WithHeaderData<T> | null> {
  setHeader({ token })
  const response = await axios.get<T>(url, { params })
  if (response.data != null) {
    const currentPage = response.headers['x-current-page'] ?? '0'
    const totalPages = response.headers['x-total-pages'] ?? '0'
    const totalCount = response.headers['x-total-count'] ?? '0'
    return {
      data: response.data,
      headerData: {
        currentPage: Number(currentPage),
        totalPages: Number(totalPages),
        totalCount: Number(totalCount),
      },
    }
  }
  return null
}

export function useRestaurantReservation(
  restaurantId: number,
  restaurantReservationId: RestaurantReservation['id'] | undefined
) {
  const token = useToken()

  const {
    data: restaurantReservation,
    error,
    mutate,
  } = useSWR<RestaurantReservation, any, ReturnType<typeof swrKey> | false>(
    restaurantReservationId !== undefined &&
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}`
      ),
    fetcher
  )

  return {
    restaurantReservation,
    error,
    mutate,
  }
}

export function useRestaurantReservations(
  restaurantId: number,
  params: UseRestaurantReservationsParams,
  config?: SWRConfiguration<WithHeaderData<RestaurantReservation[]> | null>
) {
  const token = useToken()

  const { data, error, mutate } = useSWR<WithHeaderData<
    RestaurantReservation[]
  > | null>(
    swrKey(
      token,
      `/reservation_book/restaurants/${restaurantId}/reservations`,
      params
    ),
    ([token, url]: [token: string | null, url: string]) =>
      customFetcher<RestaurantReservation[], UseRestaurantReservationsParams>(
        token,
        url,
        params
      ),
    config
  )

  return {
    restaurantReservations: data?.data,
    headerData: data?.headerData,
    error,
    mutate,
  }
}

export async function createRestaurantReservation(
  token: string,
  restaurantId: number,
  params: RestaurantReservationParams
) {
  const { response, error } = await wrapResponse(
    axios.post<RestaurantReservation>(
      `/reservation_book/restaurants/${restaurantId}/reservations`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${response.data.id}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantReservation: response?.data,
    error,
  }
}

export async function updateRestaurantReservation(
  token: string,
  restaurantId: number,
  restaurantReservationId: RestaurantReservation['id'],
  params: Partial<RestaurantReservationParams>
) {
  const { response, error } = await wrapResponse(
    axios.patch<RestaurantReservation>(
      `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${response.data.id}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantReservation: response?.data,
    error,
  }
}

export async function cancelRestaurantReservation(
  token: string,
  restaurantId: number,
  restaurantReservationId: RestaurantReservation['id'],
  params: {
    charge: boolean
  }
) {
  const { response, error } = await wrapResponse(
    axios.post<{}>(
      `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}/cancel`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/reservation_book/restaurants/${restaurantId}/reservations/${restaurantReservationId}`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantReservation: response?.data,
    error,
  }
}
