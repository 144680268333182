import React, { useState } from 'react'

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import classNames from 'classnames'
import { StyleProp, View, ViewStyle } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

function Input({
  focused = false,
  children,
  style,
}: {
  focused?: boolean
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  return (
    <View
      style={[
        {
          backgroundColor: Colors.field,
          borderColor: focused ? Colors.accent : 'transparent',
          borderWidth: 2,
          borderRadius: 8,
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

export function CardNumberInput({
  onChange,
  style,
  className: className_,
}: {
  onChange?: (event: StripeCardNumberElementChangeEvent) => any
  style?: StyleProp<ViewStyle>
  className?: string
}) {
  const [focused, setFocused] = useState(false)
  return (
    <Input focused={focused} style={style}>
      <CardNumberElement
        className={classNames(className_)}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        onChange={onChange}
        options={{
          placeholder: '**** **** **** ****',
          style: {
            base: {
              fontSize: '18px',
              color: Colors.black,
              '::placeholder': {
                color: Colors.black60,
              },
            },
            invalid: {
              color: Colors.caution,
            },
          },
        }}
      />
    </Input>
  )
}
export function CardExpiryInput({
  onChange,
  style,
  className: className_,
}: {
  onChange?: (event: StripeCardExpiryElementChangeEvent) => any
  style?: StyleProp<ViewStyle>
  className?: string
}) {
  const [focused, setFocused] = useState(false)
  return (
    <Input focused={focused} style={style}>
      <CardExpiryElement
        className={classNames(className_)}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        onChange={onChange}
        options={{
          placeholder: 'MM / YY',
          style: {
            base: {
              fontSize: '18px',
              color: Colors.black,
              '::placeholder': {
                color: Colors.black60,
              },
            },
            invalid: {
              color: Colors.caution,
            },
          },
        }}
      />
    </Input>
  )
}

export function CardCvcInput({
  onChange,
  style,
  className: className_,
}: {
  onChange?: (event: StripeCardCvcElementChangeEvent) => any
  style?: StyleProp<ViewStyle>
  className?: string
}) {
  const [focused, setFocused] = useState(false)

  return (
    <Input focused={focused} style={style}>
      <CardCvcElement
        className={classNames(className_)}
        onFocus={() => {
          setFocused(true)
        }}
        onBlur={() => {
          setFocused(false)
        }}
        onChange={onChange}
        options={{
          placeholder: t('CVV'),
          style: {
            base: {
              fontSize: '18px',
              color: Colors.black,
              '::placeholder': {
                color: Colors.black60,
              },
            },
            invalid: {
              color: Colors.caution,
            },
          },
        }}
      />
    </Input>
  )
}
