import React, { useState } from 'react'

import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { ScrollView, View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  CanceledItem,
  ReportRowView,
  Row,
  RowAmount,
  RowName,
  getFormatDate,
} from '@hello-ai/for_r_app/src/components/Reports'
import Loading from '@hello-ai/for_r_app/src/components/Shared/Loading'
import { SelectRestaurantsForm } from '@hello-ai/for_r_app/src/components/Shared/SelectRestaurantsForm'

import { DateSelectInput } from 'components/Shared/DateSelectInput'
import { useOwnerRestaurants } from 'models/Restaurant'

import { ReportType, useRestaurantsReport } from '../../models/Report'

export default function Reports() {
  const { data: restaurants } = useOwnerRestaurants()
  const [reportType, setReportType] = useState<ReportType>('daily')
  const today = dayjs()
  const [selectedValue, setSelectedValue] = useState(today)

  const [restaurantIds, setRestaurantIds] = useFormState<number[]>(
    restaurants?.restaurants.map((r) => r.id) ?? []
  )

  const date = selectedValue.format('YYYY-MM-DD') // iso8601

  const { data: reports, isValidating } = useRestaurantsReport(
    restaurantIds,
    reportType,
    date
  )
  const data = reports ?? []

  return (
    <ScrollView
      style={{
        backgroundColor: Colors.bgBlack,
        paddingTop: 24,
        paddingBottom: 36,
        paddingHorizontal: 48,
      }}
    >
      <SelectRestaurantsForm
        restaurants={restaurants?.restaurants ?? []}
        disabled={isValidating}
        onPressApply={(ids) => setRestaurantIds(ids)}
      />
      <Divider style={{ marginTop: 24 }} />
      <View
        style={{
          marginTop: 24,
          flexDirection: 'row',
          gap: 16,
          alignItems: 'center',
        }}
      >
        <SelectInput
          style={{ flex: 1, backgroundColor: 'white' }}
          containerStyle={{
            height: 48,
            shadowColor: '#000',
            shadowOpacity: 0.1,
            shadowOffset: { width: 0, height: 2 },
            borderRadius: 8,
          }}
          selectedValue={reportType}
          setValue={(value: ReportType) => {
            if (value === 'monthly') {
              setSelectedValue((prev) => {
                return prev.startOf('month')
              })
            }
            if (value === 'daily') {
              setSelectedValue(today)
            }
            setReportType(value)
          }}
          items={[
            { value: 'daily', label: t('日報') },
            { value: 'monthly', label: t('月報') },
          ]}
        />
        <View style={{ flex: 5 }}>
          <DateSelectInput
            // eslint-disable-next-line ar-i18n/require-translation-ja
            format={reportType === 'monthly' ? 'YYYY年M月' : 'YYYY年M月D日'}
            views={reportType === 'monthly' ? ['year', 'month'] : undefined}
            initialDate={selectedValue}
            setDate={setSelectedValue}
          />
        </View>
        <View
          style={{
            flex: 2,
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
            backgroundColor: Colors.white,
            height: 48,
            borderRadius: 8,
            shadowColor: '#000',
            shadowOpacity: 0.1,
            shadowOffset: { width: 0, height: 2 },
          }}
        >
          <TouchableOpacity
            style={{
              width: 44,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() =>
              setSelectedValue(
                selectedValue.subtract(
                  1,
                  reportType === 'daily' ? 'day' : 'month'
                )
              )
            }
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              color={Colors.black60}
              size={24}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setSelectedValue(today)}>
            <Text style={{ fontSize: 14, color: Colors.black }}>
              {reportType === 'daily' ? t('今日') : t('今月')}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              width: 44,
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onPress={() =>
              setSelectedValue(
                selectedValue.add(1, reportType === 'daily' ? 'day' : 'month')
              )
            }
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              color={Colors.black60}
              size={24}
            />
          </TouchableOpacity>
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          marginTop: 28,
          height: 48,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
            }}
          >
            {getFormatDate(selectedValue, reportType)}
          </Text>
        </View>
      </View>
      <ShadowBox
        style={[
          {
            padding: 0,
            overflow: 'hidden',
            marginTop: 24,
          },
        ]}
      >
        {restaurantIds.length === 0 ? null : data[0] == null ||
          data[1] == null ? (
          <View style={{ paddingVertical: 24 }}>
            <Loading />
          </View>
        ) : (
          <>
            {data[0].rows.map((rowItem, index) => (
              <View
                key={rowItem.id}
                style={[
                  index > 0 && {
                    borderTopColor: Colors.border,
                    borderTopWidth: 0.5,
                  },
                ]}
              >
                <ReportRowView item={rowItem} />
              </View>
            ))}
            <View
              style={{
                marginTop: 36,
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
                paddingHorizontal: 32,
                paddingBottom: 32 - 24,
              }}
            >
              <Text
                style={{
                  marginTop: 40,
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                ※{data[1].name}
              </Text>

              {data[1].rows.map((row) => {
                return (
                  <View key={row.id}>
                    <Row style={{ marginTop: 40 }}>
                      <RowName name={row.name} fontSize={18} fontBold />
                      <RowAmount amount={row.amount} fontSize={18} fontBold />
                    </Row>
                    <View
                      style={{
                        marginTop: row.components.length === 0 ? 32 : 0,
                      }}
                    >
                      {row.components.map((component, index) => (
                        <View
                          key={component.id}
                          style={[
                            index === 0 && {
                              marginTop: 24,
                            },
                            index > 0 && {
                              borderTopWidth: 0.5,
                              borderTopColor: Colors.border,
                            },
                          ]}
                        >
                          <CanceledItem
                            reportType={reportType}
                            onPress={() => {
                              // TODO
                            }}
                            component={component}
                          />
                        </View>
                      ))}
                    </View>
                  </View>
                )
              })}
            </View>
          </>
        )}
      </ShadowBox>
    </ScrollView>
  )
}
