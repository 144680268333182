import React, { ComponentProps } from 'react'

import { View, TouchableOpacity } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import dayjs from 'modules/dayjs'

import DatePicker from './DatePicker'

export function DateSelectInput({
  initialDate,
  setDate,
  views = ['year', 'month', 'day'],
  // eslint-disable-next-line ar-i18n/require-translation-ja
  format = 'YYYY年M月D日',
  disableDayAfterToday = false,
}: {
  initialDate: dayjs.Dayjs | null
  views?: ComponentProps<typeof DatePicker>['views']
  format?: string
  setDate: (date: dayjs.Dayjs) => void
  /**
   * @default false
   */
  disableDayAfterToday?: boolean
}) {
  const { width, sm } = useResponsive()
  const onSubmit = (newDay: dayjs.Dayjs) => {
    setDate(newDay)
  }

  return (
    <View
      style={{
        position: 'relative',
        flex: 1,
      }}
    >
      <DatePicker
        triggerElement={({ onPress }) => (
          <TouchableOpacity
            onPress={onPress}
            style={{
              borderRadius: 8,
              elevation: 4,
              shadowColor: '#000',
              shadowOpacity: 0.1,
              shadowOffset: { width: 0, height: 2 },
              backgroundColor: 'white',
              paddingVertical: 12,
              paddingHorizontal: width < sm ? 12 : 16,
              flex: 1,
            }}
          >
            <Text
              style={[
                {
                  textAlign: 'center',
                },
                width < sm && {
                  fontSize: 16,
                },
              ]}
            >
              {initialDate == null
                ? t('日付を選択してください')
                : initialDate.format(format)}
            </Text>
          </TouchableOpacity>
        )}
        format={format}
        minDate={dayjs().year(-100)}
        maxDate={disableDayAfterToday ? dayjs() : undefined}
        value={initialDate}
        views={views}
        onChange={(d) => {
          if (!d) {
            return
          }
          onSubmit(dayjs(d.toDate()))
        }}
        slotProps={{
          dialog: {
            style: {
              zIndex: 10000,
            },
          },
        }}
      />
    </View>
  )
}
