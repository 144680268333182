import React from 'react'

import Svg, { Path } from 'react-native-svg'

export default function SmartPayIcon({ color }: { color: string }) {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none">
      <Path
        d="M10.9166 1.33334H5.08331C4.39321 1.33334 3.83331 1.89323 3.83331 2.58334V13.4167C3.83331 14.1068 4.39321 14.6667 5.08331 14.6667H10.9166C11.6068 14.6667 12.1666 14.1068 12.1666 13.4167V2.58334C12.1666 1.89323 11.6068 1.33334 10.9166 1.33334ZM7.99998 13.8333C7.53904 13.8333 7.16665 13.4609 7.16665 13C7.16665 12.5391 7.53904 12.1667 7.99998 12.1667C8.46092 12.1667 8.83331 12.5391 8.83331 13C8.83331 13.4609 8.46092 13.8333 7.99998 13.8333ZM10.9166 11.0208C10.9166 11.1927 10.776 11.3333 10.6041 11.3333H5.39581C5.22394 11.3333 5.08331 11.1927 5.08331 11.0208V2.89584C5.08331 2.72396 5.22394 2.58334 5.39581 2.58334H10.6041C10.776 2.58334 10.9166 2.72396 10.9166 2.89584V11.0208Z"
        fill={color}
      />
      <Path
        d="M8.00004 4.66666C9.10539 4.66666 10.0001 5.56119 10.0001 6.66671C10.0001 7.77205 9.10556 8.66675 8.00004 8.66675C6.8947 8.66675 6 7.77222 6 6.66671C6 5.56136 6.89453 4.66666 8.00004 4.66666ZM9.40211 5.96936L9.17675 5.74218C9.13008 5.69513 9.05409 5.69482 9.00704 5.7415L7.59349 7.14369L6.99556 6.5409C6.94889 6.49385 6.87291 6.49354 6.82586 6.54021L6.59866 6.76558C6.55161 6.81225 6.5513 6.88823 6.59798 6.93529L7.50581 7.85047C7.55248 7.89752 7.62846 7.89783 7.67552 7.85115L9.40144 6.13908C9.44848 6.09239 9.44878 6.01641 9.40211 5.96936Z"
        fill={color}
      />
    </Svg>
  )
}
