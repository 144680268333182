import React from 'react'

import { StyleProp, View, ViewStyle } from 'react-native'

import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { TableMenuPage } from 'models/TableMenuPage'

function TableMenuPageParentCheckBox({
  tableMenuPage,
  selectedTableMenuPageIds,
  onToggle,
}: {
  tableMenuPage: TableMenuPage
  selectedTableMenuPageIds: string[]
  onToggle: (tableMenuPageId: string) => void
}) {
  const { width, sm } = useResponsive()
  const childrenTableMenuPageIds = tableMenuPage.table_menu_pages
    ? tableMenuPage?.table_menu_pages.map((childPage) => childPage.id)
    : []

  const isParentChecked = selectedTableMenuPageIds.includes(tableMenuPage.id)
  const hasCheckedChildren = childrenTableMenuPageIds.some(
    (childTableMenuPageId) =>
      selectedTableMenuPageIds.includes(childTableMenuPageId)
  )

  // 親か子供どちらかがチェックされていたら親はcheckedで表示する
  const isParentCheckedForView = isParentChecked || hasCheckedChildren

  const prevIsParentCheckedForView = usePrevious(isParentCheckedForView)

  return (
    <View
      style={{
        marginTop: 20,
      }}
    >
      <Checkbox
        checked={isParentCheckedForView}
        onChange={(value) => {
          if (prevIsParentCheckedForView !== value) {
            onToggle(tableMenuPage.id)
          }
        }}
        checkboxLabel={<CheckboxLabel value={tableMenuPage.name} />}
      />
      <View
        style={{
          marginTop: 10,
          marginLeft: 16,
        }}
      >
        <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
          {tableMenuPage?.table_menu_pages &&
            tableMenuPage.table_menu_pages.map((childTableMenuPage) => {
              const checked = selectedTableMenuPageIds.includes(
                childTableMenuPage.id
              )
              return (
                <View key={childTableMenuPage.id} style={{}}>
                  <TableMenuPageCheckbox
                    tableMenuPage={childTableMenuPage}
                    checked={checked}
                    disabled={!isParentCheckedForView}
                    onToggle={() => onToggle(childTableMenuPage.id)}
                  />
                </View>
              )
            })}
        </Wrap>
      </View>
    </View>
  )
}

function TableMenuPageCheckbox({
  tableMenuPage,
  checked,
  onToggle,
  disabled = false,
}: {
  tableMenuPage: TableMenuPage
  checked: boolean
  onToggle: () => void
  disabled?: boolean
}) {
  const prevChecked = usePrevious(checked)

  return (
    <Checkbox
      checked={checked}
      onChange={(value) => {
        if (prevChecked !== value) {
          onToggle()
        }
      }}
      disabled={disabled}
      checkboxLabel={<CheckboxLabel value={tableMenuPage?.name} />}
    />
  )
}

export function SelectMenuPages({
  menuPages,
  menuPageIds,
  setMenuPages,
  style,
}: {
  menuPages: TableMenuPage[]
  menuPageIds: Array<TableMenuPage['id']>
  setMenuPages: (menuPages: Array<TableMenuPage['id']>) => void
  style?: StyleProp<ViewStyle>
}) {
  const { width, sm } = useResponsive()

  const checkOrUncheckMenuPage = (tableMenuPageId: string) => {
    const parent = menuPages.find(
      (item) => !!item.table_menu_pages && item.id === tableMenuPageId
    )

    const parentFromChild = menuPages.find((item) => {
      return (
        !!item.table_menu_pages &&
        item.table_menu_pages
          .map((menuPage) => menuPage.id)
          .includes(tableMenuPageId)
      )
    })

    const childrenIds = parent?.table_menu_pages
      ? parent.table_menu_pages.map((menuPage) => menuPage.id)
      : []

    if (menuPageIds.includes(tableMenuPageId)) {
      let removeIds = [tableMenuPageId]
      // 親カテゴリがはずされる場合は、小カテゴリもすべて外す
      if (parent && !!parent.table_menu_pages) {
        removeIds = removeIds.concat(childrenIds)
      }

      // 親が選択されていない かつ 子カテゴリのチェックを外す場合、親にチェックを入れる
      const addParentIds = []
      if (parentFromChild && !menuPageIds.includes(parentFromChild.id)) {
        addParentIds.push(parentFromChild.id)
      }

      const removedMenuPages = menuPageIds.filter(
        (menuPage) => !removeIds.includes(menuPage)
      )

      setMenuPages(removedMenuPages.concat(addParentIds))
    } else {
      const hasCheckedChildren = childrenIds.some((childId) =>
        menuPageIds.includes(childId)
      )

      // 実際には選択されていない親カテゴリがcheckedされたとき、子カテゴリも全て外す
      // 親である かつ 実際は選択されていない かつ 子が選択されているものがある
      if (hasCheckedChildren) {
        setMenuPages(
          menuPageIds.filter((menuPage) => !childrenIds.includes(menuPage))
        )
        return
      }

      setMenuPages([...menuPageIds, tableMenuPageId])
    }
  }

  return (
    <View style={style}>
      <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
        {menuPages
          .filter(
            (item) =>
              !item.table_menu_pages && !item.parent_table_menu_page != null
          )
          .map((item) => {
            const checked = menuPageIds.includes(item.id)
            return (
              <View key={item.id}>
                <TableMenuPageCheckbox
                  tableMenuPage={item}
                  checked={checked}
                  onToggle={() => {
                    checkOrUncheckMenuPage(item.id)
                  }}
                />
              </View>
            )
          })}
      </Wrap>
      <View>
        {menuPages
          .filter((item) => !!item.table_menu_pages)
          .map((item) => {
            return (
              <TableMenuPageParentCheckBox
                key={item.id}
                tableMenuPage={item}
                selectedTableMenuPageIds={menuPageIds}
                onToggle={checkOrUncheckMenuPage}
              />
            )
          })}
      </View>
    </View>
  )
}
