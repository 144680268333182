import React from 'react'

import { View } from 'react-native'
import {
  VictoryChart,
  VictoryTheme,
  VictoryTooltip,
  VictoryVoronoiContainer,
  VictoryLegend,
  VictoryLine,
  VictoryGroup,
  VictoryScatter,
} from 'victory'

import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import numberWithDelimiter from '../../modules/numberWithDelimiter'

import { Price } from './Card'

const shadowBoxPadding = 24
type ValueType = 'amount' | 'ratio'
export function SalesChart({
  data,
  width,
  height,
  startDate,
  endDate,
  totalAmount,
  valueType,
}: {
  data: {
    datasets: Array<{
      name?: string
      labels?: {
        fill?: string
      }
      symbol?: {
        fill?: string
        type?: string
      }
      color: string
    }>
    legends: string[]
  }
  width: number
  height: number
  startDate: dayjs.Dayjs
  endDate: dayjs.Dayjs
  totalAmount: number
  valueType: ValueType
}) {
  const [activePoints, setActivePoints] = React.useState<any>()
  const [isDisable, setIsDisable] = React.useState(false)
  const onChangeActivePoints = (points: any) => {
    setActivePoints(points)
    if (
      activePoints != null &&
      points != null &&
      activePoints[0] != null &&
      points[0] != null
    ) {
      setIsDisable(activePoints[0].x === points[0].x)
    }
  }

  const labelSuffix = valueType === 'amount' ? t('円') : '%'

  return (
    <ShadowBox>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <View>
          <Price value={totalAmount} />
          <Text
            style={{
              marginTop: 8,
              fontSize: 12,
              color: Colors.secondaryBlack,
            }}
          >
            {/* eslint-disable ar-i18n/require-translation-ja */}
            {startDate.format('YYYY年M月DD日')} ~{' '}
            {endDate.format('YYYY年M月DD日')}
            {/* eslint-enable ar-i18n/require-translation-ja */}
          </Text>
        </View>
        {
          // WORKAROUND: 表示する値の種類を切り替えた時、keyを変えないとサイズがおかしくなる
        }
        <View key={valueType}>
          <VictoryLegend
            orientation="horizontal"
            gutter={40}
            standalone
            height={30}
            width={95 * data.datasets.length}
            style={{
              parent: {
                height: 30,
              },
              title: { fontSize: 20 },
            }}
            data={data.legends.map((name: string, i: number) => ({
              name,
              symbol: { fill: data.datasets[i].color },
            }))}
          />
        </View>
      </View>
      <View>
        <VictoryChart
          padding={{ left: 80, right: 20, top: 40, bottom: 40 }}
          theme={VictoryTheme.material}
          width={width - shadowBoxPadding * 2}
          height={height}
          containerComponent={
            <VictoryVoronoiContainer
              voronoiDimension="x"
              onActivated={(points, _) => onChangeActivePoints(points)}
              voronoiBlacklist={data.datasets.map(
                (_: unknown, i: number) => `line-${i}`
              )}
              labels={
                isDisable
                  ? undefined
                  : ({
                      datum,
                    }: {
                      datum: {
                        name: string
                        y: number
                      }
                    }) =>
                      `${datum.name} ${numberWithDelimiter(
                        datum.y
                      )}${labelSuffix}`
              }
              labelComponent={
                isDisable ? undefined : (
                  <VictoryTooltip
                    cornerRadius={8}
                    flyoutPadding={{
                      top: 10,
                      bottom: 10,
                      left: 20,
                      right: 20,
                    }}
                    centerOffset={{ x: 25 }}
                    flyoutStyle={{
                      fill: 'white',
                      strokeWidth: 1.5,
                      strokeOpacity: 0.2,
                    }}
                  />
                )
              }
            />
          }
        >
          {data.datasets.map((dataset: any, i: number) => {
            return (
              <VictoryGroup
                key={i}
                color={dataset.color}
                style={{
                  parent: { border: '1px solid #ccc' },
                }}
                data={dataset.data}
              >
                <VictoryLine name={`line-${i}`} />
                <VictoryScatter
                  style={{
                    labels: {
                      fill: dataset.color,
                      fontWeight: '600',
                    },
                  }}
                  name="scatters"
                  size={(props: { active: boolean }) => {
                    // eslint-disable-next-line react/prop-types
                    return props.active ? 8 : 3
                  }}
                />
              </VictoryGroup>
            )
          })}
        </VictoryChart>
      </View>
    </ShadowBox>
  )
}
