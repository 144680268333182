import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import TakeoutMenuPages from 'pages/Menus/TakeoutMenuPages'
import TakeoutMenuPagesForm from 'pages/Menus/TakeoutMenuPages/Form'

const routes: StackRoutes = {
  Index: {
    title: t('カテゴリー'),
    fullPath: `/restaurants/:id/takeout/menu_pages`,
    relativePath: `/`,
    exact: true,
    element: <TakeoutMenuPages />,
  },
  Show: {
    title: t('カテゴリーを追加'),
    fullPath: `/restaurants/:id/takeout/menu_pages/new`,
    relativePath: `/new`,
    element: <TakeoutMenuPagesForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('カテゴリーを編集'),
    fullPath: `/restaurants/:id/takeout/menu_pages/:takeout_menu_page_id/edit`,
    relativePath: `/:takeout_menu_page_id/edit`,
    element: <TakeoutMenuPagesForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function TakeoutMenuPagesStack() {
  return <Stack routes={routes} />
}
