import React from 'react'

import RestaurantCourseListView from '@hello-ai/for_r_app/src/components/RestaurantCourses/RestaurantCourseListView'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function RestaurantCourses() {
  const restaurantId = useRestaurantId()

  return <RestaurantCourseListView restaurantId={restaurantId} />
}
