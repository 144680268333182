import React from 'react'

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import { GestureResponderEvent, View, ScrollView } from 'react-native'
import { useNavigate } from 'react-router'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Modal } from 'components/Shared/Modal'
import {
  Restaurant as RestaurantModel,
  useOwnerRestaurants,
} from 'models/Restaurant'

import Icon from './Icon'

function Restaurant({
  restaurant,
  onPress,
}: {
  restaurant: RestaurantModel
  onPress: (event: GestureResponderEvent) => void
}) {
  return (
    <TouchableOpacity
      onPress={onPress}
      disabled={!restaurant.is_owned}
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Icon
          imageUrl={restaurant.logo?.sm}
          fallbackText={restaurant.name}
          width={48}
        />
        <Text style={{ marginLeft: 8, fontSize: 16 }}>{restaurant.name}</Text>
      </View>
      {!restaurant.is_owned && (
        <Text style={{ color: Colors.black60, fontSize: 16 }}>
          {t('申請中')}
        </Text>
      )}
    </TouchableOpacity>
  )
}

export function RestaurantSwitcherModal({
  isModalVisible,
  setModalVisible,
}: {
  isModalVisible: boolean
  setModalVisible: (value: boolean) => void
}) {
  const { width, sm } = useResponsive()

  const { data } = useOwnerRestaurants()

  const navigate = useNavigate()

  return (
    <Modal
      isVisible={isModalVisible}
      onBackdropPress={() => setModalVisible(false)}
    >
      <View
        style={{
          backgroundColor: 'white',
          width: width < sm ? '90%' : '50%',
          height: '50%',
          minHeight: 300, // スマホ横画面など縦が極端に短い場合のため最低の高さを決める
          alignSelf: 'center',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingHorizontal: 16,
            paddingVertical: 24,
            borderBottomWidth: 1,
            borderColor: Colors.border,
          }}
        >
          <TouchableOpacity onPress={() => setModalVisible(false)}>
            <View style={{ width: 30 }}>
              <FontAwesomeIcon icon={faTimes} size={22} color={Colors.black} />
            </View>
          </TouchableOpacity>
          <Text style={{ fontWeight: '600', fontSize: 22 }}>
            {t('レストランを選択')}
          </Text>
          <View style={{ width: 30 }} />
        </View>
        <ScrollView contentContainerStyle={{ paddingVertical: 12 }}>
          {data?.restaurants.map((restaurant) => {
            return (
              <View
                key={restaurant.id}
                style={{
                  paddingHorizontal: 16,
                  paddingVertical: 12,
                }}
              >
                <Restaurant
                  restaurant={restaurant}
                  onPress={() => {
                    navigate(`/restaurants/${restaurant.id}`)
                    setModalVisible(false)
                  }}
                />
              </View>
            )
          })}
        </ScrollView>
      </View>
    </Modal>
  )
}
