import React, { useEffect, useMemo, useState } from 'react'

import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import { View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { CandidatesRestaurant } from '@hello-ai/ar_shared/src/types/ForR/CandidatesRestaurant'

import VerifyCodeInput from 'components/Shared/VerifyCodeInput'

interface VerifyCodeProps {
  restaurant: CandidatesRestaurant
  onPressVerifyCode: (code: string) => Promise<void>
  onPressResendCode: () => void
}

const CODE_LENGTH = 6
const RESEND_CODE_INTERVAL = 3 * 60 * 1000 // 3 minutes

export default function VerifyCode({
  restaurant,
  onPressResendCode,
  onPressVerifyCode,
}: VerifyCodeProps) {
  const [codes, setCodes] = useState<(number | null)[]>(
    Array.from({ length: CODE_LENGTH })
  )

  const disableSubmitButton = useMemo(() => {
    return codes.some((code) => code == null)
  }, [codes])

  const onPressSubmit = async () => {
    if (disableSubmitButton) return

    onPressVerifyCode(codes.join(''))
  }

  const [isDisabledResendCode, setIsDisabledResendCode] = useState(true)
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsDisabledResendCode(false)
    }, RESEND_CODE_INTERVAL)
    return () => clearTimeout(timer)
  }, [])

  return (
    <View
      style={{ justifyContent: 'center', alignItems: 'center', width: '80%' }}
    >
      <Text style={{ fontSize: 22, fontWeight: '600', marginBottom: 40 }}>
        {t('最後に電話番号を認証して登録完了です')}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          columnGap: 8,
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            color={Colors.black40}
            size={24}
            style={{ marginRight: 8 }}
          />
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
              lineHeight: 32,
            }}
          >
            {`+${restaurant.phone.country_code} ${restaurant.phone.number}`}
          </Text>
        </View>
      </View>
      <Text
        style={{
          fontSize: 18,
          fontWeight: '300',
          color: Colors.black60,
          marginBottom: 40,
        }}
      >
        {restaurant.name_i18n}
        {restaurant.category != null ? ` ${restaurant.category}` : ''}
        {'/'}
        <Text style={{ fontSize: 16, color: Colors.black60 }}>
          {restaurant.address_short}
        </Text>
      </Text>
      <Text
        style={{
          fontSize: 18,
          fontWeight: '300',
          color: Colors.black,
          marginBottom: 40,
          textAlign: 'center',
        }}
      >
        {t(
          'こちらの電話番号に音声通話で認証コードを発信中です。案内された認証コード({{number}}桁)を入力して電話番号を認証してください',
          { number: CODE_LENGTH }
        )}
      </Text>
      <View
        style={{
          padding: 24,
          borderWidth: 0.5,
          borderStyle: 'dashed',
          borderColor: Colors.border,
          width: '100%',
        }}
      >
        <View style={{ alignSelf: 'center' }}>
          <VerifyCodeInput
            codeLength={CODE_LENGTH}
            codes={codes}
            setCodes={setCodes}
          />
          <Button
            style={{
              width: 338,
              marginVertical: 40,
              paddingVertical: 16,
              paddingHorizontal: 24,
              height: 48,
              alignSelf: 'center',
            }}
            textStyle={{ fontSize: 16, fontWeight: '600' }}
            onPress={onPressSubmit}
          >
            {t('電話番号を確認して登録を完了する')}
          </Button>
          <View
            style={{
              marginBottom: 8,
              flexDirection: 'row',
              width: '100%',
              alignItems: 'center',
            }}
          >
            <View
              style={{ borderColor: Colors.border, borderTopWidth: 1, flex: 1 }}
            />
            <Text
              style={{ paddingHorizontal: 8, fontSize: 14, fontWeight: '300' }}
            >
              {t('認証コードが届きませんか？')}
            </Text>
            <View
              style={{ borderColor: Colors.border, borderTopWidth: 1, flex: 1 }}
            />
          </View>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '300',
              color: Colors.black60,
              textAlign: 'center',
            }}
          >
            {t(
              '※再送できるのは3分に1回です。\n再送する際は現在の音声案内をお切りください。'
            )}
          </Text>
          <Button
            mode="text"
            onPress={onPressResendCode}
            textStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            disabled={isDisabledResendCode}
          >
            {t('前の画面に戻ってやり直す')}
          </Button>
        </View>
      </View>
    </View>
  )
}
