import { Methods } from '@hello-ai/api_table/api_table/reservation_book/restaurants/_restaurant_id@number/seat_statuses'
import { createAspidaSWRParameters } from '@hello-ai/ar_shared/src/modules/aspida-swr'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'

import { client } from '../modules/api'
import { setHeader } from '../modules/axios'
import useSWR, { SWRConfiguration } from '../modules/swr'

export type SeatStatusItem = Methods['get']['resBody'][number]

export const useRestaurantSeatStatuses = (
  restaurantId: number,
  floorId: string | undefined,
  config: SWRConfiguration
) => {
  const token = useToken()
  setHeader({ token })
  const { data, mutate } = useSWR(
    ...createAspidaSWRParameters(
      floorId != null
        ? client.reservation_book.restaurants._restaurant_id_number(
            restaurantId
          ).seat_statuses
        : null,
      { query: { table_floor_id: floorId } }
    ),
    config
  )
  return {
    data,
    mutate,
  }
}
