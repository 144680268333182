import React from 'react'
import { useParams } from 'react-router-dom'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'

import TableMenuPageFormContent from 'components/Menus/TableMenuPageFormContent'
import { useRestaurantTableMenus } from 'models/TableMenu'
import {
  createTableMenuPage,
  useTableMenuPage,
  updateTableMenuPage,
  useTableMenuPages,
} from 'models/TableMenuPage'
import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableMenuPageFormScreen() {
  const restaurantId = useRestaurantId()
  const { table_menu_page_id: tableMenuPageId } = useParams<{
    table_menu_page_id?: string
  }>()
  const { tableMenuPage, isLoading } = useTableMenuPage({
    tableMenuPageId,
  })

  const { tableMenuPages, isLoading: isLoadingMenuPages } = useTableMenuPages({
    restaurantId,
    params: {
      per_page: 9999,
    },
  })

  const { tableMenus, isLoading: isLoadingMenus } = useRestaurantTableMenus({
    restaurantId,
  })

  if (isLoading) {
    return <Loading />
  }

  return (
    <TableMenuPageFormContent
      isOwner={false}
      tableMenuPage={tableMenuPage}
      tableMenuPages={tableMenuPages}
      isLoadingMenuPages={isLoadingMenuPages}
      tableMenus={tableMenus}
      isLoadingMenus={isLoadingMenus}
      createMenuPage={async ({ token, params }) => {
        await createTableMenuPage({
          token,
          params,
          restaurantId,
        })
      }}
      updateMenuPage={async ({ token, params }) => {
        if (tableMenuPageId === undefined) {
          return
        }
        await updateTableMenuPage({
          token,
          params,
          tableMenuPageId,
        })
      }}
      onPressMenuPage={(menuPageId: string) => {
        history.push(
          `/restaurants/${restaurantId}/menu_pages/${menuPageId}/edit`
        )
      }}
      onPressProduct={(productId: string) => {
        history.push(`/restaurants/${restaurantId}/products/${productId}/edit`)
      }}
    />
  )
}
