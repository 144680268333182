import { TableProductOptionBox } from '@hello-ai/ar_shared/src/types/ForR/TableProductOptionBox'

import axios, { setHeader, wrapResponse } from 'modules/axios'

import useSWR, { fetcher, mutate, onError, swrKey } from '../modules/swr'

import { useToken } from './Auth'
export * from '@hello-ai/ar_shared/src/types/ForR/TableProductOptionBox'

export type FetchProductOptionBoxesResult = {
  tableProductOptionBoxes: TableProductOptionBox[]
  headerData: {
    currentPage: number
    totalPages: number
    totalCount: number
  } | null
  isLoading: boolean
  error: any
}

async function customFetcher<T = any, P extends {} = {}>([token, url, params]: [
  token: string | null,
  url: string,
  params?: P,
]) {
  setHeader({ token })
  const response = await axios.get<T>(url, { params })
  if (response?.data != null) {
    const currentPage = response?.headers['x-current-page'] ?? '0'
    const totalPages = response?.headers['x-total-pages'] ?? '0'
    const totalCount = response?.headers['x-total-count'] ?? '0'
    return {
      tableProductOptionBoxes: response.data,
      headerData: {
        currentPage: Number(currentPage),
        totalPages: Number(totalPages),
        totalCount: Number(totalCount),
      },
    }
  }
  return null
}

export const useTableProductOptionBoxes = (
  restaurantId: number,
  params: {
    page?: number
    keyword?: string
    per?: number
    status?: string
  }
) => {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    {
      tableProductOptionBoxes: TableProductOptionBox[]
      headerData: {
        currentPage: number
        totalPages: number
        totalCount: number
      }
    } | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/restaurants/${restaurantId}/product_option_boxes`, params),
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string,
    ]) => customFetcher([token, url, JSON.parse(params)])
  )
  return {
    tableProductOptionBoxes: data != null ? data.tableProductOptionBoxes : [],
    headerData: data != null ? data.headerData : null,
    isLoading: data == null && error == null,
    error,
    mutate,
  }
}

export const useTableProductOptionBox = ({
  restaurantId,
  tableProductOptionBoxId,
}: {
  restaurantId: number
  tableProductOptionBoxId?: string | null
}) => {
  const token = useToken()
  const {
    data: tableProductOptionBox,
    error,
    mutate,
  } = useSWR<TableProductOptionBox, any, ReturnType<typeof swrKey> | null>(
    tableProductOptionBoxId != null
      ? swrKey(
          token,
          `/restaurants/${restaurantId}/product_option_boxes/${tableProductOptionBoxId}`
        )
      : null,
    fetcher
  )

  if (tableProductOptionBoxId == null) {
    return {
      tableProductOptionBox: undefined,
      isLoading: false,
      error: undefined,
    }
  }

  return {
    tableProductOptionBox,
    isLoading: tableProductOptionBox == null && error == null,
    error,
    mutate,
  }
}

export const useMenuRestaurantGroupProductOptionBox = ({
  restaurantGroupId,
  tableProductOptionBoxId,
}: {
  restaurantGroupId: string
  tableProductOptionBoxId?: string | null
}) => {
  const token = useToken()
  const { data: tableProductOptionBox, error } = useSWR<
    TableProductOptionBox,
    any,
    ReturnType<typeof swrKey> | null
  >(
    tableProductOptionBoxId != null
      ? swrKey(
          token,
          `/menu_restaurant_groups/${restaurantGroupId}/product_option_boxes/${tableProductOptionBoxId}`
        )
      : null,
    fetcher
  )

  if (tableProductOptionBoxId == null) {
    return {
      tableProductOptionBox: undefined,
      isLoading: false,
      error: undefined,
    }
  }

  return {
    tableProductOptionBox,
    isLoading: tableProductOptionBox == null && error == null,
    error,
  }
}

export async function createTableProductOptionBox({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse<TableProductOptionBox>(
    axios.post(`/restaurants/${restaurantId}/product_option_boxes`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/product_option_boxes/${response.data.id}`
      ),
      response.data,
      false
    )
  }

  return {
    tableOptionBoxes: response?.data,
  }
}

export async function updateTableProductOptionBox({
  token,
  restaurantId,
  productOptionBoxId,
  params,
}: {
  token: string
  restaurantId: number
  productOptionBoxId: string
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse<TableProductOptionBox>(
    axios.put(
      `/restaurants/${restaurantId}/product_option_boxes/${productOptionBoxId}`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/product_option_boxes/${response.data.id}`
      ),
      response.data,
      false
    )
  }

  return {
    tableOptionBoxes: response?.data,
  }
}

export function useMenuRestaurantGroupProductOptionBoxes(
  id: string,
  params: {
    page: number
    keyword?: string
    per?: number
  }
) {
  const token = useToken()
  const { data, error } = useSWR<
    {
      tableProductOptionBoxes: TableProductOptionBox[]
      headerData: {
        currentPage: number
        totalPages: number
        totalCount: number
      }
    } | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/menu_restaurant_groups/${id}/product_option_boxes`, params),
    ([token, url, params]: [
      token: string | null,
      url: string,
      params: string,
    ]) => customFetcher([token, url, JSON.parse(params)])
  )
  return {
    tableProductOptionBoxes: data != null ? data.tableProductOptionBoxes : [],
    headerData: data != null ? data.headerData : null,
    isLoading: data == null && error == null,
    error,
  }
}

export async function createMenuRestaurantGroupProductOptionBox({
  token,
  restaurantGroupId,
  params,
}: {
  token: string
  restaurantGroupId: string
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse<TableProductOptionBox>(
    axios.post(
      `/menu_restaurant_groups/${restaurantGroupId}/product_option_boxes`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/menu_restaurant_groups/${restaurantGroupId}/product_option_boxes/${response.data.id}`
      ),
      response.data,
      false
    )
  }

  return {
    tableOptionBoxes: response?.data,
  }
}

export async function updateMenuRestaurantGroupProductOptionBox({
  token,
  restaurantGroupId,
  productOptionBoxId,
  params,
}: {
  token: string
  restaurantGroupId: string
  productOptionBoxId: string
  params: any
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse<TableProductOptionBox>(
    axios.put(
      `/menu_restaurant_groups/${restaurantGroupId}/product_option_boxes/${productOptionBoxId}`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/menu_restaurant_groups/${restaurantGroupId}/product_option_boxes/${response.data.id}`
      ),
      response.data,
      false
    )
  }

  return {
    tableOptionBoxes: response?.data,
  }
}
