import React, { useCallback, useEffect, useState } from 'react'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import { Trans } from 'react-i18next'
import { View } from 'react-native'
import invariant from 'tiny-invariant'

import { AspectImage } from '@hello-ai/ar_shared/src/components/AspectImage'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { BusinessUserResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_resource'

import logo from 'assets/images/logo.png'
import LinkText from 'components/Shared/LinkText'
import { signIn } from 'models/Auth'
import {
  businessUserClient,
  useBusinessUserInvitation,
} from 'models/BusinessUser'
import { getLocale } from 'modules/locale'
import { callRpc } from 'modules/rpc'
import { onError } from 'modules/swr'

type FirstRegistrationSteps = 'REGISTER' | 'DONE'

const formWidth = 600
const submitButtonHeight = 48

function RegistrationLayout({
  title,
  children,
}: {
  title: string
  children?: React.ReactNode
}) {
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 16,
      }}
    >
      <View
        style={{
          alignItems: 'center',
          width: '100%',
          maxWidth: width < sm ? '100%' : formWidth,
          flexShrink: 1,
        }}
      >
        <AspectImage
          style={{
            width: 160,
            aspectRatio: 2167 / 768,
          }}
          source={{ uri: logo }}
          resizeMode="contain"
        />
        <Text style={{ marginTop: 40, fontSize: 22, fontWeight: '600' }}>
          {title}
        </Text>
        {children}
      </View>
    </View>
  )
}

function FirstRegistrationDone({
  invitationToken,
}: {
  invitationToken: string
}) {
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from
  const url = from ?? '/'

  const { data: invitation } = useBusinessUserInvitation({
    token: invitationToken,
  })

  if (invitation === undefined) {
    return <Loading />
  }

  const ownerName = invitation.owner?.name

  invariant(ownerName !== undefined, 'ownerName cannot be undefined')

  return (
    <RegistrationLayout title={t('パスワードを設定しました')}>
      <LinkText
        style={{
          marginTop: 32,
          color: Colors.primary,
        }}
        to={url}
      >
        {t('ホームへ')}
      </LinkText>
    </RegistrationLayout>
  )
}

export function FirstRegistration({
  id,
  invitationToken,
  onSubmitted,
}: {
  id: string
  invitationToken: string
  onSubmitted: () => void
}) {
  const _locale = getLocale()
  const { width, sm, md } = useResponsive()
  const [password, setPassword] = useState('')

  const { data: invitation } = useBusinessUserInvitation({
    token: invitationToken,
  })

  const disabled = password.length < 6

  useEffect(() => {
    if (
      invitation?.invitation?.businessUser?.status ===
      BusinessUserResource_Status.REGISTERED
    ) {
      onSubmitted()
    }
  }, [invitation?.invitation?.businessUser?.status, onSubmitted])

  if (invitation === undefined) {
    return <Loading />
  }

  const ownerName = invitation.owner?.name
  const businessUserEmail = invitation.invitation?.businessUser?.email

  invariant(ownerName !== undefined, 'ownerName cannot be undefined')
  invariant(
    businessUserEmail !== undefined,
    'businessUserEmail cannot be undefined'
  )

  const onSubmit = async () => {
    if (disabled) return
    invariant(invitation.owner !== undefined, 'owner cannot be undefined')

    {
      const { error } = await callRpc(
        businessUserClient.register({
          id,
          invitationToken,
          password,
          name: t('管理者'),
        })
      )

      if (error != null) {
        onError(error)
        return
      }
    }

    {
      const { error } = await signIn(
        businessUserEmail,
        password,
        invitation.owner.id
      )
      if (error != null) {
        onError(error)
        return
      }
    }

    displayToastInfo(t('登録しました'))
    onSubmitted?.()
  }

  return (
    <RegistrationLayout title={t('パスワードを設定する')}>
      <FormGroup
        mode={width < md ? 'vertical' : 'inline'}
        style={{
          marginTop: 40,
          width: '100%',
          rowGap: width < sm ? 16 : 0,
          columnGap: width < sm ? 0 : 24,
        }}
        formLabel={<FormLabel value={t('メールアドレス')} />}
        formLabelContainerStyle={{
          minWidth: width < sm ? undefined : 202,
        }}
      >
        <Text>{businessUserEmail}</Text>
      </FormGroup>
      <FormGroup
        mode={width < md ? 'vertical' : 'inline'}
        style={{
          marginTop: 28,
          width: '100%',
          rowGap: width < sm ? 16 : 0,
          columnGap: width < sm ? 0 : 24,
        }}
        formLabel={<FormLabel value={t('パスワード')} />}
        formLabelContainerStyle={{
          minWidth: width < sm ? '100%' : 202,
        }}
      >
        <TextInput
          value={password}
          onChangeText={setPassword}
          onSubmitEditing={onSubmit}
          clearButtonMode="always"
          placeholder={t('6文字以上')}
          secureTextEntry
        />
      </FormGroup>
      {/* eslint-disable ar-i18n/require-translation-ja */}
      <Text
        style={{
          marginTop: 40,
          fontSize: 14,
        }}
      >
        <Trans
          i18nKey={
            '<0>リクエスト予約の場合は、一部の機能のご利用を制限させていただいております。その他の条件については</0><1>利用規約</1><2>に準ずる形といたします。「設定する」ボタンを押すことで</2><3>利用規約</3><4>に同意するものとします。</4>'
          }
        >
          <Text style={{ fontSize: 14 }}>
            {
              'リクエスト予約の場合は、一部の機能のご利用を制限させていただいております。その他の条件については'
            }
          </Text>
          <a
            href="https://helloincai.notion.site/request-reservation-terms"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: 14,
              color: Colors.primary,
            }}
          >
            {'利用規約'}
          </a>
          <Text style={{ fontSize: 14 }}>
            に準ずる形といたします。「設定する」ボタンを押すことで
          </Text>
          <a
            href="https://helloincai.notion.site/request-reservation-terms"
            target="_blank"
            rel="noreferrer"
            style={{
              fontSize: 14,
              color: Colors.primary,
            }}
          >
            {'利用規約'}
          </a>
          <Text style={{ fontSize: 14 }}>に同意するものとします。</Text>
        </Trans>
      </Text>
      {/* eslint-enable ar-i18n/require-translation-ja */}
      <Button
        disabled={disabled}
        onPress={onSubmit}
        style={{
          marginTop: 24,
          width: width < md ? '100%' : 278,
          height: submitButtonHeight,
        }}
      >
        {t('設定する')}
      </Button>
    </RegistrationLayout>
  )
}

export default function BusinessUsersFirstRegistration() {
  const [searchParams] = useSearchParams()
  const { id } = useParams<{ id: string }>()

  const navigate = useNavigate()
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from

  const invitationToken = searchParams.get('invitation_token')

  const [registrationType, setRegistrationType] =
    useState<FirstRegistrationSteps>('REGISTER')

  useEffect(() => {
    if (invitationToken === null || invitationToken === '') {
      navigate(from ?? '/')
    }
  }, [navigate, invitationToken, from])

  const onSubmitted = useCallback(() => {
    setRegistrationType('DONE')
  }, [])

  if (invitationToken == null || invitationToken === '') return null

  return registrationType === 'REGISTER' ? (
    <FirstRegistration
      id={id!}
      invitationToken={invitationToken}
      onSubmitted={onSubmitted}
    />
  ) : (
    <FirstRegistrationDone invitationToken={invitationToken} />
  )
}
