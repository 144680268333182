import React from 'react'

import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faAngleLeft } from '@fortawesome/pro-light-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { StyleProp, View, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

function ArrowPagination({
  disabled,
  icon,
  onPress,
}: {
  disabled: boolean
  icon: IconProp
  onPress: () => void
}) {
  const { width, sm } = useResponsive()
  const size = width < sm ? 32 : 48
  const iconSize = width < sm ? 12 : 20
  if (disabled) {
    return (
      <View
        style={{
          width: size,
          height: size,
          borderColor: 'rgba(0,0,0,.08)',
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: size / 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon icon={icon} size={iconSize} color="rgba(4,4,4,.2)" />
      </View>
    )
  } else {
    return (
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled}
        style={[
          {
            width: size,
            height: size,
            borderColor: Colors.border,
            borderWidth: 1,
            borderStyle: 'solid',
            borderRadius: size / 2,
            justifyContent: 'center',
            alignItems: 'center',
          },
        ]}
      >
        <FontAwesomeIcon icon={icon} size={iconSize} color={Colors.primary} />
      </TouchableOpacity>
    )
  }
}

function TouchablePage({
  style,
  onPress,
  page,
}: {
  style: StyleProp<ViewStyle>
  onPress: () => void
  page: number
}) {
  const hit = 10
  return (
    <TouchableOpacity
      style={style}
      onPress={onPress}
      hitSlop={{ top: hit, left: hit, right: hit, bottom: hit }}
    >
      <Text style={{ color: Colors.primary, fontSize: 14 }}>{page}</Text>
    </TouchableOpacity>
  )
}

export function Pagination({
  currentPage,
  totalPage,
  setPage,
  style,
}: {
  currentPage: number
  totalPage: number
  setPage: (page: number) => void
  style?: StyleProp<ViewStyle>
}) {
  const { width, sm } = useResponsive()
  const textHorizontalPadding = width < sm ? 8 : 16
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          // marginHorizontal: horizontalMargin * 8,
        },
        style,
      ]}
    >
      <ArrowPagination
        disabled={currentPage === 1}
        onPress={() => setPage(currentPage - 1)}
        icon={faAngleLeft}
      />
      <View
        style={{
          marginHorizontal: 20,
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {currentPage - 1 > 1 && (
          <>
            <TouchablePage
              style={{ paddingHorizontal: textHorizontalPadding }}
              onPress={() => setPage(1)}
              page={1}
            />
            {totalPage > 5 && currentPage > 3 && (
              <Text
                style={{
                  paddingHorizontal: textHorizontalPadding,
                  fontSize: 14,
                }}
              >
                ...
              </Text>
            )}
          </>
        )}
        {totalPage > 4 && totalPage === currentPage && (
          <TouchablePage
            style={{ paddingHorizontal: textHorizontalPadding }}
            onPress={() => setPage(currentPage - 3)}
            page={currentPage - 3}
          />
        )}
        {totalPage > 3 && currentPage > 3 && totalPage - currentPage < 2 && (
          <TouchablePage
            style={{ paddingHorizontal: textHorizontalPadding }}
            onPress={() => setPage(currentPage - 2)}
            page={currentPage - 2}
          />
        )}
        {currentPage - 1 > 0 && (
          <TouchablePage
            style={{ paddingHorizontal: textHorizontalPadding }}
            onPress={() => setPage(currentPage - 1)}
            page={currentPage - 1}
          />
        )}
        {/* --- currentPage --- */}
        <Text
          style={{
            fontWeight: '600',
            paddingHorizontal: textHorizontalPadding,
            fontSize: 14,
          }}
        >
          {currentPage}
        </Text>
        {/* --- currentPage --- */}
        {currentPage < totalPage && (
          <>
            <TouchablePage
              style={{ paddingHorizontal: textHorizontalPadding }}
              onPress={() => setPage(currentPage + 1)}
              page={currentPage + 1}
            />
            {currentPage === 1 && totalPage > 3 && (
              <TouchablePage
                style={{ paddingHorizontal: textHorizontalPadding }}
                onPress={() => setPage(3)}
                page={3}
              />
            )}
            {currentPage < 3 && totalPage > 4 && (
              <TouchablePage
                style={{ paddingHorizontal: textHorizontalPadding }}
                onPress={() => setPage(4)}
                page={4}
              />
            )}
            {currentPage + 1 < totalPage && (
              <>
                {totalPage > 5 && totalPage - currentPage > 2 && (
                  <Text
                    style={{
                      paddingHorizontal: textHorizontalPadding,
                      fontSize: 14,
                    }}
                  >
                    ...
                  </Text>
                )}
                <TouchablePage
                  style={{ paddingHorizontal: textHorizontalPadding }}
                  onPress={() => setPage(totalPage)}
                  page={totalPage}
                />
              </>
            )}
          </>
        )}
      </View>
      <ArrowPagination
        disabled={currentPage === totalPage}
        onPress={() => setPage(currentPage + 1)}
        icon={faAngleRight}
      />
    </View>
  )
}
