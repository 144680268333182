import {
  Restaurant,
  RestaurantImage,
  RestaurantMichelinAwardStatus,
  RestaurantTabelogAwardStatus,
} from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { UpdateRestaurantParams } from '@hello-ai/ar_shared/src/types/ForR/RestaurantUpdateParams'

import axios, { wrapResponse, setHeader } from 'modules/axios'
import useSWR, { swrKey, fetcher, onError } from 'modules/swr'

import { useToken } from './Auth'
import { Owner } from './Owner'

export * from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

export function useOwnerRestaurants() {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    {
      id: Owner['id']
      restaurants: Restaurant[]
    },
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/owner/restaurants/`), fetcher)

  return {
    data,
    error,
    mutate,
  }
}

export function useRestaurant(restaurantId: Restaurant['id']) {
  const token = useToken()

  const {
    data: restaurant,
    error,
    mutate,
  } = useSWR<Restaurant, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}`),
    fetcher
  )

  return {
    restaurant,
    error,
    mutate,
  }
}

export function useRestaurantInformation(restaurantId: Restaurant['id']) {
  const token = useToken()

  const {
    data: restaurant,
    error,
    mutate,
  } = useSWR<Restaurant, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/information`),
    fetcher
  )
  return {
    restaurant,
    error,
    mutate,
  }
}

export async function updateRestaurant(
  token: string,
  restaurantId: number,
  params: UpdateRestaurantParams
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<Restaurant>(`/restaurants/${restaurantId}`, params)
  )

  if (error != null) {
    onError(error)
  }

  return {
    restaurant: response?.data,
    error,
  }
}

export function useRestaurantMichelinAwards(restaurantId: Restaurant['id']) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    {
      michelin_awards: RestaurantMichelinAwardStatus[]
    },
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}/michelin_awards`), fetcher)

  return {
    michelinAwards: data?.michelin_awards,
    error,
    mutate,
  }
}

export async function createRestaurantMichelinAwardsAddRequest(
  token: string,
  restaurantId: number,
  params: { michelin_awards: Omit<RestaurantMichelinAwardStatus, 'status'>[] }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<{ michelin_awards: RestaurantMichelinAwardStatus[] }>(
      `/restaurants/${restaurantId}/michelin_awards/requests`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    requestedMichelinAwards: response?.data.michelin_awards,
    error,
  }
}

export function useRestaurantTabelogAawrds(restaurantId: Restaurant['id']) {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    {
      tabelog_awards: RestaurantTabelogAwardStatus[]
    },
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/restaurants/${restaurantId}/tabelog_awards`), fetcher)

  if (error != null) {
    onError(error)
  }

  return {
    tabelogAwards: data?.tabelog_awards,
    error,
    mutate,
  }
}

export async function createRestaurantTabelogAwardsRequest(
  token: string,
  restaurantId: number,
  params: { tabelog_awards: Omit<RestaurantTabelogAwardStatus, 'status'>[] }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<{ tabelog_awards: RestaurantTabelogAwardStatus[] }>(
      `/restaurants/${restaurantId}/tabelog_awards/requests`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    requestedTabelogAwards: response?.data.tabelog_awards,
    error,
  }
}

export async function createRestaurantImages(
  token: string,
  restaurantId: Restaurant['id'],
  params: { image: string; category: 'meal' | 'atmosphere' }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<RestaurantImage>(
      `/owner/restaurants/${restaurantId}/restaurant_images`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    createdImage: response,
    error,
  }
}

export async function destroyRestaurantImages(
  token: string,
  restaurantId: Restaurant['id'],
  restaurantImageId: RestaurantImage['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.delete(
      `/owner/restaurants/${restaurantId}/restaurant_images/${restaurantImageId}`
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    response,
    error,
  }
}
