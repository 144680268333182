import React, { useState } from 'react'

import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle'
import { faNote } from '@fortawesome/pro-solid-svg-icons/faNote'
import isEmpty from 'lodash/isEmpty'
import { View, ScrollView, useWindowDimensions } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Popover } from '@hello-ai/ar_shared/src/components/Popover'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { useRestaurantDailyReservationNote } from '@hello-ai/for_r_app/src/models/Restaurant'

import { HeaderRightButton } from '../Shared/HeaderRightButton'

import EditDailyReservationNoteModal from './EditDailyReservationNoteModal'

interface CationIconProps {
  isCation?: boolean
}

function CationIcon({
  isCation,
  children,
}: React.PropsWithChildren<CationIconProps>) {
  return (
    <View style={{ position: 'relative' }}>
      {children}
      <View
        style={{
          position: 'absolute',
          top: -2,
          right: -3,
          backgroundColor: 'white',
          padding: 1,
          display: isCation ? 'flex' : 'none',
          borderRadius: 10,
        }}
      >
        <FontAwesomeIcon icon={faCircle} color={Colors.caution} size={8} />
      </View>
    </View>
  )
}

function HeaderRightDailyNoteIconButton({
  restaurantId,
  date,
}: {
  restaurantId: number
  date: dayjs.Dayjs
}) {
  const [isOpenPop, setIsOpenPop] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)
  const { data } = useRestaurantDailyReservationNote(restaurantId, date)
  const { height: windowHeight, width: windowWidth } = useWindowDimensions()
  const { width, sm } = useResponsive()

  return (
    <>
      <Popover
        isOpen={isOpenPop}
        onClose={() => setIsOpenPop(false)}
        anchor={
          <HeaderRightButton
            style={{ paddingRight: 0 }}
            icon={
              <CationIcon isCation={!isEmpty(data?.content)}>
                <FontAwesomeIcon
                  icon={faNote}
                  size={width < sm ? 20 : 32}
                  color={Colors.primary}
                />
              </CationIcon>
            }
            onPress={() => {
              setIsOpenPop(true)
            }}
          />
        }
        placement="bottomEnd"
        showArrowElement={width > sm}
      >
        <View
          style={{
            padding: 24,
            minWidth: width > sm ? 380 : windowWidth * 0.9,
            maxWidth: width > sm ? 380 : windowWidth * 0.9,
            maxHeight: windowHeight / 2,
          }}
        >
          <Text style={{ fontWeight: '600', fontSize: 18 }}>
            {t('本日のメモ')}
          </Text>
          <ScrollView
            style={{
              marginVertical: 24,
            }}
          >
            <Text
              style={{
                color:
                  data?.content != null ? Colors.black : Colors.textDisabled,
              }}
            >
              {data?.content ?? t('メモはありません')}
            </Text>
          </ScrollView>
          <Button
            style={{ alignSelf: 'flex-end' }}
            mode="outline"
            variant="secondary"
            onPress={() => setModalVisible(true)}
          >
            {t('編集')}
          </Button>
        </View>
      </Popover>
      <EditDailyReservationNoteModal
        restaurantId={restaurantId}
        date={date}
        isModalVisible={isModalVisible}
        onClose={() => {
          setModalVisible(false)
        }}
      />
    </>
  )
}

export default HeaderRightDailyNoteIconButton
