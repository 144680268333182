import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import { useRestaurantRequestReservations } from '../../../models/RestaurantRequestReservation'
import { useRestaurantReservations } from '../../../models/RestaurantReservation'

type NeedConfirmReservationsParams = {
  restaurantId: number
  date: dayjs.Dayjs
}
export type UseNeedConfirmReservations = {
  requestReservationAmount: number
  smartPaymentAmount: number
  unallocatedSeatAmount: number
  isUseAccordion: boolean
  amount: number
}
export function useNeedConfirmReservations({
  restaurantId,
  date,
}: NeedConfirmReservationsParams): UseNeedConfirmReservations {
  const { restaurantReservations } = useRestaurantReservations(restaurantId, {
    date: date.format('YYYY-MM-DD'),
  })
  const { requestReservations } = useRestaurantRequestReservations(
    restaurantId,
    {
      date,
    }
  )
  const requestReservationAmount = requestReservations?.length ?? 0
  const smartPaymentAmount = restaurantReservations.filter(
    (r) => r.smart_payment != null
  ).length
  const unallocatedSeatAmount = restaurantReservations.filter(
    (r) => r.table_seats.length === 0
  ).length
  const isUseAccordion =
    [
      requestReservationAmount > 0,
      smartPaymentAmount > 0,
      unallocatedSeatAmount > 0,
    ].filter(Boolean).length > 1

  return {
    requestReservationAmount,
    smartPaymentAmount,
    unallocatedSeatAmount,
    isUseAccordion,
    amount:
      requestReservationAmount + smartPaymentAmount + unallocatedSeatAmount,
  }
}
