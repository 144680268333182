import React from 'react'

import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage'
import * as ImageManipulator from 'expo-image-manipulator'
import * as ImagePicker from 'expo-image-picker'
import { MediaTypeOptions } from 'expo-image-picker/src/ImagePicker.types'
import { Alert, StyleSheet } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

const selectImageDefaultMaxWidth = 1600

export const selectImagesFromMediaLibrary = async (
  options: {
    maxWidth?: number
  } = {}
): Promise<string[] | null> => {
  const permissionResult =
    await ImagePicker.requestMediaLibraryPermissionsAsync()

  if (permissionResult.granted === false) {
    Alert.alert(t('写真ライブラリへの使用を許可してください'))
    return null
  }

  const pickerResult = await ImagePicker.launchImageLibraryAsync({
    allowsEditing: false,
    quality: 1,
    mediaTypes: MediaTypeOptions.Images,
    allowsMultipleSelection: true,
  })

  if (pickerResult.canceled) {
    return null
  }

  const manipResults = await Promise.all(
    pickerResult.assets.map((asset) =>
      ImageManipulator.manipulateAsync(
        asset.uri,
        [
          {
            resize: {
              width: Math.min(
                options.maxWidth ?? selectImageDefaultMaxWidth,
                pickerResult.assets[0]?.width
              ),
            },
          },
        ],
        { compress: 0.8, base64: true }
      )
    )
  )

  return manipResults.map((result) => `data:image/jpg;base64,${result.base64}`)
}

export const launchCameraAsync = async () => {
  const permissionResult = await ImagePicker.requestCameraPermissionsAsync()
  if (permissionResult.granted === false) {
    Alert.alert(t('カメラの使用を許可してください'))
    return null
  }

  const cameraResult = await ImagePicker.launchCameraAsync({
    allowsEditing: false,
    quality: 1,
    mediaTypes: MediaTypeOptions.Images,
    base64: true,
  })

  if (cameraResult.canceled === true) {
    return null
  }

  return `data:image/jpg:base64,${cameraResult.assets[0].base64}`
}

const styles = StyleSheet.create({
  image: {
    position: 'relative',
    backgroundColor: Colors.bgBlack,
    justifyContent: 'center',
    alignItems: 'center',
  },
  crossButton: {
    position: 'absolute',
    top: -12,
    right: -12,
    backgroundColor: Colors.secondaryBlack,
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
})

export function MultipleSelectImage({
  isLoading,
  onSelectImages,
  width = 120,
  height = 120,
  iconSize = 60,
}: {
  isLoading?: boolean
  onSelectImages?: (base64Images: string[]) => void
  width?: number
  height?: number
  iconSize?: number
}) {
  return onSelectImages ? (
    <TouchableOpacity
      onPress={async () => {
        const images = await selectImagesFromMediaLibrary()
        if (images != null) {
          onSelectImages(images)
        }
      }}
      style={[styles.image, { width, height }]}
    >
      <FontAwesomeIcon
        icon={faImage}
        size={iconSize}
        color={Colors.secondaryBlack}
      />
      {isLoading && (
        <Loading
          style={{
            position: 'absolute',
            bottom: 6,
          }}
        />
      )}
    </TouchableOpacity>
  ) : (
    <FontAwesomeIcon
      icon={faImage}
      size={iconSize}
      color={Colors.secondaryBlack}
    />
  )
}
