import React from 'react'

import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons'
import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import { requestWithBusinessLicenses } from 'models/RestaurantOwner'

import UploadFile from './UploadFile'

const toBase64 = (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (typeof reader.result !== 'string')
        return reject(new Error('FileReader result is not string'))
      resolve(reader.result)
    }
    reader.onerror = reject
  })

interface ByBusinessPermitProps {
  onPressBack: () => void
  onSubmitEnd: () => void
}

export default function ByBusinessPermit({
  onPressBack,
  onSubmitEnd,
}: ByBusinessPermitProps) {
  const token = useToken()
  return (
    <>
      <ScrollView
        style={{ flex: 1, paddingTop: 48, backgroundColor: Colors.black6 }}
        contentContainerStyle={{
          width: 872,
          borderRadius: 8,
          backgroundColor: Colors.white,
          padding: 40,
          marginBottom: 14,
          marginHorizontal: 'auto',
          alignItems: 'center',
        }}
      >
        <UploadFile
          onSubmit={async (items) => {
            if (token == null) return
            const { error } = await requestWithBusinessLicenses(token, {
              verification_requests: await Promise.all(
                items.map(async (item) => ({
                  restaurant_id: item.restaurant.id,
                  business_licenses: [
                    {
                      file: await toBase64(item.file),
                    },
                  ],
                }))
              ),
            })
            if (error != null) return
            displayToastInfo(t('申請しました。承認までお待ち下さい'))
            onSubmitEnd()
          }}
        />
      </ScrollView>
      <View style={{ position: 'relative', padding: 16, height: 80 }}>
        <Button
          style={{
            position: 'absolute',
            left: 16,
            top: 0,
            bottom: 0,
            marginVertical: 'auto',
            borderRadius: 24,
            paddingVertical: 12,
            paddingHorizontal: 12,
            zIndex: 10,
          }}
          onPress={onPressBack}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            color={Colors.white}
            style={{ width: 12, height: 20 }}
          />
        </Button>
      </View>
    </>
  )
}
