import React from 'react'

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard'
import { faCalendarDays } from '@fortawesome/pro-solid-svg-icons/faCalendarDays'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons/faEnvelope'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faUserFriends } from '@fortawesome/pro-solid-svg-icons/faUserFriends'
import { StyleProp, View, Linking, ViewStyle } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'

import {
  getParsedPhoneNumber,
  isPossibleParsePhoneNumber,
} from '../../../modules/phoneNumber'
import { getCustomerDisplayName } from '../../Customers/Customer'
import Loading from '../../Shared/Loading'
import { ReservationRepContainer, ReservationRepWithDate } from '../RepView'
import { ReservationCoursesAndMemo } from '../ReservationDetails'

function Row({
  mode = 'inline',
  style,
  icon,
  children,
}: {
  mode?: 'inline' | 'inline-expanded'
  icon?: IconDefinition
  style?: StyleProp<ViewStyle>
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: mode === 'inline' ? 'center' : 'flex-start',
        },
        style,
      ]}
    >
      <View style={{ width: 18, marginRight: 14 }}>
        {icon && (
          <FontAwesomeIcon icon={icon} size={18} color={Colors.black50} />
        )}
      </View>
      {children}
    </View>
  )
}

export function DetailModalContent({
  restaurantId,
  data,
  isInPossibleRange,
  onPressApproval,
  onPressRejection,
}: {
  restaurantId: number
  data: RestaurantRequestReservation | undefined
  isInPossibleRange: boolean
  onPressApproval: () => void
  onPressRejection: () => void
}) {
  const { width, sm, md } = useResponsive()

  if (data == null) return <Loading />

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        flexDirection: 'column',
      }}
    >
      <ScrollView
        contentContainerStyle={[
          { padding: 24, paddingBottom: 120 },
          width >= md && { paddingHorizontal: 160 },
        ]}
      >
        <ReservationRepContainer
          style={{
            marginLeft: 0,
          }}
        >
          {data.reservation_list.complete_status === 'canceled' ? (
            <ReservationRepWithDate
              label={t('リクエストキャンセル日')}
              date={data.reservation_list.completed_at}
            />
          ) : (
            <ReservationRepWithDate
              label={t('リクエスト日')}
              date={data.created_at}
            />
          )}
        </ReservationRepContainer>
        <View
          style={{
            marginTop: 16,
            padding: 16,
            backgroundColor:
              data.reservation_list.complete_status === 'canceled'
                ? '#F4F4F4'
                : '#F6F4EE',
            gap: 4,
          }}
        >
          <View style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={Colors.primary}
              size={20}
            />
            <Text
              style={{ fontWeight: '600', fontSize: 18, color: Colors.black }}
            >
              {data.reservation_list.complete_status === 'canceled'
                ? t('このリクエスト予約はお客様によりキャンセルされました')
                : t('リクエスト予約が入りました')}
            </Text>
          </View>
          <Text style={{ fontSize: 14 }}>
            {data.reservation_list.complete_status === 'canceled'
              ? t(
                  'このリクエストに関しては、店舗様の方でご対応いただく必要はありません。'
                )
              : t('予約を確定する場合は「承認する」ボタンを押してください。')}
          </Text>
          <TouchableOpacity
            style={{ alignItems: 'center', flexDirection: 'row', gap: 4 }}
            onPress={() => {
              Linking.openURL(
                'https://help-for-restaurants.autoreserve.com/hc/ja/articles/40304740195353'
              )
            }}
          >
            <FontAwesomeIcon
              icon={faQuestionCircle}
              color={Colors.primary}
              size={16}
            />
            <Text style={{ fontSize: 14, color: Colors.primary }}>
              {t('リクエスト予約とは')}
            </Text>
          </TouchableOpacity>
        </View>
        <Text style={{ marginTop: 24, fontWeight: '600', fontSize: 18 }}>
          {t('日時・人数')}
        </Text>
        <View style={{ marginTop: 8, gap: 4 }}>
          <Row icon={faCalendarDays}>
            <Text>
              {`${dayjs(data.start_at).format(
                // eslint-disable-next-line ar-i18n/require-translation-ja
                'YYYY年MM月DD日（dd）'
              )}`}
            </Text>
          </Row>
          <Row icon={faClock}>
            <Text>
              {`${dayjs(data.start_at).format('HH:mm')} ~ ${dayjs(data.end_at).format('HH:mm')}${
                !isInPossibleRange
                  ? t('（{{start_at}}〜{{end_at}}で予約可能）', {
                      start_at: dayjs(data.start_at_min).format('HH:mm'),
                      end_at: dayjs(data.start_at_max).format('HH:mm'),
                    })
                  : ''
              }`}
            </Text>
          </Row>
          <Row icon={faUserFriends}>
            <Text>
              {t(
                '{{party_size}} ({{adult_party_size}}、{{child_party_size}})',
                {
                  party_size: t('{{count}}名様', { count: data.party_size }),
                  adult_party_size: t('大人{{count}}', {
                    count: data.adult_party_size,
                  }),
                  child_party_size: t('お子様{{count}}', {
                    count: data.child_party_size,
                  }),
                }
              )}
            </Text>
          </Row>
        </View>
        <View
          style={{ marginTop: 24, height: 1, backgroundColor: '#DDDDDD' }}
        />

        <Text style={{ marginTop: 24, fontWeight: '600', fontSize: 18 }}>
          {t('席の希望')}
        </Text>
        <View style={{ marginTop: 8, gap: 4 }}>
          <Row icon={faChair}>
            <Text>{data.restaurant_seat?.name}</Text>
          </Row>
        </View>

        <View
          style={{ marginTop: 24, height: 1, backgroundColor: '#DDDDDD' }}
        />

        <Text
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontWeight: '600',
            fontSize: 18,
          }}
        >
          {t('コース内容')}
        </Text>
        <View style={{ marginTop: 8 }}>
          <ReservationCoursesAndMemo
            restaurantId={restaurantId}
            reservationCourses={data.reservation_request_courses.map(
              (course) => ({
                partySize: course.party_size,
                restaurantCourse: course.restaurant_course,
                restaurantCourseQuestionAnswers:
                  course.restaurant_course_question_answers.map((answer) => ({
                    text: answer.text,
                    translatedText: answer.translated_text,
                    restaurantCourseQuestion: {
                      description:
                        answer.restaurant_course_question.description,
                    },
                    restaurantCourseQuestionSelections:
                      answer.restaurant_course_question_selections.map((s) => ({
                        description: s.description,
                      })),
                  })),
              })
            )}
            allergy={data.allergy}
            translatedAllergy={data.translated_allergy}
            memo={data.memo ?? ''}
            translatedMemo={data.translated_memo}
          />
        </View>

        <View
          style={{ marginTop: 24, height: 1, backgroundColor: '#DDDDDD' }}
        />

        <Text
          style={{
            marginTop: 24,
            marginBottom: 8,
            fontWeight: '600',
            fontSize: 18,
          }}
        >
          {t('予約者情報')}
        </Text>
        <View style={{ marginTop: 8, gap: 4 }}>
          <Row icon={faAddressCard}>
            <Text>
              {data.customer != null
                ? t('{{name}}様', {
                    name:
                      data.name != null
                        ? data.name
                        : getCustomerDisplayName(data.customer),
                  })
                : ''}
            </Text>
          </Row>
          <Row icon={faPhone}>
            <Text>
              {isPossibleParsePhoneNumber(data.phone_number)
                ? `${getParsedPhoneNumber(data.phone_number).countryCallingCode ? `+${getParsedPhoneNumber(data.phone_number).countryCallingCode}` : ''}${getParsedPhoneNumber(
                    data.phone_number
                  ).phone.replaceAll('-', '')}`
                : ''}
            </Text>
          </Row>
          <Row icon={faEnvelope}>
            <Text>{data.customer?.email}</Text>
          </Row>
          <Row icon={faFrown}>
            <Text>
              {data.customer?.allergy ?? t('アレルギーはありません。')}
            </Text>
          </Row>
        </View>
      </ScrollView>
      {data.reservation_list.complete_status === null && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: Colors.white,
            borderTopColor: '#DDDDDD',
            borderTopWidth: 1,
          }}
        >
          <View
            style={[
              {
                padding: 16,
                justifyContent: 'center',
                flexDirection: 'row',
                gap: 10,
              },
              width < sm && {
                paddingBottom: 32,
              },
            ]}
          >
            <Button
              mode="outline"
              variant="danger"
              style={[
                { paddingHorizontal: 0, height: 48 },
                width < sm
                  ? {
                      width: 102,
                    }
                  : {
                      flex: 1,
                    },
              ]}
              onPress={onPressRejection}
            >
              {t('拒否する')}
            </Button>
            <Button style={[{ height: 48, flex: 1 }]} onPress={onPressApproval}>
              {t('承認する')}
            </Button>
          </View>
        </View>
      )}
    </View>
  )
}
