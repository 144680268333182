import React, { useState } from 'react'

import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons/faCircleQuestion'
import { View, Text, TouchableOpacity } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Popover } from '@hello-ai/ar_shared/src/components/Popover'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { createStyles } from './styles'

type Props = {
  amount: number
  isDesktop: boolean
}

export function NeedConfirmReservationHeader({ amount, isDesktop }: Props) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)
  const styles = createStyles(isDesktop)
  const { width, sm } = useResponsive()
  const isMobile = width < sm

  return (
    <View style={styles.headerWrap}>
      <View style={styles.header}>
        <Text style={styles.headerText}>{t('要確認の予約')}</Text>
        <Text style={styles.headerCount}>
          ({amount}
          {t('件')})
        </Text>
        <Popover
          isOpen={isPopoverVisible}
          placement={isMobile ? 'bottomEnd' : 'bottomStart'}
          offsetX={isMobile ? 168 : -16}
          offsetY={-24}
          showArrowElement={false}
          anchor={
            <TouchableOpacity
              onPress={() => setIsPopoverVisible(!isPopoverVisible)}
            >
              <FontAwesomeIcon
                icon={faCircleQuestion}
                color={Colors.primary}
                size={16}
              />
            </TouchableOpacity>
          }
          onClose={() => setIsPopoverVisible(false)}
        >
          <View style={styles.popoverContent}>
            <Text style={styles.popoverText}>
              {t(
                '本日の予約のうち、確認優先度の高い予約件数を表示しています。'
              )}
            </Text>
            <Text style={styles.popoverText}>
              {t(
                'リクエスト承認待ち、スマート決済あり、ダブルブッキング、座席指定なしの予約を指しています。'
              )}
            </Text>
          </View>
        </Popover>
      </View>
    </View>
  )
}
