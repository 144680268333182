import React, { useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { View, ScrollView } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { Row, Header, HeaderCell, TextCell } from 'components/Sales'
import {
  getFormatLabel,
  SelectPeriodInput,
} from 'components/Sales/SelectPeriodInput'
import { CSVDownloadButton } from 'components/Shared/CsvDownloadButton'
import {
  getFetchPeriodParams,
  useRestaurantSalesByCategory,
  SalesByCategory as SalesByCategoryModel,
} from 'models/Sales'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import { useRestaurantId } from 'modules/useRestaurantId'

const CSVHeaders = [
  { value: 'name', label: t('カテゴリー名') },
  { value: 'total_amount', label: t('総売上') },
  { value: 'total_amount_ratio', label: t('構成比(総売上)') },
]

function convertDataFormat(rows: SalesByCategoryModel[]) {
  return rows.map((row) => {
    return {
      name: row.name,
      total_amount: numberWithDelimiter(row.total_amount),
      total_amount_ratio: (row.total_amount_ratio * 100).toFixed(1) + '%',
    }
  })
}

function SalesByCategory() {
  const restaurantId = useRestaurantId()
  const today = dayjs()

  const [selectedValue, setSelectedValue] = useState({
    start: today,
    end: today,
  })

  const [fetchParams, setFetchParams] = useState(selectedValue)

  const onChangeValues = (values: { start: dayjs.Dayjs; end: dayjs.Dayjs }) => {
    setSelectedValue(values)
  }

  const params = useMemo(() => {
    return {
      completed_at: getFetchPeriodParams(fetchParams),
    }
  }, [fetchParams])

  const { salesByCategory } = useRestaurantSalesByCategory(restaurantId, params)

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingVertical: 32,
        paddingHorizontal: 48,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            marginRight: 24,
            flex: 1,
          }}
        >
          <SelectPeriodInput
            disabled={salesByCategory === undefined}
            style={{
              backgroundColor: 'white',
              borderRadius: 8,
              height: 48,
            }}
            labelStyle={{
              fontSize: 18,
              fontWeight: '300',
            }}
            selectedStartValue={selectedValue.start}
            selectedEndValue={selectedValue.end}
            onChangeValues={onChangeValues}
          />
        </View>
        <Button
          mode="contained"
          variant="secondary"
          disabled={salesByCategory === undefined}
          width={160}
          height={48}
          roundness={24}
          onPress={() => {
            setFetchParams(selectedValue)
          }}
        >
          <Text style={{ fontWeight: '600', color: Colors.primary }}>
            {t('表示する')}
          </Text>
        </Button>
      </View>
      <View
        style={{
          marginTop: 32,
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.border,
        }}
      />
      <View
        style={{
          marginTop: 38,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '600',
              letterSpacing: -0.5,
              color: Colors.black,
            }}
          >
            {t('全てのカテゴリー')}
          </Text>
          <Text
            style={{
              fontSize: 24,
              fontWeight: '600',
              color: Colors.black,
            }}
          >
            {getFormatLabel(fetchParams.start)} ~{' '}
            {getFormatLabel(fetchParams.end)}
          </Text>
          <Text
            style={{
              marginTop: 10,
              fontSize: 14,
              lineHeight: 14,
              fontWeight: '300',
              color: Colors.black,
              opacity: 0.6,
            }}
          >
            {t(
              'カテゴリー別売上は、商品販売時点で所属していたカテゴリを基に集計しています'
            )}
          </Text>
        </View>
        <CSVDownloadButton
          data={
            salesByCategory !== undefined
              ? convertDataFormat(salesByCategory)
              : undefined
          }
          headers={CSVHeaders}
          fileName="sales_by_category"
        />
      </View>
      <ShadowBox
        style={{
          marginTop: 24,
          padding: 0,
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Header>
          <HeaderCell cellStyle={{ flex: 3 }} value={t('カテゴリー')} />
          <HeaderCell value={t('総売上')} />
          <HeaderCell value={t('割合')} />
        </Header>
        {salesByCategory.map((d, i) => {
          const isFirstItem = i === 0
          return (
            <React.Fragment key={d.id}>
              <View
                style={[
                  {
                    width: '100%',
                    paddingBottom: 4,
                  },
                  !isFirstItem && {
                    borderTopWidth: 1,
                    borderTopColor: Colors.black16,
                  },
                ]}
              >
                <Row>
                  <TextCell
                    cellStyle={{
                      flex: 3,
                      minHeight: 54,
                    }}
                    numberOfLines={2}
                    value={d.name}
                    style={{
                      fontWeight: '600',
                    }}
                  />
                  <TextCell
                    cellStyle={{ height: 54 }}
                    value={t('{{price}}円', {
                      price: numberWithDelimiter(d.total_amount),
                    })}
                    style={{
                      fontWeight: '600',
                    }}
                  />
                  <TextCell
                    cellStyle={{ height: 54 }}
                    value={(d.total_amount_ratio * 100).toFixed(1) + '%'}
                    style={{
                      fontWeight: '300',
                    }}
                  />
                </Row>
                {(d.child_table_menu_pages ?? []).map((child) => {
                  return (
                    <View
                      key={child.id}
                      style={{
                        width: '100%',
                        flexDirection: 'row',
                      }}
                    >
                      <Row>
                        <TextCell
                          cellStyle={{
                            flex: 3,
                            minHeight: 44,
                          }}
                          style={{
                            marginLeft: 24,
                            fontSize: 16,
                            opacity: 0.6,
                          }}
                          numberOfLines={2}
                          value={child.name}
                        />
                        <TextCell
                          cellStyle={{ height: 44 }}
                          style={{
                            fontSize: 16,
                            opacity: 0.6,
                          }}
                          value={t('{{price}}円', {
                            price: numberWithDelimiter(child.total_amount),
                          })}
                        />
                        <TextCell
                          cellStyle={{ height: 44 }}
                          style={{
                            fontSize: 16,
                            opacity: 0.6,
                          }}
                          value={
                            (child.total_amount_ratio * 100).toFixed(1) + '%'
                          }
                        />
                      </Row>
                    </View>
                  )
                })}
              </View>
            </React.Fragment>
          )
        })}
      </ShadowBox>
    </ScrollView>
  )
}

export default SalesByCategory
