import { Linking } from 'react-native'

export async function openURL(url: string) {
  try {
    const supported = await Linking.canOpenURL(url)
    if (!supported) {
      console.error(`Can't handle url: ${url}`)
      return
    }
    await Linking.openURL(url)
  } catch (err) {
    console.error('An error occurred', err)
  }
}
