import React from 'react'

import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

export type RequestMethod = 'phone_call' | 'business_permit'

interface SelectRequestMethodProps {
  onSelectMethod: (type: RequestMethod) => void
}

const Methods = [
  {
    type: 'phone_call',
    imageProps: {
      style: {
        width: 160,
        height: 150,
      },
      source: { uri: require('assets/images/phone_call.png') },
    },
    description: t(
      '電話番号による認証を行います。自動音声によるお電話でお伝えする認証番号を入力いただくと認証が完了します。'
    ),
    buttonText: t('店舗電話による認証'),
  },
  {
    type: 'business_permit',
    imageProps: {
      style: {
        width: 160,
        height: 150,
      },
      source: { uri: require('assets/images/business_permit.svg') },
    },
    description: t(
      '営業許可証とAutoReserve上の店舗ページを弊社にて確認し、認証を行います。認証までにはおよそ3営業日ほどかかります'
    ),
    buttonText: t('営業許可証による認証'),
  },
] as const

export default function SelectRequestMethod({
  onSelectMethod,
}: SelectRequestMethodProps) {
  return (
    <ScrollView
      style={{ flex: 1, paddingTop: 48, backgroundColor: Colors.black6 }}
      contentContainerStyle={{
        width: 872,
        borderRadius: 8,
        backgroundColor: Colors.white,
        paddingVertical: 40,
        paddingHorizontal: 24,
        marginHorizontal: 'auto',
      }}
    >
      <Text style={{ marginBottom: 40 }}>
        {t(
          '店舗アカウントの追加は、飲食店の方のみ可能です。以下のどちらかの方法で認証してください'
        )}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          columnGap: 20,
        }}
      >
        {Methods.map(({ imageProps, description, buttonText, type }, key) => (
          <View
            key={key}
            style={{
              borderRadius: 8,
              borderColor: Colors.border,
              borderWidth: 0.5,
              flex: 1,
              alignItems: 'center',
              padding: 24,
            }}
          >
            <Image {...imageProps} />
            <Text
              style={{
                fontSize: 12,
                fontWeight: '300',
                lineHeight: 18,
                color: Colors.black60,
                marginVertical: 24,
              }}
            >
              {description}
            </Text>
            <Button
              style={{
                width: 300,
                height: 48,
                paddingHorizontal: 24,
                paddingVertical: 12,
              }}
              onPress={() => onSelectMethod(type)}
            >
              {buttonText}
            </Button>
          </View>
        ))}
      </View>
    </ScrollView>
  )
}
