import React, { useLayoutEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import LinkText from 'components/Shared/LinkText'
import SignInHeader from 'components/Shared/SignInHeader'
import { authService, useToken } from 'models/Auth'
import { onError } from 'modules/swr'

const formLabelWidth = 162
const formLabelMarginRight = 24
const inputWidth = 420
const inputHeight = 64
export default function SignInSendLinks() {
  const { width, sm } = useResponsive()
  const token = useToken()

  const [email, setEmail] = useState('')

  const navigate = useNavigate()
  const location = useLocation()
  const from = (location.state as { from: string } | undefined)?.from

  const disabled = email === ''

  const onSubmit = async () => {
    if (disabled) return
    const { error } = await authService.sendSignInLinks(token, {
      email,
    })
    if (error) {
      onError(error)

      return
    }
    displayToastInfo(t('ログイン用リンクをメールアドレス宛に送信しました。'))
  }

  useLayoutEffect(() => {
    if (token != null) {
      navigate(from ?? '/', { replace: true })
    }
  }, [navigate, from, token])

  return (
    <View style={{ flexGrow: 1 }}>
      <SignInHeader />
      <View
        style={[
          {
            alignItems: 'center',
            justifyContent: 'center',
          },
          width < sm && {
            paddingHorizontal: 12,
          },
        ]}
      >
        <View
          style={{
            marginTop: 80,
            alignItems: 'center',
          }}
        >
          <Text>
            {t(
              'ご登録のメールアドレスを入力してください。ログイン用のリンクを送信します。'
            )}
          </Text>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            style={{ marginTop: 40, width: '100%', justifyContent: 'center' }}
            formLabel={<FormLabel value={t('メールアドレス')} />}
            formLabelContainerStyle={{
              width: formLabelWidth,
              marginRight: formLabelMarginRight,
            }}
            containerStyle={[
              {
                width: width < sm ? '100%' : inputWidth,
              },
            ]}
          >
            <TextInput
              placeholder="information@hello.ai"
              value={email}
              onChangeText={setEmail}
              onSubmitEditing={onSubmit}
              clearButtonMode="always"
              autoCapitalize="none"
              autoComplete="email"
              keyboardType="email-address"
              style={{
                width: '100%',
                height: inputHeight,
              }}
            />
          </FormGroup>
          <Button
            disabled={disabled}
            onPress={onSubmit}
            style={{
              marginTop: 48,
              width: width < sm ? '100%' : 278,
              height: 48,
            }}
          >
            {t('送信する')}
          </Button>
          <LinkText
            to="/sign_in"
            style={{
              fontSize: 16,
              marginTop: 24,
              color: Colors.primary,
            }}
          >
            {t('ログイン画面に戻る')}
          </LinkText>
        </View>
      </View>
    </View>
  )
}
