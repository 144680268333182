import React, { useCallback, useMemo, useState } from 'react'

import { View, Platform } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { SmartPayment as ISmartPayment } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { displayToastError } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import {
  cancelSmartPayment,
  captureSmartPayment,
} from '../../../models/TableOrder'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'

import PaymentCalculateModal from './PaymentCalculateModal'
import PaymentFailedModal from './PaymentFailedModal'
import PaymentSuccessedModal from './PaymentSuccessedModal'
import SelectCasherModal from './SelectCasherModal'

interface SmartPaymentProps {
  restaurantId: number
  restaurantReservationId: string
  fixedAmount?: number
}

export default function SmartPaymentView({
  restaurantId,
  restaurantReservationId,
  fixedAmount,
}: SmartPaymentProps) {
  const [step, setStep] = useState(0)
  const token = useToken()
  const [restaurantCrewMemberId, setRestaurantCrewMemberId] = useState<
    string | null
  >(null)
  const [successedSmartPayment, setSuccessedSmartPayment] =
    useState<ISmartPayment | null>(null)
  const navigation = useNavigation()
  const navigate = useNavigate()
  const [failedAmount, setFailedAmount] = useState(0)

  const onPressGoBack = useCallback(() => {
    if (Platform.OS === 'web') {
      navigate(-1)
    } else {
      navigation.goBack()
    }
  }, [navigate, navigation])

  const component = useMemo(() => {
    switch (step) {
      case 0:
        return (
          <SelectCasherModal
            restaurantId={restaurantId}
            defaultRestaurantCrewMemberId={restaurantCrewMemberId}
            onPressSubmit={(restaurantCrewMemberId) => {
              setRestaurantCrewMemberId(restaurantCrewMemberId)
              setStep(1)
            }}
            onPressCancel={onPressGoBack}
          />
        )
      case 1:
        return (
          <PaymentCalculateModal
            fixedAmount={fixedAmount}
            title={t('スマート決済で会計する')}
            amountLabel={t('お会計金額を入力')}
            description={t(
              'お会計金額を入力し、確定ボタンを押してください。\nお客様が予約時に登録されたクレジットカードで決済が行われます。'
            )}
            submitLabel={t('スマート決済を確定する')}
            onClose={onPressGoBack}
            onCancel={async () => {
              if (token == null || restaurantId == null) return
              const { error } = await cancelSmartPayment(
                token,
                restaurantId,
                restaurantReservationId
              )
              if (error != null) {
                onError(error)
                return
              }
              onPressGoBack()
            }}
            onSubmit={async (amount) => {
              if (
                token == null ||
                restaurantCrewMemberId == null ||
                restaurantId == null
              )
                return
              if (amount < 50) {
                displayToastError(
                  t(
                    'スマート決済で50円未満の支払いはできません。現金などでご対応ください'
                  )
                )
                return
              }
              const { data, error } = await captureSmartPayment(
                token,
                restaurantId,
                restaurantReservationId,
                {
                  amount,
                  restaurant_crew_member_id: restaurantCrewMemberId,
                }
              )
              if (error != null) {
                onError(error)
                setFailedAmount(amount)
                setStep(-1)
                return
              }
              if (data == null) return
              setSuccessedSmartPayment(data)
              setStep(2)
            }}
          />
        )
      case 2:
        return (
          successedSmartPayment != null &&
          restaurantId != null && (
            <PaymentSuccessedModal
              restaurantId={restaurantId}
              reservationId={restaurantReservationId}
              data={successedSmartPayment}
              isModalVisible
              onClose={onPressGoBack}
              onIssueReceipt={(data) => {
                if (!data) return
                setSuccessedSmartPayment(data)
              }}
            />
          )
        )
      default:
        return (
          <PaymentFailedModal
            failedAmount={failedAmount}
            onClose={onPressGoBack}
            isModalVisible
          />
        )
    }
  }, [
    step,
    restaurantCrewMemberId,
    onPressGoBack,
    restaurantId,
    restaurantReservationId,
    token,
    successedSmartPayment,
    failedAmount,
    fixedAmount,
  ])

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white }}>{component}</View>
  )
}
