import React, { useContext, useMemo } from 'react'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'
import { TouchableOpacity } from '../Touchables'
import { Text } from '../Text'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { Colors } from '../../constants/Colors'
import { FontAwesomeIcon } from '../FontAwesomeIcon'

type CheckboxMode = 'inline' | 'vertical'

type CheckboxContextValue<T> = {
  value: T[]
  onChange: (value: T[]) => void
}

const CheckboxContext = React.createContext<
  CheckboxContextValue<any> | undefined
>(undefined)

function useCheckboxProps<T>({
  value,
  checked,
  onChange,
}:
  | {
      value?: undefined
      checked?: boolean
      onChange: (value: boolean) => void
    }
  | {
      value: T
      checked?: undefined
      onChange?: undefined
    }) {
  const context = useContext(CheckboxContext) as
    | CheckboxContextValue<T>
    | undefined

  if (context === undefined && onChange === undefined) {
    throw new Error(
      'You must wrap Checkbox with CheckboxGroup or provide onChange prop to Checkbox'
    )
  }

  const onPress = () => {
    if (onChange !== undefined) {
      onChange(!checked)
      return
    }

    if (context !== undefined) {
      if (context.value.includes(value)) {
        context.onChange(context!.value.filter((item) => item !== value))
      } else {
        context.onChange([...context!.value, value])
      }
    }
  }

  function getChecked() {
    if (onChange !== undefined) {
      return checked
    }

    if (context !== undefined) {
      return context.value.includes(value)
    }
  }

  return {
    onPress,
    checked: getChecked(),
  }
}

export function CheckboxIcon({ checked }: { checked?: boolean }) {
  return (
    <View
      style={[
        {
          width: 24,
          height: 24,
          alignItems: 'center',
          justifyContent: 'center',
        },
      ]}
    >
      <View
        style={{
          width: 18,
          height: 18,
          backgroundColor: checked ? Colors.accent : 'white',
          borderRadius: 2,
          borderWidth: 2,
          borderColor: checked ? Colors.accent : Colors.black60,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {checked && <FontAwesomeIcon icon={faCheck} size={18} color="white" />}
      </View>
    </View>
  )
}

export function CheckboxLabel({
  value,
  style,
}: {
  value: string
  style?: StyleProp<TextStyle>
}) {
  return <Text style={style}>{value}</Text>
}

export function Checkbox<T>({
  variant = 'left',
  value,
  disabled,
  checked,
  onChange,
  checkboxLabel,
  checkboxLabelContainerStyle,
  style,
  testID,
}: {
  /** @deprecated */
  variant?: 'left' | 'right'
  disabled?: boolean
  checkboxLabel?: React.ReactNode
  checkboxLabelContainerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  testID?: string
} & (
  | {
      value?: undefined
      checked?: boolean
      onChange: (value: boolean) => void
    }
  | {
      value: T
      checked?: undefined
      onChange?: undefined
    }
)) {
  const { checked: checkboxChecked, onPress } = useCheckboxProps<T>(
    onChange !== undefined
      ? {
          checked,
          onChange,
        }
      : { value }
  )

  return (
    <TouchableOpacity
      disabled={disabled}
      onPressMinInterval={0}
      onPress={onPress}
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
        },
        disabled && {
          opacity: 0.4,
        },
        style,
      ]}
      testID={testID}
    >
      {variant === 'left' && <CheckboxIcon checked={checkboxChecked} />}
      <View
        style={[
          variant === 'left' && {
            marginLeft: 4,
          },
          variant === 'right' && {
            marginLeft: 4,
          },
          checkboxLabelContainerStyle,
        ]}
      >
        {checkboxLabel}
      </View>
      {variant === 'right' && <CheckboxIcon checked={checkboxChecked} />}
    </TouchableOpacity>
  )
}

export function CheckboxGroup<T>({
  mode = 'inline',
  value,
  onChange,
  children,
  checkboxContainerStyle,
  style,
}: {
  mode?: CheckboxMode
  value: T[]
  onChange: (value: T[]) => void
  children: React.ReactNode
  checkboxContainerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
}) {
  const checkboxContextValue = useMemo(() => {
    return {
      value,
      onChange,
    }
  }, [onChange, value])

  return (
    <CheckboxContext.Provider value={checkboxContextValue}>
      <View
        style={[
          mode === 'inline' && {
            flexDirection: 'row',
            marginTop: -16,
            marginLeft: -16,
            flexWrap: 'wrap',
          },
          style,
        ]}
      >
        {React.Children.map(children, (child, index) => {
          return (
            <View
              style={[
                mode === 'inline' && {
                  marginTop: 16,
                  marginLeft: 16,
                },
                mode === 'vertical' && {
                  marginTop: index === 0 ? 0 : 16,
                },
                checkboxContainerStyle,
              ]}
            >
              {child}
            </View>
          )
        })}
      </View>
    </CheckboxContext.Provider>
  )
}
