import React from 'react'

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { StyleProp, TextStyle, View, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { TableOrderItem as TableOrderItemModel } from '@hello-ai/ar_shared/src/types/ForR/TableOrderItem'
import { TableOrderItemPlan as TableOrderItemPlanModel } from '@hello-ai/ar_shared/src/types/ForR/TableOrderItemPlan'
import { TableOrder as TableOrderModel } from '@hello-ai/for_r_app/src/models/TableOrder'

import { useRestaurantCurrency } from '../../modules/useRestaurantCurrency'

import { TableProductOption } from './TableProductOption'

const chevronWidth = 24
const tHeadMargin = 12

function Header({ children }: { children: React.ReactNode }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        paddingTop: 16,
        borderBottomWidth: 0.5,
        borderColor: Colors.border,
        paddingBottom: 16,
        marginTop: 16,
      }}
    >
      {children}
    </View>
  )
}

function HeaderCell(props: React.ComponentProps<typeof TextCell>) {
  return <TextCell fontSize={16} fontWeight={'400'} {...props} />
}

export function Row({
  onPress,
  disabled,
  children,
  style,
}: {
  onPress?: React.ComponentProps<typeof TouchableOpacity>['onPress']
  disabled?: boolean
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  return (
    <TouchableOpacity
      style={[{ flexDirection: 'row', alignItems: 'center' }, style]}
      onPress={onPress}
      disabled={disabled}
    >
      {children}
    </TouchableOpacity>
  )
}

function Cell({
  isLead = false,
  flex,
  children,
}: {
  isLead?: boolean
  flex: number
  children?: React.ReactNode
}) {
  return (
    <View
      style={[
        {
          flex,
        },
        !isLead && {
          marginLeft: tHeadMargin,
        },
      ]}
    >
      {children}
    </View>
  )
}

function TextCell({
  isLead = false,
  numberOfLines,
  flex,
  fontSize = 18,
  fontWeight,
  textAlign,
  children,
}: {
  isLead?: boolean
  numberOfLines?: number
  flex: number
  fontSize?: TextStyle['fontSize']
  fontWeight?: TextStyle['fontWeight']
  textAlign?: TextStyle['textAlign']
  children?: React.ReactNode
}) {
  return (
    <Text
      style={[
        {
          flex,
          fontSize,
          fontWeight,
          textAlign,
        },
        !isLead && {
          marginLeft: tHeadMargin,
        },
      ]}
      numberOfLines={numberOfLines}
    >
      {children}
    </Text>
  )
}

function FixedCell({
  width,
  alignItems,
  justifyContent,
  children,
}: {
  width: number
  alignItems?: ViewStyle['alignItems']
  justifyContent?: ViewStyle['justifyContent']
  children?: React.ReactNode
}) {
  return (
    <View
      style={{
        width,
        alignItems,
        justifyContent,
      }}
    >
      {children}
    </View>
  )
}

export function IconEmptyCell() {
  return <FixedCell width={chevronWidth} />
}

function TableOrderItemPlan({
  tableOrderItemPlan,
  restaurant,
}: {
  tableOrderItemPlan: TableOrderItemPlanModel
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}) {
  const { formatCurrency } = useRestaurantCurrency(restaurant)

  return (
    <Row>
      <TextCell isLead flex={1} numberOfLines={1}>
        {dayjs(tableOrderItemPlan.created_at).format('HH:mm')}
      </TextCell>
      <TextCell flex={2} numberOfLines={1}>
        {tableOrderItemPlan.creator?.name}
      </TextCell>
      <Cell flex={4}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {tableOrderItemPlan.status === 'canceled' && (
            <View style={{ marginRight: 12 }}>
              <CanceledLabel />
            </View>
          )}
          <Text style={{ fontWeight: '600', flex: 1 }}>
            {tableOrderItemPlan.name}
          </Text>
        </View>
      </Cell>
      <TextCell textAlign="center" flex={1}>
        {tableOrderItemPlan.quantity}
      </TextCell>
      <Cell flex={1}>
        <Text style={{ textAlign: 'right' }}>
          {formatCurrency(tableOrderItemPlan.amount)}
        </Text>
      </Cell>
    </Row>
  )
}

export function CanceledLabel() {
  const { width, sm } = useResponsive()
  return (
    <View
      style={{
        width: width < sm ? 36 : 70,
        height: width < sm ? 18 : 28,
        backgroundColor: '#444444',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
      }}
    >
      <Text
        style={{
          color: 'white',
          fontWeight: '600',
          fontSize: width < sm ? 12 : 18,
        }}
      >
        {t('取消')}
      </Text>
    </View>
  )
}

function TableOrderItem({
  tableOrderItem,
  restaurant,
}: {
  tableOrderItem: TableOrderItemModel
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}) {
  const tableProduct = tableOrderItem.table_product
  const { formatCurrency } = useRestaurantCurrency(restaurant)

  return (
    <Row>
      <TextCell isLead flex={1} numberOfLines={1}>
        {tableOrderItem.ordered_at == null
          ? ''
          : dayjs(tableOrderItem.ordered_at).format('HH:mm')}
      </TextCell>
      <TextCell flex={2} numberOfLines={1}>
        {tableOrderItem.creator.name}
      </TextCell>
      <Cell flex={4}>
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          {tableOrderItem.status === 'canceled' && (
            <View style={{ marginRight: 12 }}>
              <CanceledLabel />
            </View>
          )}
          <Text style={{ fontWeight: '600', flex: 1 }}>
            {tableProduct.name}
          </Text>
        </View>
        {tableOrderItem.table_product_options.length > 0 && (
          <View style={{ flexDirection: 'row', marginTop: 12 }}>
            <View
              style={{
                backgroundColor: Colors.border,
                width: 4,
              }}
            />
            <View style={{ flex: 1, marginLeft: 12 }}>
              {tableOrderItem.table_product_options.map((option, index) => {
                return (
                  <View
                    key={option.id}
                    style={{ marginTop: index === 0 ? -1 : 8 }}
                  >
                    <TableProductOption tableProductOption={option} />
                  </View>
                )
              })}
            </View>
          </View>
        )}
      </Cell>
      <TextCell flex={1} textAlign="center">
        {tableOrderItem.quantity}
      </TextCell>
      <Cell flex={1}>
        {tableOrderItem.price_required ? (
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              size={16}
              color={Colors.caution}
            />
            <Text style={{ marginLeft: 6 }}>ASK</Text>
          </View>
        ) : (
          <Text style={{ textAlign: 'right' }}>
            {formatCurrency(tableOrderItem.amount)}
          </Text>
        )}
      </Cell>
    </Row>
  )
}

export function CustomerOrderHistoryTotal({
  tableOrder,
  restaurant,
}: {
  tableOrder: TableOrderModel
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}) {
  const { width, sm } = useResponsive()
  const { formatCurrency } = useRestaurantCurrency(restaurant)
  const textFlex = width < sm ? 2 : 1.5

  return (
    <View
      style={{
        borderTopWidth: 0.5,
        borderColor: Colors.border,
        alignItems: 'flex-end',
        marginTop: width < sm ? 12 : 32,
        paddingTop: width < sm ? 16 : 20,
      }}
    >
      <View style={{ width: width < sm ? '80%' : '60%' }}>
        {tableOrder.total_amount_components.map((component, index) => {
          return (
            <View
              style={[
                { flexDirection: 'row', alignItems: 'center' },
                width < sm
                  ? {
                      marginTop: index === 0 ? 0 : 12,
                    }
                  : {
                      marginTop: 12,
                    },
              ]}
              key={index}
            >
              <Text
                style={[
                  { flex: textFlex, textAlign: 'right' },
                  width < sm && {
                    fontSize: 12,
                  },
                ]}
              >
                {component.name}
              </Text>
              <Text
                style={[
                  { flex: 2, textAlign: 'right' },
                  width < sm && {
                    fontSize: 14,
                  },
                ]}
              >
                {formatCurrency(component.amount)}
              </Text>
            </View>
          )
        })}
        {tableOrder.net_amount !== undefined && (
          <View
            style={[
              {
                flexDirection: 'row',
                alignContent: 'center',
                display: 'flex',
              },
              width < sm
                ? {
                    marginTop: 20,
                  }
                : {
                    marginTop: 24,
                  },
            ]}
          >
            <View
              style={{
                flex: textFlex,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              {tableOrder.total_change_amount !== 0 && (
                <View
                  style={{
                    backgroundColor: Colors.black20,
                    padding: 4,
                    height: 33,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontWeight: '600',
                      fontSize: 12,
                    }}
                  >
                    {t('金額修正あり')}
                  </Text>
                </View>
              )}
              <Text
                style={{
                  marginLeft: 10,
                  textAlign: 'right',
                  fontSize: width < sm ? 14 : 22,
                  fontWeight: '600',
                }}
              >
                {t('お支払い金額')}
              </Text>
            </View>
            <Text
              style={{
                flex: 2,
                textAlign: 'right',
                fontSize: width < sm ? 14 : 22,
                fontWeight: '600',
              }}
            >
              {formatCurrency(tableOrder.net_amount)}
            </Text>
          </View>
        )}
      </View>
    </View>
  )
}

export interface CustomerOrderHistoryProps {
  tableOrder: TableOrderModel
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}
export const CustomerOrderHistory = React.memo(function TableOrderHistory({
  tableOrder,
  restaurant,
}: CustomerOrderHistoryProps) {
  const tableOrderItems = tableOrder.table_order_items

  return (
    <View>
      <Header>
        <HeaderCell isLead flex={1}>
          {t('時刻')}
        </HeaderCell>
        <HeaderCell flex={2}>{t('注文者')}</HeaderCell>
        <HeaderCell flex={4}>{t('商品名')}</HeaderCell>
        <HeaderCell flex={1} textAlign="center">
          {t('数量')}
        </HeaderCell>
        <HeaderCell flex={1} textAlign="right">
          {t('小計')}
        </HeaderCell>
      </Header>
      {tableOrder.table_order_item_plans.length > 0 && (
        <View
          style={{
            borderBottomWidth: 0.5,
            borderBottomColor: Colors.border,
            paddingBottom: 16,
            marginBottom: 8,
          }}
        >
          {tableOrder.table_order_item_plans.map(
            (tableOrderItemPlan, index) => {
              return (
                <View
                  key={`tableOrderItemPlan${index}`}
                  style={{ marginTop: 16 }}
                >
                  <TableOrderItemPlan
                    tableOrderItemPlan={tableOrderItemPlan}
                    restaurant={restaurant}
                  />
                </View>
              )
            }
          )}
        </View>
      )}
      {tableOrderItems != null && (
        <View>
          {tableOrderItems.map((tableOrderItem) => {
            return (
              <View
                key={`tableOrderItem${tableOrderItem.id}`}
                style={{ marginTop: 16 }}
              >
                <TableOrderItem
                  tableOrderItem={tableOrderItem}
                  restaurant={restaurant}
                />
              </View>
            )
          })}
        </View>
      )}
      <CustomerOrderHistoryTotal
        tableOrder={tableOrder}
        restaurant={restaurant}
      />
    </View>
  )
})
