import React, { useState } from 'react'

import { View, Platform, Linking } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import BroadcastTable from '@hello-ai/for_r_app/src/components/Message/Broadcast/Table'

import { useBroadcastMessages } from '../../../models/BroadcastMessage'
import { useRestaurant } from '../../../models/Restaurant'
import config from '../../../modules/config'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { Unavailable } from '../../Restaurant/Unavailable'

interface BroadcastListViewProps {
  restaurantId: number
}

export default function BroadcastListView({
  restaurantId,
}: BroadcastListViewProps) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const { data: restaurant } = useRestaurant(restaurantId)

  const { broadcastMessages } = useBroadcastMessages(restaurantId, { page })

  const isWeb = Platform.OS === 'web'

  const goToHistory = (broadcastMessageId: string) => {
    if (isWeb) {
      navigate(
        `/restaurants/${restaurantId}/broadcasts/${broadcastMessageId}/history`
      )
    }
    navigation.navigate('BroadcastHistory', {
      restaurantId,
      broadcastMessageId,
    })
  }

  const goToEdit = (broadcastMessageId: string) => {
    if (isWeb) {
      navigate(
        `/restaurants/${restaurantId}/broadcasts/${broadcastMessageId}/edit`
      )
      return
    }
    navigation.navigate('BroadcastForm', {
      broadcastMessageId,
    })
  }

  if (restaurant?.reservation_book_plan_type === 'entry') {
    return (
      <Unavailable
        description={t(
          'リクエスト予約機能のみをご利用中の場合、当機能のご利用はできません。ご利用を希望の方は、お問い合わせフォームからご連絡ください。'
        )}
        toService={`${config.webBaseUrl}/for_restaurants/reservation_book`}
        toContact={`${config.webBaseUrl}/for_restaurants/contact`}
        onPressService={() =>
          Linking.openURL(
            `${config.webBaseUrl}/for_restaurants/reservation_book`
          )
        }
        onPressContact={() =>
          Linking.openURL(`${config.webBaseUrl}/for_restaurants/contact`)
        }
      />
    )
  }

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <KeyboardAwareScrollView
        contentContainerStyle={{ paddingHorizontal: 48, paddingVertical: 24 }}
      >
        <Button
          mode="text"
          variant="primary"
          style={{
            backgroundColor: 'white',
            borderRadius: 24,
            marginBottom: 24,
          }}
          onPress={() => {
            if (isWeb) {
              navigate(`/restaurants/${restaurantId}/broadcasts/new`)
              return
            }
            navigation.navigate('BroadcastForm')
          }}
        >
          {({ textStyle }) => (
            <Text style={[{ fontSize: 14, fontWeight: '600' }, textStyle]}>
              {t('メッセージを作成')}
            </Text>
          )}
        </Button>
        <BroadcastTable
          broadcastItems={broadcastMessages?.data ?? []}
          onPressItem={(item) => {
            if (item.status === 'pending') {
              goToEdit(item.id)
            } else {
              goToHistory(item.id)
            }
          }}
          PaginationProps={{
            currentPage: broadcastMessages?.headerData?.currentPage ?? 0,
            totalPage: broadcastMessages?.headerData?.totalPages ?? 0,
            setPage: (page) => {
              setPage(page)
            },
          }}
        />
      </KeyboardAwareScrollView>
    </View>
  )
}
