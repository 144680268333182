import React from 'react'

import Svg, { SvgProps, Path } from 'react-native-svg'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

type TranslationButtonProps = {
  onPress?: () => void
  disabled?: boolean
  testID?: string
}

function TranslationIcon(props: SvgProps) {
  return (
    <Svg fill="none" {...props}>
      <Path
        fill="#AB935A"
        d="M2.4.8A2.402 2.402 0 0 0 0 3.2v9.6c0 1.324 1.076 2.4 2.4 2.4h19.2c1.324 0 2.4-1.076 2.4-2.4V3.2C24 1.876 22.924.8 21.6.8H2.4Zm19.2 1.8c.33 0 .6.27.6.6v9.6c0 .33-.27.6-.6.6H12V2.6h9.6ZM6.686 4.996l2.4 5.4a.75.75 0 1 1-1.372.608l-.334-.754H4.62l-.334.754a.753.753 0 0 1-.99.382.752.752 0 0 1-.382-.99l2.4-5.4c.12-.27.39-.446.686-.446.296 0 .566.176.686.446Zm.027 3.754L6 7.145 5.287 8.75h1.426ZM17.1 4.55a.752.752 0 0 0-.75.75v.15H14.4a.752.752 0 0 0-.75.75c0 .412.338.75.75.75h4.016A5.879 5.879 0 0 1 17.3 8.637l-.019-.018-.547-.548a.751.751 0 0 0-1.062 1.061l.454.454a6.424 6.424 0 0 1-.694.368l-.135.06a.753.753 0 0 0-.382.99.752.752 0 0 0 .99.382l.135-.06c.45-.199.877-.442 1.275-.727.161.112.322.217.491.318l.709.424a.75.75 0 0 0 .769-1.286l-.71-.424-.1-.06a7.338 7.338 0 0 0 1.485-2.452l.067-.17h.075c.412 0 .75-.337.75-.75a.752.752 0 0 0-.75-.75h-2.25V5.3a.752.752 0 0 0-.75-.75Z"
      />
    </Svg>
  )
}

export function TranslationButton(props: TranslationButtonProps) {
  return (
    <>
      <Button
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          paddingHorizontal: 0,
        }}
        mode="text"
        {...props}
      >
        <TranslationIcon
          style={{
            width: 24,
            height: 15,
          }}
        />
        <Text
          style={{
            marginLeft: 8,
            color: Colors.primary,
          }}
        >
          {t('自動翻訳')}
        </Text>
      </Button>
    </>
  )
}
