import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { faAngleRight } from '@fortawesome/pro-regular-svg-icons/faAngleRight'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange'
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { faTimesCircle } from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import { View, ScrollView, Platform } from 'react-native'
import { TabBar, TabView } from 'react-native-tab-view'

import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Checkbox } from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { hexWithOpacity } from '@hello-ai/ar_shared/src/modules/hexWithOpacity'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GOURMET_SITE_PROVIDER_TEXT_STYLE,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'

import { useRestaurant } from '../../models/Restaurant'
import {
  markAsReadRestaurantReservationActivityNotification,
  updateRestaurantReservationActivityNotifications,
  useRestaurantReservationActivityNotifications,
} from '../../models/RestaurantReservation'
import { useTableSeats } from '../../models/TableSeat'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { getCustomerDisplayName } from '../Customers/Customer'
import { Pagination } from '../Shared/Pagination'

const Routes = [
  { key: 'unread', title: t('未読') },
  { key: 'read', title: t('既読') },
]

export function Notifications({ restaurantId }: { restaurantId: number }) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const [currentReadNotificationPage, setCurrentReadNotificationPage] =
    useState(1)
  const [currentUnreadNotificationPage, setCurrentUnreadNotificationPage] =
    useState(1)
  const [listState, setListState] = useState<'unread' | 'read'>('unread')
  const [selectedItemIds, setSelectedItemIds] = useState<Set<string>>(new Set())
  const { data: restaurant } = useRestaurant(restaurantId)
  const { tableSeats } = useTableSeats(restaurantId, {})

  const isEntryOneSeat = useMemo(() => {
    if (restaurant == null || tableSeats == null) {
      return false
    }
    return (
      tableSeats.length <= 1 &&
      restaurant.reservation_book_plan_type === 'entry'
    )
  }, [restaurant, tableSeats])

  const renderNotificationIcon = (
    message_type: NonNullable<
      ReturnType<typeof useRestaurantReservationActivityNotifications>['data']
    >['data'][number]['message_type']
  ) => {
    let icon = null
    let color

    switch (message_type) {
      case 'canceled':
        icon = faTimesCircle
        color = Colors.caution
        break
      case 'modified':
        icon = faExchange
        color = Colors.black60
        break
      case 'requested':
      case 'modification_requested':
        icon = faExclamationCircle
        color = Colors.primary
        break
      case 'reserved':
        icon = faCheckCircle
        color = Colors.accent
        break
    }
    if (icon == null) {
      return null
    }
    return <FontAwesomeIcon icon={icon} color={color} size={20} />
  }

  const { data: unreadData, mutate: mutateUnreadData } =
    useRestaurantReservationActivityNotifications(
      restaurantId,
      'unread',
      currentUnreadNotificationPage
    )

  const { data: readData, mutate: mutateReadData } =
    useRestaurantReservationActivityNotifications(
      restaurantId,
      'read',
      currentReadNotificationPage
    )

  const data = useMemo(() => {
    if (listState === 'unread') {
      return unreadData
    } else {
      return readData
    }
  }, [listState, unreadData, readData])

  const totalPage = useMemo(() => {
    if (data?.headerData?.totalPages != null) {
      return data.headerData.totalPages === 0 ? 1 : data.headerData.totalPages
    }
    return 1
  }, [data])

  const isAllChecked = useMemo(() => {
    if (data != null) {
      if (data.data.length === 0) {
        return false
      }
      return data.data.every((item) => selectedItemIds.has(item.id))
    } else {
      return false
    }
  }, [data, selectedItemIds])

  const currentNotificationPage = useMemo(() => {
    if (listState === 'unread') {
      return currentUnreadNotificationPage
    } else {
      return currentReadNotificationPage
    }
  }, [listState, currentUnreadNotificationPage, currentReadNotificationPage])

  const setCurrentNotificationPage = useCallback(
    (page: number) => {
      if (listState === 'unread') {
        setCurrentUnreadNotificationPage(page)
      } else {
        setCurrentReadNotificationPage(page)
      }
    },
    [listState]
  )

  useEffect(() => {
    setSelectedItemIds(new Set())
  }, [listState])

  return (
    <View
      style={{
        flex: 1,
        position: 'relative',
      }}
    >
      <ScrollView
        contentContainerStyle={{
          margin: width < sm ? 24 : 48,
          alignItems: 'stretch',
          paddingBottom: 120,
        }}
      >
        <View style={{ height: 48, justifyContent: 'center' }}>
          <Checkbox
            checked={isAllChecked}
            checkboxLabel={
              <Text style={{ fontSize: 18, color: Colors.black }}>
                {t('すべて選択')}
              </Text>
            }
            onChange={(value) => {
              setSelectedItemIds(() =>
                value ? new Set(data?.data.map((item) => item.id)) : new Set()
              )
            }}
          />
        </View>
        <View
          style={{
            marginTop: 16,
            borderRadius: 8,
            backgroundColor: Colors.white,
            shadowColor: Colors.black,
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
          }}
        >
          <TabView
            navigationState={{
              index: listState === 'unread' ? 0 : 1,
              routes: Routes,
            }}
            renderScene={() => (
              <>
                {data?.data.length === 0 && (
                  <View
                    style={{
                      height: 120,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 18,
                        fontWeight: '600',
                        color: Colors.black60,
                      }}
                    >
                      {t('予約のお知らせはありません')}
                    </Text>
                  </View>
                )}
                {data?.data.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <TouchableOpacity
                      style={{
                        paddingVertical: width < sm ? 20 : 16,
                        paddingHorizontal: width < sm ? 16 : 24,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: 16,
                      }}
                      onPress={async () => {
                        if (listState === 'unread') {
                          markAsReadRestaurantReservationActivityNotification(
                            restaurantId,
                            {
                              reservation_activity_notification: {
                                uuids: [item.id],
                              },
                            }
                          )
                          mutateUnreadData()
                        }
                        switch (item.notifiable_type) {
                          case 'Reservation':
                            if (Platform.OS === 'web') {
                              navigate(
                                `/restaurants/${restaurantId}/reservations/${item.notifiable.id}`
                              )
                            } else {
                              navigation.navigate('ReservationsShow', {
                                restaurantReservationId: item.notifiable.id,
                              })
                            }
                            break
                          case 'ReservationElement': {
                            const startAt = dayjs(item.notifiable.start_at)
                            if (Platform.OS === 'web') {
                              if (isEntryOneSeat) {
                                if (
                                  item.notifiable.reservation
                                    ?.reservation_change_request
                                ) {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/change_requests/${item.notifiable.reservation.uuid}`
                                  )
                                } else {
                                  navigate(
                                    `/restaurants/${restaurantId}/reservations/requests/${item.notifiable.id}`
                                  )
                                }
                              } else {
                                navigate(
                                  `/restaurants/${
                                    restaurantId
                                  }/reservations?date=${startAt.format(
                                    'YYYY-MM-DD'
                                  )}&startTime=${
                                    startAt.hour() * 3600 +
                                    startAt.minute() * 60
                                  }&m=chart`
                                )
                              }
                            } else {
                              if (isEntryOneSeat) {
                                if (
                                  item.notifiable.reservation
                                    ?.reservation_change_request != null
                                ) {
                                  navigation.navigate(
                                    'ChangeRequestReservation',
                                    {
                                      data: item.notifiable.reservation,
                                      customer: item.notifiable.customer,
                                      restaurantReservation:
                                        item.notifiable.reservation,
                                    }
                                  )
                                  return
                                }
                                navigation.navigate('RequestReservation', {
                                  id: item.notifiable.id,
                                })
                              } else {
                                navigation.navigate('Reservations', {
                                  dateString: startAt.format('YYYY-MM-DD'),
                                  startTime:
                                    startAt.hour() * 3600 +
                                    startAt.minute() * 60,
                                  mode: 'chart',
                                })
                              }
                            }
                            break
                          }
                        }
                      }}
                    >
                      <View
                        style={{
                          flex: 1,
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          justifyContent: 'space-between',
                        }}
                      >
                        <View
                          style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: width < sm ? 8 : 16,
                            flex: 1,
                          }}
                        >
                          <Checkbox
                            checked={selectedItemIds.has(item.id)}
                            checkboxLabel={<></>}
                            onChange={(value) => {
                              setSelectedItemIds((prev) => {
                                if (value) {
                                  return new Set(prev.add(item.id))
                                } else {
                                  prev.delete(item.id)
                                  return new Set(prev)
                                }
                              })
                            }}
                          />
                          {listState === 'unread' && (
                            <View
                              style={{
                                width: 6,
                                height: 6,
                                borderRadius: 3,
                                backgroundColor:
                                  item.status === 'unread'
                                    ? Colors.accent
                                    : 'transparent',
                              }}
                            />
                          )}
                          <View style={{ gap: width < sm ? 8 : 16, flex: 1 }}>
                            <View style={{ gap: 4 }}>
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                  gap: 4,
                                }}
                              >
                                {renderNotificationIcon(item.message_type)}
                                <Text
                                  style={{
                                    fontWeight:
                                      listState === 'read' ? '400' : '600',
                                    fontSize: 18,
                                    color: Colors.black,
                                  }}
                                >
                                  {item.title}
                                </Text>
                              </View>
                              {width < sm && (
                                <Text
                                  style={{
                                    fontSize: 12,
                                    color: Colors.black60,
                                  }}
                                >
                                  {dayjs(item.created_at).format('llll')}
                                </Text>
                              )}
                            </View>
                            <View style={{ gap: 4, flex: 1 }}>
                              {item.source !== 'autoreserve' && (
                                <View style={{ flexDirection: 'row', flex: 1 }}>
                                  <Text
                                    style={[
                                      {
                                        fontSize: 10,
                                        fontWeight: '600',
                                        lineHeight: 10,
                                        textAlign: 'center',
                                        paddingVertical: 2,
                                        paddingHorizontal: 8,
                                        borderRadius: 2,
                                        overflow: 'hidden',
                                      },
                                      GOURMET_SITE_PROVIDER_TEXT_STYLE[
                                        item.source
                                      ],
                                    ]}
                                  >
                                    {
                                      GOURMET_SITE_PROVIDER_SOURCE[item.source]
                                        .label
                                    }
                                  </Text>
                                </View>
                              )}
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: Colors.black,
                                  lineHeight: 14,
                                }}
                              >
                                {t('来店日：{{date}}', {
                                  date: dayjs(item.notifiable.start_at).format(
                                    'll'
                                  ),
                                })}
                              </Text>
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: Colors.black,
                                  lineHeight: 14,
                                }}
                              >
                                {t('来店時刻：{{time}}', {
                                  time: dayjs(item.notifiable.start_at).format(
                                    'LT'
                                  ),
                                })}
                              </Text>
                              <Text
                                style={{
                                  fontSize: 14,
                                  color: Colors.black,
                                  lineHeight: 14,
                                }}
                              >
                                {t('予約者：{{name}}様（合計：{{count}}名）', {
                                  name:
                                    item.notifiable_type ===
                                    'ReservationElement'
                                      ? (item.notifiable.name ??
                                        getCustomerDisplayName(
                                          item.notifiable.customer
                                        ))
                                      : getCustomerDisplayName(
                                          item.notifiable.customers[0]
                                        ),
                                  count: item.notifiable.party_size,
                                })}
                              </Text>
                            </View>
                          </View>
                        </View>
                        {width >= sm && (
                          <Text
                            style={{
                              fontSize: 12,
                              color: Colors.black60,
                              position: 'absolute',
                              top: 0,
                              right: 0,
                            }}
                          >
                            {dayjs(item.created_at).format('llll')}
                          </Text>
                        )}
                      </View>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        size={16}
                        color={Colors.black80}
                      />
                    </TouchableOpacity>
                    {index !== data.data.length - 1 && (
                      <View
                        style={{
                          height: 1,
                          backgroundColor: Colors.black16,
                          marginHorizontal: width < sm ? 16 : 24,
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </>
            )}
            onIndexChange={(index) => {
              setListState(index === 0 ? 'unread' : 'read')
            }}
            renderTabBar={(props) => (
              <TabBar
                {...props}
                activeColor={Colors.primary}
                inactiveColor={
                  width < sm
                    ? Colors.black60
                    : hexWithOpacity(0.5, Colors.primary)
                }
                indicatorStyle={{
                  backgroundColor: Colors.primary,
                  height: 3,
                }}
                tabStyle={{
                  minHeight: width < sm ? 60 : 72,
                }}
                style={{
                  borderTopRightRadius: 8,
                  borderTopLeftRadius: 8,
                  backgroundColor: 'white',
                }}
                renderLabel={({ route, focused }) => {
                  return (
                    <View
                      style={{
                        flex: 1,
                        flexDirection: 'row',
                        gap: 8,
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Text
                        style={{
                          color: focused ? Colors.primary : Colors.black60,
                          fontWeight: '600',
                          fontSize: 16,
                        }}
                      >
                        {route.key === 'unread' ? t('未読') : t('既読')}
                      </Text>
                      {route.key === 'unread' &&
                        (unreadData?.headerData?.totalCount ?? 0) > 0 && (
                          <View
                            style={{
                              width: 20,
                              height: 20,
                              borderRadius: 10,
                              backgroundColor: Colors.caution,
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Text
                              style={{
                                fontWeight: '600',
                                color: Colors.white,
                                fontSize: 12,
                              }}
                            >
                              {unreadData?.headerData?.totalCount}
                            </Text>
                          </View>
                        )}
                    </View>
                  )
                }}
              />
            )}
          />
          <View
            style={{
              padding: 16,
              alignItems: 'center',
              justifyContent: 'center',
              borderTopWidth: 1,
              borderTopColor: Colors.black16,
            }}
          >
            <Pagination
              currentPage={currentNotificationPage}
              totalPage={totalPage}
              setPage={(page) => setCurrentNotificationPage(page)}
            />
          </View>
        </View>
      </ScrollView>
      {selectedItemIds.size > 0 && (
        <View
          style={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            paddingHorizontal: width < sm ? 16 : undefined,
            paddingTop: 16,
            paddingBottom: 32,
            backgroundColor: Colors.white,
            borderTopWidth: 1,
            borderTopColor: Colors.black16,
            flexDirection: width < sm ? 'column-reverse' : 'row',
            gap: 16,
            alignItems: width < sm ? 'stretch' : 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            mode="outline"
            variant="primary"
            style={{
              flex: width < sm ? undefined : 1,
              maxWidth: width < sm ? undefined : 348,
              height: 48,
            }}
            onPress={() => setSelectedItemIds(new Set())}
          >
            {t('キャンセル')}
          </Button>
          <AsyncButton
            mode="contained"
            variant="primary"
            style={{
              flex: width < sm ? undefined : 1,
              maxWidth: width < sm ? undefined : 348,
              height: 48,
            }}
            onPress={async () => {
              await updateRestaurantReservationActivityNotifications(
                restaurantId,
                listState === 'read' ? 'unread' : 'read',
                Array.from(selectedItemIds)
              )
              setSelectedItemIds(new Set())
              mutateReadData()
              mutateUnreadData()
            }}
          >
            {listState === 'read' ? t('未読にする') : t('既読にする')}
          </AsyncButton>
        </View>
      )}
    </View>
  )
}
