import React, { useImperativeHandle } from 'react'

import * as Sentry from '@sentry/browser'
import {
  useElements,
  useStripe,
  CardNumberElement,
} from '@stripe/react-stripe-js'
import { View } from 'react-native'

import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { displayToastError } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import logoAmex from 'assets/images/card_brands/amex-logomark-img.png'
import logoDiners from 'assets/images/card_brands/diners-logomark-img.png'
import logoDiscover from 'assets/images/card_brands/discover-logomark-img.png'
import logoJcb from 'assets/images/card_brands/jcb-logomark-img.png'
import logoMc from 'assets/images/card_brands/mc-logomark-img.png'
import logoVisa from 'assets/images/card_brands/visa-logomark-img.png'

import { CardCvcInput, CardExpiryInput, CardNumberInput } from './CardInput'

const FormPayments = React.forwardRef((_props, ref) => {
  const elements = useElements()
  const stripe = useStripe()

  useImperativeHandle(ref, () => {
    return {
      async addCreditCard() {
        try {
          if (stripe == null) return
          if (elements == null) return

          const { token, error } = await (
            await stripe
          ).createToken(elements.getElement(CardNumberElement)!)
          if (error) {
            displayToastError(
              t('カード情報が間違っています') + ': ' + error.message
            )
            return
          }

          return token.id
        } catch (err) {
          console.error(err)
          Sentry.captureException(err)
        }
      },
    }
  }, [stripe, elements])

  return (
    <>
      <FormGroup
        style={{ alignItems: 'baseline', paddingVertical: 24 }}
        formLabel={<FormLabel value={t('カード番号')} required />}
      >
        <CardNumberInput style={{ padding: 16 }} />
        <Text style={{ marginTop: 16, fontSize: 14 }}>
          {t('以下のクレジットカードを使用いただけます')}
        </Text>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: 8,
            columnGap: 8,
          }}
        >
          <Image
            source={{ uri: logoVisa }}
            resizeMode="contain"
            style={{
              width: 45,
              height: 22.5,
            }}
          />
          <Image
            source={{ uri: logoMc }}
            resizeMode="contain"
            style={{
              width: 39,
              height: 28,
            }}
          />
          <Image
            source={{ uri: logoJcb }}
            resizeMode="contain"
            style={{
              width: 36.48,
              height: 28,
            }}
          />
          <Image
            source={{ uri: logoDiners }}
            resizeMode="contain"
            style={{
              width: 34.13,
              height: 25,
            }}
          />
          <Image
            source={{ uri: logoAmex }}
            resizeMode="contain"
            style={{
              width: 32,
              height: 32,
            }}
          />
          <Image
            source={{ uri: logoDiscover }}
            resizeMode="contain"
            style={{
              width: 40.09,
              height: 25,
            }}
          />
        </View>
      </FormGroup>

      <FormGroup
        style={{ alignItems: 'baseline', paddingVertical: 24 }}
        formLabel={<FormLabel value={t('有効期限')} required />}
      >
        <CardExpiryInput style={{ padding: 16 }} />
      </FormGroup>

      <FormGroup
        style={{ alignItems: 'baseline', paddingVertical: 24 }}
        formLabel={<FormLabel value={t('セキュリティコード')} required />}
      >
        <CardCvcInput style={{ padding: 16 }} />
      </FormGroup>
    </>
  )
})

export default FormPayments
