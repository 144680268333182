import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { SalesDashboardData } from '../models/Sale'

export const periodTypeLabels = {
  monthly: t('月'),
  daily: t('日'),
  hourly: t('時'),
} as const

export type PeriodType = keyof typeof periodTypeLabels
export type ValueType = 'amount' | 'ratio'
export type SalesType = 'all' | 'table' | 'shop'

function getChartLabel(date: string, periodType: PeriodType): string {
  switch (periodType) {
    case 'monthly':
      // eslint-disable-next-line ar-i18n/require-translation-ja
      return dayjs(date).format('M月')
    case 'daily':
      return dayjs(date).format('DD') // 数が多く、軸が潰れる可能性があるので、日を省略
    case 'hourly':
      // eslint-disable-next-line ar-i18n/require-translation-ja
      return dayjs(date).format('HH時')
  }
}

export function getFetchParamLabel({
  periodType,
  year,
  month,
  day,
}: {
  periodType: PeriodType
  year: number
  month: number
  day: number
}): string {
  switch (periodType) {
    case 'monthly':
      return t('{{year}}年', { year })
    case 'daily':
      return t('{{year}}年{{month}}月', { year, month })
    case 'hourly':
      return t('{{year}}年{{month}}月{{day}}日', {
        year,
        month,
        day: day.toString().padStart(2, '0'),
      })
  }
}

export function getCSVHeaders(periodType: PeriodType): Array<{
  value: string
  label: string
}> {
  return [
    { value: 'salesType', label: t('販売方法') },
    { value: 'date', label: t('日付') },
    { value: 'gross', label: t('総売上') },
    { value: 'net', label: t('純売上') },
    { value: 'fee', label: t('手数料') },
    {
      value: 'amountDelta',
      label: t('前{{period}}差(総売上)', {
        period: periodTypeLabels[periodType],
      }),
    },
  ]
}

export function generateChartData(
  data: SalesDashboardData['data'],
  periodType: PeriodType,
  valueType: ValueType
) {
  const newData = [...data]
  newData.sort((a, b) => (a.time > b.time ? 1 : a.time === b.time ? 0 : -1))
  const labels = newData.map((d) => getChartLabel(d.time, periodType))
  if (valueType === 'amount') {
    const grossSales = newData.map((d, i) => ({
      x: labels[i],
      y: d.amount,
      name: t('総売上'),
    }))
    const netSales = newData.map((d, i) => ({
      x: labels[i],
      y: d.amount - d.application_fee_amount,
      name: t('純売上'),
    }))

    const grossProfitAmounts = newData.map((d, i) => ({
      x: labels[i],
      y: d.gross_profit_amount,
      name: t('粗利益'),
    }))
    const datasets = [
      {
        data: grossSales,
        color: '#AB9355',
      },
      {
        data: netSales,
        color: '#009688',
      },
      { data: grossProfitAmounts, color: '#9B45B9' },
    ]
    return {
      datasets,
      legends: [t('総売上'), t('純売上'), t('粗利益')],
    }
  }

  const grossProfitRatio = newData.map((d, i) => ({
    x: labels[i],
    y: d.gross_profit_ratio * 100,
    name: t('粗利率'),
  }))
  const datasets = [{ data: grossProfitRatio, color: '#9B45B9', type: 'line' }]
  return {
    datasets,
    legends: [t('粗利率')],
  }
}

export function getFetchPeriodParams({
  periodType,
  year,
  month,
  day,
}: {
  periodType: PeriodType
  year: number
  month: number
  day: number
}): {
  gte: string
  lte: string
} {
  switch (periodType) {
    case 'monthly': {
      const base = dayjs().year(year)
      const gte = base.startOf('year').toISOString()
      const lte = base.endOf('year').toISOString()
      return {
        gte,
        lte,
      }
    }
    case 'daily': {
      const base = dayjs()
        .year(year)
        .month(month - 1)
      const gte = base.startOf('month').toISOString()
      const lte = base.endOf('month').toISOString()
      return {
        gte,
        lte,
      }
    }
    case 'hourly': {
      const base = dayjs()
        .year(year)
        .month(month - 1)
        .date(day)
      const gte = base.startOf('day').toISOString()
      const lte = base.endOf('day').toISOString()
      return {
        gte,
        lte,
      }
    }
  }
}
