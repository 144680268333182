import {
  CreateRestaurantCustomerParams,
  RestaurantCustomer,
  UpdateRestaurantCustomerParams,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomer'

import axios, { wrapResponse } from '../modules/axios'
import { mutate, onError, swrKey } from '../modules/swr'

import { Restaurant } from './Restaurant'

export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomer'

export async function createRestaurantCustomer(
  token: string | null,
  restaurantId: Restaurant['id'],
  params: CreateRestaurantCustomerParams
) {
  const { response, error } = await wrapResponse(
    axios.post<RestaurantCustomer>(
      `/restaurants/${restaurantId}/restaurant_customers`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/restaurant_customers/${response.data.id}`
      ),
      response.data
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantCustomer: response?.data,
    error,
  }
}

export async function updateRestaurantReservationCustomer(
  token: string,
  restaurantId: Restaurant['id'],
  params: UpdateRestaurantCustomerParams
) {
  const { response, error } = await wrapResponse(
    axios.patch<RestaurantCustomer>(
      `/restaurants/${restaurantId}/restaurant_customers`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/restaurant_customers/${response.data.id}`
      ),
      response.data
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantCustomer: response?.data,
    error,
  }
}
