import React, { useState } from 'react'
import type { ComponentProps, ReactNode } from 'react'

import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

type MobileDatePickerProps = ComponentProps<typeof MobileDatePicker>

export default function DatePicker(
  props: Omit<MobileDatePickerProps, 'onAccept' | 'onClose'> & {
    triggerElement?: (props: { onPress: () => void }) => ReactNode
  }
) {
  const { triggerElement, localeText, slotProps, onChange, ...muiProps } = props
  const [open, setOpen] = useState(false)

  return (
    <>
      {triggerElement?.({
        onPress: () => {
          setOpen(true)
        },
      })}
      <MobileDatePicker
        localeText={{
          toolbarTitle: '',
          ...localeText,
        }}
        slotProps={{
          ...slotProps,
          toolbar: {
            toolbarFormat: t('M月D日（dd）'),
            ...slotProps?.toolbar,
          },
          calendarHeader: {
            format: t('YYYY年MM月'),
            ...slotProps?.calendarHeader,
          },
          textField: {
            variant: 'standard',
            ...slotProps?.textField,
          },
          dialog: {
            ...(triggerElement != null
              ? {
                  onClose: () => {
                    setOpen(false)
                  },
                }
              : {}),
            ...slotProps?.dialog,
          },
        }}
        // モーダルの外側をクリックしたときも、onAcceptが呼ばれるため、
        // 以前までの挙動と同じにするために、ここでonChangeを呼び出す
        // https://github.com/mui/mui-x/issues/10000
        onAccept={(date, context) => {
          if (onChange != null && date != null) {
            onChange(date, context)
          }
        }}
        {...muiProps}
        {...(triggerElement != null
          ? {
              sx: {
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                visibility: 'hidden',
              },
              open,
              onClose: () => {
                setOpen(false)
              },
            }
          : {})}
      />
    </>
  )
}
