import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import RestaurantReports from 'pages/Restaurants/Reports'
import TableBalancingCashDrawersShow from 'pages/Restaurants/TableBalancingCashDrawers/Show'

const routes: StackRoutes = {
  Index: {
    title: t('日報・月報'),
    fullPath: '/restaurants/:id/reports',
    relativePath: `/`,
    exact: true,
    element: <RestaurantReports />,
  },
  BalancingCashDrawersShow: {
    title: t('レジ点検詳細'),
    fullPath: `/restaurants/:id/reports/:date/balancing_cash_drawers/:balancing_cash_drawer_id`,
    relativePath: `/:date/balancing_cash_drawers/:balancing_cash_drawer_id`,
    element: <TableBalancingCashDrawersShow />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="../../" />,
    },
  },
}

export default function ReportsStack() {
  return <Stack routes={routes} />
}
