import { Owner } from '@hello-ai/ar_shared/src/types/ForR/Owner'

export type Token = string

export interface Auth {
  id: Owner['id']
  token: Token
}

export const AUTH_KEY = 'ForRWeb:auth' as const

export function useToken(): Token | null {
  const authData = localStorage.getItem(AUTH_KEY)
  if (authData === null || authData === '') {
    return null
  }
  try {
    const auth = JSON.parse(authData)
    return auth?.token ?? null
  } catch {
    return null
  }
}
