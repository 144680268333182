import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import TableUnlimitedOrderPlans from 'pages/Menus/TableUnlimitedOrderPlans'
import TableUnlimitedOrderPlanForm from 'pages/Menus/TableUnlimitedOrderPlans/Form'

const routes: StackRoutes = {
  Index: {
    title: t('放題プラン'),
    fullPath: `/restaurants/:id/unlimited_order_plans`,
    relativePath: `/`,
    exact: true,
    element: <TableUnlimitedOrderPlans />,
  },
  Show: {
    title: t('放題プランを追加'),
    fullPath: `/restaurants/:id/unlimited_order_plans/new`,
    relativePath: `/new`,
    element: <TableUnlimitedOrderPlanForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('放題プランを編集'),
    fullPath: `/restaurants/:id/unlimited_order_plans/:table_unlimited_order_plan_id/edit`,
    relativePath: `/:table_unlimited_order_plan_id/edit`,
    element: <TableUnlimitedOrderPlanForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="../.." />,
    },
  },
}

export default function TableUnlimitedOrderPlansStack() {
  return <Stack routes={routes} />
}
