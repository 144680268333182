import React from 'react'

import { useParams } from 'react-router'

import RevisionSmartPaymentView from '@hello-ai/for_r_app/src/components/Reservations/SmartPayment/RevisionSmartPaymentView'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function RevisionSmartPayment() {
  const restaurantId = useRestaurantId()
  const { reservationId } = useParams<{ reservationId: string }>()

  if (reservationId == null) return null

  return (
    <RevisionSmartPaymentView
      restaurantId={restaurantId}
      restaurantReservationId={reservationId}
    />
  )
}
