import { client } from '@hello-ai/for_r_app/src/modules/api'
import {
  GetBusinessUserRequest,
  GetInvitationRequest,
  IndexRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_service'
import { BusinessUserClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_service.client'

import { wrapAspidaResponse } from 'modules/axios'
import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { mutate, onError, swrKey } from 'modules/swr'

import { AUTH_KEY, useToken } from '../modules/token'

export const businessUserService = createRpcService(BusinessUserClient)
export const businessUserClient = businessUserService.client

export function useBusinessUserInvitation(params: GetInvitationRequest) {
  const token = useToken()

  const { data, error, mutate } = useSWR(
    swrKey(token, 'BusinessUsers/GetInvitation', params),
    () =>
      businessUserClient.getInvitation(params, rpcOptions({ token })).response
  )

  return {
    data,
    error,
    mutate,
  }
}

export function useBusinessUser(params: GetBusinessUserRequest) {
  const token = useToken()

  const {
    data: businessUser,
    error,
    mutate,
  } = useSWR(
    swrKey(token, 'BusinessUsers/GetBusinessUser', params),
    () =>
      businessUserClient.getBusinessUser(params, rpcOptions({ token })).response
  )

  return {
    businessUser,
    error,
    mutate,
  }
}

export function useOwnerBusinessUsers(
  params: IndexRequest = {
    page: 1,
    perPage: 9999,
  }
) {
  const token = useToken()

  const { data, error, mutate } = useSWR(
    swrKey(token, 'BusinessUsers/Index', params),
    () => businessUserClient.index(params, rpcOptions({ token })).response
  )

  return {
    businessUsers: data?.businessUsers,
    error,
    mutate,
  }
}

export async function signInWithLoginToken({
  loginToken,
}: {
  loginToken: string
}) {
  const { response, error } = await wrapAspidaResponse(
    client.business_users.login_token_sessions.post({
      body: {
        login_token: loginToken,
      },
    })
  )

  if (response != null) {
    const auth = {
      id: response.data.owner_id,
      token: response.data.token,
    }
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth) ?? 'null')
    await mutate(AUTH_KEY, auth)
  }

  if (error != null) {
    onError(error)
  }

  return { response, error }
}
