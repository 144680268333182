import React, { useMemo } from 'react'

import { View, ScrollView } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import SelectSeatConnectionView, {
  TableSeatStatus,
} from '@hello-ai/ar_shared/src/components/ForR/SiteController/SeatSettingEdit/SelectTableSeatView'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { confirmAsync } from '@hello-ai/ar_shared/src/modules/alert'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  SharedSeat,
  UpdateSharedSeatParams,
} from '@hello-ai/ar_shared/src/types/ForR/SiteControllerSeat'

import {
  deleteSharedSeats,
  updateSharedSeat,
  useNotSharedTableSeats,
  useParsedSeatsWithSharedSeat,
  useSharedSeat,
} from '../../models/SiteControllerSeatSetting'

import SelectParsedSeatView, {
  SelectedParsedSeatIdBySite,
} from './SelectParsedSeatView'

function getDefaultStatus(status?: SharedSeat['status']) {
  if (status == null) return 'enabled'
  if (status === 'error') return 'enabled'
  return status
}

interface GourmetSiteSeatSettingEditProps {
  restaurantId: number
  sharedSeatId: string
  displayToastSuccess: (message: string) => void
  onSubmitSuccess: () => void
}

export default function GourmetSiteSeatSettingEdit({
  restaurantId,
  sharedSeatId,
  displayToastSuccess,
  onSubmitSuccess,
}: GourmetSiteSeatSettingEditProps) {
  const sharedSeat = useSharedSeat(restaurantId, sharedSeatId)
  const notSharedTableSeats = useNotSharedTableSeats(restaurantId)
  const [selectedTableSeatId, setSelectedTableSeatId] = useFormState(
    sharedSeat.data?.table_seat?.id ?? notSharedTableSeats.data?.[0]?.id
  )
  const [selectedTableSeatStatus, setSelectedTableSeatStatus] =
    useFormState<TableSeatStatus>(getDefaultStatus(sharedSeat.data?.status))

  const prevSelectedParsedSeats = useParsedSeatsWithSharedSeat(
    restaurantId,
    sharedSeatId,
    {
      selected_status: 'select_only',
    }
  )
  const [selectedParsedSeatIdBySite, setSelectedParsedSeatIdBySite] =
    useFormState<SelectedParsedSeatIdBySite>(
      prevSelectedParsedSeats?.data?.reduce((acc, parsedSeat) => {
        if (parsedSeat.site == null) return acc
        if (acc[parsedSeat.site] == null) {
          acc[parsedSeat.site] = new Set()
        }
        acc[parsedSeat.site]?.add(parsedSeat.id)
        return acc
      }, {} as SelectedParsedSeatIdBySite) ?? {}
    )

  const token = useToken()

  const isDisabledSubmitButton = selectedTableSeatId == null

  const onPressSubmit = async () => {
    if (isDisabledSubmitButton) return
    if (token == null) return
    if (sharedSeatId == null) return

    const parsedSeats: UpdateSharedSeatParams['parsed_seats'] = []
    Object.entries(selectedParsedSeatIdBySite).forEach(
      ([, selectedParsedSeatIds]) => {
        if (selectedParsedSeatIds == null) return
        selectedParsedSeatIds.forEach((parsed_seat_id) => {
          parsedSeats.push({ parsed_seat_id })
        })
      }
    )

    prevSelectedParsedSeats.data?.forEach((prevSelectedParsedSeat) => {
      const selectedParsedSeatIds =
        selectedParsedSeatIdBySite[prevSelectedParsedSeat.site]
      if (selectedParsedSeatIds == null) {
        parsedSeats.push({
          parsed_seat_id: prevSelectedParsedSeat.id,
          _destroy: true,
        })
        return
      }
      if (!selectedParsedSeatIds.has(prevSelectedParsedSeat.id)) {
        parsedSeats.push({
          parsed_seat_id: prevSelectedParsedSeat.id,
          _destroy: true,
        })
      }
    })

    const { error } = await updateSharedSeat(
      token,
      restaurantId,
      sharedSeatId,
      {
        status: selectedTableSeatStatus,
        table_seat_id: selectedTableSeatId,
        parsed_seats: parsedSeats,
      }
    )
    if (error != null) return

    displayToastSuccess(t('連携席設定を更新しました'))

    onSubmitSuccess()
  }

  const tableSeats = useMemo(() => {
    if (notSharedTableSeats.data == null) return undefined
    if (sharedSeat.data == null) return notSharedTableSeats.data
    return [...notSharedTableSeats.data, sharedSeat.data.table_seat]
  }, [notSharedTableSeats, sharedSeat])

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        marginTop: 40,
        marginHorizontal: 56,
        paddingBottom: 80,
        rowGap: 48,
      }}
    >
      <SelectSeatConnectionView
        tableSeats={tableSeats}
        onChangeSelectTableSeatId={setSelectedTableSeatId}
        selectedTableSeatId={selectedTableSeatId}
        tableSeatStatus={selectedTableSeatStatus}
        onChangeTableSeatStatus={setSelectedTableSeatStatus}
      />
      <SelectParsedSeatView
        restaurantId={restaurantId}
        selectedParsedSeatIdBySite={selectedParsedSeatIdBySite}
        onChangeSelectedParsedSeat={setSelectedParsedSeatIdBySite}
      />
      <View
        style={{
          flexDirection: 'row',
          columnGap: 16,
          justifyContent: 'center',
        }}
      >
        <Button
          style={{ width: 280, height: 48 }}
          variant="danger-secondary"
          mode="outline"
          disabled={sharedSeatId == null}
          onPress={async () => {
            if (sharedSeatId == null) return
            if (token == null) return
            if ((await confirmAsync(t('削除してもよろしいですか？'))) === false)
              return

            const { error } = await deleteSharedSeats(token, restaurantId, [
              sharedSeatId,
            ])
            if (error != null) return
            displayToastSuccess(t('削除しました'))
            onSubmitSuccess()
          }}
        >
          {t('連携席設定を削除')}
        </Button>
        <Button
          style={{ width: 280, height: 48 }}
          onPress={onPressSubmit}
          disabled={isDisabledSubmitButton}
        >
          {t('連携席設定を更新する')}
        </Button>
      </View>
    </ScrollView>
  )
}
