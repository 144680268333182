import React from 'react'

import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

export function StatusLabel({
  isCashDrawerClosed,
}: {
  isCashDrawerClosed: boolean
}) {
  return (
    <View>
      {isCashDrawerClosed && (
        <View
          style={{
            marginLeft: 16,
            backgroundColor: 'rgba(0, 150, 136, 1)',
          }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              color: 'white',
              paddingHorizontal: 8,
              paddingVertical: 4,
            }}
          >
            {t('処理済')}
          </Text>
        </View>
      )}
      {!isCashDrawerClosed && (
        <View
          style={{ marginLeft: 16, backgroundColor: 'rgba(232, 71, 51, 1)' }}
        >
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              color: 'white',
              paddingHorizontal: 8,
              paddingVertical: 4,
            }}
          >
            {t('未処理')}
          </Text>
        </View>
      )}
    </View>
  )
}
