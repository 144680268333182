import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import axios, { wrapResponse, setHeader } from 'modules/axios'
import useSWR, { fetcher, onError, swrKey } from 'modules/swr'

import { useToken } from './Auth'

export * from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

export interface OwnerInvoiceSetting {
  id: string
  default_card: {
    id: string
    last4: string
    exp_year: string
    exp_month: string
    brand: string
  }
  payment_method: 'bill' | 'card' | ''
  stripe_customer_id: string
}

export function useOwnerInvoiceSetting() {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    OwnerInvoiceSetting,
    any,
    ReturnType<typeof swrKey>
  >(swrKey(token, `/owner/invoice_settings`), fetcher)

  return {
    data,
    error,
    mutate,
  }
}

export async function updateOwnerInvoiceSetting(
  token: string,
  id: string,
  params: {
    owner_invoice_setting: {
      id: OwnerInvoiceSetting['id']
      payment_method: OwnerInvoiceSetting['payment_method']
      stripe_token: string | void | null
    }
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<OwnerInvoiceSetting>(`/owner/invoice_settings/${id}`, params)
  )

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}

export function getInvoiceSettingPaymentMethodText(
  method: OwnerInvoiceSetting['payment_method']
) {
  switch (method) {
    case 'bill':
      return t('請求書払い')
    case 'card':
      return t('クレジットカード払い')
    default:
      return ''
  }
}
