import React from 'react'

import { useParams } from 'react-router'

import BroadcastHistoryView from '@hello-ai/for_r_app/src/components/Message/Broadcast/History'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function BroadcastHistoryScreen() {
  const restaurantId = useRestaurantId()
  const { broadcastId } = useParams()

  if (broadcastId == null) return null

  return (
    <BroadcastHistoryView
      restaurantId={restaurantId}
      broadcastMessageId={broadcastId}
    />
  )
}
