import React, { ReactNode } from 'react'

import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { StyleProp, View, ViewStyle, TextStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

interface Step {
  stepNumber: number
  text: string
}

const stepCircleDiameter = 24

type StepState = 'default' | 'completed' | 'selected'
interface ItemProps {
  stepNumber: number
  state: StepState
  children: ReactNode
  labelTextStyle?: StyleProp<TextStyle>
  hiddenLabel?: boolean
  hiddenCircle?: boolean
}

export function StepItem({
  stepNumber,
  state,
  children,
  labelTextStyle,
  hiddenLabel,
  hiddenCircle,
}: ItemProps) {
  const { width, sm } = useResponsive()
  const circleSize = width < sm ? 20 : stepCircleDiameter
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={[
          {
            width: circleSize,
            height: circleSize,
            borderRadius: circleSize / 2,
            borderWidth: 1,
            borderColor: Colors.black20,
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
          },
          state === 'completed' && {
            borderColor: Colors.accent,
          },
          state === 'selected' && {
            borderColor: Colors.accent,
            backgroundColor: Colors.accent,
          },
          hiddenCircle && {
            display: 'none',
          },
        ]}
      >
        {state === 'completed' ? (
          <FontAwesomeIcon icon={faCheck} size={10} color={Colors.accent} />
        ) : (
          <Text
            style={[
              {
                color: Colors.secondaryBlack,
                fontSize: width < sm ? 12 : 14,
                fontWeight: '600',
              },
              state === 'selected' && {
                color: 'white',
              },
            ]}
          >
            {stepNumber}
          </Text>
        )}
      </View>
      <Text
        style={[
          {
            fontSize: 14,
            marginLeft: width < sm ? 6 : 8,
            fontWeight: '600',
            textAlign: 'center',
            lineHeight: width < sm ? 20 : 22,
          },
          width < sm && {
            fontSize: 10,
            fontWeight: state === 'selected' ? '600' : '300',
          },
          hiddenLabel && {
            display: 'none',
          },
          labelTextStyle,
        ]}
      >
        {children}
      </Text>
    </View>
  )
}

interface StepperListProps {
  steps: Step[]
  currentStepNumber: number
  skipStepNumbers?: number[]
  style?: StyleProp<ViewStyle>
}

export function StepperList({
  steps,
  currentStepNumber,
  skipStepNumbers,
  style,
}: StepperListProps) {
  const { width, sm } = useResponsive()

  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
        },
        style,
      ]}
    >
      {steps.map(({ stepNumber, text }, index) => {
        const completed =
          stepNumber < currentStepNumber ||
          skipStepNumbers?.includes(stepNumber)
        const selected = stepNumber === currentStepNumber
        return (
          <View
            key={`${stepNumber}`}
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {index > 0 && (
              <View
                style={{
                  minWidth: width < sm ? 20 : 32,
                  height: 2,
                  backgroundColor:
                    width >= sm && selected ? Colors.accent : Colors.border,
                  marginHorizontal: width < sm ? 4 : 8,
                }}
              />
            )}
            <StepItem
              stepNumber={stepNumber}
              state={
                completed ? 'completed' : selected ? 'selected' : 'default'
              }
              hiddenLabel={
                width < sm ? currentStepNumber !== stepNumber : undefined
              }
            >
              {text}
            </StepItem>
          </View>
        )
      })}
    </View>
  )
}
