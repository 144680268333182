import React from 'react'

import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import { TableProductOption as TableProductOptionModel } from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'

import { useRestaurantCurrency } from '../../modules/useRestaurantCurrency'

const MAX_OPTION_BOX_NAME_LENGTH = 8
const MAX_OPTION_BOX_NAME_LENGTH_SMALL = 10
const MAX_OPTION_NAME_LENGTH = 12

export function TableProductOption({
  tableProductOption,
}: {
  tableProductOption: TableProductOptionModel
}) {
  return (
    <Text>
      <Text numberOfLines={1} style={{ fontWeight: '600' }}>
        {(tableProductOption.table_product_option_box?.name.length ?? 0) >
        MAX_OPTION_BOX_NAME_LENGTH
          ? `${tableProductOption.table_product_option_box?.name.substring(
              0,
              MAX_OPTION_BOX_NAME_LENGTH
            )}...`
          : tableProductOption.table_product_option_box?.name}
      </Text>
      <Text>：</Text>
      <Text>{tableProductOption.name}</Text>
    </Text>
  )
}

export function TableProductOptionSmall({
  tableProductOption,
  restaurant,
}: {
  tableProductOption: TableProductOptionModel
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}) {
  const { formatCurrency } = useRestaurantCurrency(restaurant)
  return (
    <>
      <Text>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 14,
            fontWeight: '600',
            lineHeight: 18,
            color: Colors.black60,
          }}
        >
          {(tableProductOption.table_product_option_box?.name.length ?? 0) >
          MAX_OPTION_BOX_NAME_LENGTH_SMALL
            ? `${tableProductOption.table_product_option_box?.name.substring(
                0,
                MAX_OPTION_BOX_NAME_LENGTH_SMALL
              )}...`
            : tableProductOption.table_product_option_box?.name}
        </Text>
        <Text
          style={{ fontSize: 14, color: Colors.black60, fontWeight: '600' }}
        >
          ：
        </Text>
        <Text
          style={{ fontSize: 14, color: Colors.black60, fontWeight: '600' }}
        >
          {tableProductOption.name}
        </Text>
      </Text>
      <Text style={{ fontSize: 14, color: Colors.black60, fontWeight: '600' }}>
        {(tableProductOption.prices.table?.price_delta_with_tax ?? 0) > 0 &&
          '+'}
        {formatCurrency(tableProductOption.prices.table?.price_delta_with_tax)}
      </Text>
    </>
  )
}

export function TableProductOptionPayment({
  tableProductOption,
  taxIncluded,
  restaurant,
}: {
  tableProductOption: TableProductOptionModel
  taxIncluded: boolean
  restaurant: Pick<Restaurant, 'price_unit'> | undefined
}) {
  const { formatCurrency } = useRestaurantCurrency(restaurant)
  const amount = taxIncluded
    ? tableProductOption.prices.table?.price_delta_with_tax
    : tableProductOption.prices.table?.price_delta
  return (
    <View>
      <Text
        style={{ fontSize: 14, fontWeight: '600', lineHeight: 24 }}
        numberOfLines={1}
      >
        {tableProductOption.name.length > MAX_OPTION_NAME_LENGTH
          ? `${tableProductOption.name.substring(0, MAX_OPTION_NAME_LENGTH)}...`
          : tableProductOption.name}
      </Text>
      <Text style={{ marginTop: 0, fontSize: 14 }}>
        {(amount ?? 0) >= 0 ? '+' : ''}
        {formatCurrency(amount)}({taxIncluded ? t('税込') : t('税抜')})
      </Text>
    </View>
  )
}
