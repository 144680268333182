import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useParams } from 'react-router'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ChangeRequestForm } from '@hello-ai/for_r_app/src/components/Reservation/ChangeRequestForm'
import { useRestaurantReservation } from '@hello-ai/for_r_app/src/models/RestaurantReservation'

import { useRestaurantId } from 'modules/useRestaurantId'

import { Redirect } from 'App'

const useSkipSteps = (v: string | null) => {
  if (v == null) return []
  return v
    .split(',')
    .map((v) => parseInt(v))
    .filter((v) => !isNaN(v))
}

export default function ChangeRequestFormPage() {
  const restaurantId = useRestaurantId()

  const { restaurantReservationId } = useParams<{
    restaurantReservationId: string
  }>()
  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )
  const [params] = useSearchParams()
  const skipSteps = useSkipSteps(params.get('skipSteps'))
  const fromRequestReservation = params.get('mode') === 'request'

  if (restaurantReservation == null) return <Loading />
  if (restaurantReservation.reservation_change_request == null) {
    return <Redirect to={`/restaurants/${restaurantId}/reservations`} />
  }

  return (
    <ChangeRequestForm
      restaurantId={restaurantId}
      data={restaurantReservation.reservation_change_request}
      customer={restaurantReservation.customers[0]}
      restaurantReservation={restaurantReservation}
      skipSteps={skipSteps}
      fromRequestReservation={fromRequestReservation}
    />
  )
}
