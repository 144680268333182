import React, { useMemo } from 'react'

import { StyleProp, View, ViewStyle } from 'react-native'

import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantCrewMember } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCrewMember'

import { useAllRestaurantCrewMembers } from '../../models/RestaurantCrewMembers'

export default function SelectRestaurantCrewMember({
  restaurantId,
  style,
  restaurantCrewMemberId,
  setRestaurantCrewMemberId,
}: {
  restaurantId: number
  style?: StyleProp<ViewStyle>
  restaurantCrewMemberId?: RestaurantCrewMember['id'] | null
  setRestaurantCrewMemberId: React.Dispatch<
    React.SetStateAction<RestaurantCrewMember['id'] | null>
  >
}) {
  const { restaurantCrewMembers } = useAllRestaurantCrewMembers(restaurantId)
  const { width, sm } = useResponsive()

  const selectItems = useMemo(() => {
    const items: SelectItem<string | null>[] = [
      {
        label: t('名前'),
        value: null,
      },
    ]

    if (restaurantCrewMembers == null || restaurantCrewMembers?.length === 0)
      return items

    return [
      ...items,
      ...restaurantCrewMembers?.map(({ id, name }) => ({
        label: name,
        value: id,
      })),
    ]
  }, [restaurantCrewMembers])

  const labelColor =
    restaurantCrewMemberId != null ? Colors.black : Colors.secondaryBlack

  return (
    <View
      style={[
        {
          flexDirection: width < sm ? 'column' : 'row',
          alignItems: width < sm ? 'flex-start' : 'center',
          justifyContent: 'space-between',
          gap: 8,
        },
        style,
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          flex: width < sm ? undefined : 3,
        }}
      >
        <Text
          style={{
            fontSize: width < sm ? 14 : 20,
            marginRight: 10,
            fontWeight: '600',
          }}
        >
          {t('担当者')}
        </Text>
        <View
          style={{
            backgroundColor: '#E84733',
            borderRadius: 2,
            marginRight: 16,
          }}
        >
          <Text
            style={{
              fontSize: 10,
              fontWeight: '400',
              color: '#FFFFFF',
              padding: 2,
            }}
          >
            {t('必須')}
          </Text>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          flex: width < sm ? undefined : 7,
          height: 64,
        }}
      >
        <SelectInput
          items={selectItems}
          labelStyle={[{ color: labelColor }]}
          selectedValue={restaurantCrewMemberId}
          setValue={(value) => setRestaurantCrewMemberId(value)}
        />
      </View>
    </View>
  )
}
