import React from 'react'

import { useParams } from 'react-router'

import BroadcastFrom, {
  FormContext,
} from '@hello-ai/for_r_app/src/components/Message/Broadcast/Form'
import { PreviewData } from '@hello-ai/for_r_app/src/components/Message/Broadcast/MessagePreviewContent'

import BroadcastPreviewModal from 'components/Restaurant/BroadcastPreviewModal'
import { downloadImageAsBase64String } from 'modules/image'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function BroadcastEditScreen() {
  const restaurantId = useRestaurantId()
  const { broadcastId } = useParams()
  const [previewData, setPreviewData] = React.useState<PreviewData | null>(null)
  const showPreview = (previewData: PreviewData) => {
    setPreviewData(previewData)
  }

  if (broadcastId == null) return null

  return (
    <FormContext.Provider value={{ downloadImageAsBase64String, showPreview }}>
      <BroadcastFrom
        restaurantId={restaurantId}
        broadcastMessageId={broadcastId}
      />
      <BroadcastPreviewModal
        restaurantId={restaurantId}
        isModalVisible={previewData != null}
        previewData={previewData}
        onClose={() => setPreviewData(null)}
      />
    </FormContext.Provider>
  )
}
