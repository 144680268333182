import React, { useEffect, useMemo, useRef, useState } from 'react'

import { faTimes } from '@fortawesome/pro-solid-svg-icons/faTimes'
import produce from 'immer'
import { inRange } from 'lodash'
import { View, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'

import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { toSeconds } from '@hello-ai/ar_shared/src/modules/time'
import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantReservationParams } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import { useRestaurant } from '../../models/Restaurant'
import { useRestaurantBusinessTimesByDate } from '../../models/RestaurantBusinessTime'
import { createRestaurantReservation } from '../../models/RestaurantReservation'
import { restaurantReservationBlockService } from '../../models/RestaurantReservationBlock'
import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../models/TableSeat'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { PartySize } from '../Reservation/ReservationForm/DateTimeAndPartySize/Form'
import { validate } from '../Reservation/ReservationForm/SelectSeats/Form'
import { displayToastError, displayToastSuccess } from '../Shared/Toast'

import { AddReservationFloatButton } from './AddReservationFloatButton'
import { Chart, createChartScrollResponder } from './Chart'
import { SelectParams, ChartMode } from './Chart/types'
import { IrregularNotification } from './IrregularNotification'
import { NeedConfirmReservation } from './NeedConfirmReservation'

const DEFAULT_ADULT_PARTY_SIZE = 0
const DEFAULT_CHILD_PARTY_SIZE = 0
const INITIAL_STEP = 3 // 日時・人数、席はチャート画面で選択済みのため詳細画面に飛ばす

export function ChartView({
  restaurantId,
  startTime,
  date,
  dateString,
  onPressAddReservationButton,
  onLoadEnd,
  onReservationAdded,
}: {
  restaurantId: number
  startTime: number | undefined
  date: dayjs.Dayjs
  dateString: string
  onPressAddReservationButton: () => void
  onLoadEnd?: () => void
  onReservationAdded?: () => void
}) {
  const { tableSeats } = useTableSeats(restaurantId, {})
  const token = useToken()
  const ref = useRef<React.ElementRef<typeof Chart>>(null)
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const { setChartRef, reset, chartProps, requestScroll } = useMemo(
    () => createChartScrollResponder(),
    []
  )

  const { data: restaurant } = useRestaurant(restaurantId)

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId,
    {
      date: date.format('YYYY-MM-DD'),
    }
  )

  useEffect(() => {
    setChartRef(ref.current)
    return () => {
      reset()
    }
  }, [reset, setChartRef])

  const prevStartTime = usePrevious(startTime)
  const prevDate = usePrevious(date)

  useEffect(() => {
    if (prevDate !== date || prevStartTime !== startTime) {
      requestScroll(
        (async () => {
          if (startTime != null) return { time: startTime }

          if (
            restaurantBusinessTimes == null ||
            restaurantBusinessTimes.length === 0
          ) {
            const now = dayjs()
            const defaultTime = toSeconds(
              now.hour(),
              Math.floor(now.minute() / 15) * 15
            )
            return { time: defaultTime }
          } else {
            const firstStartTime = restaurantBusinessTimes[0].start_time
            const lastEndTime =
              restaurantBusinessTimes[restaurantBusinessTimes.length - 1]
                .end_time
            const current = dayjs()
            const currentTime = current.hour() * 3600 + current.minute() * 60
            // 現在時刻が最初の営業時間より早い場合は最初の営業時間にスクロール
            if (currentTime < firstStartTime) {
              return { time: firstStartTime }
            }
            // 現在時刻が最後の営業時間より遅い場合は最後の営業時間にスクロール
            if (currentTime > lastEndTime) {
              return { time: lastEndTime }
            }

            return {
              time: toSeconds(
                current.hour(),
                Math.floor(current.minute() / 15) * 15
              ),
            }
          }
        })()
      )
    }
  }, [
    date,
    prevDate,
    prevStartTime,
    requestScroll,
    restaurantBusinessTimes,
    startTime,
  ])

  const onChangeSelectedSeatIds = (value: Array<TableSeatModel['id']>) => {
    setSelectParams(
      produce((draft) => {
        if (draft != null) {
          draft.selectedSeatIds = value
        }
      })
    )
  }

  const [mode, setMode] = useState<ChartMode>('default')

  const [selectParams, setSelectParams] = useState<SelectParams | undefined>()

  const { errors } = useMemo(
    () =>
      validate({
        partySize:
          (selectParams?.adultPartySize ?? DEFAULT_ADULT_PARTY_SIZE) +
          (selectParams?.childPartySize ?? DEFAULT_CHILD_PARTY_SIZE),
        selectedSeatIds: selectParams?.selectedSeatIds ?? [],
        tableSeats,
      }),
    [selectParams, tableSeats]
  )

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error, undefined, {
          marginBottom: width < sm ? 272 : undefined,
        })
      })
  }, [errors, width, sm])

  const onPressCancel = () => {
    setMode('default')
    setSelectParams(undefined)
  }

  const onPressBlock = async () => {
    if (
      // TODO: new_free への移行が完了したら削除
      restaurant?.reservation_book_plan_type === 'special' ||
      restaurant?.reservation_book_plan_type === 'new_free' ||
      restaurant?.reservation_book_plan_type === 'entry'
    ) {
      // フリー, エントリープランでは繰り返しのブロック枠を作成させないためにここで単発のブロック枠を作成する
      await restaurantReservationBlockService.create(token, {
        restaurantId,
        startDate: date.format('YYYY-MM-DD'),
        endDate: date.format('YYYY-MM-DD'),
        startTime: selectParams?.startTime ?? 0,
        endTime: selectParams?.endTime ?? 0,
        wdays: [0, 1, 2, 3, 4, 5, 6, 7],
        tableSeatIds: selectParams?.selectedSeatIds ?? [],
      })

      // Chart の予約データを更新する
      ref.current?.refreshData()

      displayToastSuccess(
        t('ブロックを追加しました'),
        undefined,
        width < sm ? { marginBottom: 48 } : undefined
      )
      setTimeout(() => {
        setMode('default')
      }, 0)
    } else {
      if (Platform.OS === 'web') {
        const params = new URLSearchParams()
        if (selectParams?.startTime != null) {
          params.append('startTime', selectParams.startTime.toString())
        }
        if (selectParams?.endTime != null) {
          params.append('endTime', selectParams.endTime.toString())
        }
        if (
          selectParams?.selectedSeatIds != null &&
          selectParams.selectedSeatIds.length > 0
        ) {
          params.append(
            'selectedSeatIds',
            selectParams.selectedSeatIds.join(',')
          )
        }
        params.append('date', date.format('YYYY-MM-DD'))
        navigate(
          `/restaurants/${restaurantId}/reservations/blocks/new?${params.toString()}`
        )
      } else {
        navigation.navigate('ReservationBlocksForm', {
          startTime: selectParams?.startTime,
          endTime: selectParams?.endTime,
          selectedSeatIds: selectParams?.selectedSeatIds,
          date,
        })
      }
      setTimeout(() => {
        setMode('default')
      }, 0)
    }
  }

  const onPressWalkin = async () => {
    if (selectParams === undefined) return

    const params: RestaurantReservationParams = {
      start_at: date
        .startOf('day')
        .add(selectParams.startTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      end_at: date
        .startOf('day')
        .add(selectParams.endTime, 'second')
        .format('YYYY-MM-DD HH:mm'),
      adult_party_size: selectParams.adultPartySize,
      child_party_size: selectParams.childPartySize,
      table_seat_ids: selectParams.selectedSeatIds,
      kind: 'walkin',
      visit_status: 'all_visited',
    }

    const { error } = await createRestaurantReservation(
      token,
      restaurantId,
      params
    )
    if (error != null) return

    // Chart の予約データを更新する
    ref.current?.refreshData()

    displayToastSuccess(
      t('ウォークインを追加しました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
    setTimeout(() => {
      setMode('default')
    }, 0)
  }

  const onPressReserve = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/new?dateString=${dateString}&startTime=${
          selectParams?.startTime
        }&endTime=${
          selectParams?.endTime
        }&selectedSeatIds=${selectParams?.selectedSeatIds?.join(',')}&adultPartySize=${
          selectParams?.adultPartySize ?? DEFAULT_ADULT_PARTY_SIZE
        }&childPartySize=${selectParams?.childPartySize ?? DEFAULT_CHILD_PARTY_SIZE}&initialStep=${INITIAL_STEP}`
      )
    } else {
      navigation.navigate('ReservationForm', {
        dateString,
        startTime: selectParams?.startTime,
        endTime: selectParams?.endTime,
        selectedSeatIds: selectParams?.selectedSeatIds,
        adultPartySize: selectParams?.adultPartySize,
        childPartySize: selectParams?.childPartySize,
        initialStep: INITIAL_STEP,
      })
    }
    setTimeout(() => {
      setMode('default')
    }, 0)
  }

  const setEndTime = (endTime: number) => {
    setSelectParams(
      produce((draft) => {
        if (draft != null) {
          draft.endTime = endTime
        }
      })
    )
  }

  function SelectBlockWrapper({ children }: { children: React.ReactNode }) {
    if (mode !== 'selectBlock') return null

    if (width < sm) {
      return (
        <SafeAreaView
          style={[
            {
              backgroundColor: Colors.white,
              borderTopColor: Colors.border,
              borderTopWidth: 0.5,
              paddingHorizontal: 16,
              paddingTop: -42,
            },
            Platform.OS === 'web' && {
              paddingTop: 24,
              paddingBottom: 24,
            },
          ]}
        >
          {children}
        </SafeAreaView>
      )
    }

    return (
      <View
        style={[
          {
            flex: 1,
            position: 'absolute',
            bottom: 0,
            right: 0,
            top: 0,
            width: 296,
            backgroundColor: Colors.white,
            borderLeftColor: Colors.border,
            borderLeftWidth: 0.5,
            padding: 24,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          },
        ]}
      >
        {children}
      </View>
    )
  }

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      {width > sm && (
        <View style={{ paddingTop: 24, paddingHorizontal: 16 }}>
          <IrregularNotification restaurantId={restaurantId} date={date} />
        </View>
      )}

      <NeedConfirmReservation restaurantId={restaurantId} date={date} />

      <Chart
        restaurantId={restaurantId}
        ref={ref}
        scrollViewProps={{
          style: {
            flex: 1,
          },
          showsVerticalScrollIndicator: false,
        }}
        date={date}
        mode={mode}
        selectParams={selectParams}
        onStartSelectBlock={({ startTime, seatId }) => {
          // 該当する営業時間のうち
          const defaultStayingTimes =
            restaurantBusinessTimes?.reduce((prev, cur) => {
              if (
                cur.staying_time != null &&
                inRange(startTime, cur.start_time, cur.end_time)
              ) {
                return [...prev, cur.staying_time]
              }
              return prev
            }, [] as number[]) ?? []
          // 最大の滞在時間 or 2h を初期値に設定する
          const defaultStayingTime =
            defaultStayingTimes.length > 0
              ? Math.max(...defaultStayingTimes)
              : 3600 * 2

          setSelectParams({
            restaurantReservation: undefined,
            startTime,
            endTime: startTime + defaultStayingTime,
            setEndTime,
            partySize: Infinity,
            selectedSeatIds: [seatId],
            onChangeSelectedSeatIds,
          })
          setMode('selectBlock')
        }}
        onEndSelectBlock={() => {
          setMode('default')
        }}
        onLoadEnd={onLoadEnd}
        onReservationAdded={onReservationAdded}
        {...chartProps}
      />
      <SelectBlockWrapper>
        <View style={width >= sm && { flex: 1 }}>
          <View
            style={[
              width < sm && { marginBottom: 16 },
              {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              },
            ]}
          >
            {(selectParams?.selectedSeatIds?.length ?? 0) ? (
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '600',
                  color: Colors.black,
                }}
              >
                {
                  tableSeats.find(
                    (seat) => seat.id === selectParams?.selectedSeatIds?.[0]
                  )?.name
                }
              </Text>
            ) : (
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '600',
                  color: Colors.black60,
                }}
              >
                {t('タップして席を選択')}
              </Text>
            )}
            <TouchableOpacity onPress={onPressCancel}>
              <FontAwesomeIcon
                icon={faTimes}
                size={24}
                color={Colors.primary}
              />
            </TouchableOpacity>
          </View>
          <PartySize
            adultPartySize={`${selectParams?.adultPartySize ?? DEFAULT_ADULT_PARTY_SIZE}`}
            childPartySize={`${selectParams?.childPartySize ?? DEFAULT_CHILD_PARTY_SIZE}`}
            setAdultPartySize={(value) =>
              setSelectParams(
                produce((draft) => {
                  if (draft != null) {
                    draft.adultPartySize = Number(value)
                  }
                })
              )
            }
            setChildPartySize={(value) =>
              setSelectParams(
                produce((draft) => {
                  if (draft != null) {
                    draft.childPartySize = Number(value)
                  }
                })
              )
            }
            isChartView
          />
        </View>
        <View
          style={[
            width < sm
              ? {
                  flexDirection: 'row',
                  gap: 8,
                  marginTop: 16,
                }
              : {
                  flexDirection: 'column',
                  gap: 16,
                },
          ]}
        >
          <View
            style={[
              width < sm
                ? {
                    flex: 2,
                    flexDirection: 'row-reverse',
                    gap: 8,
                  }
                : {
                    flex: Platform.OS === 'web' ? undefined : 0,
                    flexDirection: 'column',
                    gap: 16,
                  },
            ]}
          >
            <AsyncButton
              mode="outline"
              style={{
                paddingHorizontal: 0,
                height: 48,
                flex: width < sm ? 1 : Platform.OS === 'web' ? undefined : 0,
              }}
              textStyle={{ fontSize: 16 }}
              onPress={onPressBlock}
            >
              {t('ブロック')}
            </AsyncButton>
            <Button
              mode="outline"
              style={{
                paddingHorizontal: 0,
                height: 48,
                flex: width < sm ? 1 : Platform.OS === 'web' ? undefined : 0,
              }}
              textStyle={{ fontSize: 16 }}
              onPress={onPressWalkin}
            >
              {t('ウォークイン')}
            </Button>
          </View>
          <Button
            mode="contained"
            style={{
              paddingHorizontal: 0,
              height: 48,
              flex: width < sm ? 1 : Platform.OS === 'web' ? undefined : 0,
            }}
            textStyle={{ fontSize: 16 }}
            onPress={onPressReserve}
          >
            {t('予約')}
          </Button>
        </View>
      </SelectBlockWrapper>
      {mode !== 'selectBlock' && (
        <AddReservationFloatButton onPress={onPressAddReservationButton} />
      )}
    </View>
  )
}
