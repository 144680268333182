import React, { useMemo } from 'react'

import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { View, Text, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { useBusinessTimesFromGetCalendar } from '../../models/RestaurantBusinessTime'

export function IrregularNotification({
  restaurantId,
  date,
  style,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  style?: ViewStyle
}) {
  const { businessTimes } = useBusinessTimesFromGetCalendar(restaurantId, date)

  const irregularNotification = useMemo(() => {
    if (businessTimes.length === 0) return null

    const allIrregularClose =
      businessTimes.every((bt) => !bt.open) &&
      businessTimes.some((bt) => bt.irregularClose)
    const hasIrregularOpen = businessTimes.some((bt) => bt.irregularOpen)

    // eslint-disable-next-line ar-i18n/require-translation-ja
    const dateString = date.format('YYYY年M月D日')
    if (allIrregularClose) {
      return t('{{date}}は臨時休業日です。', { date: dateString })
    } else if (hasIrregularOpen) {
      return t('{{date}}は臨時営業日です。', { date: dateString })
    }

    return null
  }, [businessTimes, date])

  if (irregularNotification === null) return null

  return (
    <View
      style={{
        flexDirection: 'row',
        gap: 12,
        backgroundColor: Colors.primaryBg,
        borderRadius: 12,
        paddingVertical: 12,
        paddingHorizontal: 16,
        ...style,
      }}
    >
      <FontAwesomeIcon icon={faInfoCircle} size={14} color={Colors.primary} />
      <Text style={{ fontSize: 12, fontWeight: '600', color: Colors.primary }}>
        {irregularNotification}
      </Text>
    </View>
  )
}
