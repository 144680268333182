import dayjs from 'dayjs'

import {
  SalesDashboardParams,
  SalesDashboardData,
} from '@hello-ai/ar_shared/src/types/ForR/TakeoutSales'

import useSWR, { fetcher, swrKey } from 'modules/swr'

import { useToken } from './Auth'
import { Restaurant } from './Restaurant'

export * from '@hello-ai/ar_shared/src/types/ForR/TakeoutSales'

export function getFetchPeriodParams({
  start,
  end,
}: {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
}): { gte: string; lte: string } {
  return {
    gte: start.toISOString(),
    lte: end.toISOString(),
  }
}
export function useTakeoutRestaurantSalesDashboard(
  restaurantId: Restaurant['id'],
  type: string,
  params: SalesDashboardParams
) {
  const token = useToken()
  const {
    data: salesData,
    error,
    mutate,
  } = useSWR<SalesDashboardData, any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/takeout/restaurants/${restaurantId}/sales_dashboard/${type}`,
      params
    ),
    ([token, url]) => fetcher<SalesDashboardData>([token, url, params])
  )

  return {
    salesData,
    error,
    mutate,
  }
}
