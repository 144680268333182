import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import StatusLabel from 'components/GourmetSite/AccountSettings/StatusLabel'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import GourmetSiteCourseSetting from 'pages/Restaurants/GourmetSiteCourse/GourmetSiteCourseSetting'

const routes: StackRoutes = {
  Index: {
    title: t('コースの取得'),
    fullPath: `/restaurants/:id/gourmet_site/course_settings`,
    relativePath: `/`,
    exact: true,
    element: <GourmetSiteCourseSetting />,
    screenOptions: {
      headerRight: <StatusLabel />,
    },
  },
}

export default function GourmetSiteCourseSettingStack() {
  return <Stack routes={routes} />
}
