import { mapValues } from 'lodash'

import { withErrorBoundary } from 'components/Shared/ErrorBoundary'
import BusinessUser from 'navigation/BusinessUser/Stack'
import Owners from 'navigation/Owners/Stack'
import Restaurants from 'navigation/Restaurants/Drawer'
import SignIn from 'navigation/SignIn/Stack'
import SignUp from 'navigation/SignUp/Stack'
import Top from 'navigation/Top/Drawer'
import BusinessUserRoles from 'pages/BusinessUserRoles'
import BusinessUsers from 'pages/BusinessUsers'
import BusinessUsersFirstRegistration from 'pages/BusinessUsers/FirstRegistration'
import BusinessUsersRegistration from 'pages/BusinessUsers/Registration'
import EntryPlanRegistration from 'pages/EntryPlanRegistration'
import NotFound from 'pages/Error/NotFound'
import ServerError from 'pages/Error/ServerError'
import RestaurantMenuGroups from 'pages/Restaurants/MenuGroups'
import RestaurantReservationsPrint from 'pages/Restaurants/RestaurantReservations/Print'

import BusinessUsersResetPasswordRequestsConfirm from './BusinessUsers/ResetPasswordRequests/Confirm'
import DevSettings from './Top/DevSettings'

export const Pages = mapValues(
  {
    DevSettings,
    SignIn,
    SignUp,
    EntryPlanRegistration,
    Owners,
    Top,
    Restaurants,
    RestaurantReservationsPrint,
    BusinessUsers,
    BusinessUsersFirstRegistration,
    BusinessUsersRegistration,
    BusinessUsersResetPasswordRequestsConfirm,
    BusinessUserRoles,
    BusinessUser,
    RestaurantMenuGroups,
    ServerError,
    NotFound,
  },
  (Page) => withErrorBoundary(Page)
)
