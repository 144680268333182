import React, { useState } from 'react'

import dayjs from 'dayjs'
import { ScrollView, StyleProp, View, ViewStyle } from 'react-native'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { Report } from '@hello-ai/ar_shared/src/types/ForR/Report'
import {
  CanceledItem,
  ReportRowView,
  getFormatDate,
} from '@hello-ai/for_r_app/src/components/Reports'
import { StatusLabel } from '@hello-ai/for_r_app/src/components/Restaurant/TableBalancingCashDrawer/StatusLabel'

import { CashDrawerManagement } from 'components/Restaurant/CashDrawerManagement'
import { CSVDownloadButton } from 'components/Shared/CsvDownloadButton'
import { DateSelectInput } from 'components/Shared/DateSelectInput'
import { ReportType, useRestaurantReport } from 'models/Report'
import { useRestaurant } from 'models/Restaurant'
import { useLastCloseBalancingCashDrawer } from 'models/TableBalancingCashDrawer'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import { useRestaurantId } from 'modules/useRestaurantId'

const annotationNames = [t('客数'), t('客単価'), t('注文キャンセル')]

const csvHeaders = [
  { value: 'category1', label: t('大項目') },
  { value: 'category2', label: t('中項目') },
  { value: 'category3', label: t('小項目') },
  { value: 'value', label: t('値') },
  { value: 'unit', label: t('単位') },
]

const convertCsvData = (
  report?: Report
): Array<Record<string, unknown>> | undefined => {
  if (report == null) {
    return undefined
  }
  const rows: Array<Record<string, unknown>> = []
  report.rows.forEach((row) => {
    rows.push({
      category1: row.name,
      category2: '',
      category3: '',
      value: row.amount,
      unit: row.unit ?? t('円'),
    })

    const components = row.components ?? []
    components.forEach((component) => {
      rows.push({
        category1: row.name,
        category2: component.name,
        category3: '',
        value: component.amount,
        unit: component.unit ?? t('円'),
      })
      const subComponents = component.components ?? []
      subComponents.forEach((subComponent) => {
        rows.push({
          category1: row.name,
          category2: component.name,
          category3: subComponent.name,
          value: subComponent.amount,
          unit: subComponent.unit ?? t('円'),
        })
      })
    })

    const secondaryComponents = row.secondary_components ?? []
    secondaryComponents.forEach((component) => {
      rows.push({
        category1: row.name,
        category2: component.name,
        category3: '',
        value: component.amount,
        unit: component.unit ?? t('円'),
      })
      const subComponents = component.components ?? []
      subComponents.forEach((subComponent) => {
        rows.push({
          category1: row.name,
          category2: component.name,
          category3: subComponent.name,
          value: subComponent.amount,
          unit: subComponent.unit ?? t('円'),
        })
      })
    })
  })
  return rows
}

function RowName({
  name,
  fontSize,
  fontBold = false,
}: {
  name: string
  fontSize: number
  fontBold?: boolean
}) {
  const isAnnotationName = annotationNames.includes(name)
  return (
    <Text
      style={[
        {
          fontSize,
        },
        fontBold && {
          fontWeight: '600',
        },
        { textAlign: 'center' },
      ]}
    >
      {name}
      {isAnnotationName && ' ※'}
    </Text>
  )
}

function RowAmount({
  amount,
  unit = t('円'),
  fontSize,
  fontBold = false,
}: {
  amount: number | null | undefined
  unit?: string
  fontSize: number
  fontBold?: boolean
}) {
  if (amount == null) {
    return null
  }

  return (
    <Text
      style={[
        {
          fontSize,
        },
        amount < 0 && {
          color: Colors.caution,
        },
        fontBold && {
          fontWeight: '600',
        },
      ]}
    >
      {numberWithDelimiter(amount)}
      {unit}
    </Text>
  )
}

function Row({
  children,
  style,
}: {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

export default function RestaurantReports() {
  const restaurantId = useRestaurantId()
  const { restaurant } = useRestaurant(restaurantId)
  const restaurantStartTime =
    restaurant?.table_restaurant_setting?.start_time_of_restaurant_day
  let today = dayjs()
  const secondsPassedInDay = today.diff(today.startOf('day'), 'second')
  if (restaurantStartTime > 0 && secondsPassedInDay < restaurantStartTime) {
    today = today.subtract(1, 'day')
  }

  const [selectedValue, setSelectedValue] = useState(today)
  const [reportType, setReportType] = useState<ReportType>('daily')

  const date = selectedValue.format('YYYY-MM-DD') // iso8601

  const { data } = useRestaurantReport(restaurantId, reportType, date)
  const { balancingCashDrawer, error } = useLastCloseBalancingCashDrawer(
    restaurantId,
    dayjs(date),
    true,
    { onError: () => {} }
  )
  const isClosedCashDrawerLoading = !balancingCashDrawer && error == null
  const isCashDrawerClosed = !!(
    error == null &&
    balancingCashDrawer &&
    balancingCashDrawer.date === date
  )

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: 'rgba(68, 68, 68, 0.06)' }}
      contentContainerStyle={{
        marginHorizontal: 48,
        paddingBottom: 40,
      }}
    >
      <View
        style={{
          paddingVertical: 32,
          flexDirection: 'row',
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.border,
        }}
      >
        <SelectInput
          style={{ flex: 1, marginRight: 16, backgroundColor: 'white' }}
          selectedValue={reportType}
          setValue={(value: ReportType) => {
            if (value === 'monthly') {
              setSelectedValue((prev) => {
                return prev.startOf('month')
              })
            }
            if (value === 'daily') {
              setSelectedValue(today)
            }
            setReportType(value)
          }}
          items={[
            { value: 'daily', label: t('日報') },
            { value: 'monthly', label: t('月報') },
          ]}
        />
        <View style={{ flex: 3 }}>
          <DateSelectInput
            // eslint-disable-next-line ar-i18n/require-translation-ja
            format={reportType === 'monthly' ? 'YYYY年M月' : 'YYYY年M月D日'}
            views={reportType === 'monthly' ? ['year', 'month'] : undefined}
            initialDate={selectedValue}
            setDate={setSelectedValue}
          />
        </View>
      </View>
      <View
        style={{
          flexDirection: 'row',
          marginTop: 28,
          height: 48,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
            }}
          >
            {getFormatDate(selectedValue, reportType)}
          </Text>
          {!isClosedCashDrawerLoading && (
            <StatusLabel isCashDrawerClosed={isCashDrawerClosed} />
          )}
        </View>
        <CSVDownloadButton
          data={convertCsvData(data != null ? data[0] : undefined)}
          headers={csvHeaders}
          fileName={`${reportType}_report_${date}`}
        />
      </View>
      <ShadowBox
        style={[
          {
            padding: 0,
            overflow: 'hidden',
            marginTop: 24,
          },
        ]}
      >
        {data[0] == null || data[1] == null ? (
          <View style={{ paddingVertical: 24 }}>
            <Loading />
          </View>
        ) : (
          <>
            {data[0].rows.map((rowItem, index) => (
              <View
                key={rowItem.id}
                style={[
                  index > 0 && {
                    borderTopColor: Colors.border,
                    borderTopWidth: 0.5,
                  },
                ]}
              >
                <ReportRowView item={rowItem} />
              </View>
            ))}
            <View
              style={{
                marginTop: 36,
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
                paddingHorizontal: 32,
                paddingBottom: 32 - 24,
              }}
            >
              <Text
                style={{
                  marginTop: 40,
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                ※{data[1].name}
              </Text>

              {data[1].rows.map((row) => {
                return (
                  <View key={row.id}>
                    <Row style={{ marginTop: 40 }}>
                      <RowName name={row.name} fontSize={18} fontBold />
                      <RowAmount amount={row.amount} fontSize={18} fontBold />
                    </Row>
                    <View
                      style={{
                        marginTop: row.components.length === 0 ? 32 : 0,
                      }}
                    >
                      {row.components.map((component, index) => (
                        <View
                          key={component.id}
                          style={[
                            index === 0 && {
                              marginTop: 24,
                            },
                            index > 0 && {
                              borderTopWidth: 0.5,
                              borderTopColor: Colors.border,
                            },
                          ]}
                        >
                          <CanceledItem
                            reportType={reportType}
                            onPress={() => {
                              // TODOs
                            }}
                            component={component}
                          />
                        </View>
                      ))}
                    </View>
                  </View>
                )
              })}
            </View>
          </>
        )}
      </ShadowBox>
      <CashDrawerManagement restaurantId={restaurantId} date={dayjs(date)} />
    </ScrollView>
  )
}
