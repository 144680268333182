import React, { useCallback, useMemo } from 'react'

import { Location } from 'history'
import { compact } from 'lodash'
import { View, ViewProps } from 'react-native'
import { useLocation, useNavigate } from 'react-router'

type LocationState = unknown

export type To =
  | string
  | ((location: Location) => Location)
  | Location
  | null
  | undefined

function normalize(to: To, location: Location) {
  if (to == null) {
    return { href: '#', state: undefined }
  }

  if (typeof to === 'string') {
    return { href: to, state: undefined }
  }

  let pathname = ''
  let search = ''
  let hash = ''
  let state: LocationState

  if (typeof to === 'function') {
    ;({ pathname, search, hash, state } = to(location))
  } else if (to != null) {
    ;({ pathname, search, hash, state } = to)
  }

  return {
    href: compact([pathname, search, hash]).join(''),
    state,
  }
}

function isSameOrigin(url: string) {
  return (
    new URL(document.baseURI).origin === new URL(url, document.baseURI).origin
  )
}

function useNavigate_() {
  const navigate = useNavigate()

  return useCallback(
    (href: string, state: LocationState, isStatic?: boolean) => {
      if ((isStatic != null && isStatic) || !isSameOrigin(href)) {
        window.location.href = href
      } else {
        navigate(href, { state })
      }
    },
    [navigate]
  )
}

function useLinkProps({
  to,
  rel,
  target,
  isStatic = false,
  onClick,
}: {
  to: To
  rel?: string
  target?: string
  isStatic: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
}) {
  const location = useLocation()
  const navigate = useNavigate_()

  const { href, state } = normalize(to, location)

  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      onClick?.(event)
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        return
      }
      if (target != null && target !== '_self') {
        return
      }

      if (event.defaultPrevented) {
        return
      }

      event.preventDefault()
      navigate(href, state, isStatic)
    },
    [href, isStatic, navigate, onClick, state, target]
  )

  const hrefAttrs = useMemo(
    () => ({
      rel,
      target: typeof target === 'string' ? target.replace(/^_/, '') : target,
    }),
    [rel, target]
  )

  return {
    accessibilityRole: 'link',
    href,
    hrefAttrs,
    onClick: handleClick,
  }
}

export interface InjectedProps {
  to?: To
  rel?: string
  target?: string
  isStatic?: boolean
  onClick?: React.MouseEventHandler<HTMLAnchorElement>
  children?: React.ReactNode
}

export function createLinkComponent<T, P extends {}>(
  Component:
    | React.ComponentType<React.PropsWithChildren<P>>
    | React.ForwardRefExoticComponent<P & React.RefAttributes<T>>
) {
  return React.forwardRef<T, P & InjectedProps>(
    (
      {
        to,
        rel,
        target,
        isStatic = false,
        onClick,
        ...props
      }: P & InjectedProps,
      ref
    ) => {
      const linkProps = useLinkProps({
        to,
        rel,
        target,
        isStatic,
        onClick,
      })

      return <Component ref={ref} {...linkProps} {...(props as P)} />
    }
  )
}

const Link = createLinkComponent<View, ViewProps>(View)

export default Link
