import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import RestaurantBusinessTimes from 'pages/Restaurants/BusinessTimes'
import RestaurantBusinessTimeForm from 'pages/Restaurants/BusinessTimes/Form'

const routes: StackRoutes = {
  Index: {
    title: t('営業時間'),
    fullPath: `/restaurants/:id/business_times`,
    relativePath: `/`,
    exact: true,
    element: <RestaurantBusinessTimes />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  Show: {
    title: t('営業時間を作成'),
    fullPath: `/restaurants/:id/business_times/new`,
    relativePath: `/new`,
    element: <RestaurantBusinessTimeForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('営業時間を編集'),
    fullPath: `/restaurants/:id/business_times/:businessTimeId/edit`,
    relativePath: `/:businessTimeId/edit`,
    element: <RestaurantBusinessTimeForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function BusinessTimesStack() {
  return <Stack routes={routes} />
}
