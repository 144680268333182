import { useMemo } from 'react'

import { restaurantBusinessTimeService } from '../../../models/RestaurantBusinessTime'
import { restaurantReservationService } from '../../../models/RestaurantReservation'

import type { CalendarPeriod, CalendarPeriods } from './types'

export const useCalendarReservationDate = (
  restaurantId: number,
  calendarPeriod: CalendarPeriod
) => {
  const { data } = restaurantReservationService.useByCalendar({
    restaurantId,
    gte: calendarPeriod.gte,
    lte: calendarPeriod.lte,
  })
  return useMemo(
    () => [calendarPeriod.gte, data?.dates ?? []] as const,
    [data, calendarPeriod]
  )
}

export const useCalendarReservationDates = (
  restaurantId: number,
  calendarPeriods: CalendarPeriods
) => {
  const currentData = useCalendarReservationDate(
    restaurantId,
    calendarPeriods.current
  )
  return useMemo(() => {
    return Object.fromEntries(
      [currentData].map(([period, dates]) => [period, dates] as const)
    )
  }, [currentData])
}

export const useCalendarBusinessTime = (
  restaurantId: number,
  calendarPeriod: CalendarPeriod
) => {
  const { data } = restaurantBusinessTimeService.useGetCalendar({
    restaurantId,
    gte: calendarPeriod.gte,
    lte: calendarPeriod.lte,
  })
  return useMemo(
    () => [calendarPeriod.gte, data?.dates ?? []] as const,
    [data, calendarPeriod]
  )
}

export const useCalendarBusinessTimes = (
  restaurantId: number,
  calendarPeriods: CalendarPeriods
) => {
  const currentData = useCalendarBusinessTime(
    restaurantId,
    calendarPeriods.current
  )
  return useMemo(() => {
    return Object.fromEntries(
      [currentData].map(([period, dates]) => [period, dates] as const)
    )
  }, [currentData])
}
