import React, { useCallback } from 'react'

import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons/faAngleLeft'
import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { faSortDown } from '@fortawesome/pro-solid-svg-icons/faSortDown'
import { View, StyleSheet, Platform } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import type { Dayjs } from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { YearMonthPicker } from '../../Shared/YearMonthPicker'

import type { CalendarProps } from 'react-native-calendars'

const convertMonth = (day: Dayjs) => ({
  year: day.year(),
  month: day.month(),
  day: day.day(),
  timestamp: day.unix(),
  dateString: day.format('YYYY-MM-DD'),
})

const changeDate = (
  amount: number,
  unit: 'month' | 'year',
  visibleMonth?: string,
  onVisibleMonthsChange?: (date: ReturnType<typeof convertMonth>[]) => void
) => {
  if (visibleMonth == null || onVisibleMonthsChange == null) return
  const newDate = dayjs(visibleMonth).add(amount, unit)
  onVisibleMonthsChange([convertMonth(newDate)])
}

interface ReservationCalendarHeaderProps {
  restaurantId: number
  visibleMonth: string
  onVisibleMonthsChange?: CalendarProps['onVisibleMonthsChange']
  onClose: () => void
  width: number
  sm: number
  md: number
}

export function ReservationCalendarHeaderPresentation({
  restaurantId,
  visibleMonth,
  onVisibleMonthsChange,
  onClose,
  width,
  sm,
  md,
}: ReservationCalendarHeaderProps) {
  const nextMonth = () =>
    changeDate(1, 'month', visibleMonth, onVisibleMonthsChange)
  const prevMonth = () =>
    changeDate(-1, 'month', visibleMonth, onVisibleMonthsChange)
  const navigation = useNavigation()
  const navigate = useNavigate()
  const dateString = dayjs().format('YYYY-MM-DD')

  const navigateToChart = useCallback(() => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations?m=chart&date=${dateString}`
      )
    } else {
      navigation.navigate('Reservations', {
        dateString,
        mode: 'chart',
      })
      onClose()
    }
  }, [dateString, navigate, navigation, restaurantId, onClose])

  const isWide = width > md
  const isMedium = width < md && width > sm

  return (
    <View style={isWide ? style.container : mobileStyles.container}>
      {isWide || isMedium ? (
        <View
          style={
            // FIXME: iPadの幅の細かい幅に合わせて、このような妙を実装している. よりよい方法があればリファクタリングしてください
            isMedium
              ? { width: style.chartButton.width / 2 }
              : isWide
                ? { width: style.chartButton.width }
                : undefined
          }
        />
      ) : null}
      <View style={isWide ? style.middleSection : mobileStyles.middleSection}>
        <TouchableOpacity
          style={style.monthChangingSection}
          onPress={prevMonth}
        >
          <FontAwesomeIcon
            icon={faAngleLeft}
            size={24}
            color={Colors.primary}
          />
          {width >= md && (
            <Text style={style.monthChangingText}>{t('前月')}</Text>
          )}
        </TouchableOpacity>
        <YearMonthPicker
          minYear={dayjs().year() - 10}
          maxYear={dayjs().year() + 1}
          style={isWide ? style.dateTextSection : mobileStyles.dateTextSection}
          labelStyle={[style.dateText]}
          value={dayjs(visibleMonth)}
          onChange={(newDate) => {
            onVisibleMonthsChange?.([convertMonth(newDate)])
          }}
          renderLabel={(value) => (
            <View style={style.yearMonthContainer}>
              <View style={style.yearMonthTextContainer}>
                <Text style={[style.dateText]}>
                  {value.format(t('YYYY年MMMM'))}
                </Text>
                <FontAwesomeIcon
                  icon={faSortDown}
                  size={12}
                  color={Colors.primary}
                  style={{
                    transform: [{ translateY: -3 }],
                    marginLeft: 4,
                  }}
                />
              </View>
              <Button
                mode="outline"
                style={[style.todayButton]}
                textStyle={{
                  fontSize: 12,
                  fontWeight: '300',
                  whiteSpace: 'nowrap',
                }}
                onPress={() => {
                  if (onVisibleMonthsChange == null) return
                  const today = dayjs()
                  onVisibleMonthsChange([convertMonth(today)])
                }}
              >
                {t('今月')}
              </Button>
            </View>
          )}
        />
        <TouchableOpacity
          style={style.monthChangingSection}
          onPress={nextMonth}
        >
          {width >= md && (
            <Text style={style.monthChangingText}>{t('翌月')}</Text>
          )}
          <FontAwesomeIcon
            icon={faAngleRight}
            size={24}
            color={Colors.primary}
          />
        </TouchableOpacity>
      </View>
      <Button
        mode="outline"
        style={isWide ? style.chartButton : mobileStyles.chartButton}
        textStyle={{
          fontSize: 13,
          fontWeight: '300',
          lineHeight: 24,
          whiteSpace: 'nowrap',
        }}
        onPress={navigateToChart}
      >
        {isWide ? t('今日のチャートを表示') : t('今日のチャート')}
      </Button>
    </View>
  )
}

interface ReservationCalendarHeaderContainerProps {
  restaurantId: number
  visibleMonth: string
  onVisibleMonthsChange?: CalendarProps['onVisibleMonthsChange']
  onClose: () => void
}

export function ReservationCalendarHeader({
  restaurantId,
  visibleMonth,
  onVisibleMonthsChange,
  onClose,
}: ReservationCalendarHeaderContainerProps) {
  const { width, sm, md } = useResponsive()

  return (
    <ReservationCalendarHeaderPresentation
      restaurantId={restaurantId}
      visibleMonth={visibleMonth}
      onVisibleMonthsChange={onVisibleMonthsChange}
      width={width}
      sm={sm}
      md={md}
      onClose={onClose}
    />
  )
}

const style = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.white,
    width: '100%',
  },
  middleSection: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    flex: 1,
  },
  chartButton: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 'auto',
    backgroundColor: 'transparent',
    width: 172,
    height: 33,
  },
  todayButton: {
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: 'transparent',
    height: 25,
    width: 44,
    paddingHorizontal: 6, // nativeの方でpaddingHorizontalがないと文字が切れるため追加
  },
  yearMonthContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    gap: 8,
  },
  yearMonthTextContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  dateTextSection: {
    borderRadius: 19,
    height: 42,
    backgroundColor: Colors.primaryBg,
    justifyContent: 'center',
    alignItems: 'center',
    paddingHorizontal: 16,
    width: 180,
  },
  dateText: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.primary,
  },
  monthChangingSection: {
    flexDirection: 'row',
    columnGap: 4,
  },
  monthChangingText: {
    fontWeight: '600',
    fontSize: 14,
    lineHeight: 22,
    color: Colors.primary,
  },
})

const mobileStyles = StyleSheet.create({
  container: {
    ...style.container,
    justifyContent: 'space-between',
    paddingLeft: 0,
    paddingRight: 0,
    marginVertical: 0,
  },
  middleSection: {
    ...style.middleSection,
    justifyContent: 'flex-start',
    gap: 4,
  },
  chartButton: {
    ...style.chartButton,
    width: 'auto',
    paddingHorizontal: 8,
  },
  dateTextSection: {
    ...style.dateTextSection,
    minWidth: 160,
  },
})
