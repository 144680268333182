import React from 'react'

import TableSeatListView from '@hello-ai/for_r_app/src/components/Restaurant/TableSeat/ArchivedListView'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableSeatsArchivedScreen() {
  const restaurantId = useRestaurantId()

  return <TableSeatListView restaurantId={restaurantId} />
}
