import React, { useMemo } from 'react'

import { ScrollView, View } from 'react-native'

import { useCalendarContext } from './CalendarContext'
import DayComponent from './DayComponent'

export default function Calendar() {
  const { visibleMonth } = useCalendarContext()

  const weeklyDays = useMemo(() => {
    let firstDay = visibleMonth.startOf('month')
    if (firstDay.day() !== 0) {
      firstDay = firstDay.subtract(firstDay.day(), 'day')
    }
    let lastDay = visibleMonth.endOf('month')
    if (lastDay.day() !== 6) {
      lastDay = lastDay.add(6 - lastDay.day(), 'day')
    }
    const days = Array.from(
      { length: lastDay.diff(firstDay, 'day') + 1 },
      (_, i) => firstDay.add(i, 'day')
    )
    const weeklyDays = Array.from({ length: days.length / 7 }, (_, i) =>
      days.slice(i * 7, (i + 1) * 7)
    )
    return weeklyDays
  }, [visibleMonth])

  return (
    <ScrollView>
      {weeklyDays.map((weeklyDay, i) => {
        return (
          <View key={`week-${i}`} style={{ flexDirection: 'row', flex: 1 }}>
            {weeklyDay.map((day) => {
              return <DayComponent key={day.date()} date={day} />
            })}
          </View>
        )
      })}
    </ScrollView>
  )
}
