import { SWRConfiguration } from 'swr'

import { onError } from '@hello-ai/ar_shared/src/modules/auth'
import { swrNativeMiddleware } from '@hello-ai/ar_shared/src/modules/swr/middleware'

import axios, { setHeader } from '../axios'

import { onErrorRetry } from './onErrorRetry'
import { revalidateAllMiddleware } from './revalidateAllMiddleware'

export * from '@hello-ai/ar_shared/src/modules/swr'
export { default } from '@hello-ai/ar_shared/src/modules/swr'

export * from './revalidateAllMiddleware.native'

export const defaultConfig: SWRConfiguration = {
  onError,
  onErrorRetry,
  focusThrottleInterval: 0,
  use: [swrNativeMiddleware, revalidateAllMiddleware],
}

export async function fetcherPost<T = any, P extends {} = {}>([
  token,
  url,
  params,
]: [token: string | null, url: string, params?: P]) {
  setHeader({ token })
  const response = await axios.post<T>(url, params)
  return response.data
}

export async function fetcher<T = any, P extends {} = {}>([
  token,
  url,
  params,
]: [token: string | null, url: string, params?: P]) {
  setHeader({ token })
  const response = await axios.get<T>(url, { params })
  return response.data
}

export type WithHeaderData<T> = {
  data: T
  headerData: {
    currentPage: number
    totalPages: number
    totalCount: number
  }
}

export async function fetcherWithPaginationHeader<T, P extends {}>([
  token,
  url,
  params,
]: [
  token: string | null,
  url: string,
  params?: P,
]): Promise<WithHeaderData<T> | null> {
  setHeader({ token })
  const response = await axios.get<T>(url, { params })
  if (response.data != null) {
    const currentPage = response.headers['x-current-page'] ?? '0'
    const totalPages = response.headers['x-total-pages'] ?? '0'
    const totalCount = response.headers['x-total-count'] ?? '0'
    return {
      data: response.data,
      headerData: {
        currentPage: Number(currentPage),
        totalPages: Number(totalPages),
        totalCount: Number(totalCount),
      },
    }
  }
  return null
}
