import React from 'react'

import { View } from 'react-native'

import type { Dayjs } from '@hello-ai/ar_shared/src/modules/dayjs'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { NeedConfirmReservationContent } from './NeedConfirmReservationContent'
import { NeedConfirmReservationHeader } from './NeedConfirmReservationHeader'
import { useNeedConfirmReservations } from './hooks'
import { createStyles } from './styles'

type Props = {
  restaurantId: number
  date: Dayjs
}

export function NeedConfirmReservation({ restaurantId, date }: Props) {
  const { width, sm } = useResponsive()
  const isDesktop = width > sm
  const reservationAmounts = useNeedConfirmReservations({ restaurantId, date })
  const styles = createStyles(isDesktop)

  if (reservationAmounts.amount === 0) return null

  return (
    <View style={styles.container}>
      <NeedConfirmReservationHeader
        amount={reservationAmounts.amount}
        isDesktop={isDesktop}
      />
      <NeedConfirmReservationContent
        reservationAmounts={reservationAmounts}
        isDesktop={isDesktop}
      />
    </View>
  )
}
