import React, { RefObject, useMemo, useRef } from 'react'

import { faGripLines } from '@fortawesome/pro-solid-svg-icons/faGripLines'
import { filter, times } from 'lodash'
import { Trans } from 'react-i18next'
import {
  TouchableOpacity as RNTouchableOpacity,
  ScrollView,
  View,
} from 'react-native'
import DragList from 'react-native-draglist'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { toDragHandlers } from '@hello-ai/ar_shared/src/modules/drag'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import type { ReplaceAll } from '@hello-ai/ar_shared/src/types/utils'
import {
  LocaleValue,
  TranslationFormItem,
  TranslationFormList,
  confirmSaveIncompleteChangesAsync,
  getSortedLocales,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
} from '@hello-ai/for_r_app/src/components/Translation'

import { useToken } from 'models/Auth'
import { useRestaurantBusinessTimes } from 'models/RestaurantBusinessTime'
import { TableMenu } from 'models/TableMenu'
import { createTranslation } from 'models/Translation'
import { goBack } from 'modules/history'
import { supportedLocales } from 'modules/locale'
import { toDoubleDigits } from 'modules/number'
import { splitHourAndMinute } from 'modules/time'
import { useRestaurantCountryLanguage } from 'modules/useRestaurantCountryLanguage'
import { useRestaurantId } from 'modules/useRestaurantId'

const hours = times(30, (i) => {
  return {
    label: toDoubleDigits(i),
    value: i,
  }
})

const mins = times(60, (i) => {
  return {
    label: toDoubleDigits(i),
    value: i,
  }
})

function initWdays(wdays: number[]): Record<
  string,
  {
    label: string
    checked: boolean
    value: number
  }
> {
  return {
    0: {
      label: dayjs.weekdays()[0],
      checked: wdays.includes(0),
      value: 0,
    },
    1: {
      label: dayjs.weekdays()[1],
      checked: wdays.includes(1),
      value: 1,
    },
    2: {
      label: dayjs.weekdays()[2],
      checked: wdays.includes(2),
      value: 2,
    },
    3: {
      label: dayjs.weekdays()[3],
      checked: wdays.includes(3),
      value: 3,
    },
    4: {
      label: dayjs.weekdays()[4],
      checked: wdays.includes(4),
      value: 4,
    },
    5: {
      label: dayjs.weekdays()[5],
      checked: wdays.includes(5),
      value: 5,
    },
    6: {
      label: dayjs.weekdays()[6],
      checked: wdays.includes(6),
      value: 6,
    },
    7: {
      label: t('祝日'),
      checked: wdays.includes(7),
      value: 7,
    },
  }
}

function OrderItem({
  name,
  index,
  onPress,
  onDragStart,
  onDragEnd,
  isActive,
  isSortable,
  scrollRef,
}: {
  name?: string
  index?: number
  onPress: () => void
  onDragStart: () => void
  onDragEnd: () => void
  isActive: boolean
  isSortable: boolean
  scrollRef: RefObject<HTMLDivElement>
}) {
  if (name === null) {
    return null
  }

  return (
    <RNTouchableOpacity
      style={{
        paddingVertical: 24,
        borderTopWidth: index === 0 ? 0 : 0.5,
        borderColor: Colors.border,
        backgroundColor: isActive ? Colors.primaryBg : 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onPress={!isSortable ? onPress : undefined}
      {...(isSortable ? toDragHandlers(onDragStart, onDragEnd, scrollRef) : {})}
    >
      <Text>{name}</Text>
      {isSortable && (
        <FontAwesomeIcon
          icon={faGripLines}
          size={20}
          color={Colors.disabledBlack}
        />
      )}
    </RNTouchableOpacity>
  )
}

export default function TableMenuFormContent({
  tableMenu,
  createMenu,
  updateMenu,
  onPressMenuPage,
  onPressUnlimitedOrderPlan,
  isOwner,
}: {
  tableMenu?: TableMenu
  createMenu: (args: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  updateMenu: (args: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  onPressMenuPage: (id: string) => void
  onPressUnlimitedOrderPlan: (id: string) => void
  isOwner: boolean
}) {
  const scrollRef = useRef(null)
  const { width, sm } = useResponsive()
  const space = width < sm ? 20 : 40
  const restaurantId = useRestaurantId()
  const token = useToken()
  const tableMenuId = tableMenu?.id

  const preferredLocale = useRestaurantCountryLanguage()
  const sortedLocales = getSortedLocales(preferredLocale)

  const [isSortable, setIsSortable] = useFormState(false)

  const defaultName = useMemo(() => {
    return Object.fromEntries(
      supportedLocales.map((locale) => {
        const field = `name_${locale
          .replaceAll('-', '_')
          .toLowerCase()}` as `name_${Lowercase<
          ReplaceAll<typeof locale, '-', '_'>
        >}`
        return [locale, tableMenu?.[field] ?? '']
      })
    ) as LocaleValue
  }, [tableMenu])

  const [name, setName] = useFormState<LocaleValue>(defaultName)

  const [serviceFeePercent, setServiceFeePercent] = useFormState(
    tableMenu?.restaurant_service_fee_rate != null
      ? (tableMenu.restaurant_service_fee_rate * 100).toString()
      : '0'
  )
  const [startHour, setStartHour] = useFormState(
    tableMenu?.start_time != null
      ? splitHourAndMinute(tableMenu.start_time).hour
      : 0
  )
  const [startMin, setStartMin] = useFormState(
    tableMenu?.start_time != null
      ? splitHourAndMinute(tableMenu.start_time).minute
      : 0
  )
  const [endHour, setEndHour] = useFormState(
    tableMenu?.end_time != null
      ? splitHourAndMinute(tableMenu.end_time).hour
      : 0
  )
  const [endMin, setEndMin] = useFormState(
    tableMenu?.end_time != null
      ? splitHourAndMinute(tableMenu.end_time).minute
      : 0
  )
  const [wdays, setWdays] = useFormState(
    initWdays(tableMenu?.wdays ?? [0, 1, 2, 3, 4, 5, 6, 7])
  )
  const [navigationIds, setNavigationIds] = useFormState(
    (tableMenu?.available_table_menu_navigations ?? [])
      .filter((navigation) => {
        return !(
          navigation.navigation_type === 'menu_page' &&
          navigation.table_menu_page == null
        )
      })
      .map((navigation) => navigation.id)
  )

  const [unlimitedOrderPlanIds, setUnlimitedOrderPlanIds] = useFormState(
    (tableMenu?.table_unlimited_order_plans ?? []).map((plan) => {
      return plan.id
    })
  )

  const [restaurantBusinessTimeIds, setRestaurantBusinessTimeIds] =
    useFormState(tableMenu?.restaurant_business_times.map(({ id }) => id) ?? [])

  const [startTimeOffset, setStartTimeOffset] = useFormState(
    `${-Math.round(tableMenu?.start_time_offset ?? 0) / 60}`
  )
  const [endTimeOffset, setEndTimeOffset] = useFormState(
    `${Math.round(tableMenu?.end_time_offset ?? 0) / 60}`
  )

  const { restaurantBusinessTimes } = useRestaurantBusinessTimes(restaurantId)

  const hasCategories = navigationIds.length > 0
  const hasUnlimitedOrderPlans = unlimitedOrderPlanIds.length > 0

  const tabItems = useMemo(() => {
    const items = []
    if (hasCategories) {
      items.push({
        label: t('カテゴリー'),
        value: 'category',
      })
    }

    if (hasUnlimitedOrderPlans) {
      items.push({
        label: t('放題プラン'),
        value: 'unlimitedOrderPlan',
      })
    }

    return items
  }, [hasCategories, hasUnlimitedOrderPlans])

  const [tabName, setTabName] = useFormState(
    hasCategories
      ? 'category'
      : hasUnlimitedOrderPlans
        ? 'unlimitedOrderPlan'
        : 'category'
  )

  const onPress = async () => {
    if (name[preferredLocale] === '') {
      Alert.alert(t('メニュー名を入力してください'))
      return
    }

    const serviceFeeRate = Number(serviceFeePercent) / 100
    if (serviceFeeRate < 0.0 || serviceFeeRate > 1.0) {
      Alert.alert(t('サービス料は0~100%以内である必要があります'))
      return
    }

    const startTime = startHour * 3600 + startMin * 60
    const endTime = endHour * 3600 + endMin * 60
    const params = {
      ...Object.fromEntries(
        supportedLocales.map((locale) => {
          return [
            `name_${locale.replaceAll('-', '_').toLowerCase()}`,
            name[locale],
          ]
        })
      ),
      restaurant_service_fee_rate: Number(serviceFeePercent) / 100,
      start_time: startTime,
      end_time: endTime,
      wdays: filter(wdays, { checked: true }).map((wday) => {
        return wday.value
      }),
      table_menu_navigation_ids: navigationIds,
      table_unlimited_order_plan_ids: unlimitedOrderPlanIds,
      restaurant_business_time_ids: restaurantBusinessTimeIds,
      start_time_offset: -Number(startTimeOffset) * 60,
      end_time_offset: Number(endTimeOffset) * 60,
    }

    if (token == null) {
      return
    }

    if (tableMenuId != null) {
      if (hasIncompleteChanges(name, defaultName)) {
        if (!(await confirmSaveIncompleteChangesAsync(Alert.alert))) {
          return
        }
      }

      await updateMenu({
        token,
        params,
      })
      goBack('..')
    } else {
      await createMenu({
        token,
        params,
      })
      goBack('..')
    }
  }

  const onPressUpdateItemOrder = async () => {
    if (tableMenuId != null && token != null) {
      const params = {
        table_menu_navigation_ids: navigationIds,
        table_unlimited_order_plan_ids: unlimitedOrderPlanIds,
      }
      await updateMenu({
        token,
        params,
      })
    }
  }

  const alertArchive = () => {
    const params = {
      status: 'archived',
    }
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (token == null || tableMenuId == null) {
            return
          }
          await updateMenu({
            token,
            params,
          })
          goBack('..')
        },
      },
    ])
  }

  const paddingHorizontal = width < sm ? 16 : 36

  const isTimeColumnsMigrated = tableMenu?.time_columns_migrated ?? true

  return (
    <ScrollView
      ref={scrollRef}
      showsVerticalScrollIndicator={false}
      showsHorizontalScrollIndicator={false}
      style={{
        overflow: 'scroll',
        height: '100%',
      }}
      contentContainerStyle={{
        paddingVertical: space,
      }}
    >
      <DragList
        key={
          tabName === 'category'
            ? navigationIds.join()
            : unlimitedOrderPlanIds.join()
        }
        data={tabName === 'category' ? navigationIds : unlimitedOrderPlanIds}
        keyExtractor={(item) => item}
        renderItem={({ item, index, isActive, onDragStart, onDragEnd }) => {
          if (tabName === 'category') {
            const navigation = tableMenu?.available_table_menu_navigations.find(
              (navigation) => navigation.id === item
            )

            if (navigation == null) {
              return null
            }

            if (
              navigation.navigation_type === 'menu_page' &&
              navigation.table_menu_page === null
            ) {
              return null
            }

            const name =
              navigation.navigation_type === 'menu_page'
                ? navigation?.table_menu_page?.name
                : navigation.name

            return (
              <View style={{ paddingHorizontal }}>
                <OrderItem
                  name={name}
                  index={index}
                  onDragStart={onDragStart}
                  onDragEnd={onDragEnd}
                  onPress={() => {
                    if (navigation.navigation_type === 'unlimited_order_plan') {
                      return
                    }

                    if (
                      navigation?.table_menu_page?.group_managed &&
                      !isOwner
                    ) {
                      return
                    }

                    if (navigation?.table_menu_page?.id != null) {
                      onPressMenuPage(navigation?.table_menu_page?.id)
                    }
                  }}
                  isSortable={isSortable}
                  isActive={isActive}
                  scrollRef={scrollRef}
                />
              </View>
            )
          }

          const unlimitedOrderPlan =
            tableMenu?.table_unlimited_order_plans.find(
              (plan) => plan.id === item
            )
          if (!unlimitedOrderPlan) {
            return null
          }

          return (
            <View style={{ paddingHorizontal }}>
              <OrderItem
                name={unlimitedOrderPlan.name}
                index={index}
                onDragStart={onDragStart}
                onDragEnd={onDragEnd}
                onPress={() => {
                  if (unlimitedOrderPlan.group_managed && !isOwner) {
                    return
                  }
                  onPressUnlimitedOrderPlan(unlimitedOrderPlan.id)
                }}
                isSortable={isSortable}
                isActive={isActive}
                scrollRef={scrollRef}
              />
            </View>
          )
        }}
        ListHeaderComponent={
          <View
            style={{
              paddingHorizontal,
            }}
          >
            <TranslationFormList
              formLabel={<FormLabel value={t('メニュー名')} required />}
              showsFormDescription
              sortedLocales={sortedLocales}
              values={name}
              onChangeValues={setName}
              createTranslation={(params) => createTranslation(token!, params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={t('メニュー名')}
                    value={name[locale]}
                    onChangeText={(text) =>
                      setName((prev) => ({
                        ...prev,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <FormGroup formLabel={<FormLabel value={t('サービス料')} />}>
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <TextInput
                  placeholder={t('サービス料')}
                  value={serviceFeePercent}
                  onChangeText={(text) => setServiceFeePercent(text)}
                  autoCapitalize="none"
                  keyboardType="number-pad"
                  style={{ flex: 1 }}
                />
                <Text style={{ marginLeft: 16 }}>%</Text>
              </View>
            </FormGroup>

            {isTimeColumnsMigrated && (
              <>
                <View
                  style={{
                    marginTop: space,
                  }}
                >
                  <Text
                    style={{
                      fontWeight: '600',
                    }}
                  >
                    {t('営業時間')}
                  </Text>
                  <View style={{ marginTop: 24 }}>
                    <CheckboxGroup
                      value={restaurantBusinessTimeIds}
                      onChange={setRestaurantBusinessTimeIds}
                    >
                      {restaurantBusinessTimes?.map((businessTime) => {
                        return (
                          <Checkbox
                            key={businessTime.id}
                            value={businessTime.id}
                            checkboxLabel={
                              <CheckboxLabel value={businessTime.name} />
                            }
                          />
                        )
                      })}
                    </CheckboxGroup>
                  </View>
                  <View
                    style={{
                      marginTop: space,
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: '600',
                      }}
                    >
                      {t('お客さまへの表示時間')}
                    </Text>
                    <Text
                      style={{
                        marginTop: 16,
                      }}
                    >
                      {t(
                        '営業時間の前後の時間も、お客様への表示を有効にすることができます'
                      )}
                    </Text>
                    <View
                      style={{
                        marginTop: 24,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {/* eslint-disable ar-i18n/require-translation-ja */}
                      <Trans
                        i18nKey={
                          '<0></0><1></1><2>分前から</2><3></3><4>分後まで</4>'
                        }
                      >
                        {/*
                          翻訳時に prefix がつくのでそれをいれるための <Text />
                          Trans を Text で囲むと TextInput が表示されなくなる
                        */}
                        <Text />
                        <TextInput
                          style={{
                            flex: 1,
                          }}
                          value={startTimeOffset}
                          onChangeText={setStartTimeOffset}
                          autoCapitalize="none"
                          keyboardType="number-pad"
                        />
                        <Text
                          style={{
                            marginHorizontal: 8,
                          }}
                        >
                          {'分前から'}
                        </Text>
                        <TextInput
                          style={{
                            flex: 1,
                          }}
                          value={endTimeOffset}
                          onChangeText={setEndTimeOffset}
                          autoCapitalize="none"
                          keyboardType="number-pad"
                        />
                        <Text
                          style={{
                            marginLeft: 8,
                          }}
                        >
                          {'分後まで'}
                        </Text>
                      </Trans>
                      {/* eslint-enable ar-i18n/require-translation-ja */}
                    </View>
                  </View>
                </View>
              </>
            )}
            {!isTimeColumnsMigrated && (
              <>
                <View style={{ flexDirection: 'row', marginTop: space }}>
                  <View style={{ flex: 1, marginRight: 12 }}>
                    <Text style={{ fontWeight: '600' }}>{t('開始時間')}</Text>
                    <View
                      style={{
                        marginTop: 20,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <SelectInput
                        selectedValue={startHour}
                        setValue={setStartHour}
                        items={hours}
                        style={{ flex: 1 }}
                      />
                      <Text style={{ marginHorizontal: 8 }}>:</Text>
                      <SelectInput
                        selectedValue={startMin}
                        setValue={setStartMin}
                        items={mins}
                        style={{ flex: 1 }}
                      />
                    </View>
                  </View>
                  <View style={{ flex: 1, marginLeft: 12 }}>
                    <Text style={{ fontWeight: '600' }}>{t('終了時間')}</Text>
                    <View
                      style={{
                        marginTop: 16,
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <SelectInput
                        selectedValue={endHour}
                        setValue={setEndHour}
                        items={hours}
                        style={{ flex: 1 }}
                      />
                      <Text style={{ marginHorizontal: 8 }}>:</Text>
                      <SelectInput
                        selectedValue={endMin}
                        setValue={setEndMin}
                        items={mins}
                        style={{ flex: 1 }}
                      />
                    </View>
                  </View>
                </View>
                <View style={{ marginTop: space }}>
                  <Text style={{ fontWeight: '600' }}>{t('曜日')}</Text>
                  <View style={{ marginTop: 20 }}>
                    <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
                      {Object.keys(wdays).map((key) => {
                        const wday = wdays[key]
                        return (
                          <View key={key}>
                            <Checkbox
                              checked={wday.checked}
                              onChange={(value) =>
                                setWdays((state) => ({
                                  ...state,
                                  [key]: {
                                    label: wday.label,
                                    checked: value,
                                    value: wday.value,
                                  },
                                }))
                              }
                              checkboxLabel={
                                <CheckboxLabel value={wday.label} />
                              }
                            />
                          </View>
                        )
                      })}
                    </Wrap>
                  </View>
                  <Text style={{ marginTop: 16, color: Colors.secondaryBlack }}>
                    {t('休日が祝日の場合は休日の設定が優先されます')}
                  </Text>
                </View>
              </>
            )}
            {tabItems.length >= 1 && (
              <View>
                <View style={{ marginTop: space, flexDirection: 'column' }}>
                  <View
                    style={{
                      flex: 1,
                      flexDirection: width < sm ? 'column' : 'row',
                      justifyContent: 'space-between',
                      alignItems: width < sm ? 'flex-start' : 'center',
                    }}
                  >
                    <View
                      style={[
                        {
                          flex: 0.5,
                          flexDirection: width < sm ? 'column' : 'row',
                          justifyContent: 'space-between',
                          alignItems: width < sm ? 'flex-start' : 'center',
                        },
                      ]}
                    >
                      <Text style={{ fontWeight: '600' }}>
                        {tabItems.length === 1
                          ? t('{{text}}の並び替え', { text: tabItems[0].label })
                          : t('並び替え')}
                      </Text>
                      {tabItems.length > 1 && (
                        <View
                          style={[
                            width >= sm && { flex: 1, marginLeft: 12 },
                            width < sm && {
                              width: 240,
                              marginTop: 12,
                            },
                          ]}
                        >
                          <SegmentedControl
                            buttons={tabItems.map((item) => {
                              return {
                                text: item.label,
                                selected: item.value === tabName,
                                onPress: () => {
                                  setTabName(item.value)
                                },
                              }
                            })}
                          />
                        </View>
                      )}
                    </View>
                    <Button
                      style={[
                        {
                          width: 140,
                          height: 50,
                          backgroundColor: isSortable
                            ? Colors.primary
                            : 'white',
                        },
                        width < sm && {
                          marginTop: 12,
                        },
                      ]}
                      onPress={() => {
                        if (isSortable) onPressUpdateItemOrder()
                        setIsSortable((prev) => !prev)
                      }}
                    >
                      <Text
                        style={{
                          color: !isSortable ? Colors.primary : 'white',
                        }}
                      >
                        {isSortable ? t('更新') : t('並び替え')}
                      </Text>
                    </Button>
                  </View>
                </View>
              </View>
            )}
          </View>
        }
        ListFooterComponent={
          <View style={{ paddingHorizontal }}>
            <View style={{ flexDirection: 'row', marginTop: space }}>
              {tableMenuId != null && (
                <Button
                  mode="outline"
                  variant="danger-secondary"
                  height={56}
                  style={{
                    flex: 1,
                    marginRight: 32,
                  }}
                  onPress={() => alertArchive()}
                >
                  {t('アーカイブ')}
                </Button>
              )}
              <Button
                height={56}
                style={{
                  flex: 1,
                }}
                onPress={() => onPress()}
              >
                {tableMenuId != null ? t('更新する') : t('追加する')}
              </Button>
            </View>
          </View>
        }
        onReordered={async (fromIndex, toIndex) => {
          if (tabName === 'category') {
            setNavigationIds((prev) => {
              const removed = prev[fromIndex]
              return prev.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, removed)
            })
          } else {
            setUnlimitedOrderPlanIds((prev) => {
              const removed = prev[fromIndex]
              return prev.toSpliced(fromIndex, 1).toSpliced(toIndex, 0, removed)
            })
          }
        }}
      />
    </ScrollView>
  )
}
