import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { RestaurantCustomerImage } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomerImage'

import axios, { setHeader, wrapResponse } from '../modules/axios'
import useSWR, { swrKey, fetcher, mutate, onError } from '../modules/swr'

export * from '@hello-ai/ar_shared/src/types/ForR/RestaurantCustomerImage'

export function useRestaurantCustomerImages(
  restaurantId: number,
  customerId: string
) {
  const token = useToken()

  const {
    data: restaurantCustomerImages,
    error,
    mutate,
  } = useSWR<RestaurantCustomerImage[], any, ReturnType<typeof swrKey>>(
    swrKey(
      token,
      `/restaurants/${restaurantId}/customers/${customerId}/restaurant_customer_images`
    ),
    fetcher
  )

  return {
    restaurantCustomerImages,
    isLoading: restaurantCustomerImages == null && error != null,
    error,
    mutate,
  }
}

export async function createRestaurantCustomerImage(
  token: string | null,
  restaurantId: number,
  customerId: string,
  params: {
    image: string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<RestaurantCustomerImage>(
      `/restaurants/${restaurantId}/customers/${customerId}/restaurant_customer_images`,
      params
    )
  )

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/restaurants/${restaurantId}/customers/${customerId}/restaurant_customer_images`
      )
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    restaurantCustomerImage: response?.data,
    error,
  }
}

export async function destroyRestaurantCustomerImage(
  token: string | null,
  id: RestaurantCustomerImage['id']
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.delete<{}>(`/restaurant_customer_images/${id}`)
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
  }
}
