import React, { useState } from 'react'

import { faFileCsv } from '@fortawesome/pro-regular-svg-icons/faFileCsv'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { ActivityIndicator, ScrollView, View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Modal } from 'components/Shared/Modal'
import { SelectDocument } from 'components/Shared/SelectDocument'
import { FailedRowMessage, importCSV } from 'models/TableProduct'
import { useRestaurantId } from 'modules/useRestaurantId'

function ErrorMessageModal({
  failedRows,
  isVisible,
  setModalVisible,
  errorMessage,
}: {
  isVisible: boolean
  setModalVisible: () => void
  failedRows: FailedRowMessage[]
  errorMessage?: string
}) {
  const { width, sm } = useResponsive()
  return (
    <Modal isVisible={isVisible} onBackdropPress={setModalVisible}>
      <View
        style={{
          paddingTop: 24,
          backgroundColor: 'white',
          width: width < sm ? '90%' : '50%',
          minHeight: 300,
          alignSelf: 'center',
          borderRadius: 4,
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            paddingHorizontal: 24,
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              color: Colors.caution,
              fontSize: 18,
            }}
          >
            {t('エラーが発生しました')}
          </Text>
          <ScrollView
            style={{
              marginTop: 24,
              maxHeight: 400,
            }}
          >
            {errorMessage !== undefined ? (
              <Text
                style={{
                  fontSize: 18,
                  lineHeight: 24,
                }}
              >
                {errorMessage}
              </Text>
            ) : (
              failedRows.map((failedRow) => {
                return (
                  <Text
                    key={failedRow.row}
                    style={{
                      fontSize: 18,
                      lineHeight: 24,
                    }}
                  >
                    {failedRow.row}:{failedRow.error}
                  </Text>
                )
              })
            )}
          </ScrollView>
        </View>
        <View
          style={{
            borderTopWidth: 0.5,
            borderStyle: 'solid',
            borderTopColor: Colors.border,
            alignItems: 'center',
            paddingVertical: 24,
          }}
        >
          <TouchableOpacity onPress={setModalVisible}>
            <Text
              style={{
                fontSize: 16,
                color: Colors.black60,
              }}
            >
              {t('閉じる')}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  )
}

export default function ImportCSVScreen() {
  const restaurantId = useRestaurantId()
  const [fileName, setFileName] = useState<string | null>(null)
  const [csvFile, setCSVFile] = useState<File | null | undefined>(null)
  const [failedRows, setFailedRows] = useState<FailedRowMessage[]>([])
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  )
  const [hasError, setHasError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isImportSuccess, setIsImportSuccess] = useState(false)

  const reset = () => {
    setHasError(false)
    setFailedRows([])
    setErrorMessage(undefined)
    setIsImportSuccess(false)
  }

  const uploadCSVFile = async () => {
    if (csvFile != null) {
      try {
        setIsSubmitting(true)
        setIsImportSuccess(false)
        const { failedRows, error } = await importCSV({
          restaurantId,
          file: csvFile,
        })

        const isError = failedRows.length > 0 || error !== undefined

        if (isError) {
          setFailedRows(failedRows ?? [])
          setErrorMessage(error)
          setHasError(isError)
        } else {
          setIsImportSuccess(true)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setIsSubmitting(false)
      }
    }
  }

  return (
    <View
      style={{
        padding: 48,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: 48,
          borderBottomColor: Colors.border,
          borderBottomWidth: 0.5,
          borderStyle: 'solid',
        }}
      >
        <Text
          style={{
            fontSize: 18,
          }}
        >
          {t('CSVファイルをインポート')}
        </Text>
        <SelectDocument
          options={{
            type: 'text/csv',
            multiple: false,
          }}
          onPress={(result) => {
            setFileName(result.name)
            setCSVFile(result.file)
            reset()
          }}
        >
          {t('ファイルを選択')}
        </SelectDocument>
      </View>
      <View
        style={{
          paddingVertical: 24,
          paddingHorizontal: 8,
          borderBottomColor: Colors.border,
          borderBottomWidth: 0.5,
          borderStyle: 'solid',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {fileName !== null ? (
            <>
              <FontAwesomeIcon icon={faFileCsv} size={22} />
              <View
                style={{
                  marginLeft: 8,
                }}
              >
                <Text
                  style={{
                    fontSize: 18,
                  }}
                >
                  {fileName}
                </Text>
              </View>
              {isImportSuccess && (
                <View
                  style={{
                    marginLeft: 8,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    size={24}
                    color={Colors.success}
                  />
                </View>
              )}
            </>
          ) : (
            <Text
              style={{
                fontSize: 18,
              }}
            >
              {t('ファイルが選択されていません。')}
            </Text>
          )}
        </View>
      </View>
      <View
        style={{
          marginTop: 48,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          disabled={isSubmitting || csvFile === null}
          height={48}
          onPress={uploadCSVFile}
        >
          {t('インポートする')}
        </Button>
        {(isSubmitting || isImportSuccess) && (
          <View
            style={{
              marginTop: 16,
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            {isSubmitting && (
              <View
                style={{
                  marginRight: 8,
                }}
              >
                <ActivityIndicator size="small" color={Colors.primary} />
              </View>
            )}
            <Text
              style={{
                fontSize: 16,
              }}
            >
              {isSubmitting && t('インポート中です。')}
              {isImportSuccess && t('インポートが完了しました')}
            </Text>
          </View>
        )}
      </View>
      <ErrorMessageModal
        isVisible={hasError}
        failedRows={failedRows}
        errorMessage={errorMessage}
        setModalVisible={() => {
          reset()
        }}
      />
    </View>
  )
}
