import React, { useMemo, useState } from 'react'

import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { View, Dimensions, Platform } from 'react-native'

import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { CheckboxIcon } from '@hello-ai/ar_shared/src/components/Checkbox'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { onError, useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import { TableSeatResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

import {
  bulkUpdateTableSeatStatus,
  tableSeatService,
} from '../../../models/TableSeat'
import { getCategoryName } from '../../TableSeats/common'
import ConfirmDeleteArchivedProductModal from '../TableProduct/ConfirmDeleteArchivedProductModal'

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  root: {
    flex: 1,
    backgroundColor: Colors.bgBlack,
    flexGrow: 1,
  },
  container: {
    paddingVertical: 16,
    paddingHorizontal: width < sm ? 16 : 24,
  },
  scrollViewContainer: {
    backgroundColor: Colors.white,
    borderRadius: 8,
    height:
      Dimensions.get('window').height -
      (width < sm
        ? // NOTE: header + footer + padding + table header
          Platform.OS === 'web'
          ? 280
          : 320 // NOTE: Edge部分が見切れるのでリストは少し低めに設定する
        : // NOTE: header + footer + padding + table header
          238),
    paddingTop: width < sm ? 20 : 16,
    paddingHorizontal: width < sm ? 16 : 24,
  },
  tableContainer: {
    rowGap: width < sm ? 20 : 16,
  },
  rowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: width < sm ? 20 : 16,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  rowContent: {
    flexDirection: width < sm ? 'column' : 'row',
    marginLeft: width < sm ? 12 : 24,
  },
  pagination: {
    paddingVertical: 16,
    marginHorizontal: 0,
    justifyContent: 'center',
    columnGap: 20,
  },
  footerContainer: {
    flexDirection: width < sm ? 'column-reverse' : 'row',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 12,
    columnGap: 16,
    backgroundColor: Colors.white,
    paddingVertical: width < sm ? 16 : 24,
    paddingHorizontal: width < sm ? 16 : 12,
  },
  footerButton: {
    width: width < sm ? '100%' : undefined,
    height: 48,
  },
  spNone: {
    display: width < sm ? 'none' : 'flex',
  },
}))

const PER_PAGE = 10

interface ArchivedListViewProps {
  restaurantId: number
}

export default function ArchivedListView({
  restaurantId,
}: ArchivedListViewProps) {
  const style = useResponsiveStyle(responsiveStyle)
  const token = useToken()

  const { data, mutate } = tableSeatService.useList({
    restaurantId,
    status: TableSeatResource_Status.ARCHIVED,
  })
  const [selectTableSeatIds, setSelectTableSeatIds] = useState<Set<string>>(
    new Set()
  )

  const allTableSeats = useMemo(() => data?.tableSeats ?? [], [data])
  const checkedAll = useMemo(() => {
    return (
      selectTableSeatIds.size > 0 &&
      selectTableSeatIds.size === allTableSeats.length
    )
  }, [selectTableSeatIds, allTableSeats])

  const [page, setPage] = useState(1)
  const tableSeats = useMemo(() => {
    return allTableSeats.slice((page - 1) * PER_PAGE, page * PER_PAGE)
  }, [allTableSeats, page])
  const [isVisibleConfirmCancel, setIsVisibleConfirmCancel] = useState(false)

  return (
    <View style={style.root}>
      <View style={style.container}>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            columnGap: 8,
            marginBottom: 16,
          }}
          disabled={allTableSeats.length === 0}
          onPress={() => {
            if (checkedAll) {
              setSelectTableSeatIds(new Set())
            } else {
              setSelectTableSeatIds(
                new Set(allTableSeats?.map((tableSeat) => tableSeat.id) ?? [])
              )
            }
          }}
        >
          <CheckboxIcon checked={checkedAll} />
          <Text>{t('すべて選択')}</Text>
        </TouchableOpacity>
        <View style={style.scrollViewContainer}>
          <KeyboardAwareScrollView contentContainerStyle={style.tableContainer}>
            {tableSeats != null && tableSeats.length === 0 && (
              <Text
                style={{
                  paddingTop: 16,
                  marginBottom: 24,
                  paddingHorizontal: 24,
                  fontSize: 22,
                  fontWeight: '600',
                  color: Colors.disabledBlack,
                  textAlign: 'center',
                }}
              >
                {t('該当する席はありません')}
              </Text>
            )}
            {tableSeats?.map((tableSeat) => {
              const checked = selectTableSeatIds.has(tableSeat.id)
              return (
                <TouchableOpacity
                  key={tableSeat.id}
                  style={style.rowContainer}
                  onPress={() => {
                    setSelectTableSeatIds((prev) => {
                      if (!checked) {
                        return new Set(prev).add(tableSeat.id)
                      }
                      prev.delete(tableSeat.id)
                      return new Set(prev)
                    })
                  }}
                >
                  <CheckboxIcon checked={checked} />
                  <View style={style.rowContent}>
                    <View style={{ rowGap: 8 }}>
                      <Text
                        style={{
                          fontSize: 18,
                          fontWeight: '600',
                        }}
                      >
                        {tableSeat.name}
                      </Text>
                      {tableSeat.minPartySize != null &&
                        tableSeat.maxPartySize != null &&
                        tableSeat.category != null && (
                          <View
                            style={{
                              flexDirection: 'row',
                              alignItems: 'center',
                              columnGap: 8,
                            }}
                          >
                            {tableSeat.minPartySize != null &&
                              tableSeat.maxPartySize != null && (
                                <View
                                  style={{
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faUsers}
                                    size={14}
                                    color={Colors.primary}
                                    style={{ marginRight: 4 }}
                                  />
                                  <Text
                                    style={{
                                      fontSize: 14,
                                    }}
                                  >
                                    {tableSeat.minPartySize.value}~
                                    {tableSeat.maxPartySize.value}
                                  </Text>
                                </View>
                              )}
                            {tableSeat.category != null && (
                              <View
                                style={{
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faChair}
                                  size={14}
                                  color={Colors.primary}
                                  style={{ marginRight: 4 }}
                                />
                                <Text
                                  style={{
                                    fontSize: 14,
                                  }}
                                >
                                  {getCategoryName(tableSeat.category.value)}
                                </Text>
                              </View>
                            )}
                          </View>
                        )}
                    </View>
                  </View>
                </TouchableOpacity>
              )
            })}
          </KeyboardAwareScrollView>
          <Pagination
            currentPage={page}
            totalPage={Math.ceil(allTableSeats.length / PER_PAGE)}
            setPage={(page) => {
              setPage(page)
            }}
            style={style.pagination}
          />
        </View>
      </View>
      <View style={style.footerContainer}>
        <Button
          mode="outline"
          variant="danger-secondary"
          style={[style.footerButton]}
          disabled={selectTableSeatIds.size === 0}
          onPress={() => {
            setIsVisibleConfirmCancel(true)
          }}
        >
          {t('チェックしたリストを完全に削除する')}
        </Button>
        <AsyncButton
          style={style.footerButton}
          disabled={selectTableSeatIds.size === 0}
          onPress={async () => {
            if (token == null) return
            const { error } = await bulkUpdateTableSeatStatus(
              token,
              restaurantId,
              {
                ids: Array.from(selectTableSeatIds),
                status: 'published',
              }
            )
            if (error != null) {
              onError(error)
              return
            }
            setSelectTableSeatIds(new Set())
            await mutate()
          }}
        >
          {t('チェックしたリストを元に戻す')}
        </AsyncButton>
      </View>
      <ConfirmDeleteArchivedProductModal
        title={t('{{count}}件のリストを完全に削除します', {
          count: selectTableSeatIds.size,
        })}
        isModalVisible={isVisibleConfirmCancel}
        onPressCancel={() => setIsVisibleConfirmCancel(false)}
        onPressSubmit={async () => {
          if (token == null) {
            return
          }
          const { error } = await bulkUpdateTableSeatStatus(
            token,
            restaurantId,
            {
              ids: Array.from(selectTableSeatIds),
              status: 'deleted',
            }
          )
          if (error != null) {
            onError(error)
            return
          }
          await mutate()
          setSelectTableSeatIds(new Set())
          setIsVisibleConfirmCancel(false)
        }}
      />
    </View>
  )
}
