import React, { useCallback, useState } from 'react'

import { View } from 'react-native'

import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { RefreshControl } from '@hello-ai/ar_shared/src/components/RefreshControl'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import { useRestaurant } from '../../../models/Restaurant'
import { useRestaurantReservations } from '../../../models/RestaurantReservation'
import { ContentScrollView } from '../../Restaurant/Reservation/ListView/Content'
import { IrregularNotification } from '../IrregularNotification'

export default function CanceledContent({
  restaurantId,
  date,
  onPressRestaurantReservation,
  onPressCustomer,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  onPressRestaurantReservation: (restaurantReservationId: string) => void
  onPressCustomer: (customerId: string) => void
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [isRefreshing, setIsRefreshing] = useState(false)

  const { data: restaurant } = useRestaurant(restaurantId)
  const { restaurantReservations, headerData, mutate } =
    useRestaurantReservations(restaurantId, {
      date: date.format('YYYY-MM-DD'),
      future_only: false,
      status: 'canceled',
      per_page: currentPage,
    })

  const onRefresh = useCallback(async () => {
    setIsRefreshing(true)
    try {
      await mutate()
    } finally {
      setIsRefreshing(false)
    }
  }, [mutate])

  if (restaurant == null) return null

  return (
    <ContentScrollView
      restaurant={restaurant}
      restaurantReservations={restaurantReservations}
      headerContent={
        <View style={{ flex: 1 }}>
          <IrregularNotification restaurantId={restaurantId} date={date} />
        </View>
      }
      headerContentSm={
        <IrregularNotification
          restaurantId={restaurantId}
          date={date}
          style={{ marginTop: 24 }}
        />
      }
      onPressRestaurantReservation={onPressRestaurantReservation}
      onPressCustomer={onPressCustomer}
      onIssueSmartPaymentReceipt={() => mutate()}
      refreshControl={
        <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
      }
      pagination={
        <Pagination
          currentPage={currentPage}
          totalPage={headerData?.totalPages ?? 0}
          setPage={(page) => setCurrentPage(page)}
        />
      }
    />
  )
}
