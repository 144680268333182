import React from 'react'

import { faFileDownload } from '@fortawesome/pro-regular-svg-icons/faFileDownload'
import { View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { jsonToCSV, saveCSVFile } from 'modules/saveFile'

export function CSVDownloadButton({
  data,
  headers,
  fileName,
  children = t('CSVダウンロード'),
}: {
  data?: Array<Record<string, unknown>>
  headers: Array<{ value: string; label: string }>
  fileName: string
  children?: string
}) {
  const saveTo_file = async () => {
    if (data === undefined) return
    const csv = jsonToCSV(data, headers)
    saveCSVFile(csv, fileName + '.csv')
  }
  return (
    <Button
      mode="contained"
      variant="secondary"
      roundness={24}
      height={48}
      disabled={data === undefined}
      textBold
      style={{
        paddingHorizontal: 24,
      }}
      onPress={saveTo_file}
    >
      {({ textStyle }) => {
        return (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <FontAwesomeIcon
              icon={faFileDownload}
              size={24}
              color={Colors.primary}
            />
            <Text style={[textStyle, { marginLeft: 8 }]}>{children}</Text>
          </View>
        )
      }}
    </Button>
  )
}
