import React, { useState } from 'react'

import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown'
import { faClose } from '@fortawesome/pro-solid-svg-icons/faClose'
import { View, StyleProp, ViewStyle } from 'react-native'

import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

function SelectItem({
  title,
  isSelected,
  disabled = false,
  onChange,
}: {
  title: string
  isSelected: boolean
  disabled?: boolean
  onChange: (isSelected: boolean) => void
}) {
  return (
    <View
      style={{
        height: 48,
        paddingHorizontal: 12,
        justifyContent: 'center',
      }}
    >
      <Checkbox
        checked={isSelected}
        disabled={disabled}
        onChange={onChange}
        checkboxLabel={<CheckboxLabel style={{ fontSize: 14 }} value={title} />}
      />
    </View>
  )
}

export function SelectRestaurantsInput({
  style,
  isOpenForm,
  restaurants,
  selectedItems,
  disabled = false,
  onChangeSelectedItems,
  onChangeIsOpenForm,
}: {
  isOpenForm: boolean
  restaurants: { id: number; name: string }[]
  selectedItems: number[]
  disabled?: boolean
  onChangeSelectedItems: (selectedItems: number[]) => void
  onChangeIsOpenForm: (isOpenForm: boolean) => void
  style?: StyleProp<ViewStyle>
}) {
  const [formWidth, setFormWidth] = useState(0)
  const [formHeight, setFormHeight] = useState(0)

  const isAllSelected =
    selectedItems.sort().join(',') ===
    restaurants
      .map((r) => r.id)
      .sort()
      .join(',')

  return (
    <View style={[{ position: 'relative' }, style]}>
      {disabled && (
        <View
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            borderRadius: 8,
            zIndex: 9999,
          }}
        />
      )}
      {isOpenForm && (
        <TouchableOpacity
          style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0 }}
          onPress={() => onChangeIsOpenForm(false)}
          disabled={disabled}
        />
      )}
      <TouchableOpacity
        onLayout={(event) => {
          setFormWidth(event.nativeEvent.layout.width)
          setFormHeight(event.nativeEvent.layout.height)
        }}
        style={{
          position: 'relative',
          height: 48,
          backgroundColor: Colors.white,
          borderRadius: 8,
          flexDirection: 'row',
          alignItems: 'center',
          gap: 12,
          paddingHorizontal: 12,
          paddingVertical: 8,
        }}
        disabled={disabled}
        onPress={() => onChangeIsOpenForm(!isOpenForm)}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'row',
            flexWrap: 'nowrap',
            gap: 8,
            overflow: 'scroll',
          }}
        >
          {selectedItems.map((id) => (
            <View
              key={id}
              style={{
                flexDirection: 'row',
                gap: 8,
                backgroundColor: Colors.bgBlack,
                height: 32,
                borderRadius: 16,
                paddingHorizontal: 12,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={{ fontSize: 12 }} numberOfLines={1}>
                {restaurants.find((restaurant) => restaurant.id === id)?.name}
              </Text>
              <TouchableOpacity
                disabled={disabled}
                onPress={() =>
                  onChangeSelectedItems(selectedItems.filter((e) => e !== id))
                }
              >
                <FontAwesomeIcon
                  icon={faClose}
                  color={Colors.black60}
                  size={12}
                />
              </TouchableOpacity>
            </View>
          ))}
        </View>
        <TouchableOpacity
          disabled={disabled}
          onPress={() => onChangeIsOpenForm(!isOpenForm)}
        >
          <FontAwesomeIcon
            icon={faAngleDown}
            color={Colors.black60}
            size={24}
          />
        </TouchableOpacity>
        {isOpenForm && (
          <View
            style={{
              position: 'absolute',
              top: formHeight + 4,
              left: 0,
              width: formWidth,
              backgroundColor: 'white',
              borderRadius: 8,
              maxHeight: 360,
              overflow: 'scroll',
              shadowColor: '#000',
              shadowOpacity: 0.1,
              shadowOffset: { width: 2, height: 2 },
            }}
          >
            <SelectItem
              title={t('すべて選択')}
              isSelected={isAllSelected}
              disabled={disabled}
              onChange={(isSelected) => {
                onChangeSelectedItems(
                  isSelected
                    ? restaurants.map((restaurant) => restaurant.id)
                    : []
                )
              }}
            />
            <Divider />
            {restaurants.map((restaurant) => (
              <SelectItem
                key={restaurant.id}
                title={restaurant.name}
                isSelected={selectedItems.includes(restaurant.id)}
                disabled={disabled}
                onChange={(isSelected) => {
                  onChangeSelectedItems(
                    isSelected
                      ? selectedItems.includes(restaurant.id)
                        ? [...selectedItems]
                        : [...selectedItems, restaurant.id]
                      : selectedItems.filter((id) => id !== restaurant.id)
                  )
                }}
              />
            ))}
          </View>
        )}
      </TouchableOpacity>
    </View>
  )
}

export function SelectRestaurantsForm({
  restaurants,
  disabled = false,
  onPressApply,
}: {
  restaurants: { id: number; name: string }[]
  disabled?: boolean
  onPressApply: (selectedItems: number[]) => void
}) {
  const [isOpenForm, setIsOpenForm] = useState(false)
  const [selectedItems, setSelectedItems] = useFormState<number[]>(
    restaurants.map((r) => r.id)
  )

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          gap: 24,
          zIndex: 9999,
          alignItems: 'center',
        }}
      >
        <SelectRestaurantsInput
          style={{ flex: 1 }}
          isOpenForm={isOpenForm}
          restaurants={restaurants}
          selectedItems={selectedItems}
          disabled={disabled}
          onChangeSelectedItems={setSelectedItems}
          onChangeIsOpenForm={setIsOpenForm}
        />
        <TouchableOpacity
          style={{
            width: 110,
            height: 48,
            borderRadius: 24,
            backgroundColor: Colors.white,
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onPress={() => {
            setIsOpenForm(false)
            onPressApply(selectedItems)
          }}
        >
          <Text
            style={{ color: Colors.primary, fontWeight: '600', fontSize: 14 }}
          >
            {t('適用する')}
          </Text>
        </TouchableOpacity>
      </View>
    </>
  )
}
