import React from 'react'

import { View, type FlexStyle } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { PADDING_HORIZONTAL, CELL_HEIGHT, BORDER_WIDTH } from './const'

type Props = {
  text: string
  width: number
  isFirst: boolean
  justifyContent?: FlexStyle['justifyContent']
  isSp?: boolean
}
export const HeaderCell = React.memo(
  ({
    text,
    width,
    isFirst,
    justifyContent = 'flex-start',
    isSp = false,
  }: Props) => {
    return (
      <View
        style={[
          {
            width,
            paddingHorizontal: isSp ? 0 : PADDING_HORIZONTAL,
            height: CELL_HEIGHT,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent,
            backgroundColor: isSp ? 'transparent' : '#8f8f8f',
            borderRightWidth: isSp ? 0 : BORDER_WIDTH,
            borderColor: Colors.border,
            borderTopWidth: isSp ? 0 : BORDER_WIDTH,
            borderBottomWidth: isSp ? 0 : BORDER_WIDTH,
            borderLeftWidth: isFirst && !isSp ? BORDER_WIDTH : 0,
            borderRightColor: Colors.borderLight,
          },
        ]}
      >
        <Text
          style={{
            fontSize: 12,
            fontWeight: isSp ? '300' : '600',
            color: isSp ? Colors.black : Colors.white,
          }}
        >
          {text}
        </Text>
      </View>
    )
  }
)
