// @ts-strict-ignore
import { useMemo } from 'react'

import uniq from 'lodash/uniq'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { TableLocalChargePaymentMethod } from '@hello-ai/ar_shared/src/types/ForR/TableLocalChargePaymentMethod'
import {
  TableOrder,
  TableOrderLightweight,
  TableOrderOnetimeToken,
} from '@hello-ai/ar_shared/src/types/ForR/TableOrder'
import { Restaurant } from '@hello-ai/for_r_app/src/models/Restaurant'
import { SmartPayment } from '@hello-ai/for_r_app/src/models/RestaurantReservation'
import { TableSeat } from '@hello-ai/for_r_app/src/models/TableSeat'

import axios, { setHeader, wrapResponse } from '../modules/axios'
import useSWR, {
  fetcher,
  fetcherPost,
  mutate,
  onError,
  SWRConfiguration,
  swrKey,
} from '../modules/swr'

import { useRestaurantTableProducts } from './TableProduct'
import { TableProductOption } from './TableProductOption'

export * from '@hello-ai/ar_shared/src/types/ForR/TableOrder'
export * from '@hello-ai/ar_shared/src/types/ForR/TableLocalChargePaymentMethod'

// orderがなければ作成し、あれば既存のorderを返す

export async function updateTableOrder(
  token: string,
  tableOrderId: TableOrder['id'],
  params: {
    [key: string]: unknown
  } = {}
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<TableOrder>(`/orders/${tableOrderId}`, params)
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/orders/${response.data.id}`), response.data, false)
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableOrder: response?.data,
    error,
  }
}

export function useTableOrderKey(
  token: string | null,
  tableOrderId: string | undefined,
  params: { include_canceled_order_items?: boolean }
) {
  return tableOrderId == null
    ? undefined
    : swrKey(token, `/orders/${tableOrderId}`, params)
}

export function useTableOrder(
  token: string | null,
  tableOrderId: TableOrder['id'] | undefined,
  params: {
    include_canceled_order_items?: boolean
  },
  config?: SWRConfiguration<TableOrder>
) {
  const _params = { ...params, return_timeout_status: true }
  const {
    data: tableOrder,
    error,
    mutate,
  } = useSWR<TableOrder>(
    useTableOrderKey(token, tableOrderId, _params),
    ([token, url]: [token: string | null, url: string]) =>
      fetcher([token, url, _params]),
    config
  )

  return {
    tableOrder,
    isLoading: tableOrder == null && error == null,
    error,
    mutate,
  }
}

export function useTableOrderLightweight(
  token: string | null,
  tableOrderId: TableOrder['id'] | undefined,
  params: { include_canceled_order_items?: boolean; lightweight: true },
  config?: SWRConfiguration<TableOrderLightweight>
) {
  const _params = { ...params, return_timeout_status: true }
  const {
    data: tableOrder,
    error,
    mutate,
  } = useSWR<TableOrderLightweight>(
    useTableOrderKey(token, tableOrderId, params),
    ([token, url]: [token: string | null, url: string]) =>
      fetcher([token, url, _params]),
    config
  )

  return {
    tableOrder,
    isLoading: tableOrder == null && error == null,
    error,
    mutate,
  }
}

export function useTableOrderLightweightWithProducts(
  token: string | null,
  restaurantId: Restaurant['id'],
  tableOrderId: TableOrder['id'] | undefined,
  params: {
    include_canceled_order_items?: boolean
    lightweight: true
    return_timeout_status?: boolean
  },
  config?: SWRConfiguration<TableOrderLightweight>
) {
  const { tableOrder: tableOrderLightweight, mutate } =
    useTableOrderLightweight(token, tableOrderId, params, config)
  const productIds = useMemo(() => {
    if (!tableOrderLightweight) {
      return []
    }
    return uniq(
      tableOrderLightweight.table_order_items.map((t) => t.table_product_id)
    )
  }, [tableOrderLightweight])

  const { tableProducts: products } = useRestaurantTableProducts({
    restaurantId,
    params: {
      type: 'all',
      paging: false,
      table_product_ids: productIds.join(','),
    },
    config: {
      refreshInterval: config?.refreshInterval,
      errorRetryCount: 5,
      onErrorRetry: (_error, _key, config, revalidate, opts) => {
        // 商品情報が取れないとデータが表示できないのでリトライカウントに満たない場合必ずリトライする
        if (
          typeof config.errorRetryCount === 'number' &&
          opts.retryCount > config.errorRetryCount
        ) {
          return
        }

        const count = Math.min(opts.retryCount, 8)
        const timeout =
          ~~((Math.random() + 0.5) * (1 << count)) * config.errorRetryInterval
        setTimeout(() => {
          revalidate(opts)
        }, timeout)
      },
    },
  })

  const tableOrder = useMemo<TableOrder | undefined>(() => {
    if (!tableOrderLightweight) return undefined
    if (productIds.length > 0 && !products) return undefined
    const items = tableOrderLightweight.table_order_items.reduce<
      TableOrder['table_order_items']
    >((prev, current) => {
      const tableProduct = products?.find(
        (tableProduct) => tableProduct.id === current.table_product_id
      )

      // 一致する table_product が存在しない場合は無視
      if (tableProduct == null) {
        return prev
      }

      // table_product_option_ids と options の紐付け
      const options: TableProductOption[] =
        tableProduct.table_product_option_boxes.reduce(
          (p, c) => [
            ...p,
            ...c.table_product_options.map((o) => ({
              ...o,
              table_product_option_box: c,
            })),
          ],
          [] as TableProductOption[]
        )
      const tableProductOptions: TableProductOption[] =
        current.table_product_option_ids?.reduce<TableProductOption[]>(
          (p, c) => {
            const match: TableProductOption | undefined = options.find(
              (tableProductOption) => tableProductOption.id === c
            )
            return match != null ? [...p, match] : p
          },
          []
        )

      return [
        ...prev,
        {
          ...current,
          table_product: tableProduct,
          table_product_options: tableProductOptions,
        },
      ]
    }, [])
    return {
      ...tableOrderLightweight,
      table_order_items: items,
    }
  }, [tableOrderLightweight, products, productIds])

  return {
    mutate,
    tableOrder,
  }
}

interface CreateTableSeatOrderParams {
  party_size?: number
}

export async function createTableSeatOrder(
  token: string,
  seatToken: TableSeat['token'],
  params: CreateTableSeatOrderParams
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableOrder>(`/seats/${seatToken}/orders`, params)
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/seats/${seatToken}/orders`), response.data, false)
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableOrder: response?.data,
    error,
  }
}

export function useTableSeatOrder(
  token: string | null,
  seatToken: TableSeat['token']
) {
  // postしているが、idempotentなのでエラー時のリトライなどあっても問題ない
  const {
    data: tableOrder,
    error,
    mutate: boundMutate,
  } = useSWR<TableOrder, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/seats/${seatToken}/orders`),
    async ([token, url]) => {
      const tableOrder = await fetcherPost<TableOrder>([token, url])
      mutate(swrKey(token, `/orders/${tableOrder.id}`), tableOrder, false)
      return tableOrder
    }
  )

  return {
    tableOrder,
    isLoading: tableOrder == null && error == null,
    error,
    mutate: boundMutate,
  }
}

export async function joinTableOrder(
  token: string,
  tableOrderId: TableOrder['id'],
  params: Array<TableOrder['id']>
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableOrder>(`/orders/${tableOrderId}/join`, params)
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/orders/${response.data.id}`))
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableOrder: response?.data,
    error,
  }
}

export async function leaveTableOrder(
  token: string,
  tableOrderId: TableOrder['id'],
  params: Array<TableOrder['id']>
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableOrder>(`/orders/${tableOrderId}/leave`, params)
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/orders/${response.data.id}`))
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableOrder: response?.data,
    error,
  }
}

export function useCompleteLatestTableOrders(
  token: string | null,
  restaurantId: Restaurant['id'],
  params: {},
  config?: SWRConfiguration<TableOrder[]>
) {
  const _params = { ...params, return_timeout_status: true }
  const {
    data: completeLatestTableOrders,
    error,
    mutate,
  } = useSWR(
    swrKey(
      token,
      `/restaurants/${restaurantId}/orders/complete_latest`,
      _params
    ),
    ([token, url]: [token: string | null, url: string]) =>
      fetcher([token, url, _params]),
    config
  )

  return {
    completeLatestTableOrders,
    error,
    mutate,
  }
}

export async function createTableOrderOrderOnetimeToken(
  token: string,
  tableOrderId: TableOrder['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableOrderOnetimeToken>(
      `/orders/${tableOrderId}/order_onetime_tokens`
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}

export async function createPrepaymentTableOrderReady(
  token: string,
  tableOrderId: TableOrder['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableOrder>(`/prepayment//orders/${tableOrderId}/ready`)
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/orders/${response.data.id}`))
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableOrder: response?.data,
    error,
  }
}

export async function destroyPrepaymentTableOrderReady(
  token: string,
  tableOrderId: TableOrder['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.delete<TableOrder>(`/prepayment//orders/${tableOrderId}/ready`)
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/orders/${response.data.id}`))
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableOrder: response?.data,
    error,
  }
}

// 全て対応済みにするAPI
export async function createTableOrderProcessCompletion(
  token: string,
  tableOrderId: TableOrder['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<{}>(`/orders/${tableOrderId}/process_completion`)
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/orders/${tableOrderId}}`))
  }

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}

// 対応済みから戻すAPI
export async function destroyTableOrderProcessCompletion(
  token: string,
  tableOrderId: TableOrder['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.delete<{}>(`/orders/${tableOrderId}/process_completion`)
  )

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}

// 手動で入店前ステータスにするAPI
export async function createTableOrderManualReset(
  token: string,
  tableOrderId: TableOrder['id']
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<{}>(`/orders/${tableOrderId}/manual_reset`)
  )

  if (error != null) {
    onError(error)
  }

  return {
    data: response?.data,
    error,
  }
}

export interface AmountCalculationByChangeParams {
  table_order_amount_change: {
    amount: number
    tax_type: string
    tax_included: boolean
    change_reason: string | null
    restaurant_crew_member_id: string | null
  }
}

export function useTableOrderAmountCalculationByChange(
  token: string | null,
  tableOrderId: TableOrder['id'],
  amount: number,
  tax_type: string,
  tax_included: boolean,
  change_reason: string | null,
  restaurant_crew_member_id: string | null
) {
  const params: AmountCalculationByChangeParams = {
    table_order_amount_change: {
      amount,
      tax_type,
      tax_included,
      change_reason,
      restaurant_crew_member_id,
    },
  }

  const { data, error } = useSWR<
    Pick<TableOrder, 'net_amount_with_change'>,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/orders/${tableOrderId}/amount_calculation_by_change`,
      params
    ),
    () =>
      fetcher([
        token,
        `/orders/${tableOrderId}/amount_calculation_by_change`,
        params,
      ])
  )

  return {
    netAmountWithChange: data?.net_amount_with_change,
    error,
  }
}
export interface SummedLocalChargeByPaymentMethod {
  id: TableLocalChargePaymentMethod['id']
  name: TableLocalChargePaymentMethod['name']
  payment_method_type: TableLocalChargePaymentMethod['payment_method_type']
  amount: number
  payment_amount: number
}

function groupPaymentMethodById(
  paymentMethods: TableLocalChargePaymentMethod[]
): { [paymentMethodId: string]: TableLocalChargePaymentMethod } {
  return paymentMethods.reduce(
    (accumulator, paymentMethod) => ({
      ...accumulator,
      [paymentMethod.id]: paymentMethod,
    }),
    {}
  )
}

export function sumLocalChargeByPaymentMethod(
  tableOrder: TableOrder,
  allPaymentMethods: TableLocalChargePaymentMethod[]
): SummedLocalChargeByPaymentMethod[] {
  if (allPaymentMethods.length === 0) return []

  const paymentMethodsById = groupPaymentMethodById(allPaymentMethods)

  const reducablePaymentMethods = allPaymentMethods.map((paymentMethod) => ({
    table_local_charge_payment_method_id: paymentMethod.id,
    amount: 0,
    payment_amount: 0,
  }))
  const reducableLocalCharges = tableOrder.table_local_charges
  const reducableAmountChnageDetails =
    tableOrder.table_order_amount_changes.flatMap(
      (c) => c.table_order_amount_change_details
    )

  return Object.values(
    [
      ...reducablePaymentMethods,
      ...reducableLocalCharges,
      ...reducableAmountChnageDetails,
    ].reduce<{ [paymentId: string]: SummedLocalChargeByPaymentMethod }>(
      (accumulator, reducable) => {
        const paymentId = reducable.table_local_charge_payment_method_id
        const name =
          paymentMethodsById[paymentId]?.name || t('無効化された支払い種別')
        const payment_method_type =
          paymentMethodsById[paymentId]?.payment_method_type || 'custom'

        return {
          ...accumulator,
          ...{
            [paymentId]: {
              id: paymentId,
              name,
              payment_method_type,
              amount: (accumulator[paymentId]?.amount || 0) + reducable.amount,
              payment_amount:
                (accumulator[paymentId]?.payment_amount || 0) +
                reducable.payment_amount,
            },
          },
        }
      },
      {}
    )
  )
}

export function findPaymentMethodByType(
  paymentMethods: TableLocalChargePaymentMethod[],
  type: 'custom' | 'cash' | 'card' | 'transportation_card' | 'square'
) {
  return paymentMethods.find((p) => p.payment_method_type === type)
}

export async function captureSmartPayment(
  token: string,
  restaurantId: number,
  reservationId: string,
  params: {
    amount: number
    restaurant_crew_member_id: string
    reason?: string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse<SmartPayment>(
    axios.post(
      `/reservation_book/restaurants/${restaurantId}/reservations/${reservationId}/smart_payment/capture`,
      params
    )
  )

  return {
    data: response?.data,
    error,
  }
}

export async function receiptSmartPayment(
  token: string,
  restaurantId: number,
  reservationId: string,
  params: {
    name: string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse<SmartPayment>(
    axios.post(
      `/reservation_book/restaurants/${restaurantId}/reservations/${reservationId}/smart_payment/receipt`,
      params
    )
  )

  return {
    data: response?.data,
    error,
  }
}

export async function adjustmentsSmartPayment(
  token: string,
  restaurantId: number,
  reservationId: string,
  params: {
    amount: number
    restaurant_crew_member_id: string
    reason: string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse<SmartPayment>(
    axios.post(
      `/reservation_book/restaurants/${restaurantId}/reservations/${reservationId}/smart_payment/adjustments`,
      params
    )
  )

  return {
    data: response?.data,
    error,
  }
}

export async function cancelSmartPayment(
  token: string,
  restaurantId: number,
  reservationId: string
) {
  setHeader({ token })
  const { response, error } = await wrapResponse<SmartPayment>(
    axios.post(
      `/reservation_book/restaurants/${restaurantId}/reservations/${reservationId}/smart_payment/cancel`
    )
  )

  return {
    data: response?.data,
    error,
  }
}

/**
 * 注文が完了済みかどうかを判定する
 * 事前決済の場合は注文の全てのアイテムが提供済みかどうか
 * 事後決済の場合は会計完了かどうか
 */
export const isTableOrderCompleted = (
  tableOrder: TableOrder | TableOrderLightweight,
  systemType: 'prepayment' | 'postpayment'
): boolean => {
  if (systemType === 'prepayment') {
    return tableOrder.all_order_items_processed_at != null
  }
  return tableOrder.status === 'complete'
}
