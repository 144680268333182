import React, { useState } from 'react'

import { Helmet } from 'react-helmet-async'
import { View } from 'react-native'
import { useNavigate, useParams } from 'react-router'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import LinkText from 'components/Shared/LinkText'
import SignInHeader from 'components/Shared/SignInHeader'
import { authService, useToken } from 'models/Auth'
import { redirectApp } from 'modules/redirectApp'
import { onError } from 'modules/swr'

const formLabelWidth = 162
const submitButtonWidth = 278
const formLabelMarginRight = 24

function validatePassword({
  newPassword,
  confirmNewPassword,
}: {
  newPassword: string
  confirmNewPassword: string
}) {
  // 新しいパスワードの確認が一致しない場合はボタンを押したときにアラートを出すので、ここではチェックしない
  return newPassword.length >= 6 && confirmNewPassword.length >= 6
}
export default function BusinessUsersResetPasswordRequestsConfirm() {
  const { width, sm } = useResponsive()
  const token = useToken()
  const navigate = useNavigate()
  const { id: businessUserId, reset_password_token: resetPasswordToken } =
    useParams<{
      id: string
      reset_password_token: string
    }>()

  const [newPassword, setNewPassword] = useState('')
  const [confirmNewPassword, setConfirmNewPassword] = useState('')

  const onSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      Alert.alert(t('確認のパスワードが一致しません'))
      setNewPassword('')
      setConfirmNewPassword('')
      return
    }

    const { error } = await authService.confirmResetPasswordRequest(token, {
      token: resetPasswordToken!,
      newPassword,
    })
    if (error != null) {
      onError(error)
      return
    }
    navigate('/sign_in', { replace: true })
  }
  return (
    <View
      style={{
        flexGrow: 1,
      }}
    >
      <Helmet>
        <meta
          name="apple-itunes-app"
          content={`app-id=1521972781, app-argument=/business_users/${businessUserId}/reset_password_requests/${token}/confirm`}
        />
      </Helmet>
      <SignInHeader />
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            marginTop: 80,
            width: '100%',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontWeight: '600',
              fontSize: 22,
            }}
          >
            {t('新しいパスワードを入力してください')}
          </Text>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            style={{ marginTop: 40 }}
            formLabel={<FormLabel value={t('新しいパスワード')} />}
            formLabelContainerStyle={{
              width: formLabelWidth,
              marginRight: formLabelMarginRight,
            }}
            containerStyle={{
              width: width < sm ? '100%' : 420,
            }}
          >
            <TextInput
              value={newPassword}
              onChangeText={setNewPassword}
              onSubmitEditing={onSubmit}
              clearButtonMode="always"
              placeholder={t('6文字以上')}
              secureTextEntry
              style={{
                width: '100%',
              }}
            />
          </FormGroup>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            style={{
              marginTop: 24,
            }}
            formLabel={<FormLabel value={t('新しいパスワード（確認）')} />}
            formLabelContainerStyle={{
              width: formLabelWidth,
              marginRight: formLabelMarginRight,
            }}
            containerStyle={{
              width: width < sm ? '100%' : 420,
            }}
          >
            <TextInput
              value={confirmNewPassword}
              onChangeText={setConfirmNewPassword}
              onSubmitEditing={onSubmit}
              clearButtonMode="always"
              placeholder={t('6文字以上')}
              secureTextEntry
              style={{
                width: '100%',
              }}
            />
          </FormGroup>
          <Button
            disabled={!validatePassword({ newPassword, confirmNewPassword })}
            onPress={onSubmit}
            style={{
              marginTop: 48,
              width: width < sm ? '100%' : submitButtonWidth,
            }}
            height={48}
          >
            {t('送信する')}
          </Button>

          <TouchableOpacity
            onPress={() => {
              redirectApp(
                `business_users/${businessUserId}/reset_password_requests/${token}/confirm`
              )
            }}
          >
            <Text
              style={{ marginTop: 24, color: Colors.primary, fontSize: 16 }}
            >
              {t('アプリでパスワードリセットする')}
            </Text>
          </TouchableOpacity>
          <LinkText
            to="/sign_in"
            style={{
              marginTop: 24,
              color: Colors.primary,
            }}
          >
            {t('ログイン画面に戻る')}
          </LinkText>
        </View>
      </View>
    </View>
  )
}
