import React from 'react'

import {
  ActivityIndicator,
  StyleProp,
  TextStyle,
  TouchableOpacityProps,
  ViewStyle,
} from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

interface ButtonOwnProps {
  text: string
  textStyle?: StyleProp<TextStyle>
  buttonStyle?: StyleProp<ViewStyle>
  icon?: React.ReactNode
  onPress?: () => void
  disabled?: boolean
  loading?: boolean
}

type ButtonProps = ButtonOwnProps &
  Omit<TouchableOpacityProps, 'onPress' | 'disabled' | 'style'>

/**
 * @deprecated Use import { Button } from '@hello-ai/ar_shared/src/components/Button'
 */
export default function Button({
  text,
  textStyle,
  buttonStyle,
  icon,
  onPress,
  disabled = false,
  loading = false,
  ...props
}: ButtonProps) {
  const { buttonLoading, startButtonLoading, stopButtonLoading } =
    useButtonLoading({ initialLoading: loading })
  const _onPress = React.useCallback(async () => {
    startButtonLoading()
    try {
      await onPress?.()
    } finally {
      stopButtonLoading()
    }
  }, [startButtonLoading, onPress, stopButtonLoading])

  return (
    <TouchableOpacity
      onPress={_onPress}
      disabled={disabled || buttonLoading}
      style={[
        {
          borderWidth: 1,
          borderColor: Colors.primary,
          borderRadius: 4,
          height: 40,
          backgroundColor: Colors.primary,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          opacity: disabled || buttonLoading ? 0.4 : 1,
        },
        buttonStyle,
      ]}
      {...props}
    >
      {icon}
      <Text
        style={[
          {
            color: 'white',
            fontWeight: '600',
            fontSize: 16,
            marginLeft: icon !== undefined ? 8 : 0,
          },
          textStyle,
        ]}
      >
        {text}
      </Text>
      {buttonLoading && <ActivityIndicator size="small" color={'white'} />}
    </TouchableOpacity>
  )
}

export const useButtonLoading = ({
  initialLoading = false,
}: {
  initialLoading?: boolean
}) => {
  const [buttonLoading, setButtonLoading] = React.useState(initialLoading)
  const startButtonLoading = React.useCallback(
    () => setButtonLoading(true),
    [setButtonLoading]
  )
  const stopButtonLoading = React.useCallback(
    () => setButtonLoading(false),
    [setButtonLoading]
  )

  return { buttonLoading, startButtonLoading, stopButtonLoading }
}
