import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  createContext,
  useContext,
  useCallback,
} from 'react'
import { View, StyleProp, ViewStyle } from 'react-native'

function useRefChange<T>(): [(node: T | null) => void, T | null] {
  const ref = useRef<T | null>(null)
  const [node, setNode] = useState<T | null>(null)

  const setRef = useCallback((node: T | null) => {
    if (ref.current !== node) {
      ref.current = node
      setNode(node)
    }
  }, [])

  return [setRef, node]
}

export interface PopoverContextValue {
  topBias: number
  leftBias: number
}

const PopoverContext = createContext<PopoverContextValue>({
  topBias: 0,
  leftBias: 0,
})
export const PopoverProvider = PopoverContext.Provider

type Args = {
  placement?: 'bottomStart' | 'bottomEnd' | 'left'
  isOpen: boolean
  offsetX?: number
  offsetY?: number
}

export const usePopover = ({
  placement = 'bottomStart',
  isOpen,
  offsetX = 0,
  offsetY = 0,
}: Args) => {
  const [anchorMeasure, setAnchorMeasure] = useState<{
    x: number
    y: number
    width: number
    height: number
  }>()
  const [contentMeasure, setContentMeasure] = useState<{
    width: number
    height: number
  }>()
  const anchorRef = useRef<View | null>(null)
  const [setContentRef, contentNode] = useRefChange<View>()
  const { topBias, leftBias } = useContext(PopoverContext)

  useEffect(() => {
    if (anchorRef.current && isOpen) {
      anchorRef.current.measureInWindow((x, y, width, height) => {
        setAnchorMeasure({ x, y, width, height })
      })
    }
  }, [isOpen])

  useEffect(() => {
    let canceled = false

    // そのまま contentNode.measureInWindow を呼ぶと width, height が 0 になるため setTimeout で遅延させる
    setTimeout(() => {
      if (contentNode != null) {
        contentNode.measureInWindow((_, __, width, height) => {
          if (canceled) {
            return
          }
          setContentMeasure({ width, height })
        })
      }
    }, 1)

    return () => {
      canceled = true
    }
  }, [contentNode])

  const popoverStyle = useMemo(():
    | { left: number; top: number }
    | undefined => {
    if (anchorMeasure && contentMeasure) {
      const baseTop =
        anchorMeasure.y + anchorMeasure.height + 30 + topBias + offsetY
      let baseLeft: number
      switch (placement) {
        case 'bottomStart':
          baseLeft = anchorMeasure.x + leftBias + offsetX
          return {
            left: baseLeft,
            top: baseTop,
          }
        case 'bottomEnd':
          baseLeft =
            anchorMeasure.x -
            contentMeasure.width +
            anchorMeasure.width +
            leftBias +
            offsetX
          return {
            left: baseLeft,
            top: baseTop,
          }
        case 'left': {
          const left =
            anchorMeasure.x - contentMeasure.width - 10 - leftBias + offsetX
          const top =
            anchorMeasure.y +
            anchorMeasure.height / 2 -
            contentMeasure.height / 2
          return {
            left,
            top,
          }
        }
        default:
          return undefined
      }
    }
  }, [
    anchorMeasure,
    contentMeasure,
    placement,
    topBias,
    leftBias,
    offsetX,
    offsetY,
  ])

  // ▲のコンポーネント
  const arrowElement = useMemo((): JSX.Element => {
    const baseChildStyles: StyleProp<ViewStyle> = {
      position: 'absolute',
      width: 20,
      height: 20,
      bottom: -10,
      transform: [{ rotate: '45deg' }],
      backgroundColor: 'white',
      shadowColor: '#000',
      shadowOpacity: 0.2,
      shadowOffset: { width: 0, height: 2 },
      elevation: 4,
      right:
        placement === 'bottomEnd'
          ? anchorMeasure
            ? Math.max(anchorMeasure.width / 2 - 10 + offsetX, 10)
            : 10
          : placement === 'left'
            ? -10
            : undefined,
      left:
        placement === 'bottomStart'
          ? anchorMeasure
            ? Math.max(anchorMeasure.width / 2 - 10, 10)
            : 10
          : undefined,
      top:
        placement === 'left'
          ? contentMeasure?.height != null
            ? contentMeasure.height / 2 - 10
            : 0
          : -10,
    }

    const baseParentStyles: StyleProp<ViewStyle> = {
      width: 21,
      height: 20,
      bottom: -10,
      position: 'absolute',
      backgroundColor: 'white',
      transform: [{ rotate: '45deg' }],
      zIndex: 10,
      right:
        placement === 'bottomEnd'
          ? anchorMeasure
            ? Math.max(anchorMeasure.width / 2 - 11 + offsetX, 11)
            : 11
          : placement === 'left'
            ? -10
            : undefined,
      left:
        placement === 'bottomStart'
          ? anchorMeasure
            ? Math.max(anchorMeasure.width / 2 - 9, 9)
            : 9
          : undefined,
      top:
        placement === 'left'
          ? contentMeasure?.height != null
            ? contentMeasure.height / 2 - 10
            : 0
          : -6,
    }

    return (
      <>
        <View style={baseChildStyles} />
        <View style={baseParentStyles} />
      </>
    )
  }, [placement, anchorMeasure, offsetX, contentMeasure])

  return {
    anchorRef,
    setContentRef,
    popoverStyle,
    arrowElement,
  }
}
