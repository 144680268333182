import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useParams } from 'react-router'

import { ReservationForm } from '@hello-ai/for_r_app/src/components/Reservation/Form'
import { useRestaurant } from '@hello-ai/for_r_app/src/models/Restaurant'

const useTime = (value: string | null) => {
  return useMemo(() => {
    if (value == null) return undefined
    const parsed = parseInt(value)
    if (isNaN(parsed)) return undefined
    if (parsed < 0) return undefined
    if (parsed > 24 * 3600) return undefined
    return parsed
  }, [value])
}

function ReservationFormPage() {
  const { id: restaurantId, reservationId } = useParams<{
    id: string
    reservationId: string
  }>()
  const [params] = useSearchParams()
  const { data: restaurant } = useRestaurant(parseInt(restaurantId!))
  const startTime = useTime(params.get('startTime'))
  const endTime = useTime(params.get('endTime'))
  const selectedSeatIds = params.get('selectedSeatIds')?.split(',')
  const initialStep = Number(params.get('initialStep') ?? 1)
  const adultPartySize = Number(params.get('adultPartySize') ?? 0)
  const childPartySize = Number(params.get('childPartySize') ?? 0)

  let from: 'map' | 'list' | 'chart' | undefined
  if (params.has('from')) {
    const fromValue = params.get('from')!
    if (fromValue === 'map' || fromValue === 'list' || fromValue === 'chart') {
      from = fromValue
    }
  }
  let partySize: number | undefined
  if (params.has('partySize')) {
    partySize = parseInt(params.get('partySize')!)
  }

  return (
    <ReservationForm
      restaurantId={parseInt(restaurantId!)}
      restaurantReservationId={reservationId}
      tableRestaurantSetting={restaurant?.table_restaurant_setting!}
      dateString={params.get('dateString') ?? undefined}
      startTime={startTime}
      // startTime が null でない場合は endTime も渡す
      from={from}
      endTime={startTime != null ? endTime : undefined}
      selectedSeatIds={selectedSeatIds}
      adultPartySize={adultPartySize}
      childPartySize={childPartySize}
      initialStep={initialStep}
      partySize={partySize}
    />
  )
}

export default ReservationFormPage
