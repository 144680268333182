import React, { useRef, useState } from 'react'

import { faCalendarAlt } from '@fortawesome/pro-regular-svg-icons/faCalendarAlt'
import { faClock } from '@fortawesome/pro-regular-svg-icons/faClock'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { Alert, Dimensions, Platform, ScrollView, View } from 'react-native'

import {
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Radio,
  RadioGroup,
  RadioLabel,
} from '@hello-ai/ar_shared/src/components/Radio'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { getFormatTime } from '@hello-ai/ar_shared/src/modules/time'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantReservationBlockResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_resource'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'

import { useRestaurant } from '../../../models/Restaurant'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { onError } from '../../../modules/swr'
import {
  ReservationRepContainer,
  ReservationRepWithDate,
} from '../../Reservation/RepView'
import Row from '../../Reservation/Row'
import Loading from '../../Shared/Loading'
import ModalCenter from '../../Shared/ModalCenter'
import { displayToastSuccess } from '../../Shared/Toast'

type DestroyBlockResult = 'specific' | 'all'

const buttonHeight = 48
const lgButtonWidth = 278

function getTextMarginTop({
  fontSize,
  lineHeight,
}: {
  fontSize: number
  lineHeight: number
}) {
  return -(lineHeight - fontSize) / 2
}

function getMode(
  restaurantReservationBlock: RestaurantReservationBlockResource
) {
  const startDate = dayjs(
    Timestamp.toDate(restaurantReservationBlock.startDate!)
  )
  const endDate = dayjs(Timestamp.toDate(restaurantReservationBlock.endDate!))

  // ブロックが複数日にまたがっていない場合、通常ブロック
  return startDate.isSame(endDate, 'day') ? 'default' : 'repeat'
}

const destroyBlockItems: Array<{
  label: string
  value: DestroyBlockResult
}> = [
  {
    label: t('このブロック'),
    value: 'specific',
  },
  {
    label: t('すべてのブロック'),
    value: 'all',
  },
]

function DestroyBlockSelectModal({
  isModalVisible,
  onClose,
  onSubmit,
}: {
  isModalVisible: boolean
  onClose: () => void
  onSubmit: (result: DestroyBlockResult) => void
}) {
  const { width, sm } = useResponsive()
  const [result, setResult] = useState<DestroyBlockResult | undefined>(
    undefined
  )

  const onPress = () => {
    // result === undefined の場合はボタンが押せないので、ここでは result の null チェック不要
    onSubmit(result!)
  }

  const getRadioPadding = (shouldDouble: boolean) => {
    if (width < sm) {
      return 8 * (shouldDouble ? 2 : 0)
    }
    return 12 * (shouldDouble ? 2 : 1)
  }

  return (
    <ModalCenter
      title={t('繰り返しブロックの削除')}
      smWidth="90%"
      smHeight="auto"
      isModalVisible={isModalVisible}
      onClose={onClose}
    >
      <View>
        <RadioGroup
          onChange={(value) => setResult(value as DestroyBlockResult)}
          value={result}
          mode="vertical"
        >
          {destroyBlockItems.map(({ label, value }, index) => {
            return (
              <Radio
                key={value}
                value={value}
                radioLabel={
                  <RadioLabel
                    style={[
                      value === result && {
                        fontWeight: '600',
                      },
                    ]}
                    value={label}
                  />
                }
                style={{
                  paddingTop: getRadioPadding(index === 0),
                  paddingBottom: getRadioPadding(
                    index === destroyBlockItems.length - 1
                  ),
                  paddingHorizontal: width < sm ? 16 : 24,
                }}
                radioLabelContainerStyle={[{ paddingHorizontal: 6 }]}
              />
            )
          })}
        </RadioGroup>

        <View
          style={{
            borderTopWidth: 0.5,
            borderTopColor: Colors.border,
            paddingVertical: 24,
            alignItems: 'center',
          }}
        >
          <Button
            disabled={result === undefined}
            onPress={onPress}
            mode="outline"
            variant="danger-secondary"
            style={{ width: width < sm ? 130 : 260 }}
          >
            {t('削除する')}
          </Button>
        </View>
      </View>
    </ModalCenter>
  )
}

function getWdaysDescription(wdays: number[]) {
  return dayjs
    .weekdaysShort()
    .concat(t('祝'))
    .filter((_wday, i) => wdays.includes(i))
    .join('、')
}

function getTimeDescription({
  startTime,
  endTime,
}: {
  startTime: number
  endTime: number
}) {
  return `${getFormatTime(startTime)}～${getFormatTime(endTime)}`
}

export function ReservationBlocksShow({
  restaurantId,
  restaurantReservationBlockId,
  startAt,
  endAt,
}: {
  restaurantId: number
  restaurantReservationBlockId: RestaurantReservationBlockResource['id']
  startAt: Timestamp
  endAt: Timestamp
}) {
  const navigation = useNavigation()
  const token = useToken()
  const navigate = useNavigate()

  const { width, sm } = useResponsive()
  const { width: dimensionWidth } = Dimensions.get('window')
  const smButtonWidth = (dimensionWidth - 60) / 2
  const alertRef = useRef<AlertMethods>(null)

  const { data: restaurant } = useRestaurant(restaurantId)

  const { data: restaurantReservationBlock } =
    restaurantReservationBlockService.useGet({
      id: restaurantReservationBlockId,
    })

  const [isDestroyBlockSelectModalOpen, setIsDestroyBlockSelectModalOpen] =
    useState(false)

  if (restaurantReservationBlock === undefined) {
    return <Loading />
  }

  const startDate = dayjs(
    Timestamp.toDate(restaurantReservationBlock.startDate!)
  )
  const endDate = dayjs(Timestamp.toDate(restaurantReservationBlock.endDate!))

  const mode = getMode(restaurantReservationBlock)

  const destroyBlock = async () => {
    const { error } = await restaurantReservationBlockService.destroy(token, {
      id: restaurantReservationBlockId,
    })
    if (error != null) {
      onError(error)
      return
    }
    displayToastSuccess(
      t('ブロックを削除しました'),
      undefined,
      width < sm ? { marginBottom: 117 } : undefined
    )
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/reservations?m=chart`)
    } else {
      navigation.goBack()
    }
  }

  const onPressDestroy = () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    if (mode === 'default') {
      alert(t('本当に削除しますか？'), '', [
        {
          text: t('いいえ'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            await destroyBlock()
          },
        },
      ])
    } else {
      setIsDestroyBlockSelectModalOpen(true)
    }
  }

  const createException = async () => {
    const { error } = await restaurantReservationBlockService.createException(
      token,
      {
        id: restaurantReservationBlockId,
        startDate: dayjs(Timestamp.toDate(startAt)).toISOString(),
        endDate: dayjs(Timestamp.toDate(endAt)).toISOString(),
      }
    )
    if (error != null) {
      onError(error)
      return
    }
    displayToastSuccess(
      t('このブロックを削除しました'),
      undefined,
      width < sm ? { marginBottom: 117 } : undefined
    )
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/reservations?m=chart`)
    } else {
      navigation.goBack()
    }
  }

  const onPressEdit = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/blocks/${restaurantReservationBlockId}/edit`
      )
    } else {
      navigation.navigate('ReservationBlocksForm', {
        restaurantReservationBlockId,
      })
    }
  }

  const onSubmitDestroyBlockSubmitModal = (result: DestroyBlockResult) => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    if (result === 'all') {
      alert(t('本当に削除しますか？'), '', [
        {
          text: t('いいえ'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            await destroyBlock()
          },
        },
      ])
    } else {
      alert(t('本当に削除しますか？'), '', [
        {
          text: t('いいえ'),
          onPress: () => console.log('Cancel Pressed'),
          style: 'cancel',
        },
        {
          text: t('はい'),
          onPress: async () => {
            await createException()
          },
        },
      ])
    }
  }

  return (
    <>
      <ScrollView
        style={{
          flex: 1,
          backgroundColor: Colors.bgBlack,
        }}
        contentContainerStyle={{
          paddingHorizontal: width < sm ? 16 : 40,
          paddingVertical: width < sm ? 24 : 48,
        }}
      >
        <ReservationRepContainer
          style={{
            marginBottom: 8,
          }}
        >
          <ReservationRepWithDate
            label={t('作成日')}
            date={restaurantReservationBlock.createdAt!.seconds * 1000}
          />
          {restaurantReservationBlock.updatedAt &&
            // updateAtは新規登録でも作成されるため、作成日よりも後であることを確認する
            restaurantReservationBlock.updatedAt.seconds >
              restaurantReservationBlock.createdAt!.seconds && (
              <ReservationRepWithDate
                label={t('変更日')}
                date={restaurantReservationBlock.updatedAt.seconds * 1000}
              />
            )}
        </ReservationRepContainer>
        <ShadowBox>
          <Text
            style={{
              fontWeight: '600',
            }}
          >
            {mode === 'default' ? t('通常ブロック') : t('繰り返しブロック')}
          </Text>
          <Row
            mode="inline-expanded"
            icon={faCalendarAlt}
            style={{
              marginTop: 24,
            }}
          >
            <View>
              <Text
                style={{
                  fontSize: 18,
                  lineHeight: 18 * 1.5,
                  marginTop: getTextMarginTop({
                    fontSize: 18,
                    lineHeight: 18 * 1.5,
                  }),
                }}
              >
                {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
                {startDate.format('YYYY年M月DD日(ddd)')}
                {mode === 'repeat' && (
                  // eslint-disable-next-line ar-i18n/require-translation-ja
                  <>〜{endDate.format('YYYY年M月DD日(ddd)')}</>
                )}
              </Text>
              {restaurant?.reservation_book_plan_type !== 'entry' &&
                // TODO: new_free への移行が完了したら削除
                restaurant?.reservation_book_plan_type !== 'special' &&
                restaurant?.reservation_book_plan_type !== 'new_free' && (
                  <Text
                    style={{
                      marginTop: 16,
                    }}
                  >
                    {getWdaysDescription(restaurantReservationBlock.wdays)}
                  </Text>
                )}
            </View>
          </Row>
          <Row
            icon={faClock}
            style={{
              marginTop: 24,
            }}
          >
            <Text>
              {getTimeDescription({
                startTime: restaurantReservationBlock.startTime,
                endTime: restaurantReservationBlock.endTime,
              })}
            </Text>
          </Row>
          <Row
            icon={faChair}
            style={{
              marginTop: 24,
            }}
          >
            <Text>
              {restaurantReservationBlock.tableSeats
                .map((tableSeat) => tableSeat.name)
                .join(', ')}
            </Text>
          </Row>
        </ShadowBox>
        <View
          style={{
            marginTop: 32,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onPress={onPressDestroy}
            mode="outline"
            variant="danger-secondary"
            height={buttonHeight}
            width={width < sm ? smButtonWidth : lgButtonWidth}
          >
            {t('削除する')}
          </Button>
          {restaurant?.reservation_book_plan_type !== 'entry' &&
            // TODO: new_free への移行が完了したら削除
            restaurant?.reservation_book_plan_type !== 'special' &&
            restaurant?.reservation_book_plan_type !== 'new_free' && (
              <Button
                onPress={onPressEdit}
                style={{
                  marginLeft: 20,
                }}
                variant="primary"
                height={buttonHeight}
                width={width < sm ? smButtonWidth : lgButtonWidth}
              >
                {t('編集する')}
              </Button>
            )}
        </View>
        <DestroyBlockSelectModal
          isModalVisible={isDestroyBlockSelectModalOpen}
          onClose={() => {
            setIsDestroyBlockSelectModalOpen(false)
          }}
          onSubmit={onSubmitDestroyBlockSubmitModal}
        />
      </ScrollView>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </>
  )
}
