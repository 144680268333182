import React from 'react'
import { useNavigate } from 'react-router-dom'

import TableProductListView from '@hello-ai/for_r_app/src/components/Restaurant/TableProduct/TableProductListView'
import { useRestaurantCurrency } from '@hello-ai/for_r_app/src/modules/useRestaurantCurrency'

import { useRestaurant } from 'models/Restaurant'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableProductsScreen() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  const { restaurant } = useRestaurant(restaurantId)
  const restaurantCurrency = useRestaurantCurrency(restaurant)

  return (
    <TableProductListView
      restaurantId={restaurantId}
      restaurantCurrency={restaurantCurrency}
      onPressListItem={(tableProduct) => {
        navigate(
          `/restaurants/${restaurantId}/products/${tableProduct.id}/edit`
        )
      }}
      onPressAddTableProduct={() => {
        navigate(`/restaurants/${restaurantId}/products/new`)
      }}
    />
  )
}
