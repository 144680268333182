import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { TableProductOption } from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'
import { TableProductOptionBox } from '@hello-ai/ar_shared/src/types/ForR/TableProductOptionBox'

import axios, { setHeader, wrapResponse } from '../modules/axios'
import useSWR, { onError, swrKey, fetcher } from '../modules/swr'

export * from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'

export const updateTableProductOptionStock = async ({
  token,
  restaurantId,
  productOptionId,
  inStock,
}: {
  restaurantId: number
  productOptionId: string
  token: string
  inStock: boolean
}) => {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<Pick<TableProductOption, 'id' | 'in_stock'>>(
      `/restaurants/${restaurantId}/product_options/${productOptionId}/product_option_stocks`,
      {
        product_option_stock: {
          in_stock: inStock,
        },
      }
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    tableProductOption: response?.data,
  }
}

export const useTableProductOptionBoxes = (
  restaurantId: number,
  params: {
    keyword?: string
    status?: string
  }
) => {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    TableProductOptionBox[],
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/restaurants/${restaurantId}/product_option_boxes`, params),
    ([token, url]) => fetcher([token, url, params])
  )
  return {
    data,
    error,
    mutate,
  }
}

export async function bulkUpdateTableProductOptionBoxStatus(
  token: string,
  restaurantId: number,
  params: {
    ids: string[]
    status: 'published' | 'archived' | 'deleted'
  }
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.patch(
      `/restaurants/${restaurantId}/product_option_boxes/status_bulk_update`,
      params
    )
  )
  return { error }
}
