import React from 'react'

import { View } from 'react-native'
import {
  generatePath,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { getLocale } from '@hello-ai/ar_shared/src/modules/locale'

import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import { Tabs, TabProps, TabContent } from 'components/Shared/Navigation/Tab'

import { HeaderBack } from '../../components/Shared/Navigation/Header'

import SalesByPeriod from './SalesByPeriod'
import SalesDetail from './SalesDetail'

const SalesTabs: TabProps[] = [
  {
    title: t('期間別売上'),
    fullPath: '/sales',
    relativePath: '/',
    element: <SalesByPeriod />,
  },
]

const routes: StackRoutes = {
  Index: {
    title: t('売上管理'),
    fullPath: `/sales/details`,
    relativePath: `/details`,
    exact: true,
    element: <SalesDetail />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function RestaurantSales() {
  const location = useLocation()
  const params = useParams<{ id: string }>()
  const locale = getLocale()
  const isTabRoute = SalesTabs.some((tab) => {
    const to = `/${locale}${generatePath(tab.fullPath, params)}`
    return to === location.pathname
  })

  if (!isTabRoute) {
    return <Stack routes={routes} />
  }

  return (
    <View style={{ flex: 1 }}>
      <Tabs tabs={SalesTabs} />
      <TabContent>
        <Routes>
          {SalesTabs.map((tab) => {
            return (
              <Route
                key={tab.fullPath}
                path={tab.relativePath}
                element={tab.element}
              />
            )
          })}
        </Routes>
      </TabContent>
    </View>
  )
}
