import React, { useMemo } from 'react'

import { faAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'
import { faAddressBook } from '@fortawesome/pro-solid-svg-icons/faAddressBook'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { RefreshControlProps, ScrollView, View, Platform } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { numberWithDelimiter } from '@hello-ai/ar_shared/src/modules/numberWithDelimiter'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Customer } from '@hello-ai/ar_shared/src/types/ForR/Customer'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GOURMET_SITE_PROVIDER_TEXT_STYLE,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'
import {
  RestaurantReservation as RestaurantReservationModel,
  isSiteControllerReservation,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'
import { RestaurantReservationCourse } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservationCourse'
import { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'

import { useNavigate } from '../../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../../modules/navigation/useNavigation'
import { getCustomerDisplayName } from '../../../Customers/Customer'
import {
  ReservationRepContainer,
  ReservationRepWithDate,
} from '../../../Reservation/RepView'
import SmartPaymentStatusButton, {
  RevisionSmartPaymentButton,
} from '../../../Reservations/SmartPayment/SmartPaymentStatusButton'

import { VisitStatusTag } from './VisitStatusTag'

type Mode = 'default' | 'print'

export const ContentTypes = ['reserved', 'future_only', 'canceled'] as const

export type ContentType = (typeof ContentTypes)[number]

const defaultCustomer = {
  id: '',
  last_name: t('不明'),
  phone_number_format: '-',
} as Customer

export function getTitle(type: ContentType) {
  switch (type) {
    case 'reserved':
      return t('成立した予約')
    case 'future_only':
      return t('来店前の予約')
    case 'canceled':
      return t('キャンセル済みの予約')
  }
}

export function Empty({ title }: { title: string }) {
  return (
    <View
      style={{
        paddingVertical: 32,
        alignItems: 'center',
        backgroundColor: Colors.white,
        borderColor: Colors.border,
        borderBottomWidth: 0.5,
        borderRadius: 4,
      }}
    >
      <Text
        style={{
          color: Colors.black60,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
    </View>
  )
}

interface EllipsisCourse extends Omit<RestaurantReservationCourse, 'id'> {
  id: React.Key
}
function EllipsisCourseTextView({
  index,
  reservationCourse,
}: {
  index: number
  reservationCourse: EllipsisCourse
}) {
  const priceStr = useMemo(() => {
    const price = reservationCourse.restaurant_course?.price ?? 0
    if (price > 0) return `¥${numberWithDelimiter(price)}`
    return ''
  }, [reservationCourse.restaurant_course?.price])

  return (
    <View style={{ marginTop: index === 0 ? 0 : 8 }}>
      <Text numberOfLines={2} style={{ fontWeight: '600', fontSize: 14 }}>
        {reservationCourse.restaurant_course?.title ||
          t('コースが見つかりません')}
      </Text>
      <Text
        style={{
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {t('{{text}}（{{count}}名）', {
          text: priceStr,
          count: reservationCourse.party_size,
        })}
      </Text>
    </View>
  )
}

export interface ReservationData
  extends Omit<
    RestaurantReservationModel,
    | 'kind'
    | 'table_seats'
    | 'cancel_fee'
    | 'reservation_courses'
    | 'restaurant_crew_member'
  > {
  table_seats?: TableSeat[]
  reservation_courses: EllipsisCourse[]
}

function RestaurantReservation({
  mode = 'default',
  restaurant,
  restaurantReseravtion,
  onPress,
  onPressCustomer,
  onUpdateVisitStatus,
  onIssueSmartPaymentReceipt,
}: {
  mode?: Mode
  restaurant: Restaurant
  restaurantReseravtion: ReservationData
  onPress?: () => void
  onPressCustomer?: (customerId: Customer['id']) => void
  onUpdateVisitStatus?: (
    newVisitStatus: ReservationData['visit_status']
  ) => Promise<void>
  onIssueSmartPaymentReceipt: () => void
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const customers = useMemo(() => {
    if (restaurantReseravtion.customers.length === 0) {
      return [defaultCustomer]
    }

    if (mode === 'print') {
      return restaurantReseravtion.customers
    }

    return restaurantReseravtion.customers.slice(0, 1)
  }, [mode, restaurantReseravtion.customers])

  const onPressSmartPayment = () => {
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurant.id}/reservations/${restaurantReseravtion.id}/smart_payment`
      )
    } else {
      navigation.navigate('SmartPayment', {
        restaurantReservationId: restaurantReseravtion.id,
      })
    }
  }

  if (width < sm) {
    return (
      <TouchableOpacity
        style={{
          flexDirection: 'row',
        }}
        onPress={onPress}
        activeOpacity={onPress == null ? 1 : undefined}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
            paddingTop: 24,
            paddingLeft: 24,
            paddingRight: 24,
            paddingBottom: 24,
          }}
        >
          <Text style={{ fontSize: 14 }}>
            {dayjs(restaurantReseravtion.start_at).format('HH:mm')}~
            {dayjs(restaurantReseravtion.end_at).format('HH:mm')}
          </Text>
          <View
            style={{
              flex: 2,
              paddingVertical: 8,
            }}
          >
            {customers.map((customer, index) => (
              <View
                key={customer.id}
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  marginTop: index === 0 ? 0 : 16,
                }}
              >
                <Text
                  style={{
                    fontWeight: '600',
                  }}
                  numberOfLines={mode === 'print' ? 2 : undefined}
                >
                  {getCustomerDisplayName(customer)}
                  {customer.last_name !== t('不明') && t('様')}
                </Text>
                {mode === 'default' && restaurantReseravtion.party_size > 1 && (
                  <Text
                    style={{
                      fontSize: 14,
                      marginLeft: 16,
                      color: Colors.black60,
                    }}
                  >
                    {t('他{{partySize}}名', {
                      partySize: restaurantReseravtion.party_size - 1,
                    })}
                  </Text>
                )}
              </View>
            ))}
          </View>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '300',
              color: Colors.black,
            }}
          >
            {(restaurantReseravtion.reservation_child_details?.length ?? 0) >
            0 ? (
              <>
                {t('{{count}}名', {
                  count: restaurantReseravtion.party_size,
                })}
                {`(${t('子{{count}}名', {
                  count:
                    restaurantReseravtion.reservation_child_details?.length,
                })}：${restaurantReseravtion.reservation_child_details
                  ?.map((child) => t('{{count}}歳', { count: child.age }))
                  .join('/')})`}
              </>
            ) : (
              <>
                {t('{{count}}名', {
                  count:
                    restaurantReseravtion.adult_party_size +
                    restaurantReseravtion.child_party_size,
                })}
                {restaurantReseravtion.child_party_size > 0 &&
                  t('(子{{count}}名)', {
                    count: restaurantReseravtion.child_party_size,
                  })}
              </>
            )}
            {' | '}
            {(restaurantReseravtion.table_seats
              ?.map((tableSeat) => tableSeat.name)
              .join(' ').length ?? 0) > 0
              ? restaurantReseravtion.table_seats
                  ?.map((tableSeat) => tableSeat.name)
                  .join(' ')
              : t('なし')}
          </Text>
          <ReservationRepContainer
            style={{
              marginVertical: 8,
              marginLeft: 0,
            }}
          >
            <ReservationRepWithDate
              label={t('受付日')}
              date={restaurantReseravtion.created_at}
            />
            {restaurantReseravtion.status !== 'canceled' &&
              restaurantReseravtion.changed_at !== null &&
              restaurantReseravtion.changed_at !== undefined && (
                <ReservationRepWithDate
                  label={t('変更日')}
                  date={restaurantReseravtion.changed_at}
                />
              )}
            {restaurantReseravtion.status === 'canceled' &&
              restaurantReseravtion.canceled_at !== null &&
              restaurantReseravtion.canceled_at !== undefined && (
                <ReservationRepWithDate
                  label={t('キャンセル日')}
                  date={restaurantReseravtion.canceled_at}
                />
              )}
          </ReservationRepContainer>
          <View style={{ flexDirection: 'row', marginTop: 16, columnGap: 8 }}>
            <VisitStatusTag
              style={{
                height: 36,
              }}
              visitStatus={restaurantReseravtion.visit_status ?? 'all_visited'}
              reservationStatus={restaurantReseravtion.status}
              onUpdateVisitStatus={onUpdateVisitStatus}
            />
            {restaurant.table_restaurant_setting?.smart_payment_available && (
              <SmartPaymentStatusButton
                restaurantReservation={restaurantReseravtion}
                restaurantId={restaurant.id}
                onPressSmartPayment={onPressSmartPayment}
                onIssueReceipt={onIssueSmartPaymentReceipt}
              />
            )}
          </View>
        </View>
        {onPress !== undefined && (
          <View
            style={{
              justifyContent: 'center',
              paddingVertical: 24,
            }}
          >
            <View
              style={{
                paddingRight: 24,
              }}
            >
              <FontAwesomeIcon
                size={20}
                color={Colors.black}
                icon={faAngleRight}
              />
            </View>
          </View>
        )}
      </TouchableOpacity>
    )
  }

  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={onPress == null ? 1 : undefined}
      style={{
        padding: 24,
        rowGap: 24,
      }}
    >
      {isSiteControllerReservation(restaurantReseravtion) && (
        <Text
          style={[
            GOURMET_SITE_PROVIDER_TEXT_STYLE[
              restaurantReseravtion.reservation_site_controller_parsed_course
                .site_controller_parsed_course.site
            ],
            {
              width: 112,
              paddingVertical: 4,
              fontSize: 10,
              fontWeight: '600',
              lineHeight: 10,
              textAlign: 'center',
              borderRadius: 2,
            },
          ]}
        >
          {t('{{provider}}からの予約', {
            provider:
              GOURMET_SITE_PROVIDER_SOURCE[
                restaurantReseravtion.reservation_site_controller_parsed_course
                  .site_controller_parsed_course.site
              ].label,
          })}
        </Text>
      )}
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Text
            style={{
              marginTop: 2, // 予約者情報と上が揃って見えるように視覚的な補正を入れる
              fontSize: 14,
            }}
          >
            {dayjs(restaurantReseravtion.start_at).format('HH:mm')}~
            {dayjs(restaurantReseravtion.end_at).format('HH:mm')}
          </Text>
        </View>
        <View
          style={{
            flex: 2,
          }}
        >
          {customers.map((customer, index) => (
            <View
              key={customer.id}
              style={{
                marginTop: index === 0 ? 0 : 16,
              }}
            >
              <Text
                style={{
                  fontWeight: '600',
                }}
                numberOfLines={mode === 'print' ? 2 : undefined}
              >
                {getCustomerDisplayName(customer)}
                {customer.last_name !== t('不明') && t('様')}
              </Text>
              <View
                style={[
                  {
                    marginTop: 8,
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 16,
                    height: 16,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPhone}
                    size={13.3}
                    color={Colors.black50}
                  />
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                  }}
                >
                  {customer.phone_number_format ?? t('未登録')}
                </Text>
              </View>
              <View
                style={[
                  {
                    marginTop: 4,
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 16,
                    height: 16,
                  }}
                >
                  <FontAwesomeIcon
                    icon={faFrown}
                    size={16}
                    color={Colors.black50}
                  />
                </View>
                <Text
                  style={{
                    marginLeft: 8,
                    fontSize: 14,
                  }}
                >
                  {(customer.allergy ?? '') || t('未記入')}
                </Text>
              </View>
              {onPressCustomer != null && (
                <TouchableOpacity
                  disabled={customer.id === ''}
                  onPress={() => onPressCustomer(customer.id)}
                  style={[
                    {
                      marginTop: 16,
                      flexDirection: 'row',
                      alignItems: 'center',
                    },
                    customer.id === '' && {
                      opacity: 0.6,
                    },
                  ]}
                >
                  <FontAwesomeIcon
                    icon={faAddressBook}
                    size={16}
                    color={Colors.primary}
                  />
                  <Text
                    style={{
                      marginLeft: 8,
                      fontSize: 16,
                      color: Colors.primary,
                    }}
                  >
                    {t('顧客情報を見る')}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          ))}
          {mode === 'default' && restaurantReseravtion.party_size > 1 && (
            <Text
              style={{
                marginTop: 8,
                fontSize: 14,
                color: Colors.black60,
              }}
            >
              {t('他{{partySize}}名', {
                partySize: restaurantReseravtion.party_size - 1,
              })}
            </Text>
          )}
          {!!restaurantReseravtion.memo && (
            <View
              style={[
                {
                  marginTop: 8,
                  flexDirection: 'row',
                  alignItems: 'center',
                },
              ]}
            >
              <View
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 16,
                  height: 16,
                }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  size={16}
                  color={Colors.black50}
                />
              </View>
            </View>
          )}
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <View
            style={{
              paddingRight: 24,
            }}
          >
            <Text
              style={{
                fontSize: 14,
                fontWeight: '600',
              }}
            >
              {restaurantReseravtion.party_size}
              {t('人')}
            </Text>
            {(restaurantReseravtion.reservation_child_details?.length ?? 0) >
            0 ? (
              <Text style={{ fontSize: 12, color: Colors.black }}>
                {'('}
                {t('子{{count}}名', {
                  count:
                    restaurantReseravtion.reservation_child_details?.length,
                })}
                ：
                {restaurantReseravtion.reservation_child_details
                  ?.map((child) => t('{{count}}歳', { count: child.age }))
                  .join('/')}
                {')'}
              </Text>
            ) : (
              <Text style={{ fontSize: 12, color: Colors.black }}>
                {restaurantReseravtion.child_party_size > 0 &&
                  t('(子{{count}}名)', {
                    count: restaurantReseravtion.child_party_size,
                  })}
              </Text>
            )}
            <Text
              style={{
                marginTop: 8,
                fontSize: 14,
                fontWeight: '600',
              }}
            >
              {restaurantReseravtion.table_seats
                ?.map((tableSeat) => tableSeat.name)
                .join('\n') ?? t('なし')}
            </Text>
          </View>
        </View>
        <View
          style={{
            flex: 2,
          }}
        >
          <View style={{ paddingRight: 24 }}>
            {restaurantReseravtion.reservation_courses.length > 0 &&
              restaurantReseravtion.reservation_courses.map(
                (reservationCourse, index) => {
                  return (
                    <EllipsisCourseTextView
                      key={reservationCourse.id}
                      reservationCourse={reservationCourse}
                      index={index}
                    />
                  )
                }
              )}
            <Text
              style={{
                marginTop:
                  restaurantReseravtion.reservation_courses.length > 0 ? 16 : 0,
                fontSize: 14,
              }}
              numberOfLines={mode === 'default' ? 3 : undefined}
            >
              {restaurantReseravtion.memo}
            </Text>
          </View>
        </View>
        {onPress !== undefined && (
          <View
            style={{
              justifyContent: 'center',
              paddingVertical: 24,
            }}
          >
            <View
              style={{
                paddingRight: 24,
              }}
            >
              <FontAwesomeIcon
                size={20}
                color={Colors.black}
                icon={faAngleRight}
              />
            </View>
          </View>
        )}
      </View>
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            columnGap: 8,
            flex: 1 / 4,
            alignItems: 'flex-start',
          }}
        >
          <VisitStatusTag
            style={{
              height: 36,
            }}
            visitStatus={restaurantReseravtion.visit_status ?? 'all_visited'}
            reservationStatus={restaurantReseravtion.status}
            onUpdateVisitStatus={onUpdateVisitStatus}
          />
          {restaurant.table_restaurant_setting?.smart_payment_available && (
            <SmartPaymentStatusButton
              restaurantReservation={restaurantReseravtion}
              restaurantId={restaurant.id}
              onPressSmartPayment={onPressSmartPayment}
              onIssueReceipt={onIssueSmartPaymentReceipt}
            />
          )}
          {restaurant?.table_restaurant_setting?.smart_payment_available &&
            restaurantReseravtion.smart_payment?.status === 'paid' && (
              <RevisionSmartPaymentButton
                restaurantId={restaurant.id}
                restaurantReservation={restaurantReseravtion}
              />
            )}
        </View>
        <ReservationRepContainer>
          <ReservationRepWithDate
            label={t('受付日')}
            date={restaurantReseravtion.created_at}
          />
          {restaurantReseravtion.status !== 'canceled' &&
            restaurantReseravtion.changed_at !== null &&
            restaurantReseravtion.changed_at !== undefined && (
              <ReservationRepWithDate
                label={t('変更日')}
                date={restaurantReseravtion.changed_at}
              />
            )}
          {restaurantReseravtion.status === 'canceled' &&
            restaurantReseravtion.canceled_at !== null &&
            restaurantReseravtion.canceled_at !== undefined && (
              <ReservationRepWithDate
                label={t('キャンセル日')}
                date={restaurantReseravtion.canceled_at}
              />
            )}
        </ReservationRepContainer>
      </View>
    </TouchableOpacity>
  )
}

export function Header({ children }: { children: React.ReactNode }) {
  return (
    <View
      style={{
        flexDirection: 'row',
        borderColor: Colors.border,
        borderBottomWidth: 0.5,
      }}
    >
      {children}
    </View>
  )
}

export function HeaderCell({
  flex,
  children,
  isFirst = false,
}: {
  flex: number
  children: string
  isFirst?: boolean
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        paddingVertical: 16,
        alignItems: 'center',
        flex,
        paddingLeft: isFirst ? 20 : 0,
      }}
    >
      <Text
        style={{
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {children}
      </Text>
    </View>
  )
}

export function Table({
  mode = 'default',
  restaurant,
  restaurantReservations,
  onPressRestaurantReservation,
  onPressCustomer,
  onUpdateVisitStatus,
  onIssueSmartPaymentReceipt,
}: {
  mode?: Mode
  restaurant: Restaurant
  restaurantReservations: ReservationData[] | undefined
  onPressRestaurantReservation?: (
    restaurantReservationId: ReservationData['id']
  ) => void
  onPressCustomer?: (customerId: Customer['id']) => void
  onUpdateVisitStatus?: (
    reservationId: ReservationData['id'],
    newVisitStatus: ReservationData['visit_status']
  ) => Promise<void>
  onIssueSmartPaymentReceipt: () => void
}) {
  const { width, sm } = useResponsive()
  return (
    <View>
      {width >= sm && (
        <Header>
          <HeaderCell isFirst flex={1}>
            {t('予約時間')}
          </HeaderCell>
          <HeaderCell flex={2}>{t('予約者情報')}</HeaderCell>
          <HeaderCell flex={1}>{t('人数・席')}</HeaderCell>
          <HeaderCell flex={2}>{t('備考')}</HeaderCell>
          {onPressRestaurantReservation != null && (
            <View style={{ marginLeft: 24, width: 22, paddingRight: 24 }} />
          )}
        </Header>
      )}
      {restaurantReservations?.map((restaurantReservation) => (
        <View
          key={restaurantReservation.id}
          style={[
            {
              backgroundColor: Colors.white,
              borderColor: Colors.border,
              borderBottomWidth: 0.5,
              borderRadius: 4,
            },
          ]}
        >
          <RestaurantReservation
            mode={mode}
            restaurant={restaurant}
            restaurantReseravtion={restaurantReservation}
            onPress={
              onPressRestaurantReservation === undefined
                ? undefined
                : () => onPressRestaurantReservation(restaurantReservation.id)
            }
            onPressCustomer={onPressCustomer}
            onUpdateVisitStatus={
              onUpdateVisitStatus !== undefined
                ? async (newVisitStatus) =>
                    await onUpdateVisitStatus(
                      restaurantReservation.id,
                      newVisitStatus
                    )
                : undefined
            }
            onIssueSmartPaymentReceipt={onIssueSmartPaymentReceipt}
          />
        </View>
      ))}
    </View>
  )
}

export function ContentScrollView<ReservationItem extends ReservationData>({
  title,
  titleRight,
  headerContent,
  headerContentSm,
  restaurant,
  restaurantReservations,
  onPressRestaurantReservation,
  onPressCustomer,
  onUpdateVisitStatus,
  onIssueSmartPaymentReceipt,
  refreshControl,
  pagination,
}: {
  title?: string
  titleRight?: React.ReactNode
  headerContent?: React.ReactNode
  headerContentSm?: React.ReactNode
  restaurant: Restaurant
  restaurantReservations: ReservationItem[] | undefined
  onPressRestaurantReservation?: (
    restaurantReservationId: ReservationItem['id']
  ) => void
  onPressCustomer?: (customerId: Customer['id']) => void
  // Nativeの場合のみ実行される。Webの場合は呼ばれない
  onUpdateVisitStatus?: (
    reservationId: ReservationData['id'],
    newVisitStatus: ReservationData['visit_status']
  ) => Promise<void>
  onIssueSmartPaymentReceipt: () => void
  pagination: React.ReactNode
  refreshControl?: React.ReactElement<RefreshControlProps>
}) {
  const { width, sm } = useResponsive()
  return (
    <ScrollView
      style={{ flex: 1 }}
      contentContainerStyle={[
        width >= sm && {
          paddingTop: 40,
        },
        {
          paddingHorizontal: width < sm ? 16 : 24,
          paddingBottom: 20,
        },
        width < sm && {
          paddingBottom: 80,
        },
      ]}
      refreshControl={refreshControl}
    >
      {width >= sm ? (
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {headerContent ?? (
            <>
              <Text
                style={{
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                {title}
              </Text>
              {titleRight}
            </>
          )}
        </View>
      ) : (
        headerContentSm
      )}
      <View
        style={[
          {
            marginTop: 24,
            backgroundColor: Colors.white,
            borderRadius: 4,
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 1,
            },
            shadowOpacity: 0.1,
            shadowRadius: 4,
            elevation: 2,
          },
        ]}
      >
        <Table
          restaurant={restaurant}
          restaurantReservations={restaurantReservations}
          onPressRestaurantReservation={onPressRestaurantReservation}
          onPressCustomer={onPressCustomer}
          onUpdateVisitStatus={onUpdateVisitStatus}
          onIssueSmartPaymentReceipt={onIssueSmartPaymentReceipt}
        />
        {restaurantReservations !== undefined &&
          restaurantReservations.length === 0 && (
            <Empty title={t('予約がありません')} />
          )}
        {restaurantReservations === undefined && (
          <View
            style={{
              paddingVertical: 24,
            }}
          >
            <Loading />
          </View>
        )}

        <View
          style={[
            {
              paddingVertical: 24,
              marginBottom: 16,
            },
            width >= sm
              ? {
                  borderTopWidth: 0.5,
                  borderColor: Colors.border,
                }
              : {
                  backgroundColor: Colors.white,
                },
          ]}
        >
          {pagination}
        </View>
      </View>
    </ScrollView>
  )
}
