import React, { useState } from 'react'

import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View } from 'react-native'

import { Checkbox } from '@hello-ai/ar_shared/src/components/Checkbox'
import { GourmetSiteProviderLabel } from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/GourmetSiteProvider'
import SectionTitle from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/SectionTitle'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { RadioButton } from '@hello-ai/ar_shared/src/components/Radio'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GourmetSiteProvider,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { ParsedSeat } from '@hello-ai/ar_shared/src/types/ForR/SiteControllerSeat'

import { useActiveOutboundSettings } from '../../models/SiteControllerOutboundSettings'
import { useParsedSeats } from '../../models/SiteControllerSeatSetting'

interface ParsedSeatItemProps {
  site: GourmetSiteProvider
  data: Partial<
    Pick<
      ParsedSeat,
      'name' | 'max_party_size' | 'min_party_size' | 'start_at' | 'end_at'
    >
  >
  selected: boolean
  onPressParsedSeat: () => void
  multiSelect?: boolean
}

function ParsedSeatItem({
  site,
  data,
  selected,
  onPressParsedSeat,
  multiSelect = false,
}: ParsedSeatItemProps) {
  return (
    <TouchableOpacity
      style={{
        paddingVertical: 16,
        flexDirection: 'row',
      }}
      onPress={onPressParsedSeat}
    >
      {multiSelect ? (
        <Checkbox
          checked={selected}
          checkboxLabel=""
          onChange={onPressParsedSeat}
          style={{ marginRight: 16 }}
        />
      ) : (
        <RadioButton
          style={{ marginRight: 16 }}
          selected={selected}
          onPress={onPressParsedSeat}
        />
      )}
      <View style={{ flex: 1 }}>
        <View
          style={{
            flexDirection: 'row',
            columnGap: 8,
            alignItems: 'center',
          }}
        >
          <GourmetSiteProviderLabel provider={site} />
        </View>
        <Text style={{ fontSize: 16, fontWeight: '300' }}>
          {data.name ?? '-'}
        </Text>
      </View>
      <Text style={{ fontSize: 16, fontWeight: '300', width: 100 }}>
        {data.min_party_size == null && data.max_party_size == null
          ? '-'
          : // eslint-disable-next-line ar-i18n/require-translation-ja
            `${data.min_party_size} 〜 ${data.max_party_size}`}
      </Text>
      <Text style={{ fontSize: 16, fontWeight: '300', width: 140 }}>
        {data.start_at == null && data.end_at == null
          ? '-'
          : t('{{start_at}} - {{end_at}}', {
              start_at: data.start_at,
              end_at: data.end_at,
            })}
      </Text>
      <View style={{ width: 24, height: 24 }} />
    </TouchableOpacity>
  )
}

/**
 * 連携したい他社グルメサイトの席を選択する
 * サイトごとに１席のみ選択可能
 */
export type SelectedParsedSeatIdBySite = Partial<
  Record<GourmetSiteProvider, Set<ParsedSeat['id']> | null>
>

interface SelectParsedSeatViewProps {
  restaurantId: number
  selectedParsedSeatIdBySite: SelectedParsedSeatIdBySite
  /**
   *
   * @param value SelectedParsedSeatIdBySite
   * @returns
   */
  onChangeSelectedParsedSeat: (value: SelectedParsedSeatIdBySite) => void
}

const MULTI_SELECT_SITES: GourmetSiteProvider[] = ['tabelog', 'ikkyu']

export default function SelectParsedSeatView({
  restaurantId,
  selectedParsedSeatIdBySite,
  onChangeSelectedParsedSeat,
}: SelectParsedSeatViewProps) {
  const { activeOutboundSettings } = useActiveOutboundSettings(restaurantId)
  const [selectedSite, setSelectedSite] = useFormState(
    activeOutboundSettings?.[0]?.site ?? 'tabelog'
  )
  const [searchText, setSearchText] = useState('')

  const { data, pagination } = useParsedSeats(restaurantId, selectedSite, {
    name: searchText,
  })

  const multiSelect = MULTI_SELECT_SITES.includes(selectedSite)

  return (
    <View>
      <SectionTitle
        title={t('2.連携したい他社グルメサイトの席を選択')}
        helperText={t(
          '必ず同じ席の情報を選択する必要があります。また、連携できるのは１サイトごとに１席のみ可能です'
        )}
      />
      <Text
        style={{
          marginBottom: 16,
        }}
      >
        {t('連携したい他社グルメサイトの席を選択してください。')}
      </Text>

      <View
        style={{
          backgroundColor: Colors.white,
          paddingHorizontal: 16,
          paddingBottom: 16,
          borderRadius: 8,
        }}
      >
        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          {activeOutboundSettings?.map((setting) => (
            <TouchableOpacity
              key={setting.site}
              style={[
                {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 210,
                  height: 60,
                  borderBottomColor:
                    selectedSite === setting.site ? Colors.primary : 'none',
                  borderBottomWidth: selectedSite === setting.site ? 2 : 0,
                },
              ]}
              onPress={() => {
                setSelectedSite(setting.site)
              }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 27,
                  color: Colors.black60,
                }}
              >
                {GOURMET_SITE_PROVIDER_SOURCE[setting.site].label}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={{ flexGrow: 5, position: 'relative', marginVertical: 20 }}>
          <TextInput
            style={{
              backgroundColor: Colors.white,
              borderWidth: 1,
              borderColor: Colors.border,
              borderRadius: 8,
              width: '100%',
              height: 48,
              paddingLeft: 48,
            }}
            value={searchText}
            onChangeText={setSearchText}
          />
          <FontAwesomeIcon
            size={24}
            icon={faSearch}
            color={Colors.black60}
            style={{ position: 'absolute', left: 16, top: 12 }}
          />
        </View>
        <View
          style={{
            flexDirection: 'row',
            columnGap: 16,
            borderBottomWidth: 0.5,
            borderColor: Colors.border,
            paddingVertical: 16,
          }}
        >
          <View style={{ width: 24, height: 24 }} />
          <Text
            style={{
              flex: 1,
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              width: 90,
            }}
          >
            {t('席名')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              width: 90,
            }}
          >
            {t('人数')}
          </Text>
          <Text
            style={{
              fontSize: 14,
              fontWeight: '600',
              lineHeight: 22,
              width: 120,
            }}
          >
            {t('時間')}
          </Text>
          <View style={{ width: 24, height: 24 }} />
        </View>
        <View
          style={{
            borderBottomWidth: 0.5,
            borderColor: Colors.border,
            marginBottom: 16,
          }}
        >
          <ParsedSeatItem
            key="not-connected"
            site={selectedSite}
            data={{
              name: t('連携しない'),
            }}
            selected={selectedParsedSeatIdBySite[selectedSite] == null}
            onPressParsedSeat={() => {
              const newValue = {
                ...selectedParsedSeatIdBySite,
                [selectedSite]: null,
              }
              onChangeSelectedParsedSeat(newValue)
            }}
          />
          {data?.map((item) => (
            <ParsedSeatItem
              key={item.id}
              site={item.site}
              data={item}
              selected={
                selectedParsedSeatIdBySite[selectedSite]?.has(item.id) ?? false
              }
              onPressParsedSeat={() => {
                const newValue = { ...selectedParsedSeatIdBySite }
                if (newValue[selectedSite] == null) {
                  newValue[selectedSite] = new Set()
                }

                if (multiSelect) {
                  if (newValue[selectedSite]?.has(item.id)) {
                    newValue[selectedSite]?.delete(item.id)
                  } else {
                    newValue[selectedSite]?.add(item.id)
                  }
                } else {
                  newValue[selectedSite] = new Set([item.id])
                }
                onChangeSelectedParsedSeat(newValue)
              }}
              multiSelect={MULTI_SELECT_SITES.includes(selectedSite)}
            />
          ))}
        </View>
        <Pagination
          currentPage={pagination.currentPage ?? 1}
          totalPage={pagination.totalPages ?? 1}
          setPage={pagination.setPage}
        />
      </View>
    </View>
  )
}
