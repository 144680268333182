import React from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { View, Image, Linking, TouchableOpacity } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

export function PlanNotice({ restaurant }: { restaurant?: Restaurant }) {
  const { width, sm } = useResponsive()
  const isEntryPlan = restaurant?.reservation_book_plan_type === 'entry'
  const planMessage = isEntryPlan
    ? t(
        'リクエスト予約機能のみをご利用中の場合、お客様属性、予約回数の表示はご利用できません。ご利用希望の方はお問い合わせください'
      )
    : t(
        'お客様属性、予約回数の表示はスタンダードプラン以上でご利用いただけます。ご利用希望の方はお問い合わせください'
      )

  const contactUrl = 'https://autoreserve.com/for_restaurants/contact'

  return (
    <>
      {width < sm ? (
        <View
          style={{
            padding: 16,
            backgroundColor: Colors.white,
            borderRadius: 8,
            borderColor: Colors.primary,
            borderWidth: 1,
            marginBottom: 40,
            gap: 12,
          }}
        >
          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
            <Image
              style={{ height: 16, width: 16, marginRight: 4 }}
              source={require('../../assets/images/plan-icon.png')}
            />
            <Text
              style={{
                flex: 1,
                color: Colors.black,
                fontSize: 14,
                flexWrap: 'wrap',
                marginRight: 12,
              }}
            >
              {planMessage}
            </Text>
          </View>
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              gap: 4,
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
            onPress={() => Linking.openURL(contactUrl)}
          >
            <Text style={{ color: Colors.primary, fontSize: 14 }}>
              {t('お問い合わせへ')}
            </Text>
            <FontAwesomeIcon icon={faChevronRight} color={Colors.primary} />
          </TouchableOpacity>
        </View>
      ) : (
        <View
          style={{
            padding: 16,
            backgroundColor: Colors.white,
            borderRadius: 8,
            borderColor: Colors.primary,
            borderWidth: 1,
            marginBottom: 40,
            gap: 12,
          }}
        >
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: width < sm ? 'flex-start' : 'space-between',
            }}
          >
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                flexShrink: 1,
              }}
            >
              <Image
                style={{
                  height: 16,
                  width: 16,
                  marginRight: width < sm ? 4 : 8,
                }}
                source={require('../../assets/images/plan-icon.png')}
              />
              <Text
                style={{
                  color: Colors.black,
                  fontSize: 14,
                  flexWrap: 'wrap',
                  flexShrink: 1,
                  marginRight: 12,
                }}
              >
                {planMessage}
              </Text>
            </View>
            <TouchableOpacity
              style={{
                flexDirection: 'row',
                gap: 4,
                alignItems: 'center',
                marginTop: width < sm ? 12 : 0,
              }}
              onPress={() => Linking.openURL(contactUrl)}
            >
              <Text style={{ color: Colors.primary, fontSize: 14 }}>
                {t('お問い合わせへ')}
              </Text>
              <FontAwesomeIcon icon={faChevronRight} color={Colors.primary} />
            </TouchableOpacity>
          </View>
        </View>
      )}
    </>
  )
}
