import React from 'react'

import { StyleProp, Text, View, ViewStyle } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

export function ReservationRep({
  label,
  name,
}: {
  label: string
  name: string
}) {
  // nameが空の場合、labelだけ表示という欠けた状態になるため、空の場合は表示しない
  if (name === null || name === undefined || name === '') {
    return <></>
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 4,
      }}
    >
      <Text
        style={{
          fontSize: 14,
          fontWeight: '300',
          color: Colors.black60,
          minWidth: 114,
        }}
      >
        {label.padEnd(6, '　')}
      </Text>
      <Text
        style={{
          fontSize: 14,
          fontWeight: '300',
          color: Colors.black60,
        }}
      >
        : {name}
      </Text>
    </View>
  )
}

export function ReservationRepContainer({
  children,
  style,
}: {
  children: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: 4,
          marginLeft: 'auto',
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

export function ReservationRepWithDate({
  label,
  date,
}: {
  label: string
  // undefinedをdayjsに渡すと現在時刻になり意図する挙動ではないため、undefinedを除外する
  date: Exclude<dayjs.ConfigType, undefined>
}) {
  return (
    <ReservationRep
      label={label}
      // eslint-disable-next-line ar-i18n/require-translation-ja
      name={dayjs(date).format('YYYY年MM月DD日（dd）HH:mm')}
    />
  )
}
