import { TableProductOption } from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'

import axios, { setHeader, wrapResponse } from '../modules/axios'
import { onError } from '../modules/swr'

export * from '@hello-ai/ar_shared/src/types/ForR/TableProductOption'

export const updateTableProductOptionStock = async ({
  token,
  restaurantId,
  productOptionId,
  inStock,
}: {
  restaurantId: number
  productOptionId: string
  token: string
  inStock: boolean
}) => {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableProductOption>(
      `/restaurants/${restaurantId}/product_options/${productOptionId}/product_option_stocks`,
      {
        product_option_stock: {
          in_stock: inStock,
        },
      }
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    tableProductOption: response?.data,
  }
}
