import React from 'react'
import { useParams } from 'react-router-dom'

import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons'
import { generatePath } from 'react-router'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  HeaderBack,
  HeaderRightButton,
} from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import { history } from 'modules/history'
import TableMenuPages from 'pages/Menus/TableMenuPages'
import TableMenuPageArchived from 'pages/Menus/TableMenuPages/Archived'
import TableMenuPagesForm from 'pages/Menus/TableMenuPages/Form'

function TableMenuPagesRightHeaderButton() {
  const { width, sm } = useResponsive()
  const params = useParams<{ id: string }>()
  return (
    <HeaderRightButton
      icon={
        <FontAwesomeIcon icon={faEyeSlash} size={18} color={Colors.primary} />
      }
      onPress={() => {
        history.push(
          generatePath(`/restaurants/:id/menu_pages/archived`, {
            id: params.id!,
          })
        )
      }}
      label={width < sm ? null : t('非表示にしたカテゴリ')}
    />
  )
}

const routes: StackRoutes = {
  Index: {
    title: t('カテゴリ'),
    fullPath: `/restaurants/:id/menu_pages`,
    relativePath: `/`,
    exact: true,
    element: <TableMenuPages />,
    screenOptions: {
      headerRight: <TableMenuPagesRightHeaderButton />,
    },
  },
  Show: {
    title: t('カテゴリを追加'),
    fullPath: `/restaurants/:id/menu_pages/new`,
    relativePath: `/new`,
    element: <TableMenuPagesForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('カテゴリを編集'),
    fullPath: `/restaurants/:id/menu_pages/:table_menu_page_id/edit`,
    relativePath: `/:table_menu_page_id/edit`,
    element: <TableMenuPagesForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="../.." />,
    },
  },
  Archived: {
    title: t('非表示にしたカテゴリ'),
    fullPath: `/restaurants/:id/menu_pages/archived`,
    relativePath: `/archived`,
    element: <TableMenuPageArchived />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function TableMenuPagesStack() {
  return <Stack routes={routes} />
}
