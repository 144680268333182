import React from 'react'

import { Image } from 'expo-image'
import { FlatList, View } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import type { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'
import type { RestaurantReservation as RestaurantReservationModel } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import {
  VERTICAL_DASH_LINE_IMAGE_URI,
  BORDER_WIDTH,
  HOUR_SP_WIDTH,
  HOUR_PC_WIDTH,
  SEAT_SP_HEIGHT,
  SEAT_PC_HEIGHT,
  HOURS,
} from './const'

import type { useTableSeats } from '../../../models/TableSeat'

export const HourCellList = React.memo(function HourCellList({
  otherReservations,
  requestReservations,
  tableSeats,
  isRestaurantOpenAt,
  isSp,
}: {
  otherReservations: RestaurantReservationModel[]
  requestReservations: RestaurantRequestReservation[]
  tableSeats: ReturnType<typeof useTableSeats>['tableSeats']
  isRestaurantOpenAt: (hour: number, minute: number) => boolean
  isSp: boolean
}) {
  const data = React.useMemo(
    () =>
      HOURS.map((hour, hourIndex) => {
        const hourCells: {
          key: string
          hour: number
          reservationIndex: number
          isFirst: boolean
          borderBottomWidth: number
          type: 'other' | 'request' | 'seat'
        }[] = []

        otherReservations.forEach((_, reservationIndex) => {
          hourCells.push({
            key: `other-${hour}-${reservationIndex}`,
            hour,
            reservationIndex,
            isFirst: hourIndex === 0,
            borderBottomWidth:
              reservationIndex === otherReservations.length - 1
                ? BORDER_WIDTH * 2
                : BORDER_WIDTH,
            type: 'other',
          })
        })

        requestReservations.forEach((_, reservationIndex) => {
          hourCells.push({
            key: `request-${hour}-${reservationIndex}`,
            hour,
            reservationIndex,
            isFirst: hourIndex === 0,
            borderBottomWidth:
              reservationIndex === requestReservations.length - 1
                ? BORDER_WIDTH * 2
                : BORDER_WIDTH,
            type: 'request',
          })
        })

        tableSeats.forEach((_, seatIndex) => {
          hourCells.push({
            key: `seat-${hour}-${seatIndex}`,
            hour,
            reservationIndex:
              otherReservations.length + requestReservations.length + seatIndex,
            isFirst: hourIndex === 0,
            borderBottomWidth:
              isSp && seatIndex === tableSeats.length - 1 ? 0 : BORDER_WIDTH,
            type: 'seat',
          })
        })

        return { key: hour, data: hourCells }
      }),
    [otherReservations, requestReservations, tableSeats, isSp]
  )

  const renderItem = React.useCallback<
    (arg: { item: (typeof data)[0] }) => React.ReactElement
  >(
    ({ item }) => {
      return (
        <View
          style={{
            flexDirection: 'column',
          }}
        >
          {item.data.map((item) => {
            return (
              <HourCell
                key={item.key}
                isFirst={item.isFirst}
                borderBottomWidth={item.borderBottomWidth}
                isSp={isSp}
                isOpenAt0min={isRestaurantOpenAt(item.hour, 0)}
                isOpenAt15min={isRestaurantOpenAt(item.hour, 15)}
                isOpenAt30min={isRestaurantOpenAt(item.hour, 30)}
                isOpenAt45min={isRestaurantOpenAt(item.hour, 45)}
              />
            )
          })}
        </View>
      )
    },
    [isSp, isRestaurantOpenAt]
  )
  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={(item) => `${item.key}`}
      horizontal
    />
  )
})

const HourCell = React.memo(function HourCell({
  isFirst,
  borderBottomWidth = BORDER_WIDTH,
  isSp = false,
  isOpenAt0min,
  isOpenAt15min,
  isOpenAt30min,
  isOpenAt45min,
}: {
  isFirst: boolean
  borderBottomWidth?: number
  isSp?: boolean
  isOpenAt0min: boolean
  isOpenAt15min: boolean
  isOpenAt30min: boolean
  isOpenAt45min: boolean
}) {
  return (
    <View
      style={[
        {
          width: isSp ? HOUR_SP_WIDTH : HOUR_PC_WIDTH,
          height: isSp ? SEAT_SP_HEIGHT : SEAT_PC_HEIGHT,
          borderColor: Colors.border,
          borderRightWidth: BORDER_WIDTH,
          borderBottomWidth,
          borderLeftWidth: isFirst && !isSp ? BORDER_WIDTH : 0,
          flexDirection: 'row',
          backgroundColor: Colors.white,
        },
      ]}
    >
      <MinuteCell isOpen={isOpenAt0min} />
      <VerticalDashLine />
      <MinuteCell isOpen={isOpenAt15min} />
      <VerticalDashLine />
      <MinuteCell isOpen={isOpenAt30min} />
      <VerticalDashLine />
      <MinuteCell isOpen={isOpenAt45min} />
    </View>
  )
})

const MinuteCell = React.memo(function MinuteCell({
  isOpen,
}: {
  isOpen: boolean
}) {
  return (
    <View
      style={{
        backgroundColor: isOpen ? 'transparent' : Colors.bgBlack,
        width: '25%',
        height: '100%',
      }}
    />
  )
})

const VerticalDashLine = React.memo(function VerticalDashLine() {
  return (
    <Image
      style={{ width: 1, height: '100%' }}
      source={{
        uri: VERTICAL_DASH_LINE_IMAGE_URI,
      }}
    />
  )
})
