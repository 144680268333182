import React from 'react'

import { Dimensions, Platform, ScrollView, View } from 'react-native'

import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { SegmentedControl } from '@hello-ai/ar_shared/src/components/SegmentedControl'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantReservationBlockResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation_block/restaurant_reservation_block_resource'
import { Timestamp } from '@hello-ai/proto/src/gen/google/protobuf/timestamp'

import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import { useTableSeatsBulk } from '../../../models/TableSeat'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { onError } from '../../../modules/swr'
import Loading from '../../Shared/Loading'
import SelectDateTimeInput from '../../Shared/SelectDateTimeInput'
import { displayToastSuccess } from '../../Shared/Toast'

function getMode(
  restaurantReservationBlock: RestaurantReservationBlockResource
) {
  const startDate = dayjs(
    Timestamp.toDate(restaurantReservationBlock.startDate!)
  )
  const endDate = dayjs(Timestamp.toDate(restaurantReservationBlock.endDate!))

  // ブロックが複数日にまたがっていない場合、通常ブロック
  return startDate.isSame(endDate, 'day') ? 'default' : 'repeat'
}

export function ReservationBlocksForm({
  restaurantId,
  restaurantReservationBlockId,
  date,
  startTime,
  endTime,
  selectedSeatIds,
}: {
  restaurantId: number
  restaurantReservationBlockId?: string
  date?: dayjs.Dayjs
  startTime?: number
  endTime?: number
  selectedSeatIds?: string[]
}) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const token = useToken()
  const { width, sm } = useResponsive()
  const { width: dimensionWidth } = Dimensions.get('window')

  const { data: restaurantReservationBlock } =
    restaurantReservationBlockService.useGet(
      restaurantReservationBlockId === undefined
        ? undefined
        : {
            id: restaurantReservationBlockId,
          }
    )

  const [mode, setMode] = useFormState<'default' | 'repeat'>(
    restaurantReservationBlock === undefined
      ? 'default'
      : getMode(restaurantReservationBlock)
  )

  const [startDate, setStartDate] = useFormState<dayjs.Dayjs>(
    restaurantReservationBlock?.startDate === undefined
      ? (date ?? dayjs())
      : dayjs(Timestamp.toDate(restaurantReservationBlock.startDate))
  )
  const [endDate, setEndDate] = useFormState<dayjs.Dayjs>(
    restaurantReservationBlock?.endDate === undefined
      ? (date ?? dayjs())
      : dayjs(Timestamp.toDate(restaurantReservationBlock.endDate))
  )

  const [wdays, setWdays] = useFormState(
    restaurantReservationBlock === undefined
      ? [0, 1, 2, 3, 4, 5, 6, 7]
      : restaurantReservationBlock.wdays
  )

  const [_startTime, setStartTime] = useFormState<number>(
    startTime ?? restaurantReservationBlock?.startTime ?? 0
  )
  const [_endTime, setEndTime] = useFormState<number>(
    endTime ?? restaurantReservationBlock?.endTime ?? 0
  )

  const _selectedSeatIds =
    selectedSeatIds ??
    restaurantReservationBlock?.tableSeats.map((tableSeat) => tableSeat.id) ??
    []

  const { tableSeats } = useTableSeatsBulk(restaurantId, {
    ids: _selectedSeatIds,
  })

  const { mutate: mutateBlocks } =
    restaurantReservationBlockService.useListPeriod(
      {
        restaurantId,
        startDate: startDate?.format('YYYY-MM-DD'),
        endDate: startDate?.format('YYYY-MM-DD'),
      },
      {
        revalidateIfStale: false,
        revalidateOnFocus: false,
        revalidateOnMount: false,
        revalidateOnReconnect: false,
      }
    )

  const onChangeStartDate = (dateTime: dayjs.Dayjs) => {
    setStartDate(dateTime)
    if (endDate.isBefore(dateTime)) {
      setEndDate(dateTime)
    }
  }

  const onChangeEndDate = (dateTime: dayjs.Dayjs) => {
    setEndDate(dateTime)
    if (startDate.isAfter(dateTime)) {
      setStartDate(dateTime)
    }
  }

  // React.useLayoutEffect(() => {
  //   navigation.setOptions({
  //     headerTitle:
  //       restaurantReservationBlockId != null
  //         ? t('ブロックを編集')
  //         : t('ブロックを追加'),
  //   })
  // }, [navigation, restaurantReservationBlockId, route])

  if (
    restaurantReservationBlockId != null &&
    restaurantReservationBlock === undefined
  ) {
    return <Loading />
  }

  const onPressSave = async () => {
    const { error } = await restaurantReservationBlockService.create(token, {
      restaurantId,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: (mode === 'default' ? startDate : endDate).format('YYYY-MM-DD'),
      startTime: _startTime,
      endTime: _endTime,
      wdays: mode === 'default' ? [0, 1, 2, 3, 4, 5, 6, 7] : wdays,
      tableSeatIds: _selectedSeatIds,
    })

    if (error != null) {
      onError(error)
      return
    }
    await mutateBlocks()

    displayToastSuccess(
      t('ブロックを追加しました'),
      undefined,
      width < sm ? { marginBottom: 48 } : undefined
    )
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations?m=chart&date=${startDate.format(
          'YYYY-MM-DD'
        )}`
      )
    } else {
      navigation.goBack()
    }
  }

  const onPressUpdate = async () => {
    if (restaurantReservationBlockId == null) return
    const { error } = await restaurantReservationBlockService.update(token, {
      id: restaurantReservationBlockId,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: (mode === 'default' ? startDate : endDate).format('YYYY-MM-DD'),
      startTime: _startTime,
      endTime: _endTime,
      wdays: mode === 'default' ? [0, 1, 2, 3, 4, 5, 6, 7] : wdays,
      tableSeatIds: _selectedSeatIds,
    })

    if (error != null) {
      onError(error)
      return
    }

    await mutateBlocks()

    displayToastSuccess(t('ブロックを更新しました'))
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations/blocks/${restaurantReservationBlockId}`
      )
    } else {
      navigation.goBack()
    }
  }

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: 'white',
        paddingHorizontal: 48,
        paddingVertical: 40,
      }}
    >
      <SegmentedControl
        style={
          width < sm && {
            width: dimensionWidth - 40,
            marginLeft: -28,
          }
        }
        buttons={[
          {
            text: t('通常'),
            selected: mode === 'default',
            onPress: () => {
              setMode('default')
            },
          },
          {
            text: t('繰り返し'),
            selected: mode === 'repeat',
            onPress: () => {
              setMode('repeat')
            },
          },
        ]}
      />
      <FormGroup
        mode={width < sm ? 'vertical' : 'inline'}
        style={{ marginTop: 24 }}
        formLabel={
          <FormLabel value={mode === 'default' ? t('日付') : t('開始日')} />
        }
      >
        <SelectDateTimeInput
          mode="date"
          dateTime={startDate}
          onChangeDateTime={onChangeStartDate}
        />
      </FormGroup>
      {mode === 'repeat' && (
        <FormGroup
          mode={width < sm ? 'vertical' : 'inline'}
          style={{ marginTop: 24 }}
          formLabel={<FormLabel value={t('終了日')} />}
        >
          <SelectDateTimeInput
            mode="date"
            dateTime={endDate}
            onChangeDateTime={onChangeEndDate}
          />
        </FormGroup>
      )}
      {mode === 'repeat' && (
        <FormGroup
          mode={width < sm ? 'vertical' : 'inline-expanded'}
          style={{ marginTop: 24 }}
          formLabel={<FormLabel value={t('曜日')} />}
        >
          <CheckboxGroup value={wdays} onChange={setWdays}>
            {dayjs
              .weekdays()
              .concat(t('祝日'))
              .map((label, wday) => {
                return (
                  <Checkbox
                    key={wday}
                    value={wday}
                    checkboxLabel={<CheckboxLabel value={label} />}
                  />
                )
              })}
          </CheckboxGroup>
        </FormGroup>
      )}
      <FormGroup
        mode={width < sm ? 'vertical' : 'inline'}
        style={{ marginTop: 24 }}
        formLabel={<FormLabel value={t('時間')} />}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <SelectDateTimeInput
            style={{ flex: 1 }}
            mode="time"
            dateTime={
              // 時間は開始日と終了日にまたがっているので、本来特定の日付に紐づいているわけではないが
              // dateTime(dayjs.Dayjs)を渡す必要があるのでstartDateをベースにする
              startDate.hour(0).minute(0).second(_startTime).millisecond(0)
            }
            onChangeDateTime={(dateTime) => {
              const time =
                dateTime.hour() * 3600 +
                dateTime.minute() * 60 +
                dateTime.second()
              setStartTime(time)
            }}
          />
          <Text
            style={{
              marginHorizontal: 8,
            }}
          >
            〜
          </Text>
          <SelectDateTimeInput
            style={{
              flex: 1,
            }}
            mode="time"
            dateTime={startDate
              .hour(0)
              .minute(0)
              .second(_endTime)
              .millisecond(0)}
            onChangeDateTime={(dateTime) => {
              const time =
                dateTime.hour() * 3600 +
                dateTime.minute() * 60 +
                dateTime.second()
              setEndTime(time)
            }}
          />
        </View>
      </FormGroup>
      <FormGroup
        mode={width < sm ? 'vertical' : 'inline'}
        style={{
          marginTop: 24,
        }}
        formLabel={<FormLabel value={t('席')} />}
      >
        <Text>
          {tableSeats?.map((tableSeat) => tableSeat.name).join(', ') ?? ''}
        </Text>
      </FormGroup>
      <AsyncButton
        onPress={
          restaurantReservationBlockId == null ? onPressSave : onPressUpdate
        }
        style={[
          width < sm && {
            marginBottom: 100,
          },
          {
            marginTop: 48,
            height: 48,
            width: width < sm ? dimensionWidth - 40 : 278,
            alignSelf: 'center',
          },
        ]}
      >
        {t('保存する')}
      </AsyncButton>
    </ScrollView>
  )
}
