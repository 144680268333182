import React, { useState } from 'react'

import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { ContentScrollView } from '@hello-ai/for_r_app/src/components/Restaurant/Reservation/ListView/Content'

import { useRestaurant } from 'models/Restaurant'
import { useRestaurantReservations } from 'models/RestaurantReservation'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function CanceledContent({ date }: { date: dayjs.Dayjs }) {
  const restaurantId = useRestaurantId()
  const [currentPage, setCurrentPage] = useState(1)

  const { restaurantReservations, headerData, mutate } =
    useRestaurantReservations(restaurantId, {
      date: date.format('YYYY-MM-DD'),
      future_only: false,
      status: 'canceled',
      per_page: currentPage,
    })

  const { restaurant } = useRestaurant(restaurantId)

  if (restaurant == null) return null

  return (
    <ContentScrollView
      restaurant={restaurant}
      restaurantReservations={restaurantReservations}
      onIssueSmartPaymentReceipt={() => mutate()}
      pagination={
        <Pagination
          currentPage={currentPage}
          totalPage={headerData?.totalPages ?? 0}
          setPage={(page) => setCurrentPage(page)}
        />
      }
    />
  )
}
