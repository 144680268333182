import React, { useState } from 'react'

import { Platform } from 'react-native'
import invariant from 'tiny-invariant'

import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { RestaurantRequestReservation } from '@hello-ai/ar_shared/src/types/ForR/RestaurantRequestReservation'

import { createReservationFromRequest } from '../../models/RestaurantRequestReservation'
import { useRestaurantReservations } from '../../models/RestaurantReservation'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { persistReservationDate } from '../../modules/persistReservationDate'
import { getFormatTime, toSeconds } from '../../modules/time'

import { DateTimeFixPartySize } from './RequestForm/DateTimeFixPartySize'
import {
  Confirmation,
  ReservationFormState,
  SelectSeats,
} from './ReservationForm'

export function RequestForm({
  restaurantId,
  data,
  skipSteps,
  fromRequestReservation,
}: {
  restaurantId: number
  data: RestaurantRequestReservation
  skipSteps: number[]
  fromRequestReservation: boolean
}) {
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()

  const [step, setStep] = useState<number>(
    Math.min(...[1, 2, 3, 4].filter((s) => !skipSteps.includes(s)))
  )

  const [state, setState] = useState<ReservationFormState>(() => {
    const startAt = dayjs(data.start_at)
    const startTime = toSeconds(startAt.hour(), startAt.minute())
    const endAt = dayjs(data.end_at)
    const endTime = endAt.diff(startAt.startOf('day'), 'seconds')
    return {
      dateString: dayjs(data.start_at).format('YYYY-MM-DD'),
      startTime,
      endTime,
      adultPartySize: data.adult_party_size,
      childPartySize: data.child_party_size,
      selectedSeatIds: [],
      selectedCustomerIds: data.customer?.id != null ? [data.customer.id] : [],
      selectedReservationCourses: data.reservation_request_courses.map(
        (course) => ({
          partySize: course.party_size,
          restaurantCourseId: course.restaurant_course.id,
        })
      ),
      allergy: data.allergy,
      translatedAllergy: data.translated_allergy,
      memo: data.memo,
      translatedMemo: data.translated_memo,
    }
  })

  const onPressGoBack = () => {
    let prevStep = step - 1
    while (prevStep > 1 && skipSteps.includes(prevStep)) {
      prevStep = prevStep - 1
    }
    if (prevStep <= 1) {
      if (Platform.OS === 'web') {
        navigate(-1)
      } else {
        navigation.goBack()
      }
      return
    }
    setStep(prevStep)
  }

  const onPressNext = (value: Partial<ReservationFormState>) => {
    setState((state) => ({
      ...state,
      ...value,
    }))

    setStep((step) => {
      let nextStep = step + 1
      while (skipSteps.includes(nextStep)) {
        nextStep = nextStep + 1
      }
      return nextStep
    })
  }

  const { mutate } = useRestaurantReservations(restaurantId, {
    date: state.dateString,
  })

  const onPressSubmit = async () => {
    const { error } = await createReservationFromRequest(
      token,
      restaurantId,
      data.id,
      {
        start_at: `${state.dateString} ${getFormatTime(state.startTime)}`,
        end_at: `${dayjs(state.dateString).add(state.endTime, 'seconds').format('YYYY-MM-DD HH:mm:ss')}`,
        table_seat_ids: state.selectedSeatIds,
      }
    )
    await mutate()
    if (error != null) return

    await persistReservationDate(dayjs(state.dateString))
    if (Platform.OS === 'web') {
      navigate(
        `/restaurants/${restaurantId}/reservations?date=${state.dateString}&m=chart`
      )
    } else {
      navigation.navigate('Reservations', {
        dateString: state.dateString,
        startTime: state.startTime,
      })
    }
  }

  switch (step) {
    case 1:
      return (
        <DateTimeFixPartySize
          restaurantId={restaurantId}
          currentStep={step}
          skipSteps={skipSteps}
          state={state as any}
          onPressNext={onPressNext}
        />
      )
    case 2:
      return (
        <SelectSeats
          restaurantId={restaurantId}
          currentStep={step}
          skipSteps={skipSteps}
          state={state as any}
          fromRequestReservation={fromRequestReservation}
          onPressGoBack={onPressGoBack}
          onPressNext={onPressNext}
        />
      )
    case 4:
      return (
        <Confirmation
          restaurantId={restaurantId}
          currentStep={step}
          skipSteps={skipSteps}
          requestReservation={data}
          state={state as any}
          onPressGoBack={onPressGoBack}
          onPressNext={onPressSubmit}
        />
      )
    default:
      invariant(true, `unknown step ${step}`)
  }
}
