import React, { useEffect, useState } from 'react'

import {
  faTrashAlt,
  faStore,
  faQuestionCircle,
} from '@fortawesome/pro-regular-svg-icons'
import { faCheck, faPaperclip } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View } from 'react-native'
import { TouchableOpacity } from 'react-native-gesture-handler'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { CandidatesRestaurant } from '@hello-ai/ar_shared/src/types/ForR/CandidatesRestaurant'

import { useRestaurantOwnershipCandidates } from 'models/RestaurantOwner'

interface FileItemProps {
  file: File
  selectedRestaurant?: CandidatesRestaurant
  onDelete: () => void
  onSelectRestaurant: (restaurant: CandidatesRestaurant) => void
}

export default function FileItem({
  file,
  selectedRestaurant,
  onDelete,
  onSelectRestaurant,
}: FileItemProps) {
  const [restaurantUrl, setRestaurantUrl] = useState('')
  const [isOpenPopover, setIsOpenPopover] = useState(false)

  const { data: restaurants } = useRestaurantOwnershipCandidates({
    url: restaurantUrl,
  })

  const loading = restaurantUrl !== '' && restaurants === undefined

  useEffect(() => {
    if (isOpenPopover) {
      const timeout = setTimeout(() => {
        setIsOpenPopover(false)
      }, 5000)
      return () => clearTimeout(timeout)
    }
  }, [isOpenPopover])

  return (
    <View
      style={{
        width: '100%',
        borderStyle: 'dashed',
        borderWidth: 1,
        borderColor: Colors.border,
        borderRadius: 8,
      }}
    >
      <View
        style={{
          padding: 16,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          backgroundColor: Colors.black6,
        }}
      >
        <View style={{ flexDirection: 'row', columnGap: 4 }}>
          <FontAwesomeIcon icon={faPaperclip} size={24} />
          <Text style={{ fontSize: 18, fontWeight: '600' }}>{file.name}</Text>
        </View>
        <TouchableOpacity onPress={onDelete}>
          <FontAwesomeIcon icon={faTrashAlt} color={Colors.caution} size={18} />
        </TouchableOpacity>
      </View>
      <View style={{ padding: 32, position: 'relative' }}>
        <Text style={{ fontSize: 18, fontWeight: '300' }}>
          {t(
            'AutoReserve上の該当店舗ページのURLを入力し、店舗を選択してください'
          )}
        </Text>
        <View style={{ marginTop: 40 }}>
          <FormGroup
            mode="vertical"
            formLabel={
              <FormLabel
                value={t('該当店舗ページのURL')}
                labelStyle={{
                  fontSize: 14,
                }}
                rightIcon={
                  <TouchableOpacity
                    style={{ marginLeft: 4 }}
                    onPress={() => setIsOpenPopover((prev) => !prev)}
                  >
                    <FontAwesomeIcon
                      icon={faQuestionCircle}
                      color={Colors.primary}
                      size={16}
                    />
                  </TouchableOpacity>
                }
              />
            }
            containerStyle={{
              marginTop: 14,
            }}
          >
            <TextInput
              style={{
                paddingVertical: 26,
              }}
              placeholder={t(
                '入力例：https://autoreserve.com/ja/restaurants/XXXX'
              )}
              onChangeText={setRestaurantUrl}
            />
          </FormGroup>
        </View>
        {loading && <Loading />}
        {restaurants?.map((restaurant) => (
          <View
            key={restaurant.id}
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingVertical: 20,
              paddingHorizontal: 24,
              borderTopColor: Colors.border,
              borderTopWidth: 1,
            }}
          >
            <View style={{ flexDirection: 'column', flex: 1 }}>
              <View style={{ flexDirection: 'row' }}>
                <FontAwesomeIcon
                  icon={faStore}
                  style={{
                    width: 24,
                    height: 24,
                    color: Colors.primary,
                    marginRight: 8,
                  }}
                />
                <Text
                  style={{
                    fontSize: 18,
                    fontWeight: '300',
                    color: Colors.primary,
                    marginRight: 16,
                  }}
                >
                  {restaurant.name_i18n}
                </Text>
              </View>
              <Text
                style={{
                  fontSize: 16,
                  fontWeight: '300',
                  color: Colors.black60,
                }}
                numberOfLines={2}
                ellipsizeMode="tail"
              >
                {restaurant.address_short}
              </Text>
            </View>
            <Button
              mode={restaurant.has_owner ? 'text' : 'contained'}
              style={[
                { width: 120, paddingHorizontal: 16, paddingVertical: 6 },
                selectedRestaurant?.id === restaurant.id && {
                  backgroundColor: Colors.success,
                  borderColor: Colors.success,
                  opacity: 1,
                },
              ]}
              textStyle={{
                fontSize: 14,
                fontWeight: '300',
                lineHeight: 24,
                color: restaurant.has_owner ? Colors.textDisabled : undefined,
              }}
              onPress={() => onSelectRestaurant(restaurant)}
              disabled={
                restaurant.has_owner || selectedRestaurant?.id === restaurant.id
              }
            >
              {selectedRestaurant?.id === restaurant.id ? (
                <View
                  style={{
                    flexDirection: 'row',
                    columnGap: 4,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faCheck} color={Colors.white} />
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '300',
                      lineHeight: 24,
                      color: Colors.white,
                    }}
                  >
                    {t('選択中')}
                  </Text>
                </View>
              ) : (
                t('認証する')
              )}
            </Button>
          </View>
        ))}
        <ShadowBox
          style={{
            display: isOpenPopover ? 'flex' : 'none',
            position: 'absolute',
            top: 120,
            left: 180,
            padding: 24,
            backgroundColor: Colors.white,
          }}
        >
          <Text
            style={{
              width: 352,
              fontSize: 16,
              fontWeight: '300',
            }}
          >
            <Text style={{ color: Colors.primary }}>{t('AutoReserve')}</Text>
            {t(
              'ですでに掲載されているページの「https://〜」から始まるURLをすべてコピーし、入力フォームにペーストしてください。店舗ページは店舗名を検索することで確認できます'
            )}
          </Text>
        </ShadowBox>
      </View>
    </View>
  )
}
