import React, { useRef, useState } from 'react'

import { faPlus } from '@fortawesome/pro-regular-svg-icons/faPlus'
import { faArrowDownArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowDownArrowUp'
import { faTrashAlt } from '@fortawesome/pro-solid-svg-icons/faTrashAlt'
import { View, Dimensions, Keyboard, FlatList, Platform } from 'react-native'
import DragList from 'react-native-draglist'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import RequiredLabel from '@hello-ai/ar_shared/src/components/ForR/Shared/RequiredLabel'
import Title from '@hello-ai/ar_shared/src/components/ForR/Shared/Title'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import {
  TouchableOpacity,
  TouchableWithoutFeedback,
} from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { toDragHandlers } from '@hello-ai/ar_shared/src/modules/drag'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  createResponsiveSheet,
  useResponsiveStyle,
} from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantCrewMember } from '@hello-ai/ar_shared/src/types/ForR/RestaurantCrewMember'

import {
  deleteRestaurantCrewMember,
  postRestaurantCrewMembers,
  putRestaurantCrewMembers,
  useAllRestaurantCrewMembers,
} from '../../../models/RestaurantCrewMembers'

const ITEM_HEIGHT = 114

const responsiveStyle = createResponsiveSheet(({ width, sm }) => ({
  container: { flex: 1 },
  scrollViewContainer: {
    padding: width < sm ? 24 : 48,
    backgroundColor: Colors.bgBlack,
    flexGrow: 1,
    rowGap: 16,
  },
  helperText: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
  },

  tableContainer: {
    backgroundColor: Colors.white,
    borderRadius: 8,
  },
  header: {
    backgroundColor: Colors.white,
    paddingVertical: 16,
    paddingHorizontal: width < sm ? 20 : 24,
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
    columnGap: 16,
    marginTop: -1, // NOTE: sticky headerの上部が透けて見えるため
  },
  headerText: {
    fontSize: 14,
    fontWeight: '600',
    color: Colors.black,
    lineHeight: 22,
    textAlign: 'center',
  },
  orderColumn: {
    width: 42,
  },
  nameColumn: {
    flex: 1,
    width: width < sm ? 180 : undefined,
  },
  enableColumn: {
    width: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteColumn: {
    width: 42,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flatListRoot: {
    maxHeight:
      Dimensions.get('window').height -
      (width < sm
        ? // NOTE: header + footer + padding + table header
          264
        : // NOTE: header + footer + padding + table header
          288),
  },
  rowContainer: {
    height: ITEM_HEIGHT,
    padding: width < sm ? 20 : 24,
    borderBottomWidth: 0.5,
    borderColor: Colors.border,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 16,
  },
  itemText: {
    fontSize: 18,
    lineHeight: 27,
    color: Colors.black,
  },
  footerContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    columnGap: 16,
    backgroundColor: Colors.white,
    paddingVertical: 12,
  },
  footerButton: {
    width: width < sm ? 180 : 280,
    height: 48,
  },
  addCrewMemberButton: {
    flexDirection: 'row',
    paddingHorizontal: 0,
    columnGap: 8,
  },
  addCrewMemberButtonText: {
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 24,
    letterSpacing: 0,
    textAlign: 'left',
    color: Colors.primary,
  },
  spNone: {
    display: width < sm ? 'none' : 'flex',
  },
  pcNone: {
    display: width < sm ? 'flex' : 'none',
  },
}))

interface CrewMembersEditViewProps {
  restaurantId: number
  onSuccessfulSubmit: () => void
}

export default function CrewMembersEditView({
  restaurantId,
  onSuccessfulSubmit,
}: CrewMembersEditViewProps) {
  const [submitting, setSubmitting] = useState(false)
  const style = useResponsiveStyle(responsiveStyle)
  const { restaurantCrewMembers, mutate } = useAllRestaurantCrewMembers(
    restaurantId,
    {
      only_statuses_with: ['normal', 'disabled'],
    }
  )
  const [formData, setFormData] = useFormState(
    // NOTE: onSubmitでrestaurantCrewMembersと比較するため、deep copyを擬似的に行う
    restaurantCrewMembers?.map((member) => ({
      ...member,
    }))
  )
  const token = useToken()
  const ref = useRef<FlatList<RestaurantCrewMember>>(null)

  const onSubmit = async () => {
    if (formData == null || token == null) return
    try {
      setSubmitting(true)
      const diffMembers = formData.filter((member) => {
        const original = restaurantCrewMembers?.find((m) => m.id === member.id)
        if (original == null) return false
        return (
          original.name !== member.name ||
          original.disabled !== member.disabled ||
          original.position !== member.position
        )
      })
      const deleteMembers = restaurantCrewMembers?.filter(
        (member) => !formData.some((m) => m.id === member.id)
      )
      const newMembers = formData.filter((member) =>
        member.id.startsWith('new-')
      )
      try {
        if (diffMembers.length > 0) {
          const result = await Promise.all(
            diffMembers.map((member) => {
              return putRestaurantCrewMembers(restaurantId, token, member.id, {
                restaurant_crew_member: {
                  name: member.name,
                  disabled: member.disabled,
                  position: member.position,
                },
              })
            })
          )
          if (result.some((r) => r.error != null)) {
            throw new Error('error')
          }
        }
        if (deleteMembers != null && deleteMembers.length > 0) {
          const result = await Promise.all(
            deleteMembers.map((member) => {
              return deleteRestaurantCrewMember(token, restaurantId, member.id)
            })
          )
          if (result.some((r) => r.error != null)) {
            throw new Error('error')
          }
        }
        if (newMembers.length > 0) {
          const result = await Promise.all(
            newMembers.map((member) => {
              return postRestaurantCrewMembers(restaurantId, token, {
                restaurant_crew_member: {
                  name: member.name,
                  disabled: member.disabled,
                  position: member.position,
                },
              })
            })
          )
          if (result.some((r) => r.error != null)) {
            throw new Error('error')
          }
        }
      } catch {
        mutate()
        return
      }
      mutate()
      onSuccessfulSubmit()
    } finally {
      setSubmitting(false)
    }
  }

  return (
    <TouchableWithoutFeedback
      onPress={Platform.OS === 'web' ? undefined : Keyboard.dismiss}
    >
      <View style={style.container}>
        <View style={style.scrollViewContainer}>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <Title
              title={t('担当者一覧')}
              helperText={t(
                '予約や会計時の担当者一覧です。追加は「担当者を編集する」画面からメールアドレス不要で追加することができます。'
              )}
              helperTextStyle={style.helperText}
            />
          </View>
          <DragList
            ref={ref}
            key={
              Platform.OS === 'web'
                ? formData?.map((item) => item.id).join('')
                : undefined
            }
            style={style.flatListRoot}
            contentContainerStyle={style.tableContainer}
            keyExtractor={(item) => item.id}
            data={formData ?? []}
            getItemLayout={(_, index: number) => ({
              length: ITEM_HEIGHT,
              offset: ITEM_HEIGHT * index,
              index,
            })}
            onLayout={() => {
              console.log('onLayout')
            }}
            renderItem={({ item, onDragStart, onDragEnd }) => (
              <View style={style.rowContainer}>
                <View style={[style.row]}>
                  <TouchableOpacity
                    style={[style.orderColumn]}
                    {...toDragHandlers(onDragStart, onDragEnd)}
                  >
                    <FontAwesomeIcon
                      icon={faArrowDownArrowUp}
                      size={24}
                      color={Colors.primary}
                    />
                  </TouchableOpacity>
                  <TextInput
                    style={[style.itemText, style.nameColumn]}
                    value={item.name}
                    onChangeText={(text) => {
                      setFormData((prev) => {
                        if (prev == null) return prev
                        const newFormData = [...prev]
                        const index = newFormData.findIndex(
                          (i) => i.id === item.id
                        )
                        if (index === -1) return prev
                        newFormData[index] = {
                          ...newFormData[index],
                          name: text,
                        }
                        return newFormData
                      })
                    }}
                  />
                  <Switch
                    style={style.enableColumn}
                    value={!item.disabled}
                    onValueChange={(state) => {
                      setFormData((prev) => {
                        const targetIndex = prev?.findIndex(
                          (i) => i.id === item.id
                        )
                        if (targetIndex == null || targetIndex === -1)
                          return prev
                        if (prev == null) return prev
                        prev[targetIndex] = {
                          ...prev[targetIndex],
                          disabled: !state,
                        }
                        return [...prev]
                      })
                    }}
                  />
                  <TouchableOpacity
                    style={[style.deleteColumn, style.spNone]}
                    onPress={() => {
                      setFormData((prev) => {
                        if (prev == null) return prev
                        const newFormData = prev.filter((i) => i.id !== item.id)
                        newFormData.forEach((item, index) => {
                          item.position = index
                        })
                        return newFormData
                      })
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrashAlt}
                      color={Colors.caution}
                      size={20}
                    />
                  </TouchableOpacity>
                </View>
                <TouchableOpacity
                  style={[
                    {
                      alignSelf: 'flex-end',
                      flexDirection: 'row',
                      alignItems: 'center',
                      columnGap: 4,
                      marginTop: 12,
                    },
                    style.pcNone,
                  ]}
                  onPress={() => {
                    setFormData((prev) => {
                      if (prev == null) return prev
                      const newFormData = prev.filter((i) => i.id !== item.id)
                      newFormData.forEach((item, index) => {
                        item.position = index
                      })
                      return newFormData
                    })
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTrashAlt}
                    color={Colors.caution}
                    size={20}
                  />
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '300',
                      lineHeight: 24,
                      letterSpacing: 0,
                      textAlign: 'left',
                      color: Colors.caution,
                    }}
                  >
                    {t('この項目を削除')}
                  </Text>
                </TouchableOpacity>
              </View>
            )}
            ListHeaderComponent={
              <View style={style.header}>
                <Text style={[style.headerText, style.orderColumn]}>
                  {t('表示順')}
                </Text>
                <View
                  style={[
                    style.nameColumn,
                    {
                      flexDirection: 'row',
                      columnGap: 4,
                      alignItems: 'center',
                    },
                  ]}
                >
                  <Text style={[style.headerText, { textAlign: 'left' }]}>
                    {t('担当者名')}
                  </Text>
                  <RequiredLabel
                    textStyle={{
                      fontSize: 10,
                      lineHeight: 10,
                      height: 14,
                      width: 24,
                    }}
                  />
                </View>
                <Text style={[style.headerText, style.enableColumn]}>
                  {t('表示')}
                </Text>
                <Text
                  style={[style.headerText, style.deleteColumn, style.spNone]}
                >
                  {t('削除')}
                </Text>
              </View>
            }
            onReordered={(fromIndex, toIndex) => {
              setFormData((prev) => {
                if (prev == null) return prev
                const newFormData = prev
                  .toSpliced(fromIndex, 1)
                  .toSpliced(toIndex, 0, prev[fromIndex])
                  .map((item, index) => ({ ...item, position: index }))
                return newFormData
              })
              if (Platform.OS === 'web') {
                setTimeout(() => {
                  ref.current?.scrollToOffset({
                    offset: toIndex * ITEM_HEIGHT,
                  })
                }, 1)
              }
            }}
          />
        </View>
        <View style={style.footerContainer}>
          <Button
            mode="outline"
            style={[style.addCrewMemberButton, style.footerButton]}
            onPress={() => {
              setFormData((prev) => {
                if (prev == null) return prev
                const item = {
                  id: `new-${prev.length + 1}`,
                  name: '',
                  disabled: false,
                  position: prev.length + 1,
                  created_at: new Date().toISOString(),
                  updated_at: new Date().toISOString(),
                }
                const items = [...prev, item]
                requestAnimationFrame(() => {
                  ref.current?.scrollToOffset({
                    offset: items.length * ITEM_HEIGHT,
                  })
                })
                return items
              })
            }}
          >
            <FontAwesomeIcon icon={faPlus} color={Colors.primary} size={16} />
            <Text style={style.addCrewMemberButtonText}>
              {t('担当者を追加する')}
            </Text>
          </Button>
          <Button
            style={style.footerButton}
            onPress={onSubmit}
            disabled={submitting}
          >
            {t('保存する')}
          </Button>
        </View>
      </View>
    </TouchableWithoutFeedback>
  )
}
