import React, { useEffect, useMemo } from 'react'

import { View } from 'react-native'
import { ScrollView } from 'react-native-gesture-handler'

import { Stepper } from '@hello-ai/ar_shared/src/components/Stepper'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'

import { useRestaurantBusinessTimesByDate } from '../../../models/RestaurantBusinessTime'
import { getFormatTime, toSeconds } from '../../../modules/time'
import SelectDateTimeInput from '../../Shared/SelectDateTimeInput'
import { displayToastError } from '../../Shared/Toast'
import { RESERVATION_STEPPERS } from '../FormCommon/Steppers'
import {
  DateQuestionTitle,
  DateTimeQuestionTitle,
  PartySizeQuestionTitle,
} from '../FormCommon/Title'
import { ReservationFormStepProps } from '../ReservationForm'

export function DateTimeFixPartySize({
  restaurantId,
  currentStep,
  skipSteps,
  state,
  onPressNext,
  onPressGoBack,
}: ReservationFormStepProps) {
  const [dateTime, setDateTime] = useFormState(
    dayjs(`${state.dateString} ${getFormatTime(state.startTime)}`)
  )

  const { restaurantBusinessTimes } = useRestaurantBusinessTimesByDate(
    restaurantId!,
    { date: dateTime.format('YYYY-MM-DD') }
  )

  const errors = useMemo(() => {
    const errors: string[] = []
    const startTime = toSeconds(dateTime.hour(), dateTime.minute())
    if (restaurantBusinessTimes != null) {
      const businessTimesWithinOpeningHours = restaurantBusinessTimes.filter(
        (time) => startTime >= time.start_time && startTime <= time.end_time
      )
      if (businessTimesWithinOpeningHours.length === 0) {
        errors.push(t('営業時間外です'))
      }

      const businessTimesPastLastOrder = businessTimesWithinOpeningHours.filter(
        (time) => {
          if (time.last_order_time == null) return false
          return startTime > time.last_order_time
        }
      )
      if (businessTimesPastLastOrder.length > 0) {
        errors.push(t('ラストオーダーの時間を超えています'))
      }
    }
    return errors
  }, [dateTime, restaurantBusinessTimes])

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error)
      })
  }, [errors])

  return (
    <Stepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep!}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={() => {
        onPressNext({
          dateString: dateTime.format('YYYY-MM-DD'),
          startTime: dateTime.hour() * 3600 + dateTime.minute() * 60,
        })
      }}
      isDisabledNextPress={false}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: 'white' }}
        contentContainerStyle={{
          flexDirection: 'column',
          alignItems: 'center',
          paddingHorizontal: 32,
          paddingVertical: 48,
        }}
      >
        <View style={{ width: '64%' }}>
          <View>
            <DateQuestionTitle />
            {/* eslint-disable-next-line ar-i18n/require-translation-ja */}
            <Text>{dateTime.format('YYYY年MM月DD日(ddd)')}</Text>
          </View>
          <View style={{ marginTop: 40 }}>
            <DateTimeQuestionTitle />
            <View
              style={{
                marginTop: 24,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <SelectDateTimeInput
                mode="time"
                dateTime={dateTime}
                minuteInterval={15}
                onChangeDateTime={(d) => {
                  setDateTime((dateTime) =>
                    dateTime.hour(d.hour()).minute(d.minute())
                  )
                }}
                style={{
                  height: 64,
                  paddingHorizontal: 16,
                  width: '100%',
                }}
              />
            </View>
          </View>
          <View style={{ marginTop: 40 }}>
            <PartySizeQuestionTitle />
            <View style={{ marginTop: 24 }}>
              <Text>
                {t('大人')}： {state.adultPartySize}
              </Text>
              <Text style={{ marginTop: 16 }}>
                {t('お子様')}： {state.childPartySize}
              </Text>
            </View>
          </View>
        </View>
      </ScrollView>
    </Stepper>
  )
}
