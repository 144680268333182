import React from 'react'

import { View, ScrollView } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import SelectSeatConnectionView, {
  TableSeatStatus,
} from '@hello-ai/ar_shared/src/components/ForR/SiteController/SeatSettingEdit/SelectTableSeatView'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { CreateSharedSeatParams } from '@hello-ai/ar_shared/src/types/ForR/SiteControllerSeat'

import {
  createSharedSeats,
  useNotSharedTableSeats,
} from '../../models/SiteControllerSeatSetting'

import SelectParsedSeatView, {
  SelectedParsedSeatIdBySite,
} from './SelectParsedSeatView'

interface GourmetSiteSeatSettingCreateProps {
  restaurantId: number
  displayToastSuccess: (message: string) => void
  onSubmitSuccess: () => void
}

export default function GourmetSiteSeatSettingCreate({
  restaurantId,
  displayToastSuccess,
  onSubmitSuccess,
}: GourmetSiteSeatSettingCreateProps) {
  const notSharedTableSeats = useNotSharedTableSeats(restaurantId)
  const [selectedTableSeatId, setSelectedTableSeatId] = useFormState(
    notSharedTableSeats.data?.[0]?.id
  )
  const [selectedTableSeatStatus, setSelectedTableSeatStatus] =
    useFormState<TableSeatStatus>('enabled')

  const [selectedParsedSeatIdBySite, setSelectedParsedSeatIdBySite] =
    useFormState<SelectedParsedSeatIdBySite>({})

  const token = useToken()

  const isDisabledSubmitButton =
    selectedTableSeatId == null ||
    Object.keys(selectedParsedSeatIdBySite).length === 0

  const onPressSubmit = async () => {
    if (isDisabledSubmitButton) return
    if (token == null) return

    const parsedSeats: CreateSharedSeatParams['parsed_seats'] = []

    Object.entries(selectedParsedSeatIdBySite).forEach(
      ([_, parsed_seat_ids]) => {
        if (parsed_seat_ids == null) return
        parsed_seat_ids.forEach((parsed_seat_id) => {
          parsedSeats.push({ parsed_seat_id })
        })
      }
    )

    const { error } = await createSharedSeats(token, restaurantId, {
      status: selectedTableSeatStatus,
      table_seat_id: selectedTableSeatId,
      parsed_seats: parsedSeats,
    })
    if (error != null) return

    displayToastSuccess(t('連携席設定を作成しました'))
    onSubmitSuccess()
  }

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        marginTop: 40,
        marginHorizontal: 56,
        paddingBottom: 80,
        rowGap: 48,
      }}
    >
      <SelectSeatConnectionView
        tableSeats={notSharedTableSeats.data}
        onChangeSelectTableSeatId={setSelectedTableSeatId}
        selectedTableSeatId={selectedTableSeatId}
        tableSeatStatus={selectedTableSeatStatus}
        onChangeTableSeatStatus={setSelectedTableSeatStatus}
      />
      <SelectParsedSeatView
        restaurantId={restaurantId}
        selectedParsedSeatIdBySite={selectedParsedSeatIdBySite}
        onChangeSelectedParsedSeat={setSelectedParsedSeatIdBySite}
      />
      <View
        style={{
          flexDirection: 'row',
          columnGap: 16,
          justifyContent: 'center',
        }}
      >
        <Button
          style={{ width: 280, height: 48 }}
          variant="danger-secondary"
          mode="outline"
          disabled
        >
          {t('連携席設定を削除')}
        </Button>
        <Button
          style={{ width: 280, height: 48 }}
          onPress={onPressSubmit}
          disabled={isDisabledSubmitButton}
        >
          {t('連携席設定を作成する')}
        </Button>
      </View>
    </ScrollView>
  )
}
