import React, { useState } from 'react'

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { Picker } from '@react-native-picker/picker'
import { StyleProp, View, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { SelectItem } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import CountryCodes from '../../assets/data/CountryCodes.json'

import { Modal } from './Modal'

function SelectInputModal<T = any>({
  isModalVisible,
  onClose,
  selectedValue,
  onValueChange,
  items,
}: {
  isModalVisible: boolean
  onClose: () => void
  selectedValue: T
  onValueChange: (value: T) => void
  items: SelectItem<T>[]
}) {
  return (
    <Modal
      isVisible={isModalVisible}
      onBackButtonPress={onClose}
      onBackdropPress={onClose}
      style={{ justifyContent: 'flex-end', margin: 0 }}
      contentContainerStyle={{
        flex: 1,
        maxHeight: '50%',
        backgroundColor: 'white',
      }}
    >
      <Picker<number>
        selectedValue={items.findIndex((item) => item.value === selectedValue)}
        style={{ paddingVertical: 0, flex: 1 }}
        onValueChange={(index) => onValueChange(items[index].value)}
      >
        {items.map((item, index) => {
          return <Picker.Item key={index} label={item.label} value={index} />
        })}
      </Picker>
    </Modal>
  )
}

const items: SelectItem[] = CountryCodes.map(
  ({ name_ja: name, dial_code: dialCode }) => ({
    label: `${name} ${dialCode}`,
    value: dialCode,
  })
)

export function PhoneNumberInput({
  style,
  dialCode,
  onChangeDialCode,
  children,
}: {
  style?: StyleProp<ViewStyle>
  dialCode: string
  onChangeDialCode: (value: string) => void
  children?: React.ReactNode
}) {
  const [isModalVisible, setModalVisible] = useState(false)
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: Colors.field,
          borderRadius: 8,
        },
        style,
      ]}
    >
      <TouchableOpacity
        onPress={() => setModalVisible(true)}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginHorizontal: 16,
        }}
      >
        <Text
          style={{
            color: Colors.black,
            fontSize: 14,
            lineHeight: 21,
          }}
        >
          {dialCode}
        </Text>
        <FontAwesomeIcon
          icon={faChevronDown}
          size={8}
          color={Colors.black}
          style={{ marginLeft: 4 }}
        />
      </TouchableOpacity>
      <View
        style={{
          width: 1,
          height: 40,
          backgroundColor: Colors.border,
        }}
      />
      {children}
      <SelectInputModal
        isModalVisible={isModalVisible}
        onClose={() => setModalVisible(false)}
        selectedValue={dialCode}
        onValueChange={onChangeDialCode}
        items={items}
      />
    </View>
  )
}
