import React from 'react'

import { View, StyleProp, ViewStyle } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

export function HeaderRightButton({
  icon = null,
  label = null,
  badgeCount = 0,
  onPress,
  style,
}: {
  icon?: React.ReactNode
  label?: string | null
  badgeCount?: number
  onPress: () => void
  style?: StyleProp<ViewStyle>
}) {
  const { width, sm } = useResponsive()

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        {
          paddingRight: 16,
        },
        style,
      ]}
    >
      {badgeCount > 0 && (
        <View
          style={{
            zIndex: 1,
            position: 'absolute',
            top: 0,
            right: 0,
            backgroundColor: 'red',
            borderRadius: 10,
            width: width < sm ? 14 : 20,
            height: width < sm ? 14 : 20,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              color: 'white',
              fontSize: width < sm ? 8 : 12,
            }}
          >
            {badgeCount}
          </Text>
        </View>
      )}
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {icon != null && icon}
        {label != null && (
          <Text
            style={[
              {
                color: Colors.primary,
                fontSize: 20,
              },
              icon != null && {
                marginLeft: 8,
              },
            ]}
          >
            {label}
          </Text>
        )}
      </View>
    </TouchableOpacity>
  )
}
