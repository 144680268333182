import React from 'react'

import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { View } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { TableSeatResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

import { getCategoryName } from './common'

export function TableSeat({
  tableSeat,
  onPress,
}: {
  tableSeat: TableSeatResource
  onPress: () => void
}) {
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          backgroundColor: 'white',
          height: 153,
          borderRadius: 4,
          shadowRadius: 2,
          elevation: 2,
          shadowColor: '#000',
          shadowOpacity: 0.1,
          shadowOffset: { width: 0, height: 1 },
          padding: 12,
        }}
      >
        <Text
          style={{
            fontWeight: '600',
          }}
          numberOfLines={3}
        >
          {tableSeat.name}
        </Text>
        <View>
          {tableSeat.minPartySize && tableSeat.maxPartySize && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 2,
              }}
            >
              <FontAwesomeIcon
                icon={faUsers}
                size={14}
                color={Colors.primary}
                style={{ marginRight: 4 }}
              />
              <Text style={{ fontSize: 14, color: Colors.secondaryBlack }}>
                {tableSeat.minPartySize.value}~{tableSeat.maxPartySize.value}
              </Text>
            </View>
          )}
          {tableSeat.category && (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: 2,
              }}
            >
              <FontAwesomeIcon
                icon={faChair}
                size={14}
                color={Colors.primary}
                style={{ marginRight: 4 }}
              />
              <Text
                style={{ fontSize: 14, color: Colors.secondaryBlack, flex: 1 }}
              >
                {getCategoryName(tableSeat.category.value)}
              </Text>
            </View>
          )}
        </View>
      </View>
    </TouchableOpacity>
  )
}
