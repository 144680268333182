import React from 'react'

import { View } from 'react-native'

import { FormGroup } from '@hello-ai/ar_shared/src/components/Form'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { onError } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  SupportedLocale,
  localeData,
} from '@hello-ai/ar_shared/src/modules/locale'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { LanguageExpandToggleButton } from '../Shared/LanguageExpandToggleButton'

import { TranslationButton } from './TranslationButton'
import { TranslationModal } from './TranslationModal'

type TranslationFormGroupProps = {
  formLabel: React.ReactNode
  showsFormDescription?: boolean
  formDescriptionText?: string
  values: Record<SupportedLocale, string>
  onChangeValues: (
    values:
      | Record<SupportedLocale, string>
      | ((
          values: Record<SupportedLocale, string>
        ) => Record<SupportedLocale, string>)
  ) => void
  sortedLocales: SupportedLocale[]
  createTranslation: (params: {
    text: string
    source_lang: SupportedLocale
    target_langs: SupportedLocale[]
  }) => Promise<{
    data?: {
      translations: Record<SupportedLocale, string>
    }
    error?: any
  }>
  children: (props: { locale: SupportedLocale }) => React.ReactNode
}

export function getTranslationLocaleLabel(locale: SupportedLocale) {
  return localeData[locale]?.label
}

const defaultFormDescriptionText = t(
  '新規作成時のみ、日本語の入力内容を自動で翻訳して他の言語も登録します。'
)

export function TranslationFormList({
  formLabel,
  values,
  showsFormDescription,
  formDescriptionText = defaultFormDescriptionText,
  onChangeValues,
  sortedLocales,
  createTranslation,
  children,
}: TranslationFormGroupProps) {
  const { width, sm } = useResponsive()
  const [isTranslationModalOpen, setIsTranslationModalOpen] =
    React.useState(false)

  const [isExpanded, setIsExpanded] = React.useState(false)

  return (
    <FormGroup
      formLabel={
        <View>
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 12,
            }}
          >
            <View style={{ flex: 1 }}>
              {formLabel}
              {showsFormDescription && width >= sm && (
                <Text style={{ marginTop: 12, fontSize: 14 }}>
                  {formDescriptionText}
                </Text>
              )}
            </View>
            <TranslationButton
              disabled={Object.values(values).every((value) => value === '')}
              onPress={() => {
                setIsTranslationModalOpen(true)
              }}
            />
            <TranslationModal
              isVisible={isTranslationModalOpen}
              onClose={() => {
                setIsTranslationModalOpen(false)
              }}
              initialLocale={sortedLocales[0]}
              values={
                Object.fromEntries(
                  sortedLocales.map((locale) => {
                    return [locale, values[locale]]
                  })
                ) as Record<SupportedLocale, string>
              }
              onTranslate={async ({ locale, locales }) => {
                const { data, error } = await createTranslation({
                  text: values[locale],
                  source_lang: locale,
                  target_langs: locales,
                })

                if (error != null) {
                  onError(error)
                  return { error }
                }

                onChangeValues((value) => ({
                  ...value,
                  ...data!.translations,
                }))

                setIsExpanded(true)
                setIsTranslationModalOpen(false)
                return {}
              }}
            />
          </View>
          {showsFormDescription && width < sm && (
            <Text style={{ marginTop: 8, fontSize: 14 }}>
              {formDescriptionText}
            </Text>
          )}
        </View>
      }
      mode={'vertical'}
      containerStyle={{
        marginTop: 0,
      }}
    >
      {sortedLocales
        .slice(0, isExpanded ? sortedLocales.length : 1)
        .map((locale: SupportedLocale) => (
          <View key={locale}>
            {children({
              locale,
            })}
          </View>
        ))}
      <LanguageExpandToggleButton
        isExpanded={isExpanded}
        onPress={() => {
          setIsExpanded((value) => !value)
        }}
        style={{ marginTop: 24 }}
      />
    </FormGroup>
  )
}

export function TranslationFormItem({
  formLabel,
  children,
}: {
  formLabel: React.ReactNode
  children: React.ReactNode
}) {
  const { width, sm } = useResponsive()
  return (
    <FormGroup
      formLabel={formLabel}
      style={{
        marginTop: 24,
      }}
      mode={width < sm ? 'vertical' : 'inline'}
      containerStyle={{
        marginTop: width < sm ? 8 : 0,
      }}
    >
      {children}
    </FormGroup>
  )
}
