import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { HeaderBackButton } from '@hello-ai/for_r_app/src/components/Shared/Header'

import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import { useNavigate } from 'modules/router/useNavigate'
import BroadcastEditScreen from 'pages/Broadcast/Form'
import BroadcastHistoryScreen from 'pages/Broadcast/History'
import Broadcasts from 'pages/Broadcast/List'
import BroadcastNewScreen from 'pages/Broadcast/New'

function HeaderBack() {
  const navigate = useNavigate()

  return <HeaderBackButton onPress={() => navigate(-1)} />
}

const routes: StackRoutes = {
  Index: {
    title: t('配信'),
    fullPath: `/restaurants/:id/broadcasts`,
    relativePath: '/',
    exact: true,
    element: <Broadcasts />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  History: {
    title: t('配信したメッセージ'),
    fullPath: `/restaurants/:id/broadcasts/:broadcastId/history`,
    relativePath: '/:broadcastId/history',
    exact: true,
    element: <BroadcastHistoryScreen />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
  Edit: {
    title: t('メッセージを編集'),
    fullPath: `/restaurants/:id/broadcasts/:broadcastId/edit`,
    relativePath: '/:broadcastId/edit',
    exact: true,
    element: <BroadcastEditScreen />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
  New: {
    title: t('メッセージを作成'),
    fullPath: `/restaurants/:id/broadcasts/new`,
    relativePath: '/new',
    exact: true,
    element: <BroadcastNewScreen />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
}

export default function BroadcastStack() {
  return <Stack routes={routes} />
}
