import React from 'react'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import type { StyleProp, ViewStyle } from 'react-native'

export default function RoundedActionButton({
  children,
  onPress,
  style,
}: {
  children: React.ReactNode
  onPress: () => void
  style?: StyleProp<ViewStyle>
}) {
  return (
    <Button
      mode="contained"
      variant="secondary"
      height={48}
      style={[{ flex: 1, minHeight: 48 }, style]}
      roundness={24}
      onPress={onPress}
    >
      <Text
        style={{
          color: Colors.primary,
          fontWeight: '600',
          fontSize: 14,
        }}
      >
        {children}
      </Text>
    </Button>
  )
}
