import React from 'react'

import { View } from 'react-native'

import { ModalProps } from '@hello-ai/ar_shared/src/components/Modal'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Header } from './Header'
import { Modal } from './Modal'

export interface ModalCenterProps {
  title?: string | React.ReactNode
  width?: string | number
  height?: string | number
  smWidth?: string | number
  smHeight?: string | number
  avoidKeyboard?: boolean
  isModalVisible: boolean
  onClose?: () => void
  children: React.ReactNode
  headerLeft?: () => React.ReactNode
  headerRight?: () => React.ReactNode
  minHeight?: number
  contentContainerStyle?: ModalProps['contentContainerStyle']
}

export default function ModalCenter({
  title,
  width: modalWidth = '50%',
  height = undefined,
  smWidth,
  smHeight,
  avoidKeyboard,
  isModalVisible,
  onClose,
  headerLeft,
  headerRight,
  children,
  minHeight = 300,
  contentContainerStyle,
}: ModalCenterProps) {
  const { width, sm } = useResponsive()

  return (
    <Modal
      style={[
        width < sm && {
          margin: 0,
        },
      ]}
      isVisible={isModalVisible}
      onBackdropPress={onClose}
      avoidKeyboard={avoidKeyboard}
      contentContainerStyle={contentContainerStyle}
    >
      <View
        style={[
          {
            backgroundColor: 'white',
            alignSelf: 'center',
          },
          width < sm
            ? {
                width: smWidth ?? '100%',
                height: smHeight ?? '100%',
                borderRadius:
                  smWidth !== undefined || smHeight !== undefined
                    ? 8
                    : undefined,
              }
            : {
                width: modalWidth,
                borderRadius: 8,
                minHeight,
                overflow: 'hidden',
              },
          width >= sm && height != null && { height },
        ]}
      >
        {title !== undefined || title !== '' ? (
          <Header
            title={title}
            onClose={() => onClose?.()}
            headerLeft={headerLeft}
            headerRight={headerRight}
          />
        ) : null}
        {children}
      </View>
    </Modal>
  )
}
