import { times } from 'lodash'

export const SCROLL_VIEW_PADDING_Y = 16
export const SCROLL_VIEW_PC_PADDING_X = 16
export const PADDING_HORIZONTAL = 11
export const CELL_HEIGHT = 24
export const SEAT_PC_WIDTH = 102
export const SEAT_SP_WIDTH = 90
export const SEAT_PC_HEIGHT = 53
export const SEAT_SP_HEIGHT = 58
export const PARTY_SIZE_WIDTH = 55
export const HOUR_PC_WIDTH = 131
export const HOUR_SP_WIDTH = 112
export const BORDER_WIDTH = 1
export const REFRESH_INTERVAL = 10000
export const CELL_PADDING_X = 3
export const CELL_PADDING_Y = 3
// in hours
export const MIN_DURATION = 0.25
export const MAX_DURATION = 23
export const SEAT_DURATION_INTERVAL = 0.25

/**
 * デフォルトで表示するリクエスト予約機能数
 */
export const DEFAULT_SHOW_REQUEST_RESERVATION_SIZE = 4

export const VERTICAL_DASH_LINE_IMAGE_URI =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAZAAQMAAABpOAE6AAAABlBMVEUAAAAAAAClZ7nPAAAAAnRSTlMVAEEmKywAAAAmSURBVEjH7cmhAQAACMMw/r+K0zBg0JNpZerq98Y555xzzjnnKR/oOlgf2CQXnAAAAABJRU5ErkJggg=='

export const HOURS = times(30, (i) => {
  return i
})
