import invariant from 'tiny-invariant'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { TableSeatChargePolicyResource_ChargeUnit } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_resource'
import {
  GetRequest,
  ListRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_service'
import { TableSeatChargePolicyClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat_charge_policy/table_seat_charge_policy_service.client'

import { assertNever } from 'modules/assert'
import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { swrKey } from 'modules/swr'

import { useToken } from './Auth'

export const tableSeatChargePolicyService = createRpcService(
  TableSeatChargePolicyClient
)
export const tableSeatChargePolicyClient = tableSeatChargePolicyService.client

export function getChargeUnitName(
  value: TableSeatChargePolicyResource_ChargeUnit
) {
  switch (value) {
    case TableSeatChargePolicyResource_ChargeUnit.TABLE:
      return t('テーブルごと')
    case TableSeatChargePolicyResource_ChargeUnit.PERSON:
      return t('一人につき')
    default: {
      assertNever(value)
    }
  }
}

export function useTableSeatChargePolicies(restaurantId: number) {
  const token = useToken()
  const params: ListRequest = { restaurantId }

  const { data, mutate, error } = useSWR(
    swrKey(token, 'TableSeatChargePolicy/List'),
    () =>
      tableSeatChargePolicyClient.list(params, rpcOptions({ token })).response
  )

  return {
    tableSeatChargePolicies: data?.tableSeatChargePolicies,
    mutate,
    error,
  }
}

export function useTableSeatChargePolicy(params: GetRequest | undefined) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    params === undefined ? null : swrKey(token, 'TableSeatChargePolicy/Get'),
    () => {
      invariant(params !== undefined, 'params cannot be undefined')
      return tableSeatChargePolicyClient.get(params, rpcOptions({ token }))
        .response
    }
  )

  return {
    tableSeatChargePolicy: data,
    mutate,
    error,
  }
}
