import React, { useMemo } from 'react'

import { faArrowsAltH } from '@fortawesome/pro-solid-svg-icons/faArrowsAltH'
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck'
import { range } from 'lodash'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'
import { Gesture, GestureDetector } from 'react-native-gesture-handler'
import Animated, {
  runOnJS,
  useAnimatedStyle,
  useSharedValue,
  withSpring,
} from 'react-native-reanimated'
import { snapPoint } from 'react-native-redash'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantReservation as RestaurantReservationModel } from '@hello-ai/ar_shared/src/types/ForR/RestaurantReservation'

import {
  TableSeat as TableSeatModel,
  useTableSeats,
} from '../../../models/TableSeat'

import { MIN_DURATION, MAX_DURATION, SEAT_DURATION_INTERVAL } from './const'
import {
  getCellHeight,
  getCellStyle,
  getCellWidth,
  getSelectSeatState,
  getSelectSeatStyle,
  getUnselectableTableSeatIds,
} from './utils'

import type { ChartMode, SelectParams } from './types'

type Props = {
  mode: ChartMode
  selectParams: SelectParams
  tableSeats: ReturnType<typeof useTableSeats>['tableSeats']
  restaurantReservations: RestaurantReservationModel[]
  date: dayjs.Dayjs
  otherReservations: RestaurantReservationModel[]
}
export function SelectSeatList({
  mode,
  selectParams,
  tableSeats,
  restaurantReservations,
  date,
  otherReservations,
}: Props) {
  const {
    startTime,
    endTime,
    setEndTime,
    partySize,
    selectedSeatIds,
    onChangeSelectedSeatIds,
    restaurantReservation,
  } = selectParams

  const { width, sm } = useResponsive()
  const hours = (endTime - startTime) / 3600
  const offsetX = useSharedValue(getCellWidth(hours, width < sm))

  const unselectableTableSeatIds = useMemo(() => {
    return getUnselectableTableSeatIds({
      mode,
      date,
      startTime,
      endTime,
      restaurantReservations,
      restaurantReservation,
    })
  }, [
    date,
    endTime,
    mode,
    restaurantReservations,
    startTime,
    restaurantReservation,
  ])

  const onChangeDuration = (duration: number) => {
    const endTime = startTime + duration * 3600
    setEndTime(endTime)

    const unselectableTableSeatIds = getUnselectableTableSeatIds({
      mode,
      date,
      startTime,
      endTime,
      restaurantReservations,
      restaurantReservation,
    })

    onChangeSelectedSeatIds(
      selectedSeatIds?.filter(
        (seatId) => !unselectableTableSeatIds.includes(seatId)
      ) ?? []
    )
  }

  const toggleSelectSeat = (tableSeatId: TableSeatModel['id']) => {
    onChangeSelectedSeatIds(
      selectedSeatIds?.includes(tableSeatId)
        ? selectedSeatIds?.filter((seatId) => seatId !== tableSeatId)
        : [...(selectedSeatIds ?? []), tableSeatId]
    )
  }

  const animatedStyle = useAnimatedStyle(() => {
    return {
      width: offsetX.value,
    }
  })

  const reservedSeatIds = restaurantReservations.flatMap((i) =>
    i.table_seats.flatMap((t) => t.id)
  )

  return (
    <>
      {tableSeats.map((tableSeat, index) => {
        const reservedSeat = reservedSeatIds.includes(tableSeat.id)
        const selected = selectedSeatIds?.includes(tableSeat.id) ?? false
        return (
          <SelectSeat
            key={tableSeat.id}
            mode={mode}
            offsetX={offsetX}
            index={otherReservations.length + index}
            startTime={startTime}
            // endTime={endTime}
            onChangeDuration={onChangeDuration}
            selected={selected}
            unselectable={unselectableTableSeatIds.includes(tableSeat.id)}
            tableSeat={tableSeat}
            partySize={partySize}
            onPress={() => toggleSelectSeat(tableSeat.id)}
            style={[
              animatedStyle,
              reservedSeat && !selected && { opacity: 0.5 },
            ]}
            hiddenSelectedIcon={reservedSeat && !selected}
            isSp={width < sm}
          />
        )
      })}
    </>
  )
}

const SelectSeat = React.memo(
  ({
    mode,
    index,
    offsetX,
    startTime,
    onChangeDuration,
    selected,
    hiddenSelectedIcon,
    unselectable,
    tableSeat,
    partySize,
    onPress,
    style,
    isSp = false,
  }: {
    mode: ChartMode
    index: number
    offsetX: Animated.SharedValue<number>
    startTime: number
    // endTime: number
    onChangeDuration: (value: number) => void
    selected: boolean
    hiddenSelectedIcon: boolean
    unselectable: boolean
    tableSeat: ReturnType<typeof useTableSeats>['tableSeats'][number]
    partySize: number
    onPress: () => void
    style?: StyleProp<Animated.AnimateStyle<StyleProp<ViewStyle>>>
    isSp?: boolean
  }) => {
    const x = startTime / 3600
    const y = index
    const startX = useSharedValue(0)

    const snapPoints = range(
      MIN_DURATION,
      MAX_DURATION + SEAT_DURATION_INTERVAL,
      SEAT_DURATION_INTERVAL
    ).map((hour) => getCellWidth(hour, isSp))

    // 30分から24時間まで
    const panGesture = Gesture.Pan()
      .onBegin(() => {
        // TODO: react-compiler for react-native-reanimated
        // eslint-disable-next-line react-compiler/react-compiler
        startX.value = offsetX.value
      })
      .onChange(({ translationX }) => {
        offsetX.value = startX.value + translationX
      })
      .onEnd(({ translationX, velocityX }) => {
        const point = snapPoint(
          startX.value + translationX,
          velocityX,
          snapPoints
        )
        offsetX.value = withSpring(point)
        const duration =
          snapPoints.indexOf(point) * SEAT_DURATION_INTERVAL + MIN_DURATION
        runOnJS(onChangeDuration)(duration)
      })

    const state = getSelectSeatState({
      selected,
      unselectable,
      partySize,
      minPartySize: tableSeat.min_party_size,
      maxPartySize: tableSeat.max_party_size,
    })

    return (
      <Animated.View
        style={[
          {
            position: 'absolute',
            height: getCellHeight(isSp),
            zIndex: 20,
          },
          mode === 'selectBlock' && {
            backgroundColor: Colors.accent40,
          },
          getCellStyle({ x, y, isSp }),
          style,
        ]}
      >
        <TouchableOpacity
          onPress={onPress}
          disabled={state === 'unselectable'}
          style={[
            {
              flex: 1,
              borderWidth: 4,
              borderRadius: 4,
              paddingVertical: 4,
              paddingHorizontal: 8,
              flexDirection: 'row',
              alignItems: 'center',
            },

            getSelectSeatStyle(state),
          ]}
        >
          {selected && !hiddenSelectedIcon && (
            <FontAwesomeIcon icon={faCheck} size={24} color="white" />
          )}
        </TouchableOpacity>
        {mode !== 'seatChange' && (
          <GestureDetector gesture={panGesture}>
            <Animated.View
              style={{
                position: 'absolute',
                top: 32 / 2,
                right: -32 / 2,
                width: 32,
                height: 32,
                backgroundColor: 'white',
                borderRadius: 32 / 2,
                borderWidth: 2,
                borderColor: Colors.accent,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon
                icon={faArrowsAltH}
                size={20}
                color={Colors.accent}
              />
            </Animated.View>
          </GestureDetector>
        )}
      </Animated.View>
    )
  }
)
