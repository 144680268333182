import React from 'react'

import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer'
import QRCode from 'qrcode'

import { TableSeatResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'

import NotoSansJPBold from 'assets/fonts/NotoSansJP-Bold.ttf'
import NotoSansJPRegular from 'assets/fonts/NotoSansJP-Regular.ttf'

Font.register({ family: 'NotoSansJPRegular', src: NotoSansJPRegular })
Font.register({ family: 'NotoSansJPBold', src: NotoSansJPBold })

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
  },
  tableSeats: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  tableSeat: {
    width: 108,
    borderColor: '#DDDDDD',
    borderWidth: 3,
    borderStyle: 'solid',
    marginLeft: 6,
    marginTop: 6,
  },
  tableSeatCode: {},
  text: {
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'NotoSansJPBold',
    marginBottom: 6,
  },
})

function TableSeatCode({ tableSeat }: { tableSeat: TableSeatResource }) {
  const canvas = document.createElement('canvas')
  QRCode.toCanvas(
    canvas,
    `https://autoreserve.com/table/authenticate?token=${tableSeat.token}`
  )
  const qr = canvas.toDataURL()
  return <Image src={qr} style={styles.tableSeatCode} />
}

function TableSeatQrDocument({
  tableSeats,
}: {
  tableSeats: TableSeatResource[]
}) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.tableSeats}>
          {tableSeats.map((tableSeat, index) => {
            return (
              <View
                style={styles.tableSeat}
                key={tableSeat.id}
                break={index !== 0 && index % 20 === 0}
              >
                <TableSeatCode tableSeat={tableSeat} />
                <Text style={styles.text}>{tableSeat.name}</Text>
              </View>
            )
          })}
        </View>
      </Page>
    </Document>
  )
}

export default TableSeatQrDocument
