import React, { forwardRef, useImperativeHandle, useState } from 'react'

import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { View, StyleProp, TextStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { GourmetSiteProviderLabel } from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/GourmetSiteProvider'
import SectionTitle from '@hello-ai/ar_shared/src/components/ForR/SiteController/Shared/SectionTitle'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import {
  GOURMET_SITE_PROVIDER_SOURCE,
  GourmetSiteProvider,
} from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import { ParsedCourse } from '@hello-ai/ar_shared/src/types/ForR/SiteControllerCourse'

import { useSiteControllerParsedCourses } from '../../models/SiteControllerCourseSetting'
import { useActiveOutboundSettings } from '../../models/SiteControllerOutboundSettings'

const STATUS_LABELS: Record<ParsedCourse['sync_status'], string> = {
  complete: t('販売中'),
  no_exist: t('エラー'),
  error: t('エラー'),
}
const STATUS_STYLES: Record<
  ParsedCourse['sync_status'],
  StyleProp<TextStyle>
> = {
  complete: {
    color: Colors.success,
    backgroundColor: Colors.accentBg,
  },
  no_exist: {
    color: Colors.caution,
    backgroundColor: Colors.cautionBg,
  },
  error: {
    color: Colors.caution,
    backgroundColor: Colors.cautionBg,
  },
}

interface ParsedCourseListViewProps {
  restaurantId: number
}

export interface ParsedCourseListViewRef {
  refresh: () => void
}

export default forwardRef(function ParsedCourseListView(
  { restaurantId }: ParsedCourseListViewProps,
  ref: React.Ref<ParsedCourseListViewRef>
) {
  const { activeOutboundSettings } = useActiveOutboundSettings(restaurantId)
  const [selectedSite, setSelectedSite] = useFormState<GourmetSiteProvider>(
    () => {
      return activeOutboundSettings?.[0]?.site ?? 'tabelog'
    }
  )
  const [searchText, setSearchText] = useState('')

  const {
    data: parsedCourses,
    pagination,
    mutate,
  } = useSiteControllerParsedCourses(
    restaurantId,
    selectedSite,
    searchText !== '' ? { name: searchText } : undefined
  )

  useImperativeHandle(
    ref,
    () => ({
      refresh: mutate,
    }),
    [mutate]
  )

  return (
    <View>
      <SectionTitle
        title={t('他社グルメサイトのコース一覧')}
        helperText={t(
          '他社グルメサイトで設定されているコース情報です。他社グルメサイト経由で予約された場合、予約時にそのコース名が表示されます。'
        )}
      />
      <View
        style={{
          paddingHorizontal: 16,
          backgroundColor: Colors.white,
          borderRadius: 8,
        }}
      >
        <View style={{ flexDirection: 'row', flexGrow: 1 }}>
          {activeOutboundSettings?.map((setting) => (
            <TouchableOpacity
              key={setting.site}
              style={[
                {
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 210,
                  height: 60,
                  borderBottomColor:
                    selectedSite === setting.site ? Colors.primary : 'none',
                  borderBottomWidth: selectedSite === setting.site ? 2 : 0,
                },
              ]}
              onPress={() => {
                setSelectedSite(setting.site)
              }}
            >
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 27,
                  color: Colors.black60,
                }}
              >
                {GOURMET_SITE_PROVIDER_SOURCE[setting.site].label}
              </Text>
            </TouchableOpacity>
          ))}
        </View>
        <View style={{ position: 'relative', flexGrow: 1, marginVertical: 20 }}>
          <TextInput
            style={{
              backgroundColor: Colors.white,
              borderWidth: 1,
              borderColor: Colors.border,
              borderRadius: 8,
              width: '100%',
              height: 48,
              paddingLeft: 48,
            }}
            value={searchText}
            onChangeText={setSearchText}
          />
          <FontAwesomeIcon
            size={24}
            icon={faSearch}
            color={Colors.black60}
            style={{ position: 'absolute', left: 16, top: 12 }}
          />
        </View>
        <View
          style={{ flexDirection: 'row', paddingVertical: 16, columnGap: 16 }}
        >
          <Text style={{ fontSize: 14, fontWeight: '600', flex: 1 }}>
            {t('コース名')}
          </Text>
          <Text style={{ fontSize: 14, fontWeight: '600', width: 90 }}>
            {t('ステータス')}
          </Text>
          <Text style={{ fontSize: 14, fontWeight: '600', width: 80 }}>
            {t('料金')}
          </Text>
          <Text style={{ fontSize: 14, fontWeight: '600', width: 100 }}>
            {t('利用可能人数')}
          </Text>
          <Text style={{ fontSize: 14, fontWeight: '600', width: 130 }}>
            {t('滞在時間')}
          </Text>
        </View>
        {parsedCourses?.map((parsedCourse) => (
          <View
            key={parsedCourse.id}
            style={{
              rowGap: 16,
              paddingVertical: 16,
              borderTopWidth: 0.5,
              borderColor: Colors.border,
            }}
          >
            <View style={{ flexDirection: 'row', columnGap: 16 }}>
              <View style={{ flex: 1 }}>
                <View style={{ flexDirection: 'row', columnGap: 4 }}>
                  <GourmetSiteProviderLabel provider={parsedCourse.site} />
                </View>
                <Text style={{ fontSize: 14, fontWeight: '600' }}>
                  {parsedCourse.name}
                </Text>
              </View>
              <View style={{ width: 90 }}>
                <Text
                  style={[
                    STATUS_STYLES[parsedCourse.sync_status],
                    {
                      fontSize: 14,
                      fontWeight: '600',
                      lineHeight: 22,
                      alignSelf: 'flex-start',
                      padding: 8,
                    },
                  ]}
                >
                  {STATUS_LABELS[parsedCourse.sync_status]}
                </Text>
              </View>
              <Text style={{ fontSize: 16, fontWeight: '300', width: 80 }}>
                {parsedCourse.delimited_price}
              </Text>
              <Text style={{ fontSize: 16, fontWeight: '300', width: 100 }}>
                {t('{{max_party_size}}〜{{min_party_size}}名', parsedCourse)}
              </Text>
              <Text style={{ fontSize: 16, fontWeight: '300', width: 130 }}>
                {parsedCourse.staying_time}
              </Text>
            </View>
            <Text
              numberOfLines={1}
              ellipsizeMode="tail"
              style={{
                padding: 16,
                fontSize: 14,
                fontWeight: '300',
                backgroundColor: Colors.bgBlack,
                borderRadius: 4,
              }}
            >
              {parsedCourse.description}
            </Text>
          </View>
        ))}
        <View style={{ paddingVertical: 16 }}>
          <Pagination
            currentPage={pagination.currentPage ?? 1}
            totalPage={pagination.totalPages ?? 1}
            setPage={pagination.setPage}
          />
        </View>
      </View>
    </View>
  )
})
