import { useToken } from '@hello-ai/ar_shared/src/modules/auth'

import axios, { setHeader, wrapResponse } from '../modules/axios'
import useSWR, { fetcher, swrKey } from '../modules/swr'

export interface Attribute {
  id: string
  display_in_list: boolean
  label_color: LabelColor
  name: string
}

export type LabelColor = 'gray' | 'red' | 'orange' | 'blue' | 'green'

export const useRestaurantAttributes = (restaurantId?: number) => {
  const token = useToken()

  const { data, error, mutate } = useSWR<
    Attribute[],
    any,
    ReturnType<typeof swrKey> | null
  >(
    restaurantId === undefined
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/customer_custom_attributes`
        ),
    fetcher
  )

  return {
    data,
    error,
    mutate,
  }
}

export interface UpdateAttributesParams {
  restaurant: {
    customer_custom_attributes_attributes: {
      id?: string
      name: string
      display_in_list: boolean
      label_color: LabelColor
      _destroy?: boolean
    }[]
  }
}

export const saveRestaurantAttributes = (
  token: string | null,
  restaurantId: number,
  params: UpdateAttributesParams
) => {
  setHeader({ token })
  return wrapResponse(
    axios.put(`/restaurants/${restaurantId}/customer_custom_attributes`, params)
  )
}
