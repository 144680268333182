import React from 'react'

import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons/faExclamationTriangle'
import { View } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'

import ModalCenter from '../../Shared/ModalCenter'

interface PaymentFailedModalProps {
  failedAmount: number
  onClose: () => void
  isModalVisible: boolean
}

export default function PaymentFailedModal({
  failedAmount,
  onClose,
  isModalVisible,
}: PaymentFailedModalProps) {
  return (
    <>
      <View
        style={{ flex: 1, width: '100%', backgroundColor: Colors.bgLightBlack }}
      />
      <ModalCenter
        isModalVisible={isModalVisible}
        width={'98%'}
        height={'98%'}
        title={t('スマート決済で会計する')}
        onClose={onClose}
      >
        <View style={{ marginTop: 102, alignItems: 'center' }}>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            size={32}
            color={Colors.caution}
            style={{ marginBottom: 20 }}
          />
          <Text
            style={{
              color: Colors.caution,
              width: '100%',
              fontSize: 22,
              fontWeight: '600',
              lineHeight: 33,
              textAlign: 'center',
            }}
          >
            {t(
              'エラーによりスマート決済に失敗しました。\n決済はまだ実行されていません。'
            )}
          </Text>
          <Text
            style={{
              width: '100%',
              color: Colors.caution,
              fontSize: 16,
              fontWeight: '300',
              lineHeight: 24,
              textAlign: 'center',
              marginVertical: 40,
            }}
          >
            {t(
              'お手数おかけいたしますが、店舗にてこちらの金額で\n直接決済を実行してください。'
            )}
          </Text>
          <Text
            style={{
              width: '100%',
              fontSize: 40,
              fontWeight: '400',
              lineHeight: 40,
              textAlign: 'center',
            }}
          >
            {t('合計金額 ¥{{amount}}', {
              amount: numberWithDelimiter(failedAmount),
            })}
          </Text>
        </View>
      </ModalCenter>
    </>
  )
}
