import React from 'react'

import { View } from 'react-native'

import { OutboundSettingList } from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/OutboundSettingList'
import OutboundSettingTitle from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/OutboundSettingTitle'
import UnContractedView from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/UnContractedView'
import { useSupportedOutboundSettings } from '@hello-ai/for_r_app/src/models/SiteControllerOutboundSettings'

import { useImportMailSettings } from '../../models/ImportMailSettings'

function ContractedView({
  restaurantId,
  onPressItem,
}: {
  restaurantId: number
  onPressItem: (source: string) => void
}) {
  const { supportedOutboundSettings } =
    useSupportedOutboundSettings(restaurantId)

  return (
    <View>
      <OutboundSettingTitle />
      <OutboundSettingList
        outboundSettings={supportedOutboundSettings}
        onPressItem={onPressItem}
      />
    </View>
  )
}

interface GourmetSiteAccountSettingScreenProps {
  restaurantId: number
  onPressItem: (source: string) => void
}

export default function GourmetSiteAccountSettingView({
  restaurantId,
  onPressItem,
}: GourmetSiteAccountSettingScreenProps) {
  const { importMailSettingsData } = useImportMailSettings(restaurantId)

  if (importMailSettingsData == null) return null

  return (
    <View
      style={{
        marginTop: 40,
        marginHorizontal: 56,
      }}
    >
      {importMailSettingsData.site_controller_enabled ? (
        <ContractedView restaurantId={restaurantId} onPressItem={onPressItem} />
      ) : (
        <UnContractedView />
      )}
    </View>
  )
}
