import React from 'react'

import { useParams } from 'react-router'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ChangeRequestReservation } from '@hello-ai/for_r_app/src/components/Reservation/ChangeRequestReservation'
import { useRestaurantCourse } from '@hello-ai/for_r_app/src/models/RestaurantCourse'
import { useRestaurantReservation } from '@hello-ai/for_r_app/src/models/RestaurantReservation'

import { useRestaurantId } from 'modules/useRestaurantId'

import { Redirect } from 'App'

export default function ChangeRequestReservationPage() {
  const restaurantId = useRestaurantId()
  const { restaurantReservationId } = useParams<{
    restaurantReservationId: string
  }>()
  const { restaurantReservation } = useRestaurantReservation(
    restaurantId,
    restaurantReservationId
  )
  const { restaurantCourse } = useRestaurantCourse(
    restaurantId,
    restaurantReservation?.restaurant_course?.id
  )

  if (restaurantReservation == null) return <Loading />
  if (restaurantReservation.reservation_change_request == null) {
    return <Redirect to={`/restaurants/${restaurantId}/reservations`} />
  }

  const data = {
    id: restaurantReservation.id,
    reservation_change_request:
      restaurantReservation.reservation_change_request,
    restaurant_course: restaurantCourse,
  }

  return (
    <ChangeRequestReservation
      restaurantId={restaurantId}
      data={data}
      customer={restaurantReservation.customers[0]}
      restaurantReservation={restaurantReservation}
    />
  )
}
