import React from 'react'

import { faPlus } from '@fortawesome/pro-solid-svg-icons/faPlus'
import { StyleProp, TextStyle, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

export function AddFloatingButton({
  title,
  onPress,
}: {
  title: string
  onPress: () => void
}) {
  const { width, sm } = useResponsive()
  return (
    <AddButton
      title={title}
      style={{
        position: 'absolute',
        bottom: 32,
        right: 16,
        width: width >= sm ? 96 : 60,
        height: width >= sm ? 96 : 60,
        borderRadius: width >= sm ? 48 : 30,
        shadowOffset: {
          width: 0,
          height: 3,
        },
        shadowRadius: 5,
        elevation: 4,
      }}
      iconSize={width >= sm ? 30 : 24}
      textStyle={{
        fontSize: width >= sm ? 14 : 12,
        lineHeight: 18,
        paddingTop: 2,
        paddingHorizontal: 12,
        textAlign: 'center',
      }}
      onPress={onPress}
    />
  )
}

export function AddButton({
  title = t('追加'),
  style,
  iconSize = 20,
  textStyle,
  onPress,
}: {
  title?: string
  style: StyleProp<ViewStyle>
  iconSize?: number
  textStyle?: StyleProp<TextStyle>
  onPress: () => void
}) {
  return (
    <TouchableOpacity
      style={[
        {
          backgroundColor: Colors.primary,
          borderRadius: 30,
          width: 60,
          height: 60,
          justifyContent: 'center',
          alignItems: 'center',
          shadowRadius: 10,
          shadowColor: Colors.darkOverlay,
          shadowOpacity: 0.2,
          shadowOffset: { width: 0, height: 2 },
        },
        style,
      ]}
      onPress={onPress}
    >
      <FontAwesomeIcon icon={faPlus} size={iconSize} color={Colors.white} />
      <Text
        style={[
          {
            fontSize: 12,
            fontWeight: '600',
            color: Colors.white,
          },
          textStyle,
        ]}
      >
        {title}
      </Text>
    </TouchableOpacity>
  )
}
