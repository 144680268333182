import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { DrawerHeaderToggle } from 'components/Shared/Navigation/Drawer'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import CrewMembers from 'pages/Restaurants/CrewMembers'
import CrewMembersEdit from 'pages/Restaurants/CrewMembers/Edit'

const routes: StackRoutes = {
  Index: {
    title: t('担当者'),
    fullPath: `/restaurants/:id/crew_members`,
    relativePath: `/`,
    exact: true,
    element: <CrewMembers />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
  Edit: {
    title: t('担当者編集'),
    fullPath: `/restaurants/:id/crew_members/edit`,
    relativePath: `/edit`,
    exact: true,
    element: <CrewMembersEdit />,
    screenOptions: {
      headerLeft: <DrawerHeaderToggle />,
    },
  },
}

export default function CrewMemberStack() {
  return <Stack routes={routes} />
}
