import React from 'react'

import { FlatList, View, ScrollView } from 'react-native'
import { Button } from 'react-native-paper'

import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import TakeoutView from 'components/Restaurant/TakeoutView'
import {
  TakeoutMenuPage as TakeoutMenuPageModel,
  useTakeoutMenuPages,
} from 'models/TakeoutMenuPage'
import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

function TakeoutMenuPage({
  takeoutMenuPage,
}: {
  takeoutMenuPage: TakeoutMenuPageModel
}) {
  const restaurantId = useRestaurantId()
  return (
    <View>
      <TouchableOpacity
        onPress={() =>
          history.push(
            `/restaurants/${restaurantId}/takeout/menu_pages/${takeoutMenuPage.id}/edit`
          )
        }
        style={{
          padding: 24,
        }}
      >
        <Text style={{ fontWeight: '600' }}>{takeoutMenuPage.name}</Text>
        {takeoutMenuPage.takeout_menus &&
          takeoutMenuPage.takeout_menus.length > 0 && (
            <View
              style={{
                marginTop: 8,
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              {takeoutMenuPage.takeout_menus.map((takeoutMenu, index) => {
                return (
                  <Text
                    key={takeoutMenu.id}
                    style={{ marginLeft: index === 0 ? 0 : 4 }}
                  >
                    {takeoutMenu.name}
                  </Text>
                )
              })}
            </View>
          )}
      </TouchableOpacity>
    </View>
  )
}

export default function MenusScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const { takeoutMenuPages } = useTakeoutMenuPages({
    restaurantId,
  })

  return (
    <TakeoutView>
      <ScrollView
        style={{ backgroundColor: Colors.bgBlack }}
        contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
      >
        <View style={{ flexDirection: 'row', marginLeft: -20 }}>
          <Button
            mode="outlined"
            style={{
              marginLeft: 20,
              backgroundColor: 'white',
              flex: 1,
              borderRadius: 24,
            }}
            contentStyle={{ height: 44 }}
            onPress={() => {
              history.push(
                `/restaurants/${restaurantId}/takeout/menu_pages/new`
              )
            }}
          >
            {t('カテゴリーを追加')}
          </Button>
        </View>
        <View style={{ marginTop: 40 }}>
          <ShadowBox
            style={[
              {
                padding: 0,
              },
            ]}
          >
            <FlatList
              data={takeoutMenuPages}
              keyExtractor={(item) => item.id}
              renderItem={({ item: takeoutMenuPage, index }) => {
                return (
                  <View
                    key={takeoutMenuPage.id}
                    style={{
                      borderTopWidth: index === 0 ? 0 : 0.5,
                      borderColor: Colors.border,
                    }}
                  >
                    <TakeoutMenuPage takeoutMenuPage={takeoutMenuPage} />
                  </View>
                )
              }}
              ListEmptyComponent={
                takeoutMenuPages != null ? ( // ロード中もListEmptyComponentの表示になるので隠す
                  <Text
                    style={{
                      paddingVertical: 20,
                      paddingHorizontal: 24,
                      color: Colors.disabledBlack,
                      fontWeight: '600',
                      textAlign: 'center',
                    }}
                  >
                    {t('カテゴリがありません')}
                  </Text>
                ) : null
              }
            />
          </ShadowBox>
        </View>
      </ScrollView>
    </TakeoutView>
  )
}
