import React, { useCallback, useMemo, useState } from 'react'

import { View, FlatList, Platform } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Customer } from '@hello-ai/for_r_app/src/components/Customers/Customer'
import { displayToastSuccess } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { useCustomerSearch } from '@hello-ai/for_r_app/src/models/Customer'
import { useRestaurantAttributes } from '@hello-ai/for_r_app/src/models/CustomerAttributes'
import {
  isAvailableShowVisitCount,
  useRestaurant,
} from '@hello-ai/for_r_app/src/models/Restaurant'

import Loading from '../../components/Shared/Loading'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'

import { CustomerSearchForm, SearchHandler } from './CustomerSearchForm'
import { PlanNotice } from './PlanNotice'

export function Customers({
  restaurantId,
  customerId,
}: {
  restaurantId: number
  customerId?: string
}) {
  const { width, sm } = useResponsive()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { data: restaurant } = useRestaurant(restaurantId)
  const isFreePlan =
    restaurant == null ||
    restaurant.reservation_book_plan_type === 'free' ||
    restaurant.reservation_book_plan_type === 'entry'
  // const [isSort, setIsSort] = useState(0)

  const { data } = useRestaurantAttributes(
    isFreePlan || !restaurant.reservation_book_available
      ? undefined
      : restaurantId
  )
  const attributes = useMemo(
    () => data?.filter((e) => e.display_in_list) ?? [],
    [data]
  )

  const [currentPage, setCurrentPage] = useState(1)

  const {
    setSearchParams,
    mutate: mutateSearch,
    customers,
    headerData,
  } = useCustomerSearch(restaurantId, currentPage)

  const searchCustomer = useCallback<SearchHandler>(
    ({
      phoneNumber,
      dialCode,
      lastName,
      lastNameKana,
      firstName,
      firstNameKana,
    }) => {
      setSearchParams({
        phoneNumber,
        dialCode,
        reservationLastName: lastNameKana,
        reservationFirstName: firstNameKana,
        lastName,
        firstName,
      })
      setCurrentPage(1)
    },
    [setSearchParams, setCurrentPage]
  )

  const handleCustomerAdded = useCallback(() => {
    mutateSearch()
    displayToastSuccess(t('新規顧客を登録しました'))
  }, [mutateSearch])

  return (
    <KeyboardAwareScrollView
      style={{ flex: 1, backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: width < sm ? 24 : 32,
      }}
    >
      {isFreePlan && <PlanNotice restaurant={restaurant} />}
      <CustomerSearchForm
        restaurantId={restaurantId}
        onSearch={searchCustomer}
        onCustomerAdded={handleCustomerAdded}
      />

      {customers == null ? (
        <View style={{ paddingVertical: 24 }}>
          <Loading />
        </View>
      ) : (
        <View
          style={[
            {
              borderRadius: 8,
              shadowRadius: 4,
              elevation: 4,
              shadowColor: '#000',
              shadowOpacity: 0.1,
              shadowOffset: { width: 0, height: 2 },
              backgroundColor: 'white',
              padding: 0,
              marginTop: width >= sm ? 16 : 24,
            },
          ]}
        >
          <FlatList
            data={
              customerId != null
                ? customers.filter((i) => i.id !== customerId)
                : customers
            }
            keyExtractor={(item) => item.id}
            renderItem={({ item: customer }) => {
              return (
                <View
                  key={customer.id}
                  style={{
                    [width >= sm ? 'marginHorizontal' : 'paddingHorizontal']:
                      width >= sm ? 24 : 16,
                    borderBottomWidth: 0.5,
                    borderBottomColor: Colors.border,
                  }}
                >
                  <Customer
                    customer={customer}
                    restaurant={restaurant}
                    onPress={() => {
                      if (Platform.OS === 'web') {
                        navigate(`./${customer.id}`)
                      } else {
                        navigation.navigate('Customer', {
                          customerId: customer.id,
                        })
                      }
                    }}
                    attributes={attributes}
                  />
                </View>
              )
            }}
            ListHeaderComponent={
              <View
                style={{
                  flexDirection: 'row',
                  paddingVertical: 16,
                  [width >= sm ? 'marginHorizontal' : 'paddingHorizontal']:
                    width >= sm ? 24 : 16,
                  borderBottomWidth: 0.5,
                  borderColor: Colors.border,
                }}
              >
                <Text style={{ flex: 1, fontSize: 14, fontWeight: '600' }}>
                  {t('名前')}
                </Text>
                {isAvailableShowVisitCount(restaurant) && (
                  <Text
                    style={{
                      width: width >= sm ? 142 : 64,
                      fontSize: 14,
                      fontWeight: '600',
                    }}
                  >
                    {t('予約回数')}
                  </Text>
                )}
              </View>
            }
            ListEmptyComponent={
              <Text
                style={{
                  paddingVertical: 40,
                  paddingHorizontal: 16,
                  fontSize: 18,
                  lineHeight: 27,
                  fontWeight: '600',
                  color: Colors.disabledBlack,
                  textAlign: 'center',
                }}
              >
                {t('該当する顧客はいません')}
              </Text>
            }
            ListFooterComponent={
              <View
                style={{
                  paddingVertical: 16,
                }}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPage={headerData?.totalPages ?? 0}
                  setPage={(page) => setCurrentPage(page)}
                />
              </View>
            }
          />
        </View>
      )}
    </KeyboardAwareScrollView>
  )
}
