import React, { useRef } from 'react'

import composeRefs from '@seznam/compose-react-refs'
import { View } from 'react-native'
import { useHover } from 'react-native-web-hooks'

import Link from './Link'

type LinkOpacityProps = React.ComponentProps<typeof Link>

const LinkOpacity = React.forwardRef<View, LinkOpacityProps>(
  ({ to, children, style, ...props }: LinkOpacityProps, hostRef) => {
    const ref = useRef<View>(null)
    const isHovered = useHover(ref)
    return (
      <Link
        ref={composeRefs(ref, hostRef)}
        to={to}
        style={[
          {
            cursor: to == null ? 'auto' : 'pointer',
            textDecorationLine: 'none',
          },
          isHovered && {
            opacity: 0.7,
            textDecorationLine: 'none',
          },

          style,
        ]}
        {...props}
      >
        {children}
      </Link>
    )
  }
)

export default LinkOpacity
