import React from 'react'

import {
  StyleSheet,
  View,
  useWindowDimensions,
  StyleProp,
  ViewStyle,
} from 'react-native'
import RNModal, { ModalProps, Orientation } from 'react-native-modal'

import Loading from './Loading'
import { Toast } from './Toast'

const supportedOrientations: Orientation[] = [
  'portrait',
  'portrait-upside-down',
  'landscape',
  'landscape-left',
  'landscape-right',
]

export function Modal({
  children,
  style,
  contentContainerStyle,
  fetching,
  ...props
}: {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
  isVisible?: boolean
  fetching?: boolean
  contentContainerStyle?: StyleProp<ViewStyle>
} & Partial<ModalProps>) {
  const { width: windowWidth } = useWindowDimensions()
  // https://github.com/react-native-modal/react-native-modal/blob/2fd79d15af2d79cb794b9a4b385e945339b85afb/src/modal.tsx#L756
  // Toastの表示位置を補正するためModalのstyleに当たっているmarginもしくはデフォルトで当たっているmarginを知る必要がある
  const margin = StyleSheet.flatten(style)?.margin ?? windowWidth * 0.05

  return (
    <RNModal
      supportedOrientations={supportedOrientations}
      style={style}
      {...props}
    >
      <View style={[contentContainerStyle]}>{children}</View>
      <View
        pointerEvents="none"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          margin: -margin,
        }}
      >
        <Toast />
      </View>
      {fetching && (
        <View
          style={{
            margin: -margin,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
          }}
        >
          <Loading />
        </View>
      )}
    </RNModal>
  )
}
