import React from 'react'

import { faDiagramCells } from '@fortawesome/pro-regular-svg-icons/faDiagramCells'
import { faList } from '@fortawesome/pro-regular-svg-icons/faList'
import { View, TouchableOpacity } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { HeaderViewModeType } from './HeaderViewModeButton'

export function HeaderSegmentedControl({
  viewMode,
  onPressChart,
  onPressList,
}: {
  viewMode: HeaderViewModeType
  onPressChart: () => void
  onPressList: () => void
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          borderWidth: 1,
          borderColor: Colors.primary,
          borderRadius: 4,
          overflow: 'hidden',
          height: 24,
        },
      ]}
    >
      <TouchableOpacity
        style={[
          {
            width: 96,
            justifyContent: 'center',
            alignItems: 'center',
          },
          viewMode === 'chart' && {
            backgroundColor: Colors.primary,
          },
        ]}
        onPress={onPressChart}
      >
        <FontAwesomeIcon
          icon={faDiagramCells}
          color={viewMode === 'chart' ? Colors.white : Colors.primary}
          size={16}
        />
      </TouchableOpacity>
      <TouchableOpacity
        style={[
          {
            width: 96,
            justifyContent: 'center',
            alignItems: 'center',
          },
          viewMode === 'list' && {
            backgroundColor: Colors.primary,
          },
        ]}
        onPress={onPressList}
      >
        <FontAwesomeIcon
          icon={faList}
          color={viewMode === 'list' ? Colors.white : Colors.primary}
          size={16}
        />
      </TouchableOpacity>
    </View>
  )
}
