import React, { createContext, useContext, useMemo } from 'react'

import { Day } from '../../Restaurant/Reservation/ReservationCalendarList'

import type { CalendarDate } from './types'
import type { DayProps } from 'react-native-calendars/src/calendar/day'
import type { DateData } from 'react-native-calendars/src/types'

export const CalendarDatesContext = createContext<Record<string, CalendarDate>>(
  {}
)

export const DayComponent = React.memo(
  ({ date, onPress }: { date?: DateData; onPress?: DayProps['onPress'] }) => {
    const calendarDates = useContext(CalendarDatesContext)

    const { periods, isHoliday, businessTimes, hasRequestReservation } =
      React.useMemo(
        () =>
          date != null
            ? (calendarDates[date.dateString] ?? {
                periods: [],
                isHoliday: false,
                businessTimes: [],
                hasRequestReservation: false,
              })
            : {
                periods: [],
                isHoliday: false,
                businessTimes: [],
                hasRequestReservation: false,
              },
        [calendarDates, date]
      )

    const isClosed = useMemo(() => {
      if (businessTimes == null) return false
      if (businessTimes.length === 0) return true
      return businessTimes.every((b) => !b.open)
    }, [businessTimes])

    if (date == null) return null

    return (
      <Day
        date={date}
        periods={periods}
        isHoliday={isHoliday}
        isClosed={isClosed}
        hasRequestReservation={hasRequestReservation}
        onPress={onPress}
      />
    )
  }
)
