import {
  RpcError,
  RpcTransport,
  ServiceInfo,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc'
import useSWR, { SWRConfiguration, SWRResponse } from 'swr'

import { swrKey } from '../swr'
import { useToken, Token } from '../token'

import { callRpc, rpcOptions, rpcTransport, WrappedResponse } from './rpc'

type UnaryCallResponse<T> = T extends UnaryCall<any, infer R> ? R : never

export function createRpcService<
  T extends {
    [key: string]: any
  } & ServiceInfo,
  Keys extends keyof Omit<T, keyof ServiceInfo> & string,
>(
  Client: new (_transport: RpcTransport) => T
): { client: T } & {
  [K in Keys as `use${Capitalize<K>}`]: (
    params: Parameters<T[K]>[0] | undefined,
    config?: SWRConfiguration<Parameters<T[K]>[0], RpcError>
  ) => SWRResponse<UnaryCallResponse<ReturnType<T[K]>>, RpcError>
} & {
  [K in Keys]: (
    token: Token | null,
    params: Parameters<T[K]>[0]
  ) => WrappedResponse<UnaryCallResponse<ReturnType<T[K]>>>
} {
  const client = new Client(rpcTransport())

  const hooks = Object.fromEntries(
    client.methods.map((method) => {
      function useSWRRpc(params: any, config?: SWRConfiguration) {
        const token = useToken()
        return useSWR(
          params === undefined
            ? null
            : swrKey(token, `${client.typeName}/${method.name}`, params),
          () =>
            client[method.localName](params, rpcOptions({ token })).response,
          config
        )
      }

      return [`use${method.name}`, useSWRRpc]
    })
  ) as any

  const rpcMethods = Object.fromEntries(
    client.methods.map((method) => {
      function rpcCall(token: Token | null, params: any) {
        return callRpc(client[method.localName](params, rpcOptions({ token })))
      }
      return [method.localName, rpcCall]
    })
  )

  return {
    client,
    ...hooks,
    ...rpcMethods,
  }
}
