import React from 'react'

import { StyleProp, View, ViewStyle } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

interface BadgeCountProps {
  badgeCount: number
  style?: StyleProp<ViewStyle>
  badgeCountStyle?: StyleProp<ViewStyle>
}

export default function BadgeCount({
  badgeCount,
  style,
  badgeCountStyle,
}: BadgeCountProps) {
  const badgeCountForceMinWidth = 60
  return (
    <View
      style={[
        {
          position: 'absolute',
          top: -8,
          left: '100%',
          marginLeft: 2,

          // 最小幅を指定しないとbadgeのテキストが折り返されてしまうので
          // ボタン幅を超えない程度の適当な値を指定している
          minWidth: badgeCountForceMinWidth,
          alignItems: 'flex-start',
        },
        style,
      ]}
    >
      <View
        style={[
          {
            backgroundColor: Colors.caution,
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 8,
            paddingVertical: 1,
            paddingHorizontal: 4,
            minHeight: 16,
            minWidth: 16,
          },
          badgeCountStyle,
        ]}
      >
        <Text
          numberOfLines={1}
          style={{
            color: 'white',
            fontWeight: '600',
            fontSize: 12,
          }}
        >
          {badgeCount}
        </Text>
      </View>
    </View>
  )
}
