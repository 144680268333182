import React, { useLayoutEffect } from 'react'

import { ScrollView, StyleProp, View, ViewStyle } from 'react-native'
import { useNavigate, useParams } from 'react-router'
import invariant from 'tiny-invariant'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import {
  BusinessUserResource_RestaurantResource,
  BusinessUserResource_Role,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_resource'
import {
  UpdateBusinessUserRequest,
  UpdateBusinessUserRequest_Role,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user/business_user_service'
import { BusinessUserRolePermissionResource_Level } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_permission_resource'
import { BusinessUserRoleResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_resource'
import { BusinessUserRolePermission } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_service'

import { useNavigation } from 'components/Shared/Navigation/Screen'
import { PermittedRestaurantsFormGroup } from 'components/Top/BusinessUsers/Form'
import { useToken } from 'models/Auth'
import { businessUserClient, useBusinessUser } from 'models/BusinessUser'
import {
  businessFeatures,
  compareBusinessUserRolePermissionLevels as compareLevels,
  getBusinessUserRolePermission,
  useBusinessUserRole,
  useBusinessUserRoles,
} from 'models/BusinessUserRole'
import { useOwnerRestaurants } from 'models/Restaurant'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'

const formSectionMargin = 48
function FormSection({
  style,
  title,
  children,
}: {
  style?: StyleProp<ViewStyle>
  title: String
  children?: React.ReactNode
}) {
  return (
    <View style={style}>
      <Text
        style={{
          fontSize: 22,
          fontWeight: '600',
        }}
      >
        {title}
      </Text>
      <ShadowBox style={{ marginTop: 22 }}>{children}</ShadowBox>
    </View>
  )
}

const FORM_BUTTON_WITH = 278
const FORM_BUTTON_HEIGHT = 48

const formGroupMargin = 28
export default function BusinessUsersEdit() {
  const token = useToken()
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const { businessUserId } = useParams<{ businessUserId: string }>()

  const { businessUser, mutate } = useBusinessUser({ id: businessUserId! })

  const [name, setName] = useFormState(businessUser?.name ?? '')

  const [isAdmin, setIsAdmin] = useFormState(
    businessUser?.role === BusinessUserResource_Role.ADMIN
  )

  const [
    reservationEmailNotificationsEnabled,
    setReservationEmailNotificationsEnabled,
  ] = useFormState(businessUser?.reservationEmailNotificationsEnabled ?? true)

  const [businessUserRoleId, setBusinessUserRoleId] = useFormState<
    BusinessUserRoleResource['id'] | null
  >(businessUser?.businessUserRole?.id ?? null)
  const { businessUserRole } = useBusinessUserRole(
    businessUserRoleId === null ? undefined : { id: businessUserRoleId }
  )

  const [businessUserRolePermissions] = useFormState<
    BusinessUserRolePermission[]
  >(businessUserRole?.businessUserRolePermissions ?? [])

  const [permittedRestaurantIds, setPermittedRestaurantIds] = useFormState<
    Array<BusinessUserResource_RestaurantResource['id']>
  >(businessUser?.permittedRestaurants.map(({ id }) => id) ?? [])

  const { data } = useOwnerRestaurants()
  const { businessUserRoles } = useBusinessUserRoles()

  const titleName =
    businessUser?.name === undefined || businessUser.name === ''
      ? t('メンバー')
      : businessUser.name

  useLayoutEffect(() => {
    navigation.setOptions({
      title: t('{{name}}を編集', { name: titleName }),
    })
  }, [titleName, navigation])

  if (
    businessUser === undefined ||
    businessUserRoles === undefined ||
    data === undefined
  ) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.bgBlack,
        }}
      >
        <Loading />
      </View>
    )
  }

  const onChangeIsAdmin = (value: boolean) => {
    setIsAdmin(value)
    setBusinessUserRoleId(null)
  }

  const destroy = async () => {
    const { error } = await callRpc(
      businessUserClient.destroyBusinessUser(
        {
          id: businessUserId!,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }
    displayToastInfo(t('メンバーを削除しました'))
    navigate('/business_users')
  }

  const onAlertDestroy = () => {
    Alert.alert(t('本当に削除しますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => destroy(),
      },
    ])
  }

  const onUpdate = async () => {
    const role = isAdmin
      ? UpdateBusinessUserRequest_Role.ADMIN
      : UpdateBusinessUserRequest_Role.NORMAL

    const params: UpdateBusinessUserRequest = {
      id: businessUserId!,
      name,
      role,
      permittedRestaurantIds,
      reservationEmailNotificationsEnabled,
    }

    if (!isAdmin) {
      invariant(
        businessUserRoleId !== null,
        'businessUserRoleId cannot be null'
      )
      params.businessUserRoleId = { value: businessUserRoleId }
    }

    const { error } = await callRpc(
      businessUserClient.updateBusinessUser(params, rpcOptions({ token }))
    )
    if (error != null) {
      onError(error)
      return
    }
    displayToastInfo(t('メンバー情報を更新しました'))
    navigate('/business_users')
    mutate()
  }

  const businessUserRoleItems: Array<
    SelectItem<BusinessUserRoleResource['id'] | null>
  > = [
    {
      label: t('未選択'),
      value: null,
    },
    ...businessUserRoles.map(({ id, name }) => {
      return { label: name, value: id }
    }),
  ]

  return (
    <View style={{ flex: 1 }}>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          flexGrow: 1,
          padding: width < sm ? 24 : 48,
          backgroundColor: Colors.bgBlack,
        }}
      >
        <FormSection title={t('メンバー情報')}>
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('名前')} />}
          >
            <TextInput
              placeholder={t('名前')}
              onChangeText={setName}
              value={name}
            />
          </FormGroup>
          <FormGroup
            style={{
              marginTop: formGroupMargin,
            }}
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('メールアドレス')} />}
          >
            <Text>{businessUser.email}</Text>
          </FormGroup>
        </FormSection>
        <FormSection
          style={{ marginTop: formSectionMargin }}
          title={t('権限設定')}
        >
          <FormGroup
            style={{
              marginTop: 22,
            }}
            formLabel={<FormLabel value={t('管理者権限')} />}
          >
            <Switch value={isAdmin} onValueChange={onChangeIsAdmin} />
          </FormGroup>
          <FormGroup
            style={{
              marginTop: 28,
              marginBottom: 28,
            }}
            formLabel={<FormLabel value={t('役割')} required={!isAdmin} />}
          >
            <SelectInput
              disabled={isAdmin}
              style={{ width: '50%' }}
              selectedValue={businessUserRoleId}
              setValue={setBusinessUserRoleId}
              items={businessUserRoleItems}
            />
          </FormGroup>
          <View
            style={{
              flexDirection: 'row',
              flexWrap: 'wrap',
              borderTopColor: Colors.border,
              borderTopWidth: 0.5,
            }}
          >
            {businessFeatures.map(
              ([featureKey, { name: featureName, maxLevel }], index) => {
                return (
                  <View key={index} style={{ width: '50%' }}>
                    {[
                      {
                        name: t('なし'),
                        level: BusinessUserRolePermissionResource_Level.NONE,
                      },
                      {
                        name: t('閲覧'),
                        level: BusinessUserRolePermissionResource_Level.READ,
                      },
                      {
                        name: t('閲覧・編集'),
                        level: BusinessUserRolePermissionResource_Level.ALL,
                      },
                    ]
                      .filter(
                        ({ level }) => compareLevels(level, maxLevel) <= 0
                      )
                      .filter(({ level }) => {
                        const isPermissionExist =
                          businessUserRolePermissions.find(
                            (role) => role.featureKey === featureKey
                          )
                        const targetLevel = isPermissionExist
                          ? getBusinessUserRolePermission(
                              businessUserRolePermissions,
                              featureKey
                            ).level
                          : maxLevel

                        return level === targetLevel
                      })
                      .map(({ name }, index) => {
                        return (
                          <View key={index}>
                            <FormGroup
                              mode={width < sm ? 'vertical' : 'inline'}
                              containerStyle={{ flex: 1 }}
                              formLabel={<FormLabel value={featureName} />}
                              style={{ marginTop: 16 }}
                            >
                              <Text>{name}</Text>
                            </FormGroup>
                          </View>
                        )
                      })}
                  </View>
                )
              }
            )}
          </View>
        </FormSection>
        <FormSection
          style={{ marginTop: formSectionMargin }}
          title={t('取扱店舗')}
        >
          <PermittedRestaurantsFormGroup
            style={{ marginTop: 8 }}
            restaurants={data.restaurants}
            permittedRestaurantIds={permittedRestaurantIds}
            setPermittedRestaurantIds={setPermittedRestaurantIds}
            isAdmin={isAdmin}
          />
        </FormSection>
        <FormSection
          style={{ marginTop: formSectionMargin }}
          title={t('お知らせ')}
        >
          <FormGroup
            mode={width < sm ? 'vertical' : 'inline'}
            formLabel={<FormLabel value={t('メール配信設定')} />}
          >
            <Checkbox
              checked={reservationEmailNotificationsEnabled}
              onChange={(value) =>
                setReservationEmailNotificationsEnabled(value)
              }
              checkboxLabel={<CheckboxLabel value={t('予約')} />}
            />
          </FormGroup>
        </FormSection>
      </ScrollView>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: Colors.white,
          paddingVertical: 12,
          columnGap: 20,
        }}
      >
        <Button
          mode="outline"
          variant="danger"
          style={{
            width: width < sm ? undefined : FORM_BUTTON_WITH,
            height: FORM_BUTTON_HEIGHT,
            backgroundColor: 'transparent',
          }}
          onPress={onAlertDestroy}
        >
          {t('削除する')}
        </Button>
        <Button
          style={{
            width: width < sm ? undefined : FORM_BUTTON_WITH,
            height: FORM_BUTTON_HEIGHT,
          }}
          onPress={onUpdate}
        >
          {t('更新する')}
        </Button>
      </View>
    </View>
  )
}
