/**
 * このファイルを変更する必要がある場合、 packages/for_r_app/src/api/TableMenu.ts へ該当メソッドを移行してください
 */
import { TableMenu } from '@hello-ai/ar_shared/src/types/ForR/TableMenu'
import { TableOrder } from '@hello-ai/for_r_app/src/models/TableOrder'

import axios, { setHeader, wrapResponse } from 'modules/axios'
import useSWR, { fetcher, mutate, onError, swrKey } from 'modules/swr'

import { useToken } from './Auth'

export * from '@hello-ai/ar_shared/src/types/ForR/TableMenu'

export function useRestaurantTableMenus({
  restaurantId,
}: {
  restaurantId: number
}) {
  const token = useToken()
  const {
    data: tableMenus,
    error,
    mutate,
  } = useSWR<TableMenu[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/menus`),
    fetcher
  )

  return {
    tableMenus,
    isLoading: tableMenus == null && error == null,
    error,
    mutate,
  }
}

export function useTableMenu({ tableMenuId }: { tableMenuId?: string }) {
  const token = useToken()
  const {
    data: tableMenu,
    error,
    mutate,
  } = useSWR<TableMenu, any, ReturnType<typeof swrKey> | null>(
    tableMenuId != null ? swrKey(token, `/menus/${tableMenuId}`) : null,
    fetcher
  )

  if (tableMenuId == null) {
    return {
      tableMenu: undefined,
      isLoading: false,
      mutate,
    }
  }

  return {
    tableMenu,
    isLoading: tableMenu == null && error == null,
    error,
    mutate,
  }
}

export async function createTableMenu({
  token,
  restaurantId,
  params,
}: {
  token: string
  restaurantId: number
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableMenu>(`/restaurants/${restaurantId}/menus`, params)
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(token, `/menus/${response?.data?.id}`, params),
      response.data,
      false
    )
  }

  return {
    tableMenu: response?.data,
  }
}

export async function updateTableMenu({
  token,
  tableMenuId,
  params,
}: {
  token: string
  tableMenuId: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.patch<TableMenu>(`/menus/${tableMenuId}`, params)
  )
  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(swrKey(token, `/menus/${tableMenuId}`), response.data, false)
  }

  return {
    tableMenu: response?.data,
  }
}

export async function getTableOrderDefaultMenu(
  token: string,
  tableOrderId: TableOrder['id'],
  params: {
    for_order?: boolean
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.get<TableMenu>(`/orders/${tableOrderId}/menu`, { params })
  )

  if (response?.data != null) {
    mutate(swrKey(token, `/orders/${tableOrderId}/menu`), response.data, false)
    mutate(
      swrKey(
        token,
        `/orders/${tableOrderId}/menus/${response.data.id}`,
        params
      ),
      response.data,
      false
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableMenu: response?.data,
    error,
  }
}

export function useTableOrderDefaultMenu(
  tableOrderId: TableOrder['id'],
  params: {
    for_order?: boolean
  }
) {
  const token = useToken()
  const {
    data: tableMenu,
    error,
    mutate: boundMutate,
  } = useSWR<TableMenu, any, ReturnType<typeof swrKey>>(
    swrKey(token, `/orders/${tableOrderId}/menu`),
    async ([token, url]) => {
      const tableMenu = await fetcher<TableMenu>([token, url, params])
      mutate(
        swrKey(token, `/orders/${tableOrderId}/menus/${tableMenu.id}`, params),
        tableMenu,
        false
      )
      return tableMenu
    }
  )

  return {
    tableMenu,
    isLoading: tableMenu == null && error == null,
    error,
    mutate: boundMutate,
  }
}

export function useTableOrderMenu(
  tableOrderId: TableOrder['id'],
  tableMenuId: TableMenu['id'] | null,
  params: {
    for_order?: boolean
  }
) {
  const token = useToken()
  const {
    data: tableMenu,
    error,
    mutate,
  } = useSWR<TableMenu, any, ReturnType<typeof swrKey> | null>(
    tableMenuId == null
      ? null
      : swrKey(token, `/orders/${tableOrderId}/menus/${tableMenuId}`, params),
    ([token, url]) => fetcher<TableMenu>([token, url, params])
  )

  return {
    tableMenu,
    isLoading: tableMenu == null && error == null,
    error,
    mutate,
  }
}

export type OrderMenusMenu = Pick<
  TableMenu,
  'id' | 'name' | 'time_range_description'
>

export async function getTableOrderMenus(
  token: string,
  tableOrderId: TableOrder['id'],
  params: {
    for_order?: boolean
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.get<OrderMenusMenu[]>(`/orders/${tableOrderId}/menus`, {
      params,
    })
  )

  if (response?.data != null) {
    mutate(
      swrKey(token, `/orders/${tableOrderId}/menus`, params),
      response.data,
      false
    )
  }

  if (error != null) {
    onError(error)
  }

  return {
    tableMenus: response?.data,
    error,
  }
}

export function useTableOrderMenus(
  tableOrderId: TableOrder['id'],
  params: {
    for_order?: boolean
  }
) {
  const token = useToken()
  const {
    data: tableMenus,
    error,
    mutate,
  } = useSWR<OrderMenusMenu[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/orders/${tableOrderId}/menus`, params),
    ([token, url]) => fetcher<OrderMenusMenu[]>([token, url, params])
  )

  return {
    tableMenus,
    isLoading: tableMenus == null && error == null,
    error,
    mutate,
  }
}

export function useMenuRestaurantGroupMenus(restaurantGroupId: string) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    TableMenu[],
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/menu_restaurant_groups/${restaurantGroupId}/menus`),
    fetcher
  )

  return {
    data: data ?? [],
    isLoading: data == null && error == null,
    error,
    mutate,
  }
}

export async function createMenuRestaurantGroupMenu({
  restaurantGroupId,
  token,
  params,
}: {
  restaurantGroupId: string
  token: string
  params: Record<string, any>
}) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<TableMenu>(
      `/menu_restaurant_groups/${restaurantGroupId}/menus`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  if (response?.data != null) {
    mutate(
      swrKey(
        token,
        `/menu_restaurant_groups/${restaurantGroupId}/menus/${response?.data?.id}`,
        params
      ),
      response.data,
      false
    )
  }

  return {
    tableMenu: response?.data,
  }
}
