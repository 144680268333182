import React from 'react'

import { View, Image } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export default function Icon({
  imageUrl,
  fallbackText = '',
  width,
}: {
  imageUrl?: string
  fallbackText: string
  width: number
}) {
  if (imageUrl != null) {
    return (
      <Image
        source={{ uri: imageUrl }}
        style={{
          width,
          height: width,
          borderRadius: width / 2,
        }}
      />
    )
  } else {
    return (
      <View
        style={{
          backgroundColor: Colors.primary,
          width,
          height: width,
          borderRadius: width / 2,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            color: 'white',
            fontWeight: '600',
            fontSize: 24,
          }}
        >
          {fallbackText.charAt(0)}
        </Text>
      </View>
    )
  }
}
