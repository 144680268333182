import React, { useEffect, useState } from 'react'

import { faAngleRight } from '@fortawesome/pro-solid-svg-icons/faAngleRight'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { Platform, View, ScrollView } from 'react-native'
import * as Svg from 'react-native-svg'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Popover } from '@hello-ai/ar_shared/src/components/Popover'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import {
  updateRestaurantReservation,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'
import { restaurantReservationBlockService } from '../../../models/RestaurantReservationBlock'
import { useNavigate } from '../../../modules/navigation/useNavigate'
import { useNavigation } from '../../../modules/navigation/useNavigation'
import { getCustomerDisplayName } from '../../Customers/Customer'

import { ChangeStatusButtons } from './ChangeStatusButtons'
import { ReservationIcons } from './ReservationIcons'
import { getVisitStatusStyle } from './common'

const HOUR_HEIGHT = 130

function BlockItem({
  restaurantId,
  block,
}: {
  restaurantId: number
  block: NonNullable<
    ReturnType<typeof restaurantReservationBlockService.useListPeriod>['data']
  >['restaurantReservationBlockPeriods'][number]
}) {
  const navigate = useNavigate()
  const navigation = useNavigation()

  if (block.startAt == null || block.endAt == null) {
    return null
  }
  const startAt = dayjs(block.startAt.seconds * 1000)
  const endAt = dayjs(block.endAt.seconds * 1000)
  return (
    <TouchableOpacity
      style={{
        position: 'absolute',
        height:
          (endAt.hour() - startAt.hour()) * HOUR_HEIGHT +
          ((endAt.minute() - startAt.minute()) / 60) * HOUR_HEIGHT,
        top:
          startAt.hour() * HOUR_HEIGHT + (startAt.minute() / 60) * HOUR_HEIGHT,
        left: 51,
        right: 16,
        backgroundColor: Colors.white,
      }}
      onPress={() => {
        if (Platform.OS === 'web') {
          navigate(
            `/restaurants/${restaurantId}/reservations/blocks/${block.id}`
          )
        } else {
          navigation.navigate('ReservationBlocksShow', {
            restaurantReservationBlockId: block.restaurantReservationBlock?.id,
            startAt: block.startAt,
            endAt: block.endAt,
          })
        }
      }}
    >
      <View
        style={{
          backgroundColor: Colors.black60,
          width: '100%',
          height: '100%',
          padding: 8,
          borderRadius: 8,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <View>
            <Text style={{ color: Colors.white, fontSize: 12 }}>
              {dayjs(block.startAt!.seconds * 1000).format('HH:mm')}-
            </Text>
            <Text
              style={{
                color: Colors.white,
                fontSize: 12,
                fontWeight: '600',
              }}
            >
              {t('ブロック')}
            </Text>
          </View>
          <FontAwesomeIcon icon={faAngleRight} size={16} color={Colors.white} />
        </View>
      </View>
    </TouchableOpacity>
  )
}

function ReservationItem({
  restaurantId,
  reservation,
  onUpdateVisitStatus,
}: {
  restaurantId: number
  reservation: ReturnType<
    typeof useRestaurantReservations
  >['restaurantReservations'][number]
  onUpdateVisitStatus: () => void
}) {
  const token = useToken()
  const navigate = useNavigate()
  const navigation = useNavigation()
  const startAt = dayjs(reservation.start_at)
  const endAt = dayjs(reservation.end_at)
  const { color, backgroundColor } = getVisitStatusStyle(
    reservation.visit_status
  )
  const [isShowPopover, setIsShowPopover] = useState(false)
  const height =
    (endAt.hour() - startAt.hour()) * HOUR_HEIGHT +
    ((endAt.minute() - startAt.minute()) / 60) * HOUR_HEIGHT

  const shouldShowButtons = height >= 130

  return (
    <Popover
      containerStyle={{
        position: 'absolute',
        height,
        top:
          startAt.hour() * HOUR_HEIGHT + (startAt.minute() / 60) * HOUR_HEIGHT,
        left: 51,
        right: 16,
        backgroundColor: Colors.white,
      }}
      isOpen={isShowPopover}
      placement="left"
      onClose={() => setIsShowPopover(false)}
      anchor={
        <TouchableOpacity
          style={
            Platform.OS === 'web' && {
              height: '100%',
            }
          }
          onPress={() => {
            if (shouldShowButtons) {
              if (Platform.OS === 'web') {
                navigate(
                  `/restaurants/${restaurantId}/reservations/${reservation.id}`
                )
              } else {
                navigation.navigate('ReservationsShow', {
                  restaurantReservationId: reservation.id,
                })
              }
            } else {
              setIsShowPopover(!isShowPopover)
            }
          }}
        >
          <View
            style={{
              backgroundColor,
              width: '100%',
              height: '100%',
              padding: 8,
              borderRadius: 8,
              justifyContent: 'space-between',
            }}
          >
            <View>
              <View
                style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}
              >
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 8,
                    }}
                  >
                    <Text style={{ color, fontSize: 12 }}>
                      {dayjs(reservation.start_at).format('HH:mm')}-
                    </Text>
                    <Text style={{ color, fontSize: 12 }}>
                      {t(
                        '{{party_size}} ({{adult_party_size}}、{{child_party_size}})',
                        {
                          party_size: t('{{count}}人', {
                            count: reservation.party_size,
                          }),
                          adult_party_size: t('大人{{count}}', {
                            count: reservation.adult_party_size,
                          }),
                          child_party_size: t('子供{{count}}', {
                            count: reservation.child_party_size,
                          }),
                        }
                      )}
                    </Text>
                  </View>
                  <Text
                    style={{
                      color,
                      marginTop: 4,
                      fontWeight: '600',
                      fontSize: 14,
                    }}
                  >
                    {getCustomerDisplayName(reservation.customers[0], t('様'))}
                  </Text>
                </View>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  size={16}
                  color={Colors.white}
                />
              </View>
              <ReservationIcons reservation={reservation} />
            </View>
            {shouldShowButtons && (
              <ChangeStatusButtons
                visit_status={reservation.visit_status}
                onPressChangeVisitStatus={async (visitStatus) => {
                  if (token == null) return
                  const { error } = await updateRestaurantReservation(
                    token,
                    restaurantId,
                    reservation.id,
                    {
                      visit_status: visitStatus,
                    }
                  )
                  if (error != null) return
                  onUpdateVisitStatus()
                }}
              />
            )}
          </View>
        </TouchableOpacity>
      }
    >
      <View
        style={{
          overflow: 'visible',
          width: 246,
          backgroundColor: Colors.white,
          elevation: 4,
          zIndex: 100,
          borderRadius: 8,
          padding: 16,
        }}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
          <Text style={{ color: Colors.black, fontSize: 12 }}>
            {dayjs(reservation.start_at).format('HH:mm')}-
          </Text>
          <Text style={{ color: Colors.black, fontSize: 12 }}>
            {t('{{party_size}} ({{adult_party_size}}、{{child_party_size}})', {
              party_size: t('{{count}}人', {
                count: reservation.party_size,
              }),
              adult_party_size: t('大人{{count}}', {
                count: reservation.adult_party_size,
              }),
              child_party_size: t('子供{{count}}', {
                count: reservation.child_party_size,
              }),
            })}
          </Text>
        </View>
        <Text
          style={{
            color: Colors.black,
            marginTop: 4,
            fontWeight: '600',
            fontSize: 14,
          }}
        >
          {getCustomerDisplayName(reservation.customers[0], t('様'))}
        </Text>
        {reservation.visit_status !== 'departed' && (
          <ChangeStatusButtons
            visit_status={reservation.visit_status}
            onPressChangeVisitStatus={async (visitStatus) => {
              if (token == null) return
              const { error } = await updateRestaurantReservation(
                token,
                restaurantId,
                reservation.id,
                {
                  visit_status: visitStatus,
                }
              )
              if (error != null) return
              onUpdateVisitStatus()
            }}
          />
        )}
        <Button
          mode="outline"
          variant="secondary"
          style={{ marginTop: 8, height: 33 }}
          textStyle={{ fontSize: 14 }}
          onPress={() => {
            setIsShowPopover(false)
            if (Platform.OS === 'web') {
              navigate(
                `/restaurants/${restaurantId}/reservations/${reservation.id}`
              )
            } else {
              navigation.navigate('ReservationsShow', {
                restaurantReservationId: reservation.id,
              })
            }
          }}
        >
          {t('予約詳細')}
        </Button>
      </View>
    </Popover>
  )
}

export function SeatScheduleView({
  restaurantId,
  dateString,
  selectedItem,
  restaurantReservationBlocks,
  onUpdateVisitStatus,
}: {
  restaurantId: number
  dateString: string
  selectedItem: {
    id: string
    min_party_size: number | null
    max_party_size: number | null
    layout_parts: 'square' | 'counter' | 'round' | null
  }
  restaurantReservationBlocks: NonNullable<
    ReturnType<typeof restaurantReservationBlockService.useListPeriod>['data']
  >['restaurantReservationBlockPeriods']
  onUpdateVisitStatus: () => void
}) {
  const now = new Date()
  const hour = now.getHours()
  const minutes = now.getMinutes()
  const currentPosition = hour * HOUR_HEIGHT + (minutes / 60) * HOUR_HEIGHT
  const scrollViewRef = React.useRef<ScrollView>(null)
  const [isRendered, setIsRendered] = React.useState(false)

  const { restaurantReservations, mutate } = useRestaurantReservations(
    restaurantId,
    { date: dateString, table_seat_id: selectedItem.id },
    { refreshInterval: 1000 * 10, revalidateOnFocus: true }
  )

  useEffect(() => {
    if (!isRendered) {
      return
    }
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({
        y: currentPosition - 200,
        animated: true,
      })
    }
  }, [isRendered, currentPosition, selectedItem.id])

  return (
    <>
      <View
        style={{
          paddingVertical: 8,
          paddingHorizontal: 16,
          height: 40,
          borderBottomWidth: 1,
          borderBottomColor: Colors.border,
          flexDirection: 'row',
          alignItems: 'center',
          gap: 8,
        }}
      >
        {/* <FontAwesomeIcon icon={faSmokingBan} size={20} color={Colors.caution} /> */}
        <View style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
          <FontAwesomeIcon icon={faUsers} size={16} color={Colors.primary} />
          <Text
            style={{
              fontSize: 12,
              color: Colors.black60,
            }}
          >
            {selectedItem.min_party_size === null ||
            selectedItem.max_party_size === null
              ? t('未設定')
              : `${selectedItem.min_party_size}~${selectedItem.max_party_size}`}
          </Text>
        </View>
        <View style={{ flexDirection: 'row', gap: 4, alignItems: 'center' }}>
          <FontAwesomeIcon icon={faChair} size={16} color={Colors.primary} />
          <Text
            style={{
              fontSize: 12,
              color: Colors.black60,
            }}
          >
            {
              {
                square: t('四角テーブル'),
                counter: t('カウンター'),
                round: t('丸テーブル'),
              }[selectedItem.layout_parts ?? 'square']
            }
          </Text>
        </View>
      </View>
      <ScrollView
        style={{ position: 'relative' }}
        contentContainerStyle={{
          paddingBottom: 220,
          paddingHorizontal: 8,
        }}
        ref={scrollViewRef}
        onLayout={() => setIsRendered(true)}
      >
        {new Array(24).fill(null).map((_, index) => (
          <View
            key={`hours-${index}`}
            style={{
              borderBottomWidth: 0.5,
              borderBottomColor: '#DDDDDD',
              height: HOUR_HEIGHT,
              boxSizing: 'border-box',
            }}
          >
            <Text
              style={{
                position: 'absolute',
                top: 8,
                left: 8,
                fontSize: 12,
                color: Colors.black,
              }}
            >
              {index.toString().padStart(2, '0')}:00
            </Text>
            <View
              style={{
                position: 'absolute',
                top: 64,
                left: 0,
                right: 0,
              }}
            >
              <Svg.Svg height={1} width="100%" style={{ alignSelf: 'center' }}>
                <Svg.Line
                  x1="0"
                  y1="0"
                  x2="100%"
                  y2="1"
                  strokeDasharray="2, 2"
                  strokeWidth={1}
                  stroke="#DDDDDD"
                />
              </Svg.Svg>
            </View>
          </View>
        ))}
        {restaurantReservationBlocks.map((block) => (
          <BlockItem
            key={`seat-schedule-${selectedItem.id}-${block.id}`}
            restaurantId={restaurantId}
            block={block}
          />
        ))}
        {restaurantReservations.map((reservation) => (
          <ReservationItem
            key={`seat-schedule-${selectedItem.id}-${reservation.id}`}
            restaurantId={restaurantId}
            reservation={reservation}
            onUpdateVisitStatus={() => {
              mutate()
              onUpdateVisitStatus()
            }}
          />
        ))}
        <View
          style={{
            position: 'absolute',
            top: currentPosition,
            left: 0,
            right: 0,
            height: 5,
            backgroundColor: Colors.caution60,
          }}
        />
      </ScrollView>
    </>
  )
}
