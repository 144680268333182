import React from 'react'

import { ScrollView, View } from 'react-native'
import { useNavigate } from 'react-router'

import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import LinkOpacity from 'components/Shared/LinkOpacity'
import RoundedActionButton from 'components/Shared/RoundedActionButton'
import {
  useRestaurantBusinessTimes,
  RestaurantBusinessTime as RestaurantBusinessTimeModel,
} from 'models/RestaurantBusinessTime'
import { wdayName, getFormatTime, splitHourAndMinute } from 'modules/time'
import { useRestaurantId } from 'modules/useRestaurantId'

function RestaurantBusinessTime({
  restaurantBusinessTime,
}: {
  restaurantBusinessTime: RestaurantBusinessTimeModel
}) {
  const restaurantId = useRestaurantId()
  const stayingTime =
    restaurantBusinessTime.staying_time != null
      ? splitHourAndMinute(restaurantBusinessTime.staying_time)
      : null

  return (
    <LinkOpacity
      style={{ flexDirection: 'row' }}
      to={`/restaurants/${restaurantId}/business_times/${restaurantBusinessTime.id}/edit`}
    >
      <Text style={{ flex: 1 }}>{restaurantBusinessTime.name}</Text>
      <Text style={{ flex: 1 }}>
        {getFormatTime(restaurantBusinessTime.start_time)}
      </Text>
      <Text style={{ flex: 1 }}>
        {getFormatTime(restaurantBusinessTime.last_order_time)}
      </Text>
      <Text style={{ flex: 1 }}>
        {getFormatTime(restaurantBusinessTime.end_time)}
      </Text>
      <Text style={{ flex: 1 }}>
        {stayingTime !== null
          ? `${`${
              stayingTime.hour === 0
                ? ''
                : t('{{hours}}時間', { hours: stayingTime.hour })
            }${
              stayingTime.minute === 0
                ? ''
                : t('{{minutes}}分', { minutes: stayingTime.minute })
            }`}`
          : '-'}
      </Text>
      <Text style={{ flex: 1 }}>
        {restaurantBusinessTime.wdays.map((wday, index) => {
          return <Text key={index}>{wdayName(wday)} </Text>
        })}
      </Text>
    </LinkOpacity>
  )
}

export default function RestaurantBusinessTimes() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()
  const { restaurantBusinessTimes } = useRestaurantBusinessTimes(restaurantId)

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{
        padding: width < sm ? 16 : 32,
      }}
    >
      <RoundedActionButton
        onPress={() =>
          navigate(`/restaurants/${restaurantId}/business_times/new`)
        }
      >
        {t('営業時間を作成')}
      </RoundedActionButton>
      <View style={{ marginTop: 40 }}>
        <ShadowBox
          style={[
            {
              paddingHorizontal: 0,
              paddingVertical: 0,
            },
          ]}
        >
          <View
            style={{
              paddingHorizontal: 24,
              flexDirection: 'row',
              borderBottomWidth: 1,
              borderColor: Colors.border,
              paddingVertical: 16,
              alignItems: 'flex-end',
            }}
          >
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('名称')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('開始')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>
              {t('ラストオーダー')}
            </Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('終了')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('滞在')}</Text>
            <Text style={{ flex: 1, fontWeight: '600' }}>{t('曜日')}</Text>
          </View>
          {restaurantBusinessTimes?.map(
            (
              restaurantBusinessTime: RestaurantBusinessTimeModel,
              index: number
            ) => {
              return (
                <View
                  key={restaurantBusinessTime.id}
                  style={{
                    borderTopWidth: index === 0 ? 0 : 0.5,
                    borderColor: Colors.border,
                    paddingTop: 16,
                    marginBottom: 16,
                    paddingHorizontal: 24,
                  }}
                >
                  <RestaurantBusinessTime
                    restaurantBusinessTime={restaurantBusinessTime}
                  />
                </View>
              )
            }
          )}
        </ShadowBox>
      </View>
    </ScrollView>
  )
}
