import React, { useCallback } from 'react'

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import { ColorValue, ScrollView, StyleSheet, View } from 'react-native'
import { Calendar, CalendarProps } from 'react-native-calendars'
import { DayProps } from 'react-native-calendars/src/calendar/day'
import { DateData } from 'react-native-calendars/src/types'
import invariant from 'tiny-invariant'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { ByCalendarResponse_DateResource_PeriodResource } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/restaurant_reservation/restaurant_reservation_service'

import { ReservationCalendarHeader } from './ReservationCalendarHeader'
import { ReservationCalendarSummary } from './ReservationCalendarSummary'

export interface CalendarState {
  selectedDate: dayjs.Dayjs
}

interface ReservationCalendarProps {
  selectedDate: string
  selectedPeriods: ByCalendarResponse_DateResource_PeriodResource[]
  onVisibleMonthsChange?: CalendarProps['onVisibleMonthsChange']
  visibleMonth: string
  dayComponent: CalendarProps['dayComponent']
  onDayPress: CalendarProps['onDayPress']
  onClose: () => void
  displayLoadingIndicator?: CalendarProps['displayLoadingIndicator']
  restaurantId: number
}

const CalendarStateContext = React.createContext<CalendarState | undefined>(
  undefined
)

export const CalendarStateProvider = CalendarStateContext.Provider

export function useCalendarState() {
  const value = React.useContext(CalendarStateContext)
  invariant(
    value !== undefined,
    'useCalendarState() must be used inside <CalendarStateProvider />'
  )
  return value
}

const DayPeriodIconStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 2,
    backgroundColor: Colors.bgBlack,
  },
  iconText: {
    textAlign: 'center',
    fontWeight: '600',
    lineHeight: 12,
  },
})

const DayPeriodIcon = React.memo(function DayPeriodIcon({
  text,
  vacant,
}: {
  text: string
  vacant: boolean
}) {
  const { width, sm } = useResponsive()
  const color = vacant ? Colors.secondaryBlack : Colors.white
  const bgColor = vacant ? Colors.black6 : Colors.caution

  return (
    <View
      style={[
        DayPeriodIconStyles.container,
        {
          width: width < sm ? 14 : 34,
          height: width < sm ? 14 : 19,
          backgroundColor: bgColor,
        },
      ]}
    >
      <Text
        style={[
          DayPeriodIconStyles.iconText,
          {
            fontSize: width < sm ? 10 : 12,
            color,
          },
        ]}
      >
        {text.charAt(0)}
      </Text>
    </View>
  )
})

const DayRows = React.memo(function DayRows({
  periods,
}: {
  periods: ByCalendarResponse_DateResource_PeriodResource[]
}) {
  return (
    <>
      {periods.map((period, index) => (
        <DayRow
          key={`${index}`}
          icon={<DayPeriodIcon text={period.name} vacant={period.vacant} />}
          vacant={period.vacant}
          description={period.description}
        />
      ))}
    </>
  )
})

const DayRow = React.memo(function DayRow({
  icon,
  vacant,
  description,
}: {
  icon: React.ReactNode
  vacant: boolean
  description: string
}) {
  const { width, sm } = useResponsive()
  const color = vacant ? Colors.secondaryBlack : Colors.caution
  // sp では `〇〇組 (〇〇名)` -> `〇〇組` にする
  const spDescription = description.replace(/\s*\([^)]*\)/, '')
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 2,
      }}
    >
      {icon}
      <Text
        style={[
          {
            marginLeft: 4,
            fontSize: width > sm ? 14 : 10,
            color,
          },
        ]}
      >
        {width > sm ? description : spDescription}
      </Text>
    </View>
  )
})

const DayStyles = StyleSheet.create({
  touchable: {
    width: '100%',
    paddingHorizontal: 2,
    paddingTop: 8,
    paddingBottom: 16,
    borderColor: 'transparent',
    borderRadius: 2,
    borderWidth: 1,
  },
  touchableSelected: {
    borderColor: Colors.accent,
    backgroundColor: Colors.accentBg,
  },
  dayTextContainer: {
    alignSelf: 'flex-start',
    alignItems: 'center',
    width: '100%',
    marginBottom: 10,
  },
  dayText: {
    fontSize: 14,
    fontWeight: '600',
  },
  dayRowContainer: {
    flexWrap: 'wrap',
    width: '100%',
    gap: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const COLOR_SATURDAY = '#1B76E1'
const COLOR_SUNDAY = '#D21028'
export const Day = React.memo(
  ({
    date,
    onPress,
    periods,
    isHoliday,
    isClosed,
    hasRequestReservation,
  }: {
    date: DateData
    onPress?: DayProps['onPress']
    periods: ByCalendarResponse_DateResource_PeriodResource[]
    isHoliday?: boolean
    isClosed?: boolean
    hasRequestReservation: boolean
  }) => {
    const { width, sm } = useResponsive()
    const { selectedDate } = useCalendarState()
    const [isToday, isSaturday, isSunday, isSelected, isPast] =
      React.useMemo(() => {
        const d = dayjs(date.dateString, 'YYYY-MM-DD')
        const isToday = dayjs().isSame(d, 'day')
        const isSaturday = d.day() === 6
        const isSunday = d.day() === 0
        const isSelected = selectedDate.isSame(d, 'day')
        const isPast = d.isBefore(dayjs(), 'day')
        return [isToday, isSaturday, isSunday, isSelected, isPast]
      }, [date.dateString, selectedDate])

    const textColor = React.useMemo(() => {
      let textColor: ColorValue = Colors.black
      if (isToday) {
        textColor = Colors.white
      } else if (isSaturday) {
        textColor = COLOR_SATURDAY
      } else if (isSunday || isHoliday || isClosed) {
        textColor = COLOR_SUNDAY
      }
      return textColor
    }, [isClosed, isHoliday, isSaturday, isSunday, isToday])
    const textOpacity = isPast || isClosed ? 0.4 : 1

    return (
      <TouchableOpacity
        style={[DayStyles.touchable, isSelected && DayStyles.touchableSelected]}
        onPress={() => onPress?.(date)}
      >
        <View
          style={[
            DayStyles.dayTextContainer,
            {
              backgroundColor: isToday ? Colors.primary : 'transparent',
              opacity: textOpacity,
            },
          ]}
        >
          <Text
            style={[
              DayStyles.dayText,
              {
                color: textColor,
                lineHeight: width < sm ? 14 : 21,
              },
            ]}
          >
            {date.day}
          </Text>
          {hasRequestReservation && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color={Colors.caution}
              style={[
                {
                  position: 'absolute',
                  width: 16,
                  height: 16,
                  borderRadius: 8,
                },
                width < sm
                  ? {
                      right: 0,
                      top: -2,
                    }
                  : {
                      right: 8,
                      top: 2,
                    },
              ]}
            />
          )}
        </View>
        <View
          style={[
            DayStyles.dayRowContainer,
            {
              opacity: textOpacity,
            },
          ]}
        >
          <DayRows periods={periods} />
        </View>
      </TouchableOpacity>
    )
  }
)

export function ReservationCalendar({
  selectedDate,
  selectedPeriods,
  onVisibleMonthsChange,
  visibleMonth,
  dayComponent,
  onDayPress,
  onClose,
  displayLoadingIndicator,
  restaurantId,
}: ReservationCalendarProps) {
  const { width, sm } = useResponsive()
  const isSmallScreen = width < sm
  const wrappedOnDayPress = useCallback(
    (date: DateData) => {
      onDayPress?.(date)
      if (!isSmallScreen) {
        onClose()
      }
    },
    [isSmallScreen, onDayPress, onClose]
  )
  return (
    <View style={{ flex: 1 }}>
      <ScrollView style={[isSmallScreen && { flex: 0.6 }]}>
        <Calendar
          key={visibleMonth}
          hideArrows
          current={visibleMonth}
          removeClippedSubviews
          displayLoadingIndicator={displayLoadingIndicator}
          renderHeader={() => (
            <ReservationCalendarHeader
              restaurantId={restaurantId}
              visibleMonth={visibleMonth}
              onVisibleMonthsChange={onVisibleMonthsChange}
              onClose={onClose}
            />
          )}
          onVisibleMonthsChange={onVisibleMonthsChange}
          dayComponent={dayComponent}
          onDayPress={wrappedOnDayPress}
          theme={
            {
              'stylesheet.calendar.header': {
                header: {
                  marginTop: 8,
                  marginBottom: 8,
                  marginHorizontal: 16,
                },
                headerContainer: {
                  flex: 1,
                  flexDirection: 'row',
                },
                week: {
                  flexDirection: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  height: 27,
                  backgroundColor: Colors.bgBlack,
                },
                dayHeader: {
                  fontSize: 14,
                  lineHeight: 22,
                  fontWeight: '600',
                  color: Colors.black,
                },
                dayTextAtIndex0: {
                  color: COLOR_SUNDAY,
                },
                dayTextAtIndex6: {
                  color: COLOR_SATURDAY,
                },
              },
            } as any
          }
        />
      </ScrollView>
      {isSmallScreen && (
        <View style={{ flex: 0.4 }}>
          <ReservationCalendarSummary
            periods={selectedPeriods}
            selectedDate={selectedDate}
            visibleMonth={visibleMonth}
            onPress={onClose}
          />
        </View>
      )}
    </View>
  )
}
