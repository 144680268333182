import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import {
  BroadcastMessageItem,
  BroadcastMessagePreview,
  RequestBroadcastMessageData,
  RequestBroadcastMessagePreviewData,
} from '@hello-ai/ar_shared/src/types/ForR/BroadcastMessage'

import axios, { setHeader, wrapResponse } from '../modules/axios'
import useSWR, {
  fetcher,
  fetcherPost,
  fetcherWithPaginationHeader,
  WithHeaderData,
  swrKey,
} from '../modules/swr'

export function useBroadcastMessages(
  restaurantId: number,
  params: { page: number }
) {
  const token = useToken()
  const {
    data: broadcastMessages,
    error,
    mutate,
  } = useSWR<
    WithHeaderData<BroadcastMessageItem[]> | null,
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(token, `/restaurants/${restaurantId}/broadcast_messages`, params),
    ([token, url]) =>
      fetcherWithPaginationHeader<BroadcastMessageItem[], {}>([
        token,
        url,
        params,
      ])
  )

  return {
    broadcastMessages,
    error,
    mutate,
  }
}

export async function createBroadcastMessage(
  token: string,
  restaurantId: number,
  body: RequestBroadcastMessageData
) {
  const { response, error } = await wrapResponse(
    axios.post(`/restaurants/${restaurantId}/broadcast_messages`, body)
  )

  return {
    broadcastMessage: response?.data,
    error,
  }
}

export function useBroadcastMessage(
  restaurantId: number,
  broadcastMessageId?: string
) {
  const token = useToken()
  const {
    data: broadcastMessage,
    error,
    mutate,
  } = useSWR<BroadcastMessageItem, any, ReturnType<typeof swrKey> | null>(
    broadcastMessageId == null
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/broadcast_messages/${broadcastMessageId}`
        ),
    ([token, url]) => fetcher([token, url])
  )

  return {
    broadcastMessage,
    error,
    mutate,
  }
}

export const useBroadcastNumberOfCustomers = (restaurantId: number) => {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    { number_of_customers: number },
    any,
    ReturnType<typeof swrKey>
  >(
    swrKey(
      token,
      `/restaurants/${restaurantId}/broadcast_messages/number_of_customers`
    ),
    fetcher
  )

  return {
    numberOfCustomers: data?.number_of_customers ?? 0,
    error,
    mutate,
  }
}

export const useBroadcastMessagePreview = (
  restaurantId: number,
  previewData: RequestBroadcastMessagePreviewData | null
) => {
  const token = useToken()
  const { data: preview, error } = useSWR<
    BroadcastMessagePreview,
    any,
    ReturnType<typeof swrKey> | null
  >(
    previewData == null
      ? null
      : swrKey(
          token,
          `/restaurants/${restaurantId}/broadcast_messages/preview`,
          previewData
        ),
    ([token, url]) =>
      fetcherPost<BroadcastMessagePreview>([
        token,
        url,
        previewData ?? undefined,
      ])
  )

  return {
    preview,
    error,
  }
}

export async function deleteBroadcastMessage(
  restaurantId: number,
  broadcastMessageId: string
) {
  const { error } = await wrapResponse(
    axios.delete(
      `/restaurants/${restaurantId}/broadcast_messages/${broadcastMessageId}`
    )
  )

  return {
    error,
  }
}

export async function updateBroadcastMessage(
  token: string,
  restaurantId: number,
  broadcastMessageId: string,
  body: RequestBroadcastMessageData
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.patch(
      `/restaurants/${restaurantId}/broadcast_messages/${broadcastMessageId}`,
      body
    )
  )

  return {
    error,
  }
}
