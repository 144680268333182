import { useEffect, useRef } from 'react'
import type { NavigateProps } from 'react-router-dom'

import { isEqual } from 'lodash'

import { usePrevious } from '@hello-ai/ar_shared/src/modules/usePrevious'

import { useNavigate } from 'modules/router/useNavigate'

// A replacement component for <Navigate> from react-router
// https://github.com/remix-run/react-router/issues/8733
export function Navigate({
  to,
  replace,
  state,
}: React.PropsWithChildren<NavigateProps>) {
  const navigate = useNavigate()
  const navigateRef = useRef(navigate)

  useEffect(() => {
    navigateRef.current = navigate
  }, [navigate])

  const previousNavigationParams = usePrevious({ to, replace, state })

  useEffect(() => {
    if (isEqual({ to, replace, state }, previousNavigationParams)) return
    navigateRef.current(to, { replace, state })
  }, [previousNavigationParams, to, replace, state])

  return null
}
