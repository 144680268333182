import React, { useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useParams } from 'react-router'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { TableProductOptionBox } from '@hello-ai/ar_shared/src/types/ForR/TableProductOptionBox'
import { useSearchAndPagination } from '@hello-ai/for_r_app/src/modules/useSearchAndPagination'

import TableProductFormContent from 'components/Menus/TableProductFormContent'
import TableProductOptionBoxSearchModal from 'components/Menus/TableProductOptionBoxSearchModal'
import { useTableMenuPages } from 'models/TableMenuPage'
import {
  createTableProduct,
  useTableProduct,
  updateTableProduct,
} from 'models/TableProduct'
import { useTableProductOptionBoxes } from 'models/TableProductOptionBox'
import { useRestaurantTableUnlimitedOrderPlans } from 'models/TableUnlimitedOrderPlan'
import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableProductFormScreen() {
  const restaurantId = useRestaurantId()
  const { table_product_id: tableProductId } = useParams<{
    table_product_id: string
  }>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const copyTableProductId = queryParams.get('copy_table_product_id')
  const { tableProduct, isLoading } = useTableProduct({
    tableProductId: tableProductId ?? copyTableProductId ?? undefined,
  })

  const hookResult = useSearchAndPagination()
  const [isVisibleOptionBoxModal, setIsVisibleOptionBoxModal] = useState(false)
  const ref = useRef<{
    onPressOptionBox: (optionBox: TableProductOptionBox) => void
  }>(null)

  // fetch all menu page
  const { tableMenuPages, isLoading: isLoadingMenuPages } = useTableMenuPages({
    restaurantId,
    params: {
      per_page: 9999,
    },
  })

  const { tableUnlimitedOrderPlans, isLoading: isLoadingUnlimitedPlans } =
    useRestaurantTableUnlimitedOrderPlans(restaurantId)

  const optionBoxesResult = useTableProductOptionBoxes(restaurantId, {
    page: hookResult.currentPage,
    keyword: hookResult.keyword,
  })

  const isCopy = copyTableProductId != null

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <TableProductFormContent
        ref={ref}
        tableProduct={tableProduct}
        isCopy={isCopy}
        createProduct={async ({ token, params }) => {
          await createTableProduct({
            token,
            params,
            restaurantId,
          })
        }}
        updateProduct={async ({ token, params }) => {
          if (tableProductId === undefined) {
            return
          }

          await updateTableProduct({
            token,
            params,
            tableProductId,
          })
        }}
        onPressCopy={({ tableProductId }) => {
          // ここでnavivation.navigate()を使ってはいけない
          // 理由は同一Screen内の遷移のため、現在のページが上書きされてしまい
          // paramsだけが変わってstateのクリアがされないため。
          history.push(
            `/restaurants/${restaurantId}/products/new?copy_table_product_id=${tableProductId}`
          )
        }}
        tableMenuPages={tableMenuPages}
        isLoadingMenuPages={isLoadingMenuPages}
        tableUnlimitedOrderPlans={tableUnlimitedOrderPlans}
        isLoadingUnlimitedPlans={isLoadingUnlimitedPlans}
        onPressOpenOptionBoxModal={(isVisible) =>
          setIsVisibleOptionBoxModal(isVisible)
        }
      />
      <TableProductOptionBoxSearchModal
        title={t('追加するオプションを選ぶ')}
        isModalVisible={isVisibleOptionBoxModal}
        onClose={() => setIsVisibleOptionBoxModal(false)}
        onPress={ref.current?.onPressOptionBox}
        {...optionBoxesResult}
        {...hookResult}
      />
    </>
  )
}
