import { useState, useCallback } from 'react'

import { LayoutChangeEvent } from 'react-native'

export const useComponentSize = () => {
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  })
  const [isReady, setIsReady] = useState(false)

  const onLayout = useCallback(
    (event: LayoutChangeEvent) => {
      const { width, height } = event.nativeEvent.layout
      if (size.width !== width || size.height !== height) {
        setSize({ width, height })
      }
      if (!isReady) {
        setIsReady(true)
      }
    },
    [isReady, size.height, size.width]
  )

  return [size, isReady, onLayout] as const
}
