import React from 'react'

import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { BusinessUserRolePermissionResource_Level } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_permission_resource'
import { BusinessUserRolePermission } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/business_user_role/business_user_role_service'

import {
  businessFeatures,
  compareBusinessUserRolePermissionLevels as compareLevels,
  getBusinessUserRolePermission,
} from 'models/BusinessUserRole'

function Label({ text }: { text: string }) {
  return (
    <View
      style={[
        {
          width: 24,
          height: 14,
          borderRadius: 2,
          padding: 2,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: Colors.accent,
        },
      ]}
    >
      <Text
        style={[
          {
            fontSize: 10,
            color: 'white',
            fontWeight: '600',
          },
        ]}
      >
        {text}
      </Text>
    </View>
  )
}

function canEdit(
  maxLevel: BusinessUserRolePermissionResource_Level,
  level: BusinessUserRolePermissionResource_Level
) {
  return (
    compareLevels(maxLevel, BusinessUserRolePermissionResource_Level.ALL) ===
      0 &&
    compareLevels(level, BusinessUserRolePermissionResource_Level.ALL) === 0
  )
}
function BusinessRolePermission({
  label,
  canEdit,
}: {
  label: string
  canEdit: boolean
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Text style={{ fontSize: 16 }}>{label}</Text>
      {canEdit && (
        <View style={{ marginLeft: 10 }}>
          <Label text={t('編集')} />
        </View>
      )}
    </View>
  )
}

export function BusinessRolePermissionList({
  businessUserRolePermissions,
}: {
  businessUserRolePermissions: BusinessUserRolePermission[]
}) {
  return (
    <View>
      {businessFeatures.flatMap(([featureKey, { name, maxLevel }]) => {
        const { level } = getBusinessUserRolePermission(
          businessUserRolePermissions,
          featureKey
        )

        if (
          compareLevels(
            level,
            BusinessUserRolePermissionResource_Level.NONE
          ) === 0
        ) {
          return []
        }

        return [
          <BusinessRolePermission
            key={featureKey}
            label={name}
            canEdit={canEdit(maxLevel, level)}
          />,
        ]
      })}
    </View>
  )
}
