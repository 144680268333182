import React from 'react'

import { ScrollView } from 'react-native'

import AgreementForm from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSettingDetail/AgreementForm'
import OutboundSettingView from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSettingDetail/OutboundSetting'
import OutboundSyncView, {
  waitSyncFinish,
} from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSettingDetail/OutboundSync'
import UpdateHistoryList from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSettingDetail/UpdateHistoryList'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { GourmetSiteProvider } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'
import {
  OutboundSettingData,
  isExistOutboundSetting,
} from '@hello-ai/ar_shared/src/types/ForR/OutboundSetting'

import { useAuthMe } from '../../models/Auth'
import {
  authenticateGourmetSite,
  postImportMailNoteAgreement,
  updateOutboundSettings,
  useExecuteHistories,
  useOutboundSettings,
} from '../../models/SiteControllerOutboundSettings'

interface ContractedViewProps {
  outboundSettingsData: OutboundSettingData
  mutateOutboundSettingData: () => void
  onPressEditLoginInfo: () => void
}

function ContractedView({
  outboundSettingsData,
  mutateOutboundSettingData,
  onPressEditLoginInfo,
}: ContractedViewProps) {
  const token = useToken()
  const executeHistories = useExecuteHistories(
    outboundSettingsData.restaurant_id,
    {
      event_type_and_actions: [
        {
          event_type: 'setting',
          actions: ['authenticate'],
        },
      ],
    }
  )

  const latestUpdateHistory = executeHistories?.data?.[0]
  const { actor } = useAuthMe()
  return (
    <>
      <OutboundSyncView
        source={outboundSettingsData.site}
        latestUpdateHistory={latestUpdateHistory}
        onPressSync={async () => {
          if (token == null) return
          if (actor.businessUser == null) return
          await authenticateGourmetSite(
            token,
            outboundSettingsData.restaurant_id,
            outboundSettingsData.site,
            actor.businessUser.id
          )
          if (latestUpdateHistory == null) return

          await waitSyncFinish(latestUpdateHistory, async () => {
            const res = await executeHistories.mutate()
            return res?.data
          })
        }}
      />
      <OutboundSettingView
        outboundSetting={outboundSettingsData}
        onPressEditLoginInfo={onPressEditLoginInfo}
        onChangeEnabled={async (enabled) => {
          if (token == null) return
          const { error } = await updateOutboundSettings(
            token,
            outboundSettingsData.restaurant_id,
            outboundSettingsData.id,
            {
              enabled,
            }
          )
          if (error == null) {
            await mutateOutboundSettingData()
          }
        }}
      />
    </>
  )
}

interface GourmetSiteAccountSettingScreenProps {
  restaurantId: number
  source: GourmetSiteProvider
  onPressEditLoginInfo: (outboundSetting: OutboundSettingData) => void
  onMailNoteAgreed: () => void
}

export default function GourmetSiteAccountSettingDetailView({
  restaurantId,
  source,
  onPressEditLoginInfo,
  onMailNoteAgreed,
}: GourmetSiteAccountSettingScreenProps) {
  const token = useToken()
  const { outboundSettingsData, mutate: mutateOutboundSettingData } =
    useOutboundSettings(restaurantId, source)

  return (
    <ScrollView
      contentContainerStyle={{
        paddingVertical: 40,
        marginHorizontal: 50,
        rowGap: 48,
      }}
    >
      {!isExistOutboundSetting(outboundSettingsData) ? (
        <AgreementForm
          onPressSubmit={async () => {
            if (token == null) return
            const { error } = await postImportMailNoteAgreement({
              restaurantId,
              token,
            })
            if (error != null) return
            onMailNoteAgreed()
          }}
        />
      ) : (
        <ContractedView
          outboundSettingsData={outboundSettingsData}
          mutateOutboundSettingData={mutateOutboundSettingData}
          onPressEditLoginInfo={() => {
            onPressEditLoginInfo(outboundSettingsData)
          }}
        />
      )}
      <UpdateHistoryList histories={outboundSettingsData?.histories} />
    </ScrollView>
  )
}
