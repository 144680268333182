import React from 'react'

import { View, StyleProp, ViewStyle } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'

import {
  ReportRow,
  ReportRowComponent,
  ReportRowSecondaryComponent,
} from '../../models/Report'

const annotationNames = [t('客数'), t('客単価'), t('注文キャンセル')]

export function RowName({
  name,
  fontSize,
  fontBold = false,
}: {
  name: string
  fontSize: number
  fontBold?: boolean
}) {
  const isAnnotationName = annotationNames.includes(name)
  return (
    <Text
      style={[
        {
          fontSize,
        },
        fontBold && {
          fontWeight: '600',
        },
        { textAlign: 'center' },
      ]}
    >
      {name}
      {isAnnotationName && ' ※'}
    </Text>
  )
}

export function RowAmount({
  amount,
  unit = t('円'),
  fontSize,
  fontBold = false,
}: {
  amount: number | null | undefined
  unit?: string
  fontSize: number
  fontBold?: boolean
}) {
  if (amount == null) {
    return null
  }

  return (
    <Text
      style={[
        {
          fontSize,
        },
        amount < 0 && {
          color: Colors.caution,
        },
        fontBold && {
          fontWeight: '600',
        },
      ]}
    >
      {numberWithDelimiter(amount)}
      {unit}
    </Text>
  )
}

export function Row({
  children,
  style,
}: {
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        style,
      ]}
    >
      {children}
    </View>
  )
}

function ReportRowSubComponents({
  components,
}: {
  components: ReportRowComponent['components']
}) {
  return (
    <View>
      {components.map((component) => (
        <Row
          key={component.id}
          style={[
            {
              paddingVertical: 24,
              borderTopWidth: 0.5,
              borderTopColor: Colors.border,
            },
          ]}
        >
          <RowName name={component.name} fontSize={18} />
          <RowAmount
            amount={component.amount}
            unit={component.unit}
            fontSize={18}
          />
        </Row>
      ))}
    </View>
  )
}

function ReportRowSecondaryComponents({
  secondaryComponents,
}: {
  secondaryComponents: ReportRowSecondaryComponent[]
}) {
  return (
    <View
      style={{
        marginTop: 20,
        paddingLeft: 48,
        paddingRight: 32,
        marginBottom: 40,
      }}
    >
      <View
        style={{
          borderRadius: 8,
          paddingHorizontal: 24,
          backgroundColor: Colors.bgBlack,
        }}
      >
        {secondaryComponents.map((component, index) => (
          <View key={component.id} style={[{}]}>
            <Row
              style={[
                {
                  paddingTop: 24,
                  paddingBottom: 24,
                },
                index > 0 && {
                  paddingTop: 24,

                  borderTopWidth: 0.5,
                  borderTopColor: Colors.border,
                },
              ]}
            >
              <RowName name={component.name} fontSize={18} fontBold />
              <RowAmount
                amount={component.amount}
                unit={component.unit}
                fontSize={18}
                fontBold
              />
            </Row>
            {component.name === t('客単価') && (
              <Row
                style={[
                  {
                    borderTopWidth: 0.5,
                    borderTopColor: Colors.border,
                    paddingTop: 12,
                    paddingBottom: 24,
                  },
                ]}
              >
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '300',
                  }}
                >
                  {t(
                    '※設定画面より注文時に来店人数の入力を必須にしていない場合、人数が確定している注文から算出しています'
                  )}
                </Text>
              </Row>
            )}
            {component.components?.map((subComponent, index) => (
              <View
                key={index.toString()}
                style={{
                  marginLeft: 24,
                  borderTopWidth: 0.5,
                  borderTopColor: Colors.border,
                }}
              >
                <Row
                  style={{
                    paddingVertical: 24,
                  }}
                >
                  <RowName name={subComponent.name} fontSize={18} />
                  <RowAmount amount={subComponent.amount} fontSize={18} />
                </Row>
              </View>
            ))}
          </View>
        ))}
      </View>
    </View>
  )
}

function ReportRowComponents({
  components,
}: {
  components: ReportRowComponent[]
}) {
  return (
    <View
      style={{
        paddingLeft: 48,
        paddingRight: 32,
      }}
    >
      {components.map((component) => {
        return (
          <View
            key={component.id}
            style={[
              {
                paddingTop: 24,
                borderTopWidth: 0.5,
                borderTopColor: Colors.border,
              },
            ]}
          >
            <Row>
              <RowName name={component.name} fontSize={18} fontBold />
              <RowAmount
                amount={component.amount}
                unit={component.unit}
                fontSize={18}
                fontBold
              />
            </Row>
            <View
              style={{
                marginLeft: 28,
                marginTop: 24,
              }}
            >
              {component.components != null && (
                <ReportRowSubComponents components={component.components} />
              )}
            </View>
          </View>
        )
      })}
    </View>
  )
}

function ReportRowTitle({
  name,
  amount,
  highlight = false,
}: {
  name: string
  amount: number | null
  highlight?: boolean
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: 32,
          backgroundColor: 'white',
        },
        highlight && {
          backgroundColor: Colors.accentBg,
          height: 100,
        },
      ]}
    >
      <Text
        style={{
          fontWeight: '600',
        }}
      >
        {name}
      </Text>

      <Text
        style={[
          {
            fontWeight: '600',
          },
          amount != null &&
            amount < 0 && {
              color: Colors.caution,
            },
        ]}
      >
        {t('{{price}}円', { price: numberWithDelimiter(amount ?? '-') })}
      </Text>
    </View>
  )
}

export function ReportRowView({ item }: { item: ReportRow }) {
  return (
    <View>
      <ReportRowTitle
        name={item.name}
        amount={item.amount}
        highlight={item.highlight}
      />
      {item.components != null && (
        <ReportRowComponents components={item.components} />
      )}
      {item.secondary_components != null && (
        <ReportRowSecondaryComponents
          secondaryComponents={item.secondary_components}
        />
      )}
    </View>
  )
}
