import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { toDoubleDigits } from './number'

export function toHoursAndMinutes(seconds: number) {
  return {
    hours: Math.floor(seconds / 3600),
    minutes: Math.floor((seconds % 3600) / 60),
  }
}

export function getFormatTime(seconds: number) {
  const { hours: hour, minutes: minute } = toHoursAndMinutes(seconds)
  return `${toDoubleDigits(hour)}:${toDoubleDigits(minute)}`
}

export function getFormatDate(day: dayjs.Dayjs): string {
  return day.format('YYYY-MM-D')
}

export function toSeconds(hours: number, mins: number) {
  return hours * 3600 + mins * 60
}

export function wdayName(wday: number) {
  if (wday === 7) {
    return t('祝日')
  }

  return dayjs.weekdays()[wday]
}

export function showDayUnlessToday(time: string) {
  return dayjs(time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')
    ? dayjs(time).format('HH:mm')
    : dayjs(time).format('M/D HH:mm')
}

export function round(
  time: dayjs.Dayjs,
  interval: number,
  unit: dayjs.UnitType
) {
  return time
    .startOf(unit)
    .set(unit, Math.round(time.get(unit) / interval) * interval)
}

export function floor(
  time: dayjs.Dayjs,
  interval: number,
  unit: dayjs.UnitType
) {
  return time
    .startOf(unit)
    .set(unit, Math.floor(time.get(unit) / interval) * interval)
}

export function ceil(
  time: dayjs.Dayjs,
  interval: number,
  unit: dayjs.UnitType
) {
  return time
    .startOf(unit)
    .set(unit, Math.ceil(time.get(unit) / interval) * interval)
}
