import React from 'react'
import { useParams } from 'react-router-dom'

import { View } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { Notifications } from '@hello-ai/for_r_app/src/components/Reservations/Notifications'

function ReservationNotificationsPage() {
  const { id: restaurantId } = useParams<{
    id: string
  }>()
  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <Notifications restaurantId={parseInt(restaurantId!)} />
    </View>
  )
}

export default ReservationNotificationsPage
