import React, { ComponentProps, useEffect, useMemo, useState } from 'react'

import { faQuestionCircle } from '@fortawesome/pro-regular-svg-icons/faQuestionCircle'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch'
import { View, Platform } from 'react-native'

import AsyncButton from '@hello-ai/ar_shared/src/components/AsyncButton'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { Popover } from '@hello-ai/ar_shared/src/components/Popover'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInputWithIcon } from '@hello-ai/ar_shared/src/components/TextInputWithIcon'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useTableMenuPages } from '../../../models/TableMenuPage'
import {
  bulkUpdateProductStock,
  updateTableProductStock,
  useRestaurantTableProducts,
} from '../../../models/TableProduct'
import { useThrottle } from '../../../modules/useThrottle'
import { AddFloatingButton } from '../../Shared/AddButton'
import { displayToastError, displayToastSuccess } from '../../Shared/Toast'
import GroupCautionMessage from '../GroupCautionMessage'

import TableProductListItem from './TableProductListItem'

const filterOptionValues = {
  all: 'all',
  inStock: 'inStock',
  soldout: 'soldout',
}
const filterOptions: Array<SelectItem<string>> = [
  {
    value: filterOptionValues.all,
    label: t('すべて'),
  },
  {
    value: filterOptionValues.inStock,
    label: t('注文可能'),
  },
  {
    value: filterOptionValues.soldout,
    label: t('売り切れ'),
  },
]

const DEFAULT_TABLE_MENU = {
  label: t('すべてのカテゴリー'),
  value: null,
}

const SORT_ORDER_OPTIONS: SelectItem<string>[] = [
  {
    value: 'created_at_asc',
    label: t('追加が古い順'),
  },
  {
    value: 'created_at_desc',
    label: t('追加が新しい順'),
  },
  {
    value: 'menu_page_asc_order',
    label: t('カテゴリー順'),
  },
]
interface TableProductListViewProps
  extends Pick<
    ComponentProps<typeof TableProductListItem>,
    'restaurantId' | 'restaurantCurrency' | 'onPressListItem'
  > {
  onPressAddTableProduct: () => void
}

export default function TableProductListView({
  restaurantId,
  restaurantCurrency,
  onPressListItem,
  onPressAddTableProduct,
}: TableProductListViewProps) {
  const { width, sm } = useResponsive()
  const token = useToken()

  const [keyword, setKeyword] = useState('')
  const debouncedKeyword = useThrottle(keyword)

  const [isAvailabilityHelpOpen, setIsAvailabilityHelpOpen] = useState(false)

  const [filter, setFilter] = useState<string>(filterOptions[0].value)
  const [changedStockByProductId, setChangedStockByProductId] = useState<
    Record<string, number>
  >({})
  const onFilter = (v: string) => {
    setFilter(v)
    setProductsCurrentPage(1)
  }
  const [parentTableMenuPageId, setParentTableMenuPageId] = useState<
    string | null
  >(null)
  const onChangeParentTableMenuPageId = (v: string | null) => {
    setParentTableMenuPageId(v)
    setProductsCurrentPage(1)
  }

  const [sortOrder, setSortOrder] = useState<string>(
    SORT_ORDER_OPTIONS[0].value
  )
  const onChangeSortOrder = (v: string) => {
    setSortOrder(v)
    setProductsCurrentPage(1)
  }

  const [productsCurrentPage, setProductsCurrentPage] = useState(1)

  const { tableProducts, headerData, mutate } = useRestaurantTableProducts({
    restaurantId,
    params: {
      type: 'paging',
      page: productsCurrentPage,
      keyword: debouncedKeyword,
      parent_menu_page_id: parentTableMenuPageId,
      in_stock:
        filter === filterOptionValues.all
          ? undefined
          : filter === filterOptionValues.inStock,
      sort_type: sortOrder,
    },
  })
  const { tableMenuPages, isLoading: isLoadingMenuPages } = useTableMenuPages({
    restaurantId,
    params: {
      per_page: 9999,
    },
  })

  const tableMenuParents = useMemo(() => {
    if (isLoadingMenuPages || tableMenuPages == null) {
      return [DEFAULT_TABLE_MENU]
    }
    const childTableMenuPageIds = tableMenuPages
      .filter(
        (menuPage) =>
          menuPage.table_menu_pages != null &&
          menuPage.table_menu_pages.length > 0
      )
      .map((menuPage) =>
        menuPage.table_menu_pages?.map((childMenuPage) => childMenuPage.id)
      )
      .flat()

    const menuPages = tableMenuPages
      .filter((page) => {
        return !childTableMenuPageIds.includes(page.id)
      })
      .map((page) => {
        return {
          value: page.id,
          label: page.name,
        }
      })
    return [DEFAULT_TABLE_MENU, ...menuPages]
  }, [isLoadingMenuPages, tableMenuPages])

  const searchProducts = (text: string) => {
    setKeyword(text)
    setProductsCurrentPage(1)
  }

  const hasGroupManagedItem = tableProducts?.some((item) => {
    return item.group_managed
  })

  const isLoading = tableProducts == null || headerData == null

  useEffect(() => {
    setChangedStockByProductId({})
  }, [
    productsCurrentPage,
    debouncedKeyword,
    parentTableMenuPageId,
    filter,
    sortOrder,
  ])

  return (
    <View
      style={[
        { position: 'relative', flex: 1 },
        Platform.OS === 'web' && { flex: 1, backgroundColor: Colors.bgBlack },
      ]}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={[
          width < sm ? { padding: 24 } : { padding: 32 },
          { paddingBottom: 100 },
        ]}
      >
        {hasGroupManagedItem && (
          <View
            style={{
              marginBottom: 20,
            }}
          >
            <GroupCautionMessage />
          </View>
        )}
        <View style={{ rowGap: 16 }}>
          <TextInputWithIcon
            value={keyword}
            onChangeText={(text) => searchProducts(text)}
            autoCapitalize="none"
            icon={faSearch}
            placeholder={t('商品名で検索')}
            style={[
              {
                backgroundColor: Colors.white,
                borderColor: Colors.border,
                borderRadius: 8,
                borderWidth: 0.5,
                flex: 1,
                flexGrow: 1,
                fontSize: 18,
                height: 48,
                paddingVertical: 14,
              },
            ]}
            containerStyle={{ flex: 1 }}
          />
          <View
            style={{
              flexDirection: 'row',
              columnGap: width >= sm ? 24 : 8,
              alignItems: 'center',
            }}
          >
            <View
              style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}
            >
              {width >= sm && (
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    lineHeight: 22,
                    marginRight: 16,
                  }}
                >
                  {t('カテゴリー')}
                </Text>
              )}
              <SelectInput
                selectedValue={parentTableMenuPageId}
                setValue={onChangeParentTableMenuPageId}
                items={tableMenuParents}
                labelStyle={{
                  color: Colors.black,
                  fontSize: 14,
                  lineHeight: 21,
                }}
                style={{
                  flex: width < sm ? 1 : undefined,
                  backgroundColor: Colors.white,
                  borderColor: Colors.border,
                  borderRadius: 8,
                  borderWidth: 0.5,
                  paddingVertical: 12,
                }}
                containerStyle={{ flex: 1 }}
                indicatorIcon={
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size={10}
                    color={Colors.black}
                  />
                }
              />
            </View>
            <View
              style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}
            >
              {width >= sm && (
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    lineHeight: 22,
                    marginRight: 16,
                  }}
                >
                  {t('表示')}
                </Text>
              )}
              <SelectInput
                selectedValue={filter}
                setValue={onFilter}
                items={filterOptions}
                labelStyle={{
                  color: Colors.black,
                  fontSize: 14,
                  lineHeight: 21,
                }}
                style={{
                  flex: width < sm ? 1 : undefined,
                  backgroundColor: Colors.white,
                  borderColor: Colors.border,
                  borderRadius: 8,
                  borderWidth: 0.5,
                  paddingVertical: 12,
                }}
                containerStyle={{ flex: width < sm ? 1 : 1 }}
                indicatorIcon={
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    size={10}
                    color={Colors.black}
                  />
                }
              />
            </View>
            {width >= sm && (
              <View
                style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}
              >
                {width >= sm && (
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '600',
                      lineHeight: 22,
                      marginRight: 16,
                    }}
                  >
                    {t('並び替え')}
                  </Text>
                )}
                <SelectInput
                  selectedValue={sortOrder}
                  setValue={onChangeSortOrder}
                  items={SORT_ORDER_OPTIONS}
                  labelStyle={{
                    color: Colors.black,
                    fontSize: 14,
                    lineHeight: 21,
                  }}
                  style={{
                    backgroundColor: Colors.white,
                    borderColor: Colors.border,
                    borderRadius: 8,
                    borderWidth: 0.5,
                  }}
                  containerStyle={{ flex: 1 }}
                  indicatorIcon={
                    <FontAwesomeIcon
                      icon={faChevronDown}
                      size={10}
                      color={Colors.black}
                    />
                  }
                />
              </View>
            )}
          </View>
          {width < sm && (
            <SelectInput
              selectedValue={sortOrder}
              setValue={onChangeSortOrder}
              items={SORT_ORDER_OPTIONS}
              labelStyle={{
                color: Colors.black,
                fontSize: 14,
                lineHeight: 21,
              }}
              style={{
                backgroundColor: Colors.white,
                borderColor: Colors.border,
                borderRadius: 8,
                borderWidth: 0.5,
                marginBottom: 6,
              }}
              containerStyle={{ flex: 1 }}
              indicatorIcon={
                <FontAwesomeIcon
                  icon={faChevronDown}
                  size={10}
                  color={Colors.black}
                />
              }
            />
          )}
          {width < sm && (
            <Text style={{ fontSize: 14, color: Colors.black60 }}>
              {t(
                '「注文可能」をオンにすると、商品がお客様から注文可能になります。販売停止にする場合はオフにしてください。'
              )}
            </Text>
          )}
          <View
            style={[
              {
                borderRadius: 8,
                shadowRadius: 4,
                elevation: 4,
                shadowColor: '#000',
                shadowOpacity: 0.1,
                shadowOffset: { width: 0, height: 2 },
                backgroundColor: 'white',
              },
            ]}
          >
            {width >= sm && (
              <View
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: 16,
                  paddingLeft: 16,
                  paddingRight: 52,
                  borderBottomWidth: 0.5,
                  borderColor: Colors.border,
                  gap: 20,
                }}
              >
                <View
                  style={{
                    width: 80,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 4,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '600',
                      color: Colors.black,
                    }}
                  >
                    {t('注文可能')}
                  </Text>
                  <Popover
                    isOpen={isAvailabilityHelpOpen}
                    anchor={
                      <TouchableOpacity
                        onPress={() =>
                          setIsAvailabilityHelpOpen(!isAvailabilityHelpOpen)
                        }
                      >
                        <FontAwesomeIcon
                          icon={faQuestionCircle}
                          size={16}
                          color={Colors.primary}
                        />
                      </TouchableOpacity>
                    }
                    onClose={() => setIsAvailabilityHelpOpen(false)}
                  >
                    <View style={{ padding: 16 }}>
                      <Text style={{ fontSize: 14, color: Colors.black }}>
                        {t(
                          '「注文可能」をオンにすると、商品がお客様から注文可能になります。販売停止にする場合はオフにしてください。'
                        )}
                      </Text>
                    </View>
                  </Popover>
                </View>
                <View
                  style={{
                    width: 120,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '600',
                      lineHeight: 22,
                    }}
                  >
                    {t('カテゴリー')}
                  </Text>
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: '300',
                    }}
                  >
                    {t('子カテゴリー')}
                  </Text>
                </View>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    lineHeight: 22,
                    flex: 1,
                  }}
                >
                  {t('商品名')}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    width: 64,
                    textAlign: 'center',
                  }}
                >
                  {t('管理方法')}
                </Text>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '600',
                    width: 80,
                    textAlign: 'center',
                  }}
                >
                  {t('在庫数')}
                </Text>
              </View>
            )}
            {isLoading && (
              <View style={{ padding: 24 }}>
                <Loading />
              </View>
            )}
            {!isLoading &&
              tableProducts?.length > 0 &&
              tableProducts
                .filter((tableProduct) => {
                  if (filter === filterOptionValues.all) {
                    return true
                  } else if (
                    filter === filterOptionValues.inStock &&
                    tableProduct.in_stock
                  ) {
                    return true
                  } else if (
                    filter === filterOptionValues.soldout &&
                    !tableProduct.in_stock
                  ) {
                    return true
                  }
                  return false
                })
                .map((tableProduct, index) => {
                  return (
                    <View
                      key={tableProduct.id}
                      style={{
                        borderTopWidth: index === 0 ? 0 : 0.5,
                        borderColor: Colors.border,
                        paddingVertical: 16,
                        paddingHorizontal: 16,
                      }}
                    >
                      <TableProductListItem
                        restaurantId={restaurantId}
                        restaurantCurrency={restaurantCurrency}
                        onChangeInStock={async (tableProductId, params) => {
                          if (token == null) return
                          const { error } = await updateTableProductStock({
                            token,
                            tableProductId,
                            params,
                          })
                          if (error != null) {
                            return
                          }
                          mutate()
                        }}
                        tableProduct={tableProduct}
                        right={
                          <FontAwesomeIcon
                            icon={faChevronRight}
                            size={12}
                            color={Colors.black}
                          />
                        }
                        onPressListItem={onPressListItem}
                        stockValue={
                          changedStockByProductId[tableProduct.id] ??
                          tableProduct.current_stock_quantity
                        }
                        onUpdateStockValue={(value) =>
                          setChangedStockByProductId({
                            ...changedStockByProductId,
                            [tableProduct.id]: value,
                          })
                        }
                      />
                    </View>
                  )
                })}
            {!isLoading && tableProducts.length === 0 && (
              <Text
                style={{
                  padding: 24,
                  fontSize: 22,
                  fontWeight: '600',
                  color: Colors.disabledBlack,
                  textAlign: 'center',
                }}
              >
                {t('該当する商品はありません')}
              </Text>
            )}
            <View
              style={{
                height: 0.5,
                backgroundColor: Colors.border,
                marginHorizontal: 16,
              }}
            />
            <View
              style={{
                paddingTop: 24,
                marginBottom: 16,
              }}
            >
              <Pagination
                currentPage={productsCurrentPage}
                totalPage={headerData?.totalPages ?? 0}
                setPage={(page) => setProductsCurrentPage(page)}
              />
            </View>
          </View>
        </View>
      </KeyboardAwareScrollView>
      {Object.keys(changedStockByProductId).length === 0 && (
        <AddFloatingButton
          title={width >= sm ? t('商品追加') : t('追加')}
          onPress={onPressAddTableProduct}
        />
      )}
      {Object.keys(changedStockByProductId).length > 0 && (
        <View
          style={[
            {
              justifyContent: 'center',
              backgroundColor: Colors.white,
              borderTopWidth: 1,
              borderTopColor: Colors.black16,
              paddingHorizontal: 16,
              position: 'absolute',
              bottom: 0,
              right: 0,
              left: 0,
            },
            width < sm
              ? {
                  height: 140,
                  gap: 12,
                  alignItems: 'stretch',
                  paddingTop: 12,
                  paddingBottom: 24,
                }
              : {
                  paddingTop: 12,
                  paddingBottom: 24,
                  flexDirection: 'row-reverse',
                  gap: 16,
                  alignItems: 'center',
                },
          ]}
        >
          <AsyncButton
            mode="contained"
            height={48}
            width={width < sm ? undefined : 300}
            onPress={async () => {
              if (token != null) {
                const { error } = await bulkUpdateProductStock(
                  token,
                  restaurantId,
                  Object.keys(changedStockByProductId).map((id) => ({
                    product_id: id,
                    after_quantity: changedStockByProductId[id],
                  }))
                )
                if (error != null) {
                  displayToastError(t('在庫数の変更に失敗しました'))
                  return
                }
                displayToastSuccess(t('在庫数を更新しました'))
                await mutate()
                setChangedStockByProductId({})
              }
            }}
          >
            {t('在庫数の変更を保存する')}
          </AsyncButton>
          <Button
            mode="outline"
            height={48}
            width={width < sm ? undefined : 300}
            onPress={() => setChangedStockByProductId({})}
          >
            {t('キャンセル')}
          </Button>
        </View>
      )}
    </View>
  )
}
