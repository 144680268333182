import React, { useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { View, ScrollView } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import {
  Row,
  Header,
  HeaderCell,
  TextCell,
  TextCellWithDetail,
} from 'components/Sales'
import {
  getFormatLabel,
  SelectPeriodInput,
} from 'components/Sales/SelectPeriodInput'
import { CSVDownloadButton } from 'components/Shared/CsvDownloadButton'
import {
  SalesByMenu as SalesByMenuModel,
  useRestaurantSalesByMenu,
} from 'models/Sales'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import { useRestaurantId } from 'modules/useRestaurantId'

const CSVHeaders = [
  { value: 'name', label: t('メニュー名') },
  { value: 'total_amount', label: t('総売上') },
  { value: 'total_amount_ratio', label: t('構成比(総売上)') },
  { value: 'average_amount_per_customer', label: t('客単価') },
]

function convertDataFormat(rows: SalesByMenuModel[]) {
  return rows.map((row) => {
    return {
      name: row.name,
      total_amount: numberWithDelimiter(row.total_amount),
      total_amount_ratio: (row.total_amount_ratio * 100).toFixed(0) + '%',
      average_amount_per_customer: numberWithDelimiter(
        row.average_amount_per_customer
      ),
    }
  })
}

function getFetchPeriodParams({
  start,
  end,
}: {
  start: dayjs.Dayjs
  end: dayjs.Dayjs
}): { gte: string; lte: string } {
  return {
    gte: start.toISOString(),
    lte: end.toISOString(),
  }
}

function SalesByMenu() {
  const restaurantId = useRestaurantId()
  const today = dayjs()

  const [selectedValue, setSelectedValue] = useState({
    start: today,
    end: today,
  })

  const [fetchParams, setFetchParams] = useState(selectedValue)

  const onChangeValues = (values: { start: dayjs.Dayjs; end: dayjs.Dayjs }) => {
    setSelectedValue(values)
  }

  const params = useMemo(() => {
    return {
      completed_at: getFetchPeriodParams(fetchParams),
    }
  }, [fetchParams])

  const { salesByMenu } = useRestaurantSalesByMenu(restaurantId, params)

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{
        paddingVertical: 32,
        paddingHorizontal: 48,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            marginRight: 24,
            flex: 1,
          }}
        >
          <SelectPeriodInput
            disabled={salesByMenu === undefined}
            style={{
              backgroundColor: 'white',
              borderRadius: 8,
              height: 48,
            }}
            labelStyle={{
              fontSize: 18,
              fontWeight: '300',
            }}
            selectedStartValue={selectedValue.start}
            selectedEndValue={selectedValue.end}
            onChangeValues={onChangeValues}
          />
        </View>
        <Button
          mode="contained"
          variant="secondary"
          disabled={salesByMenu === undefined}
          width={160}
          height={48}
          roundness={24}
          onPress={() => {
            setFetchParams(selectedValue)
          }}
        >
          <Text style={{ fontWeight: '600', color: Colors.primary }}>
            {t('表示する')}
          </Text>
        </Button>
      </View>
      <View
        style={{
          marginTop: 32,
          borderBottomWidth: 0.5,
          borderBottomColor: Colors.border,
        }}
      />
      <View
        style={{
          marginTop: 38,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <View>
          <Text
            style={{
              fontSize: 18,
              fontWeight: '600',
              letterSpacing: -0.5,
              color: Colors.black,
            }}
          >
            {t('全てのメニュー')}
          </Text>
          <Text
            style={{
              fontSize: 24,
              fontWeight: '600',
              color: Colors.black,
            }}
          >
            {getFormatLabel(fetchParams.start)} ~{' '}
            {getFormatLabel(fetchParams.end)}
          </Text>
        </View>
        <CSVDownloadButton
          data={
            salesByMenu !== undefined
              ? convertDataFormat(salesByMenu)
              : undefined
          }
          headers={CSVHeaders}
          fileName="sales_by_menu"
        />
      </View>
      <ShadowBox
        style={[
          {
            marginTop: 24,
            padding: 0,
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          },
        ]}
      >
        <Header>
          <HeaderCell value={t('メニュー名')} />
          <HeaderCell value={t('総売上')} />
          <HeaderCell value={t('客単価')} />
        </Header>
        {salesByMenu.map((d, i) => {
          return (
            <Row
              key={d.id}
              style={[
                {
                  paddingVertical: 10,
                },
                i !== salesByMenu.length - 1 && {
                  borderBottomWidth: 1,
                  borderBottomColor: Colors.border,
                },
              ]}
            >
              <TextCell numberOfLines={2} value={d.name} />
              <TextCellWithDetail
                value={t('{{price}}円', {
                  price: numberWithDelimiter(d.total_amount),
                })}
                detailValue={(d.total_amount_ratio * 100).toFixed(0) + '%'}
              />
              <TextCell
                value={t('{{price}}円', {
                  price: numberWithDelimiter(d.average_amount_per_customer),
                })}
              />
            </Row>
          )
        })}
      </ShadowBox>
    </ScrollView>
  )
}

export default SalesByMenu
