import React, { forwardRef, useImperativeHandle } from 'react'

import { faDeleteLeft } from '@fortawesome/pro-light-svg-icons/faDeleteLeft'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { View, StyleProp, ViewStyle, TextStyle } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

interface ButtonProps {
  style: StyleProp<ViewStyle>
  text?: string | null
  textStyle?: StyleProp<TextStyle>
  onPress: () => void
  disabled?: boolean
  children?: React.ReactNode
}

function Button({
  style,
  text = null,
  textStyle = null,
  onPress,
  disabled = false,
  children = null,
}: ButtonProps) {
  const { width, sm } = useResponsive()
  return (
    <TouchableOpacity
      disabled={disabled}
      style={[
        {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 4,
          maxHeight: width >= sm ? 72 : 44,
        },
        style,
      ]}
      onPress={onPress}
      onPressMinInterval={0}
    >
      {children != null ? (
        children
      ) : (
        <Text style={[{ color: Colors.white, fontSize: 24 }, textStyle]}>
          {text}
        </Text>
      )}
    </TouchableOpacity>
  )
}

const defaultSetEnter = () => {}

// NOTE: 金額提案ボタンの切り上げ桁数
const SUGGEST_ROUNDED_SIZE = 3
// NOTE: これ以下の金額は固定値を返す
const MINIUM_SUGGEST_ROUNDED_AMOUNT = 1000
/**
 * @description 金額提案ボタンは常に100の位で切り上げ。実際の金額と提案する値は以下の通り。
 * - 800円→1,000円
 * - 1,200円→2,000円
 * - 14,300円→15,000円
 * @param value
 * @returns 提案する金額
 */
function getSuggestRoundedAmount(value: number): number {
  if (value < MINIUM_SUGGEST_ROUNDED_AMOUNT)
    return MINIUM_SUGGEST_ROUNDED_AMOUNT

  // 繰り上げるべき桁数を計算
  const roundTo = Math.pow(10, SUGGEST_ROUNDED_SIZE)
  // 既に繰り上がっているかどうかを確認
  if (value % roundTo === 0) {
    return value
  }
  // 未繰り上げであれば、繰り上げを行う
  return Math.ceil(value / roundTo) * roundTo
}

const MAXIMUM_BILL = 10000

/**
 * @description 0を食いつぶしながら末尾に数字を追加する
 * @param prevValue
 * @param pressNumber
 * @returns
 */
function appendNumberIntoBill(prevValue: number, pressNumber: string): number {
  let tmp = prevValue

  switch (pressNumber) {
    case '0':
      tmp *= 10
      break
    case '00':
      tmp *= 100
      break
    default: {
      const tmpArr = tmp.toString().split('')
      const lastZeroIndex = tmpArr.lastIndexOf('0')
      if (lastZeroIndex === -1) {
        // NOTE: 0がない場合は、桁上げして加算する
        tmp *= 10
        tmp += Number(pressNumber)
      } else {
        // NOTE: 0がある場合は、0を削除して末尾追加する。例: 10506の時6を押すと10566になる
        tmpArr.splice(lastZeroIndex, 1)
        const tmpStr = tmpArr.join('')
        tmp = Number(`${tmpStr}${pressNumber}`)
      }
      break
    }
  }
  return tmp
}

function appendNumberIntBaseBill(
  prevValue: number,
  pressNumber: string,
  /**
   * この金額桁数以上には追加できない
   * nullの場合は無制限。0を食いつぶす機能も無効になる
   *
   * 例: 10,000円の場合、19,999円以上は追加できない
   * 例: 9,000円の場合、9,999円以上は追加できない
   */
  baseBill: number | null
) {
  let tmp = Number(prevValue) - (baseBill ?? 0) // NOTE: baseBillを引くのは、0押下で10倍するため
  tmp =
    baseBill != null
      ? appendNumberIntoBill(tmp, pressNumber)
      : Number(`${tmp}${pressNumber}`)
  if (baseBill != null) {
    if (`${baseBill}`.length - 1 < `${tmp}`.length) return prevValue
    tmp += baseBill
  }
  return tmp
}

export interface CalculatorRefProps {
  reset: () => void
}

interface CalculatorProps {
  value: string
  setValue: (value: string) => void
  disabledNumber?: boolean
  enter?: boolean // trueにすると次の数字入力が最初からになる
  setEnter?: (value: boolean) => void
  suggestionBaseValue?: string
  // 繰り上げ金額提案
  enableSuggestions?: boolean
  // 最大金額ショートカット
  enableMaxBill?: boolean
  /**
   * @description delキーを無効にする
   * @default false
   */
  disableDelKey?: boolean
  disableClearKey?: boolean
}

export default forwardRef<CalculatorRefProps, CalculatorProps>(
  function Calculator(
    {
      value,
      setValue,
      disabledNumber = false,
      enter = false,
      setEnter = defaultSetEnter,
      enableSuggestions = false,
      enableMaxBill = false,
      suggestionBaseValue = value,
      disableDelKey = false,
      disableClearKey = false,
    },
    ref
  ) {
    // NOTE: 最大金額ショートカットボタンを押した時に、次の数字入力は一万円の中で追加する
    const [maximumPressedCount, setMaximumPressedCount] = React.useState(0)
    const [suggestionPressedCount, setSuggestionPressedCount] =
      React.useState(0)

    const suggestRoundedAmount = getSuggestRoundedAmount(
      Number(suggestionBaseValue)
    )

    useImperativeHandle(
      ref,
      () => ({
        reset: () => {
          setMaximumPressedCount(0)
          setSuggestionPressedCount(0)
        },
      }),
      []
    )

    const onPressNumber = (pressNumber: string) => {
      if (enter) {
        setEnter(false)
      }
      let baseBill: number | null = null
      if (suggestionPressedCount > 0) {
        baseBill = suggestRoundedAmount
      }
      if (maximumPressedCount > 0) {
        baseBill = MAXIMUM_BILL * maximumPressedCount
      }

      switch (pressNumber) {
        case 'del': {
          if (value.length <= 1) {
            setValue('0')
            return
          }
          const newNumber = value.slice(0, -1)
          setMaximumPressedCount(0)
          setSuggestionPressedCount(0)
          setValue(newNumber)
          return
        }
        case 'clear': {
          setMaximumPressedCount(0)
          setSuggestionPressedCount(0)
          setValue('0')
          return
        }
        case 'suggestion': {
          setValue(suggestRoundedAmount.toString())
          setMaximumPressedCount(0)
          setSuggestionPressedCount(1)
          return
        }
        case 'maximum_bill': {
          setMaximumPressedCount((prev) => prev + 1)
          setSuggestionPressedCount(0)
          if (enter) {
            setValue(MAXIMUM_BILL.toString())
          } else {
            setValue((Number(value) + MAXIMUM_BILL).toString())
          }
          return
        }
        case '0':
        case '00': {
          if (value === '0' || enter) {
            setValue('0')
            return
          }
          const newValue = appendNumberIntBaseBill(
            Number(value),
            pressNumber,
            baseBill
          )
          setValue(newValue.toString())
          return
        }
        default: {
          if (value === '0' || enter) {
            setValue(pressNumber)
          } else {
            const newValue = appendNumberIntBaseBill(
              Number(value),
              pressNumber,
              baseBill
            )
            setValue(newValue.toString())
          }
        }
      }
    }
    const valueBgColor = disabledNumber ? Colors.black40 : Colors.black

    const { width, sm } = useResponsive()
    const columnGap = width >= sm ? 10 : 8

    const _disabledSuggestions =
      !enableSuggestions || suggestRoundedAmount === MAXIMUM_BILL

    const rowGap = width >= sm ? 12 : 8
    const buttonMaxHeight = width >= sm ? 72 : 44
    const rowCount = 5
    const maxHeight = buttonMaxHeight * rowCount + rowGap * (rowCount - 1)

    return (
      <View style={{ flex: 1, maxHeight, rowGap }}>
        <View style={{ flex: 1, flexDirection: 'row', columnGap }}>
          <Button
            disabled={_disabledSuggestions}
            style={{
              backgroundColor: _disabledSuggestions
                ? Colors.black40
                : Colors.black,
            }}
            text={
              _disabledSuggestions ? '' : suggestRoundedAmount.toLocaleString()
            }
            onPress={() => onPressNumber('suggestion')}
          />
          <Button
            disabled={!enableMaxBill}
            style={{
              backgroundColor: !enableMaxBill ? Colors.black40 : Colors.black,
            }}
            text={!enableMaxBill ? '' : MAXIMUM_BILL.toLocaleString()}
            onPress={() => onPressNumber('maximum_bill')}
          />
          <Button
            style={{
              backgroundColor: disableDelKey ? Colors.black40 : Colors.accent,
            }}
            disabled={disableDelKey}
            onPress={() => onPressNumber('del')}
          >
            {!disableDelKey && (
              <FontAwesomeIcon
                icon={faDeleteLeft}
                size={32}
                color={Colors.white}
              />
            )}
          </Button>
        </View>
        <View style={{ flex: 1, flexDirection: 'row', columnGap }}>
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="7"
            onPress={() => onPressNumber('7')}
          />
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="8"
            onPress={() => onPressNumber('8')}
          />
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="9"
            onPress={() => onPressNumber('9')}
          />
        </View>
        <View style={{ flex: 1, flexDirection: 'row', columnGap }}>
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="4"
            onPress={() => onPressNumber('4')}
          />
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="5"
            onPress={() => onPressNumber('5')}
          />
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="6"
            onPress={() => onPressNumber('6')}
          />
        </View>
        <View style={{ flex: 1, flexDirection: 'row', columnGap }}>
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="1"
            onPress={() => onPressNumber('1')}
          />
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="2"
            onPress={() => onPressNumber('2')}
          />
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="3"
            onPress={() => onPressNumber('3')}
          />
        </View>
        <View style={{ flex: 1, flexDirection: 'row', columnGap }}>
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="0"
            onPress={() => onPressNumber('0')}
          />
          <Button
            disabled={disabledNumber}
            style={{
              backgroundColor: valueBgColor,
            }}
            text="00"
            onPress={() => onPressNumber('00')}
          />
          <Button
            disabled={disableClearKey}
            style={{
              backgroundColor: disableClearKey
                ? Colors.caution20
                : Colors.caution,
            }}
            text="C"
            onPress={() => onPressNumber('clear')}
          />
        </View>
      </View>
    )
  }
)
