import React from 'react'

import { View } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import ArchivedListView from '@hello-ai/for_r_app/src/components/Restaurant/TableProduct/ArchivedListView'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableProductsScreen() {
  const restaurantId = useRestaurantId()

  return (
    <View style={{ backgroundColor: Colors.bgBlack }}>
      <ArchivedListView restaurantId={restaurantId} />
    </View>
  )
}
