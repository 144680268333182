import React from 'react'

import { View, StyleProp, ViewStyle, ColorValue } from 'react-native'

import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

export function SegmentedControl({
  buttons,
  tintColor,
  style,
}: {
  buttons: {
    onPress: () => void
    selected: boolean
    Component: React.ReactNode
  }[]
  tintColor?: ColorValue
  style?: StyleProp<ViewStyle>
}) {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          borderWidth: 1,
          borderColor: tintColor ?? Colors.accent,
          borderRadius: 8,
          alignItems: 'stretch',
          justifyContent: 'space-between',
          overflow: 'hidden',
        },
        style,
      ]}
    >
      {buttons.map(({ onPress, selected, Component }, index) => (
        <TouchableOpacity
          key={index}
          onPress={onPress}
          style={{
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: selected
              ? (tintColor ?? Colors.accent)
              : Colors.white,
            borderColor: tintColor ?? Colors.accent,
            borderLeftWidth: index === 0 ? 0 : 1,
          }}
        >
          {Component}
        </TouchableOpacity>
      ))}
    </View>
  )
}
