import React from 'react'

import { useNavigate } from 'react-router'

import GourmetSiteSeatSettingCreate from '@hello-ai/for_r_app/src/components/GourmetSiteSiteSeat/GourmetSiteSeatSettingCreate'
import { displayToastSuccess } from '@hello-ai/for_r_app/src/components/Shared/Toast'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function GourmetSiteSeatSettingCreateScreen() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  return (
    <GourmetSiteSeatSettingCreate
      restaurantId={restaurantId}
      displayToastSuccess={displayToastSuccess}
      onSubmitSuccess={() => navigate(-1)}
    />
  )
}
