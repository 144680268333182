import React from 'react'

import { faCalendarCheck } from '@fortawesome/pro-regular-svg-icons/faCalendarCheck'
import { faCheckCircle } from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import { faEdit } from '@fortawesome/pro-solid-svg-icons/faEdit'
import { faFrown } from '@fortawesome/pro-solid-svg-icons/faFrown'
import { faPhone } from '@fortawesome/pro-solid-svg-icons/faPhone'
import { faTag } from '@fortawesome/pro-solid-svg-icons/faTag'
import { View, ColorValue, StyleProp, ViewStyle } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { Customer as CustomerModel } from '@hello-ai/ar_shared/src/types/ForR/Customer'
import { Restaurant } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

import { useCustomerProfile } from '../../models/Customer'
import { LabelColor } from '../../models/CustomerAttributes'
import { isAvailableShowVisitCount } from '../../models/Restaurant'

import { labelColors } from './Attributes'

const customerHeight = 80
const customerImageSize = 40
const selectedCustomerImageSize = 60

// レストランがお客さんの名前を見て呼ぶことがあるので、表示系は基本的に予約名などを使う必要がある
export function getCustomerDisplayName(
  customer: CustomerModel,
  suffix?: string
) {
  if ((customer.last_name ?? '') !== '' || (customer.first_name ?? '') !== '') {
    return [customer.last_name, customer.first_name]
      .filter((value) => value != null)
      .join(' ')
  }

  const joinedName = [
    customer.reservation_last_name,
    customer.reservation_first_name,
  ]
    .filter((value) => value != null)
    .join(' ')

  return joinedName ? `${joinedName}${suffix ?? ''}` : t('未設定')
}

export function getReservationName(customer: CustomerModel) {
  return [[customer.reservation_last_name, customer.reservation_first_name]]
    .filter((value) => value != null)
    .join(' ')
}

export function CustomerTypeLabel({
  style,
  backgroundColor,
  color,
  children,
}: {
  style?: StyleProp<ViewStyle>
  backgroundColor: ColorValue
  color: ColorValue
  children: string
}) {
  return (
    <View
      style={[
        {
          paddingHorizontal: 8,
          height: 20,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor,
        },
        style,
      ]}
    >
      <Text
        style={{
          fontSize: 12,
          fontWeight: '600',

          color,
        }}
      >
        {children}
      </Text>
    </View>
  )
}

export function Customer({
  onPressInfo,
  selection,
  customer,
  attributes,
  restaurant,
}: {
  onPressInfo: () => void
  selection: {
    selected: boolean
    onPress: () => void
  }
  customer: CustomerModel
  attributes?: {
    id: string
    display_in_list: boolean
    label_color: LabelColor
    name: string
  }[]
  restaurant: Pick<Restaurant, 'reservation_book_plan_type' | 'id'>
}) {
  const { width, sm } = useResponsive()
  const attributeIds =
    customer.profiles?.slice(-1)[0]?.custom_attributes?.split(',') ?? []
  const selectedAttributes = attributes?.filter((i) =>
    attributeIds.includes(i.id)
  )
  const { profile } = useCustomerProfile(restaurant.id, customer.id)

  return (
    <View
      style={[
        {
          paddingVertical: 16,
        },
        width > sm && {
          display: 'flex',
          minHeight: customerHeight,
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16,
        },
      ]}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          flex: 1,
          gap: 16,
        }}
      >
        {width > sm && (
          <Image
            source={{
              uri: customer.image_url,
            }}
            style={{
              width: customerImageSize,
              height: customerImageSize,
              borderRadius: customerImageSize / 2,
              backgroundColor: Colors.black10,
            }}
          />
        )}
        <View style={{ flex: 1, gap: 12 }}>
          <View
            style={[
              { gap: 8 },
              width <= sm && { flexDirection: 'row', alignItems: 'center' },
            ]}
          >
            {width <= sm && (
              <Image
                source={{
                  uri: customer.image_url,
                }}
                style={{
                  width: customerImageSize,
                  height: customerImageSize,
                  borderRadius: customerImageSize / 2,
                  backgroundColor: Colors.black10,
                }}
              />
            )}
            <View style={{ gap: 4 }}>
              <View
                style={[
                  width > sm
                    ? {
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 8,
                        flexWrap: 'wrap',
                      }
                    : {
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: 4,
                      },
                ]}
              >
                <Text
                  style={{
                    fontWeight: '600',
                    fontSize: 18,
                    lineHeight: 22,
                  }}
                >
                  {getCustomerDisplayName(customer)}
                </Text>
                <View
                  style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}
                >
                  <Text
                    style={[
                      {
                        fontSize: 12,
                        lineHeight: 18,
                        color: Colors.secondaryBlack,
                      },
                    ]}
                  >
                    {getReservationName(customer)}
                  </Text>
                  {customer.username != null && customer.username !== '' && (
                    <Text
                      style={{
                        fontSize: 12,
                        lineHeight: 18,
                        color: Colors.secondaryBlack,
                      }}
                    >
                      {customer.username}
                    </Text>
                  )}
                </View>
              </View>
              {(customer.favoriting || customer.user != null) && (
                <View
                  style={[
                    {
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: 4,
                      flexWrap: 'wrap',
                    },
                  ]}
                >
                  {customer.favoriting && (
                    <CustomerTypeLabel
                      backgroundColor={Colors.accentBg}
                      color={Colors.accent}
                    >
                      {t('フォロー中')}
                    </CustomerTypeLabel>
                  )}
                  {customer.user != null && (
                    <CustomerTypeLabel
                      backgroundColor={Colors.primaryBg}
                      color={Colors.primary}
                    >
                      {t('AutoReserveユーザー')}
                    </CustomerTypeLabel>
                  )}
                </View>
              )}
            </View>
          </View>
          <View style={{ gap: 4 }}>
            {selectedAttributes && selectedAttributes.length > 0 && (
              <View style={{ flexDirection: 'row', gap: 16 }}>
                <View style={{ marginTop: 2 }}>
                  <FontAwesomeIcon
                    icon={faTag}
                    color={Colors.black40}
                    size={16}
                  />
                </View>
                <View
                  style={{
                    flex: 1,
                    flexDirection: 'row',
                    gap: 4,
                    flexWrap: 'wrap',
                  }}
                >
                  {selectedAttributes.map((a) => (
                    <View
                      key={a.id}
                      style={{
                        height: 20,
                        borderRadius: 2,
                        backgroundColor:
                          labelColors[a.label_color].backgroundColor,
                        paddingHorizontal: 8,
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 12,
                          color: labelColors[a.label_color].text,
                          fontWeight: '600',
                        }}
                      >
                        {a.name}
                      </Text>
                    </View>
                  ))}
                </View>
              </View>
            )}
            {isAvailableShowVisitCount(restaurant) && (
              <View
                style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}
              >
                <FontAwesomeIcon
                  icon={faCalendarCheck}
                  color={Colors.black40}
                  size={16}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '300',
                    color: Colors.black80,
                  }}
                >
                  {t('{{count}}回の予約', { count: customer.visit_count })}
                </Text>
              </View>
            )}
            {customer.phone_number_format !== null && (
              <View
                style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}
              >
                <FontAwesomeIcon
                  icon={faPhone}
                  color={Colors.black40}
                  size={16}
                />
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: '300',
                    color: Colors.black80,
                  }}
                >
                  {customer.phone_number_format}
                </Text>
              </View>
            )}
            {profile && (profile.company || profile.post) && (
              <View
                style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  color={Colors.black40}
                  size={16}
                />
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: 8,
                  }}
                >
                  {!!profile.company && (
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: '300',
                        color: Colors.black,
                      }}
                    >
                      {profile.company}
                    </Text>
                  )}
                  {!!profile.post && (
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: '300',
                        color: Colors.black,
                      }}
                    >
                      {profile.post}
                    </Text>
                  )}
                </View>
              </View>
            )}
          </View>
        </View>
      </View>
      <View
        style={[
          { gap: 12 },
          width <= sm && {
            flexDirection: 'row-reverse',
            marginTop: 16,
          },
        ]}
      >
        <TouchableOpacity
          style={[
            {
              borderRadius: 4,
              borderColor: Colors.primary,
              borderWidth: 1,
              width: 130,
              height: 33,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: Colors.white,
            },
            width <= sm && {
              width: 'auto',
              flex: 1,
            },
          ]}
          onPress={selection.onPress}
        >
          <Text
            style={{
              fontSize: 14,
              lineHeight: 24,
              color: Colors.primary,
            }}
          >
            {t('選択する')}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={[
            {
              borderRadius: 4,
              borderColor: Colors.black80,
              borderWidth: 1,
              width: 130,
              height: 33,
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: Colors.white,
            },
            width <= sm && {
              width: 'auto',
              flex: 1,
            },
          ]}
          onPress={onPressInfo}
        >
          <Text
            style={{
              fontSize: 14,
              lineHeight: 24,
              color: Colors.black80,
            }}
          >
            {t('顧客詳細を見る')}
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}

export function SelectedCustomer({
  customer,
  attributes,
}: {
  customer: CustomerModel
  attributes?: {
    id: string
    display_in_list: boolean
    label_color: LabelColor
    name: string
  }[]
}) {
  const { width, sm } = useResponsive()
  const attributeIds =
    customer.profiles?.slice(-1)[0]?.custom_attributes?.split(',') ?? []
  const selectedAttributes = attributes?.filter((i) =>
    attributeIds.includes(i.id)
  )
  return (
    <View
      style={[
        {
          display: 'flex',
          paddingVertical: 20,
          paddingHorizontal: 24,
          minHeight: customerHeight,
          flexDirection: width > sm ? 'row' : 'column',
          alignItems: width > sm ? 'center' : 'stretch',
          borderRadius: 8,
          borderWidth: 0.5,
          borderColor: Colors.border,
          gap: width > sm ? 24 : 16,
        },
      ]}
    >
      <View
        style={{
          flex: 1,
          flexDirection: 'row',
          alignItems: 'center',
          gap: 20,
          flexWrap: 'wrap',
        }}
      >
        <Image
          source={{
            uri: customer.image_url,
          }}
          style={{
            width: selectedCustomerImageSize,
            height: selectedCustomerImageSize,
            borderRadius: selectedCustomerImageSize / 2,
            backgroundColor: Colors.black10,
          }}
        />
        <View style={{ gap: width > sm ? 12 : 0 }}>
          <Text
            style={{
              fontWeight: '600',
              fontSize: width > sm ? 22 : 18,
              lineHeight: width > sm ? 22 : 24,
            }}
          >
            {getCustomerDisplayName(customer)}
          </Text>
          <Text
            style={[
              {
                fontSize: width > sm ? 16 : 14,
                lineHeight: width > sm ? 16 : 24,
                color: Colors.secondaryBlack,
              },
            ]}
          >
            {getReservationName(customer)}
          </Text>
          {customer.username != null && customer.username !== '' && (
            <Text
              style={{
                fontSize: 12,
                lineHeight: width > sm ? 12 : 18,
                color: Colors.secondaryBlack,
              }}
            >
              {customer.username}
            </Text>
          )}
          {customer.favoriting && (
            <View
              style={{
                marginTop: width > sm ? 2 : 4,
                backgroundColor: Colors.accentBg,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 4,
                borderRadius: 40,
                height: 20,
                width: 90,
              }}
            >
              <Text
                style={{
                  fontSize: 12,
                  fontWeight: '600',
                  color: Colors.accent,
                }}
              >
                {t('フォロー中')}
              </Text>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color={Colors.accent}
                size={12}
              />
            </View>
          )}
        </View>
      </View>
      <View style={{ flex: 1, gap: width > sm ? 12 : 4 }}>
        {customer.phone_number_format !== null && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 8,
              flexWrap: 'wrap',
            }}
          >
            <FontAwesomeIcon
              icon={faPhone}
              color={Colors.black40}
              size={width > sm ? 20 : 16}
            />
            <Text
              style={{
                fontSize: width > sm ? 18 : 14,
                fontWeight: '300',
                color: Colors.black80,
              }}
            >
              {customer.phone_number_format}
            </Text>
          </View>
        )}
        <View
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            gap: 8,
            flexWrap: 'wrap',
          }}
        >
          <FontAwesomeIcon
            icon={faFrown}
            color={Colors.black40}
            size={width > sm ? 20 : 16}
          />
          <Text
            style={{
              fontSize: width > sm ? 18 : 14,
              fontWeight: '300',
              color: Colors.black80,
            }}
          >
            {customer.allergy != null && customer.allergy !== ''
              ? customer.allergy
              : t('未記入')}
          </Text>
        </View>
        {selectedAttributes && selectedAttributes.length > 0 && (
          <View
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: 8,
              flexWrap: 'wrap',
            }}
          >
            <FontAwesomeIcon
              icon={faTag}
              color={Colors.black40}
              size={width > sm ? 20 : 16}
            />
            <View
              style={{
                flex: 1,
                fontSize: 14,
                fontWeight: '300',
                color: Colors.black,
                flexDirection: 'row',
                gap: 4,
                flexWrap: 'wrap',
              }}
            >
              {selectedAttributes.map((a) => (
                <View
                  key={a.id}
                  style={{
                    height: 20,
                    borderRadius: 2,
                    backgroundColor: labelColors[a.label_color].backgroundColor,
                    paddingHorizontal: 8,
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: labelColors[a.label_color].text,
                      fontWeight: '600',
                    }}
                  >
                    {a.name}
                  </Text>
                </View>
              ))}
            </View>
          </View>
        )}
      </View>
    </View>
  )
}
