import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import type { CalendarPeriod } from './types'

export const getStartAndEndOfMonth = (date: dayjs.Dayjs): CalendarPeriod => {
  const startOfMonth = date
    .clone()
    .startOf('month')
    .subtract(1, 'week')
    .format('YYYY-MM-DD')
  const endOfMonth = date
    .clone()
    .endOf('month')
    .add(1, 'week')
    .format('YYYY-MM-DD')
  return { gte: startOfMonth, lte: endOfMonth }
}
