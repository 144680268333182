import React, { useState } from 'react'

import { View } from 'react-native'

import { CollapsedAccordion } from './CollapsedAccordion'
import { NeedConfirmReservationItem } from './NeedConfirmReservationItem'
import { createStyles } from './styles'

import type { UseNeedConfirmReservations } from './hooks'

type Props = {
  reservationAmounts: UseNeedConfirmReservations
  isDesktop: boolean
}

export function NeedConfirmReservationContent({
  reservationAmounts,
  isDesktop,
}: Props) {
  const [isOpenAccordion, setIsOpenAccordion] = useState(false)
  const styles = createStyles(isDesktop)

  if (isDesktop) {
    return (
      <NeedConfirmReservationItem
        reservationAmounts={reservationAmounts}
        isOpenAccordion
      />
    )
  }

  return (
    <View style={styles.accordionWrap}>
      {reservationAmounts.isUseAccordion ? (
        <CollapsedAccordion
          isOpen={isOpenAccordion}
          toggleOpen={() => setIsOpenAccordion(!isOpenAccordion)}
        >
          <NeedConfirmReservationItem
            reservationAmounts={reservationAmounts}
            isOpenAccordion={isOpenAccordion}
          />
        </CollapsedAccordion>
      ) : (
        <View style={styles.itemContainer}>
          <View style={styles.children}>
            <NeedConfirmReservationItem
              reservationAmounts={reservationAmounts}
              isOpenAccordion
            />
          </View>
        </View>
      )}
    </View>
  )
}
