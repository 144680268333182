import React, { useState } from 'react'

import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faArrowDownArrowUp } from '@fortawesome/pro-solid-svg-icons/faArrowDownArrowUp'
import { ScrollView, View, Platform } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { useRestaurantCourses } from '../../models/RestaurantCourse'
import { useNavigate } from '../../modules/navigation/useNavigate'
import { useNavigation } from '../../modules/navigation/useNavigation'
import { AddFloatingButton } from '../Shared/AddFloatingButton'
import Loading from '../Shared/Loading'

import { RestaurantCourse } from './RestaurantCourse'
import SupplyCalendarModal from './SupplyCalendarModal'

interface RestaurantCourseListViewProps {
  restaurantId: number
}

export default function RestaurantCourseListView({
  restaurantId,
}: RestaurantCourseListViewProps) {
  const navigation = useNavigation()
  const navigate = useNavigate()
  const { width, sm } = useResponsive()
  const { restaurantCourses } = useRestaurantCourses(restaurantId)

  const onPressCreateRestaurantCourse = () => {
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/courses/new`)
    } else {
      navigation.navigate('RestaurantCourseForm')
    }
  }

  const onPressQuestionRestaurantCourse = () => {
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/course_questions`)
    } else {
      navigation.navigate('RestaurantCourseQuestions')
    }
  }

  const onPressOrderRestaurantCourse = () => {
    if (Platform.OS === 'web') {
      navigate(`/restaurants/${restaurantId}/courses/edit_order`)
    } else {
      navigation.navigate('RestaurantCourseEditOrder')
    }
  }

  const [isVisibleSupplyCalendarModal, setIsVisibleSupplyCalendarModal] =
    useState(false)

  if (restaurantCourses == null) {
    return <Loading />
  }

  return (
    <>
      <ScrollView
        style={{
          position: 'relative',
        }}
        contentContainerStyle={{
          padding: width < sm ? 16 : 32,
          backgroundColor:
            Platform.OS === 'web' ? Colors.bgBlack : Colors.bgLightBlack,
          flexGrow: 1,
        }}
      >
        <View
          style={{
            flexDirection: width >= sm ? 'row' : 'column',
            columnGap: 24,
            rowGap: 12,
            alignItems: 'center',
            marginTop: 36,
            height: width >= sm ? undefined : 140,
          }}
        >
          <Button
            mode="contained"
            variant="secondary"
            style={{
              borderRadius: 8,
              flex: 1,
              minHeight: 72,
              flexDirection: 'row',
              alignItems: 'center',
            }}
            onPress={onPressQuestionRestaurantCourse}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 18,
                }}
              >
                {t('お客様への質問を設定')}
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  fontWeight: '300',
                  lineHeight: 14,
                }}
              >
                {t('予約時にお客様に確認したい質問を作成できます')}
              </Text>
            </View>
            <FontAwesomeIcon icon={faChevronRight} size={14} />
          </Button>
          <Button
            mode="contained"
            variant="secondary"
            style={{
              borderRadius: 8,
              flex: 1,
              minHeight: 72,
              alignItems: 'center',
            }}
            onPress={() => {
              setIsVisibleSupplyCalendarModal(true)
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontWeight: '600',
                  fontSize: 18,
                  lineHeight: 18,
                }}
              >
                {t('コース提供可能日を設定')}
              </Text>
              <Text
                style={{
                  marginTop: 10,
                  fontSize: 14,
                  fontWeight: '300',
                  lineHeight: 14,
                }}
              >
                {t('コースごとに提供する日を設定します')}
              </Text>
            </View>
            <FontAwesomeIcon icon={faChevronRight} size={14} />
          </Button>
        </View>
        <View
          style={{
            flexDirection: width >= sm ? 'row' : 'column',
            marginTop: width >= sm ? 48 : 24,
            alignItems: width >= sm ? 'center' : 'flex-end',
          }}
        >
          <Text
            style={{
              fontSize: 14,
              color: Colors.black60,
              flex: 1,
            }}
          >
            {t(
              '予約サービスAutoReserveで使用します。セルフオーダーには反映されません。'
            )}
          </Text>
          <Button
            mode="text"
            style={{
              backgroundColor: Colors.primary10,
              borderWidth: 0,
              borderRadius: 18,
            }}
            onPress={onPressOrderRestaurantCourse}
          >
            <FontAwesomeIcon
              icon={faArrowDownArrowUp}
              size={16}
              color={Colors.primary}
            />
            <Text
              style={{
                color: Colors.primary,
                fontSize: 14,
                fontWeight: '300',
                marginLeft: 4,
              }}
            >
              {t('コースの並べ替え')}
            </Text>
          </Button>
        </View>
        <ShadowBox style={{ marginTop: 16, padding: 0 }}>
          {restaurantCourses.map((restaurantCourse, index) => {
            return (
              <RestaurantCourse
                key={restaurantCourse.id}
                restaurantCourse={restaurantCourse}
                onPress={() => {
                  if (Platform.OS === 'web') {
                    navigate(
                      `/restaurants/${restaurantId}/courses/${restaurantCourse.id}`
                    )
                  } else {
                    navigation.navigate('RestaurantCourseForm', {
                      restaurantCourseId: restaurantCourse.id,
                    })
                  }
                }}
                style={{
                  borderTopWidth: index === 0 ? 0 : 0.5,
                  borderColor: Colors.border,
                }}
              />
            )
          })}
        </ShadowBox>
      </ScrollView>
      <AddFloatingButton
        title={width >= sm ? t('コース追加') : t('追加')}
        onPress={onPressCreateRestaurantCourse}
      />
      <SupplyCalendarModal
        restaurantId={restaurantId}
        isVisible={isVisibleSupplyCalendarModal}
        onClose={() => setIsVisibleSupplyCalendarModal(false)}
      />
    </>
  )
}
