import React, { useState } from 'react'

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import { View } from 'react-native'

import CountryModal, {
  CountryCodes,
} from '@hello-ai/ar_shared/src/components/CountryModal'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Image } from '@hello-ai/ar_shared/src/components/Image'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

interface PhoneNumberInputProps {
  phoneNumber: string
  setPhoneNumber: (phoneNumber: string) => void
  country: (typeof CountryCodes)[0]
  setCountry: (country: (typeof CountryCodes)[0]) => void
  onPressEnter: () => void
}
export default function PhoneNumberInput({
  phoneNumber,
  setPhoneNumber,
  country,
  setCountry,
  onPressEnter,
}: PhoneNumberInputProps) {
  const { width, sm } = useResponsive()
  const [isVisibleCountryModal, setIsVisibleCountryModal] = useState(false)

  return (
    <>
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: Colors.field,
          borderRadius: 8,
          position: 'relative',
        }}
      >
        <TouchableOpacity
          onPress={() => setIsVisibleCountryModal(true)}
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            paddingHorizontal: width < sm ? 0 : 16,
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            borderColor: Colors.border,
            marginVertical: 12,
            borderRightWidth: width >= sm ? 0.5 : undefined,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Image
              style={{ width: 24, height: 16 }}
              source={{
                uri: require(
                  `../../assets/images/country_flg/${country.code.toLowerCase()}.svg`
                ),
              }}
            />
          </Text>
          <Text
            style={{
              fontSize: 12,
              fontWeight: '300',
              lineHeight: 18,
              marginHorizontal: 4,
            }}
          >
            {country.dial_code}
          </Text>
          <FontAwesomeIcon icon={faChevronDown} size={10} />
        </TouchableOpacity>
        <TextInput
          style={{
            height: 24,
            fontSize: width < sm ? 12 : 18,
            paddingHorizontal: 16,
            paddingVertical: 30,
            paddingLeft: 126,
            flex: 1,
          }}
          keyboardType="numeric"
          textContentType="telephoneNumber"
          placeholder={t('ハイフンなしで入力')}
          value={phoneNumber}
          onChangeText={setPhoneNumber}
          onSubmitEditing={onPressEnter}
        />
      </View>
      <CountryModal
        style={{ backgroundColor: 'white' }}
        isVisible={isVisibleCountryModal}
        defaultSelectedCountry={country}
        onClose={() => setIsVisibleCountryModal(false)}
        onPressCountry={(country) => {
          setCountry(country)
          setIsVisibleCountryModal(false)
        }}
      />
    </>
  )
}
