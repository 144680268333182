import React from 'react'

import { faHistory } from '@fortawesome/pro-solid-svg-icons/faHistory'
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare'
import { View } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { GOURMET_SITE_PROVIDER_SOURCE } from '@hello-ai/ar_shared/src/types/ForR/GourmetSiteSetting'

import {
  isSiteControllerReservation,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'

export function ReservationIcons({
  reservation,
}: {
  reservation: ReturnType<
    typeof useRestaurantReservations
  >['restaurantReservations'][number]
}) {
  const shouldShowIcons =
    reservation.smart_payment != null ||
    !!reservation.memo ||
    isSiteControllerReservation(reservation) ||
    reservation.has_previous_table_order
  if (!shouldShowIcons) {
    return null
  }
  return (
    <View style={{ flexDirection: 'row', marginTop: 4, gap: 2 }}>
      {reservation.smart_payment != null && (
        <View
          style={{
            width: 16,
            height: 16,
            backgroundColor: Colors.primary,
            borderColor: Colors.white,
            borderWidth: 1,
            borderRadius: 16,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text
            style={{
              fontSize: 10,
              lineHeight: 14,
              fontWeight: '600',
              color: Colors.white,
            }}
          >
            {t('ス')}
          </Text>
        </View>
      )}
      {!!reservation.memo && (
        <FontAwesomeIcon
          style={{ marginTop: 2 }}
          icon={faPenToSquare}
          size={16}
          color={'white'}
        />
      )}
      {reservation.has_previous_table_order && (
        <FontAwesomeIcon icon={faHistory} size={16} color={Colors.white} />
      )}
      {isSiteControllerReservation(reservation) && (
        <View
          style={{
            backgroundColor: '#FAF9F5',
            borderRadius: 8,
            paddingHorizontal: 6,
            padding: 2,
          }}
        >
          <Text
            style={{
              fontSize: 12,
              lineHeight: 18,
              fontWeight: '300',
              color: Colors.primary,
            }}
          >
            {
              GOURMET_SITE_PROVIDER_SOURCE[
                reservation.reservation_site_controller_parsed_course
                  .site_controller_parsed_course.site
              ].label
            }
          </Text>
        </View>
      )}
    </View>
  )
}
