import { AxiosError } from 'axios'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { isAxiosError } from './axios'

export function getErrorMessage(error: Error | AxiosError | unknown) {
  if (isAxiosError(error)) {
    if (error.response != null) {
      return error.response?.data?.error ?? t('エラーが発生しました')
    }
    if (error.request != null) {
      return t('サーバーへの接続に失敗しました')
    }
  }
  if (error instanceof Error) {
    return error.message
  }

  return t('エラーが発生しました')
}
