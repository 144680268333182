import React, { useRef, useState } from 'react'

import { View, ActivityIndicator, Platform } from 'react-native'

import {
  SupportedLocale,
  localeData,
} from '@hello-ai/ar_shared/src//modules/locale'
import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { CheckboxIcon } from '@hello-ai/ar_shared/src/components/Checkbox'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { Header } from '@hello-ai/ar_shared/src/components/ForR/Shared/Header'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { Modal } from '@hello-ai/ar_shared/src/components/Modal'
import { SelectInput } from '@hello-ai/ar_shared/src/components/SelectInput'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

type TranslationModalProps = {
  isVisible: boolean
  onClose: () => void
  initialLocale: SupportedLocale
  values: Record<SupportedLocale, string>
  onTranslate: (params: {
    locale: SupportedLocale
    locales: SupportedLocale[]
  }) => Promise<{
    error?: unknown
  }>
}

const items = Object.entries(localeData).map(([locale, data]) => ({
  value: locale as SupportedLocale,
  label: data.label,
}))

function confirmOverwrite(alert: AlertMethods['alert']) {
  return new Promise<boolean>((resolve) => {
    alert(t('入力済みの言語が選択されています。上書きしますか？'), undefined, [
      {
        text: t('キャンセル'),
        onPress: () => {
          resolve(false)
        },
        style: 'cancel',
      },
      {
        text: t('上書きする'),
        onPress: () => {
          resolve(true)
        },
        isPreferred: true,
      },
    ])
  })
}

export function TranslationModal({
  isVisible,
  onClose,
  initialLocale,
  values,
  onTranslate,
}: TranslationModalProps) {
  const { width, sm, md } = useResponsive()
  const alertRef = useRef<AlertMethods>(null)

  const [isLoading, setIsLoading] = useState(false)

  const availableItems = items.filter(({ value }) => values[value] !== '')

  const [selectedLocale, setSelectedLocale] = useFormState<SupportedLocale>(
    // initialLocale が選択可能な場合はそれを選択する
    // そうでない場合は選択可能な最初の言語を選択する
    // モーダルは閉じている時にもレンダリングされるので、（閉じている時のみ）選択可能な言語がない状態が存在する
    // エラーにさえならなければ問題ないのでその場合は適当にinitialLocaleで初期化しておく

    availableItems.find(({ value }) => value === initialLocale) != null
      ? initialLocale
      : (availableItems?.[0]?.value ?? initialLocale)
  )

  const [locales, setLocales] = useState<SupportedLocale[]>([])

  const isAllSelected = locales.length === items.length - 1

  const disabled = locales.length === 0 || values[selectedLocale] === ''

  return (
    <Modal
      isVisible={isVisible}
      onBackButtonPress={onClose}
      onBackdropPress={onClose}
      style={{
        margin: 0,
      }}
      contentContainerStyle={{
        flex: 1,
        backgroundColor: 'white',
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.white,
        }}
      >
        <Header onClose={onClose} title={t('自動翻訳')} />
        <KeyboardAwareScrollView
          style={{ flex: 1, backgroundColor: 'white' }}
          contentContainerStyle={{
            paddingVertical: 24,
            paddingHorizontal: width < md ? 24 : 184,
          }}
        >
          <FormGroup
            formLabel={<FormLabel value={t('元にする言語')} />}
            mode={width < sm ? 'vertical' : 'inline'}
            containerStyle={{
              marginTop: width < sm ? 8 : 0,
            }}
          >
            <SelectInput<SupportedLocale>
              items={availableItems}
              selectedValue={selectedLocale}
              style={{
                height: width < sm ? 48 : 64,
              }}
              setValue={(value) => {
                setSelectedLocale(value)
                setLocales([])
              }}
            />
          </FormGroup>
          <Divider
            style={{
              marginVertical: 32,
            }}
          />

          <Text
            style={{
              fontWeight: '600',
            }}
          >
            {t('翻訳を入力する言語を選んでください。')}
          </Text>
          <TouchableOpacity
            style={{
              marginTop: 16,
              flexDirection: 'row',
              alignItems: 'center',
              height: 48,
            }}
            onPress={() => {
              setLocales(() => {
                if (isAllSelected) {
                  return []
                } else {
                  return items
                    .map(({ value }) => value)
                    .filter((value) => value !== selectedLocale)
                }
              })
            }}
          >
            <CheckboxIcon checked={isAllSelected} />
            <Text
              style={{
                marginLeft: 4,
              }}
            >
              {t('全ての言語を選択')}
            </Text>
          </TouchableOpacity>
          {items.map(({ label, value }, index) => (
            <TouchableOpacity
              key={index}
              disabled={selectedLocale === value}
              style={[
                {
                  marginTop: width < sm ? 4 : 24,
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingVertical: width < sm ? 8 : 16,
                },
                selectedLocale === value && {
                  opacity: 0.6,
                },
              ]}
              onPress={() => {
                setLocales((locales) => {
                  if (locales.includes(value)) {
                    return locales.filter((l) => l !== value)
                  } else {
                    return [...locales, value]
                  }
                })
              }}
              onPressMinInterval={0}
            >
              <CheckboxIcon checked={locales.includes(value)} />
              <View
                style={[
                  width < sm
                    ? {
                        marginLeft: 4,
                      }
                    : {
                        flexDirection: 'row',
                        alignItems: 'center',
                      },
                ]}
              >
                <Text
                  style={[
                    {
                      fontWeight: '600',
                      width: 202,
                    },
                    width < sm
                      ? {
                          fontSize: 14,
                        }
                      : {
                          marginLeft: 4,
                          width: 202,
                        },
                  ]}
                >
                  {label}
                </Text>
                <Text
                  style={[
                    width < sm
                      ? {
                          marginTop: 8,
                          fontSize: 14,
                        }
                      : {
                          marginLeft: 24,
                        },
                    values[value] === '' && {
                      color: Colors.secondaryBlack,
                    },
                  ]}
                >
                  {values[value] || t('未入力')}
                </Text>
              </View>
            </TouchableOpacity>
          ))}
        </KeyboardAwareScrollView>
        <View
          style={{
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: -4,
            },
            shadowOpacity: 0.15,
            shadowRadius: 8,
            elevation: 2,
            paddingTop: 16,
            paddingBottom: 32,
            paddingHorizontal: 16,
            backgroundColor: 'white',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
          }}
        >
          <Button
            mode="outline"
            variant="danger"
            onPress={() => onClose()}
            width={width < sm ? 108 : 128}
            style={{ paddingHorizontal: width < sm ? 0 : 16 }}
            height={48}
          >
            {t('キャンセル')}
          </Button>
          <Button
            style={{
              marginLeft: width < sm ? 10 : 16,
            }}
            disabled={disabled || isLoading}
            onPress={async () => {
              const alert = Platform.select({
                web: alertRef.current?.alert,
                default: Alert.alert,
              })
              const shouldConfirmOverwrite = locales.some(
                (locale) => values[locale] !== ''
              )

              const shouldProceed =
                !shouldConfirmOverwrite || (await confirmOverwrite(alert))

              if (!shouldProceed) return

              setIsLoading(true)
              try {
                await onTranslate({
                  locale: selectedLocale,
                  locales,
                })
              } finally {
                setIsLoading(false)
              }
            }}
            width={width < sm ? 246 : 260}
            height={48}
          >
            {({ textStyle }) => (
              <>
                <Text style={textStyle}>
                  {t('入力する')}
                  {isLoading && (
                    <ActivityIndicator size="small" color={'white'} />
                  )}
                </Text>
              </>
            )}
          </Button>
        </View>
      </View>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </Modal>
  )
}
