import React from 'react'

import { View } from 'react-native'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { RestaurantTabelogAwardStatus } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

import {
  getTabelogAwardLabel,
  getTabelogAwardStatusColor,
  getTabelogAwardStatusLabel,
} from './common'

export function TabelogList({
  tablelogAwards,
}: {
  tablelogAwards: RestaurantTabelogAwardStatus[]
}) {
  return (
    <>
      {tablelogAwards
        ?.filter((award) => award.status !== 'rejected')
        .map((award, index) => (
          <View key={index} style={[{ flexDirection: 'row', marginTop: 24 }]}>
            <Text style={[{ flex: 1 }]}>
              {t('{{year}}年 {{label}}', {
                year: award.year,
                label: getTabelogAwardLabel(award.category),
              })}
            </Text>
            <Text
              style={{
                flex: 1,
                color: getTabelogAwardStatusColor(award.status),
                flexGrow: 2,
              }}
            >
              {getTabelogAwardStatusLabel(award.status)}
            </Text>
          </View>
        ))}
    </>
  )
}
