import React, { ComponentProps } from 'react'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

interface QuantityButtonProps {
  icon: ComponentProps<typeof FontAwesomeIcon>['icon']
  disabled?: boolean
  onPress: () => void
  radius?: number
}
function QuantityButton({
  icon,
  disabled = false,
  onPress,
  radius = 24,
}: QuantityButtonProps) {
  return (
    <TouchableOpacity
      onPressMinInterval={0}
      onPress={() => {
        if (!disabled) onPress()
      }}
      disabled={disabled}
      hitSlop={{ top: 10, left: 10, right: 10, bottom: 10 }}
      style={{
        borderRadius: radius,
        width: radius * 2,
        height: radius * 2,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Colors.primaryBg,
        opacity: disabled ? 0.4 : 1,
      }}
    >
      <FontAwesomeIcon icon={icon} size={16} color={Colors.primary} />
    </TouchableOpacity>
  )
}

export default QuantityButton
