import React from 'react'

import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import StatusLabel from 'components/GourmetSite/AccountSettings/StatusLabel'
import { HeaderBack } from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import GourmetSiteSeatSetting from 'pages/Restaurants/GourmetSiteSiteSeat/GourmetSiteSeatSetting'
import GourmetSiteSeatSettingCreate from 'pages/Restaurants/GourmetSiteSiteSeat/GourmetSiteSeatSettingCreate'
import GourmetSiteSeatSettingEdit from 'pages/Restaurants/GourmetSiteSiteSeat/GourmetSiteSeatSettingEdit'

const routes: StackRoutes = {
  Index: {
    title: t('席の連携'),
    fullPath: `/restaurants/:id/gourmet_site/shared_seat_settings`,
    relativePath: `/`,
    exact: true,
    element: <GourmetSiteSeatSetting />,
    screenOptions: {
      headerRight: <StatusLabel />,
    },
  },
  Create: {
    title: t('連携席設定の追加'),
    fullPath: `/restaurants/:id/gourmet_site/shared_seat_settings/create`,
    relativePath: `/create`,
    element: <GourmetSiteSeatSettingCreate />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
  Edit: {
    title: t('連携席設定を編集'),
    fullPath: `/restaurants/:id/gourmet_site/shared_seat_settings/edit/:sharedSeatId`,
    relativePath: `/edit/:sharedSeatId`,
    element: <GourmetSiteSeatSettingEdit />,
    screenOptions: {
      headerLeft: <HeaderBack />,
    },
  },
}

export default function GourmetSiteSeatSettingStackNavigator() {
  return <Stack routes={routes} />
}
