import React from 'react'

import { Route, Routes } from 'react-router'

import { Screen, ScreenOptions } from './Screen'

export interface StackRoute {
  title: string
  fullPath: string
  relativePath: string
  exact?: boolean
  hidden?: boolean
  element: React.ReactNode
  screenOptions?: ScreenOptions
}

export type StackRoutes = Record<string, StackRoute>

export function Stack({ routes }: { routes: StackRoutes }) {
  return (
    <Routes>
      {Object.entries(routes).map(
        ([key, route]) =>
          (route.hidden === undefined || !route.hidden) && (
            <Route
              key={key}
              path={
                route.exact ? route.relativePath : `${route.relativePath}/*`
              }
              element={
                <Screen
                  options={{
                    title: route.title,
                    ...route.screenOptions,
                  }}
                >
                  {route.element}
                </Screen>
              }
            />
          )
      )}
    </Routes>
  )
}
