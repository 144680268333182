import React, { useEffect, useState } from 'react'

import { faPhone } from '@fortawesome/pro-solid-svg-icons'
import { View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { CandidatesRestaurant } from '@hello-ai/ar_shared/src/types/ForR/CandidatesRestaurant'

import useReCaptcha, {
  GoogleReCaptchaScript,
} from 'components/Shared/ReCaptcha'

const GOOGLE_RECAPTCHA_CONTAINER_ID = 'google-recaptcha-container'

interface CallPhoneProps {
  restaurant: CandidatesRestaurant
  onPressReSelectRestaurant: () => void
  onPressVerifyCode: (captchaToken: string) => void
}

export default function CallPhone({
  restaurant,
  onPressReSelectRestaurant,
  onPressVerifyCode,
}: CallPhoneProps) {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)

  const successCallback = (response: string) => {
    setCaptchaToken(response)
  }
  const expiredCallback = () => setCaptchaToken(null)
  const errorCallback = () => setCaptchaToken(null)

  const { reset } = useReCaptcha({
    containerId: GOOGLE_RECAPTCHA_CONTAINER_ID,
    successCallback,
    expiredCallback,
    size: 'normal',
    theme: 'light',
    badge: 'inline',
    errorCallback,
  })

  useEffect(() => {
    reset()
  }, [reset])

  return (
    <View style={{ justifyContent: 'center', alignItems: 'center' }}>
      <Text style={{ fontSize: 22, fontWeight: '600', marginBottom: 40 }}>
        {t('電話番号認証のための音声通話を発信します')}
      </Text>
      <View
        style={{
          flexDirection: 'row',
          columnGap: 8,
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: 16,
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon
            icon={faPhone}
            color={Colors.black40}
            size={24}
            style={{ marginRight: 8 }}
          />
          <Text
            style={{
              fontSize: 22,
              fontWeight: '600',
              lineHeight: 32,
            }}
          >
            {`+${restaurant.phone.country_code} ${restaurant.phone.number}`}
          </Text>
        </View>
      </View>
      <Text
        style={{
          fontSize: 18,
          fontWeight: '300',
          color: Colors.black60,
          marginBottom: 40,
        }}
      >
        {restaurant.name_i18n}
        {restaurant.category != null ? ` ${restaurant.category}` : ''}
        {'/'}
        <Text style={{ fontSize: 16, color: Colors.black60 }}>
          {restaurant.address_short}
        </Text>
      </Text>
      <Text
        style={{
          fontSize: 18,
          fontWeight: '300',
          color: Colors.black,
          marginBottom: 40,
          textAlign: 'center',
        }}
      >
        {t(
          'こちらの電話番号、店舗名で間違いございませんか？\nご確認の上、音声通話を発信し認証コードを取得してください。'
        )}
      </Text>
      <GoogleReCaptchaScript />
      <div
        id={GOOGLE_RECAPTCHA_CONTAINER_ID}
        style={{
          marginBottom: 40,
        }}
      />
      <Button
        style={{
          marginBottom: 16,
          paddingVertical: 16,
          paddingHorizontal: 24,
          height: 48,
        }}
        textStyle={{ fontSize: 16, fontWeight: '600' }}
        disabled={captchaToken == null}
        onPress={() => {
          if (captchaToken != null) {
            onPressVerifyCode(captchaToken)
          }
        }}
      >
        {t('音声通話でコードを取得する')}
      </Button>
      <Button mode="text" onPress={onPressReSelectRestaurant}>
        {t('店舗を選択し直す')}
      </Button>
    </View>
  )
}
