import React from 'react'

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import { View } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import numberWithDelimiter from '@hello-ai/for_r_app/src/modules/numberWithDelimiter'

import { CanceledReportRowComponent, ReportType } from '../../models/Report'

export function CanceledItem({
  onPress,
  reportType,
  component,
}: {
  onPress: () => void
  reportType: ReportType
  component: CanceledReportRowComponent
}) {
  const disabled = reportType === 'monthly'
  return (
    <TouchableOpacity
      disabled={disabled}
      onPress={onPress}
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 24,
      }}
    >
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: 24,
          flex: 1,
        }}
      >
        <Text
          style={{
            width: 90,
          }}
        >
          {reportType === 'daily'
            ? dayjs(component.time).format('HH:mm')
            : // eslint-disable-next-line ar-i18n/require-translation-ja
              dayjs(component.time).format('DD日(ddd)')}
        </Text>
        <Text
          style={{
            flex: 1,
          }}
        >
          {component.name}
        </Text>
        <Text
          style={[
            component.amount != null &&
              component.amount < 0 && {
                color: Colors.caution,
              },
          ]}
        >
          {t('{{price}}円', {
            price: numberWithDelimiter(component.amount ?? '-'),
          })}
        </Text>
        {!disabled && (
          <FontAwesomeIcon
            icon={faChevronRight}
            size={12}
            color={Colors.black}
          />
        )}
      </View>
    </TouchableOpacity>
  )
}
