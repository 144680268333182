import React from 'react'

import { View, ScrollView } from 'react-native'

import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import GroupCautionMessage from 'components/Menus/GroupCautionMessage'
import TableUnlimitedOrderPlan from 'components/Restaurant/TableUnlimitedOrderPlan'
import RoundedActionButton from 'components/Shared/RoundedActionButton'
import { useRestaurantTableUnlimitedOrderPlans } from 'models/TableUnlimitedOrderPlan'
import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableUnlimitedOrderPlans() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()
  const { tableUnlimitedOrderPlans } =
    useRestaurantTableUnlimitedOrderPlans(restaurantId)

  const hasGroupManagedItem = (tableUnlimitedOrderPlans ?? []).some((item) => {
    return item.group_managed
  })

  return (
    <ScrollView
      style={{ backgroundColor: Colors.bgBlack }}
      contentContainerStyle={{ padding: width < sm ? 16 : 32 }}
    >
      {hasGroupManagedItem && (
        <View
          style={{
            marginBottom: 40,
          }}
        >
          <GroupCautionMessage />
        </View>
      )}
      <View style={{ flexDirection: 'row' }}>
        <RoundedActionButton
          onPress={() =>
            history.push(
              `/restaurants/${restaurantId}/unlimited_order_plans/new`
            )
          }
        >
          {t('放題プランを追加')}
        </RoundedActionButton>
      </View>
      {tableUnlimitedOrderPlans !== undefined &&
        tableUnlimitedOrderPlans.length > 0 && (
          <View style={{ marginTop: 40 }}>
            <ShadowBox
              style={[
                {
                  paddingHorizontal: 0,
                  paddingVertical: 8,
                },
              ]}
            >
              {tableUnlimitedOrderPlans.length > 0 &&
                tableUnlimitedOrderPlans.map(
                  (tableUnlimitedOrderPlan, index) => {
                    return (
                      <View
                        key={tableUnlimitedOrderPlan.id}
                        style={{
                          borderTopWidth: index === 0 ? 0 : 0.5,
                          borderColor: Colors.border,
                          paddingTop: 16,
                          marginBottom: 16,
                          paddingHorizontal: 24,
                        }}
                      >
                        <TableUnlimitedOrderPlan
                          locked={tableUnlimitedOrderPlan.group_managed}
                          tableUnlimitedOrderPlan={tableUnlimitedOrderPlan}
                          onPress={() =>
                            history.push(
                              `/restaurants/${restaurantId}/unlimited_order_plans/${tableUnlimitedOrderPlan.id}/edit`
                            )
                          }
                        />
                      </View>
                    )
                  }
                )}
              {tableUnlimitedOrderPlans.length === 0 && (
                <Text
                  style={{
                    paddingTop: 16,
                    marginBottom: 24,
                    paddingHorizontal: 24,
                    fontSize: 22,
                    fontWeight: '600',
                    color: Colors.disabledBlack,
                    textAlign: 'center',
                  }}
                >
                  {t('該当する放題プランはありません')}
                </Text>
              )}
            </ShadowBox>
          </View>
        )}
    </ScrollView>
  )
}
