import { useMemo, useEffect, useState } from 'react'

import { throttle, ThrottleSettings } from 'lodash'

export const useThrottleFn = <T extends (...args: any) => any>(
  fn: T,
  ms = 500,
  options: ThrottleSettings = {}
) => {
  const { leading = true, trailing = true } = options
  const throttledFn = useMemo(() => {
    return throttle(fn, ms, { leading, trailing })
  }, [fn, leading, ms, trailing])
  // This is must because fn can call setState.
  useEffect(() => {
    return () => {
      throttledFn.cancel()
    }
  }, [throttledFn])
  return throttledFn
}

export const useThrottle = <T>(
  value: T,
  ms = 500,
  options: ThrottleSettings = {}
) => {
  const [throttledValue, setThrottledValue] = useState(value)
  const setThrottledValueThrottled = useThrottleFn(
    setThrottledValue,
    ms,
    options
  )
  useEffect(() => {
    setThrottledValueThrottled(value)
  }, [setThrottledValueThrottled, value])
  return throttledValue
}
