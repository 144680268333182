import React, { FC, ReactNode } from 'react'
import { StyleSheet, View, ViewStyle } from 'react-native'
import { Portal } from 'react-native-paper'
import Animated from 'react-native-reanimated'
import { TouchableOpacity } from '../Touchables'
import { ShadowBox } from '../ShadowBox'

import { usePopover, PopoverProvider, PopoverContextValue } from './usePopover'

type Props = {
  anchor: ReactNode
  isOpen: boolean
  children: ReactNode
  placement?: 'bottomStart' | 'bottomEnd' | 'left'
  onClose: () => void
  showArrowElement?: boolean
  containerStyle?: ViewStyle
  offsetX?: number
  offsetY?: number
}

export function PopoverHost(props: {
  children: ReactNode
  value: PopoverContextValue
}) {
  return (
    <PopoverProvider value={props.value}>
      <Portal.Host>{props.children}</Portal.Host>
    </PopoverProvider>
  )
}

export function Popover({
  anchor,
  isOpen,
  children,
  placement = 'bottomStart',
  onClose,
  showArrowElement = true,
  containerStyle,
  offsetX,
  offsetY,
}: Props) {
  const { anchorRef, setContentRef, popoverStyle, arrowElement } = usePopover({
    placement,
    offsetX,
    offsetY,
    isOpen,
  })

  return (
    <>
      <View style={containerStyle} ref={anchorRef}>
        {anchor}
      </View>
      {isOpen && (
        <Portal>
          <TouchableOpacity onPress={onClose} style={StyleSheet.absoluteFill} />
          <View
            style={[
              { position: 'absolute' },
              popoverStyle,
              // popOverのスタイル計算はisOpenがtrueの時に走るため、isOpen: null && popoverStyle: undefinedの状態が一瞬発生する
              // チラつきを防ぐために最初だけopacityを0にしておく
              popoverStyle == null && {
                opacity: 0,
              },
            ]}
            onAccessibilityEscape={onClose}
          >
            <Animated.View>
              {showArrowElement && arrowElement}
              <ShadowBox
                style={{
                  flex: 1,
                  padding: 0,
                }}
              >
                <View ref={setContentRef}>{children}</View>
              </ShadowBox>
            </Animated.View>
          </View>
        </Portal>
      )}
    </>
  )
}
