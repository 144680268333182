import React, { useState, useEffect } from 'react'

import minMax from 'dayjs/plugin/minMax'
import { ScrollView, View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { getFormatLabel } from 'components/Sales/SelectPeriodInput'
import { CSVDownloadButton } from 'components/Shared/CsvDownloadButton'
import { DateSelectInput } from 'components/Shared/DateSelectInput'
import {
  Sale as SaleModel,
  SaleByPaymentMethod,
  useRestaurantSales,
  useRestaurantSalesByPaymentMethod,
} from 'models/Sales'
import dayjs from 'modules/dayjs'
import numberWithDelimiter from 'modules/numberWithDelimiter'
import { useRestaurantId } from 'modules/useRestaurantId'

dayjs.extend(minMax)

const initStartDate = dayjs().startOf('day').subtract(6, 'day')
const initEndDate = dayjs().endOf('day')

const CSVHeaders = [
  { value: 'id', label: t('注文ID ') },
  { value: 'completed_at', label: t('注文日時') },
  { value: 'recorded_at', label: t('計上日時') },
  { value: 'payment_method', label: t('支払い方法') },
  { value: 'amount', label: t('金額') },
]

function convertDataFormat(sales: SaleByPaymentMethod[]) {
  return sales.map((sale) => {
    return {
      id: sale.table_order.id,
      recorded_at: dayjs(sale.table_order.recorded_at).format('YYYY/M/D H:m'),
      completed_at: dayjs(sale.table_order.completed_at).format('YYYY/M/D H:m'),
      amount: numberWithDelimiter(sale.amount),
      payment_method: sale.payment_method,
    }
  })
}

function Sale({ sale }: { sale: SaleModel }) {
  const { width, sm } = useResponsive()
  return (
    <View>
      <Text style={{ fontWeight: '600' }}>
        {dayjs(sale.date).format('YYYY/M/D')}
      </Text>
      <ShadowBox
        style={[
          {
            marginTop: 24,
            paddingVertical: 0,
          },
        ]}
      >
        {sale.table_orders.map((tableOrder, index) => {
          const tableSeat = tableOrder.table_seat
          const isRecordedAtChanged =
            tableOrder.completed_at !== tableOrder.recorded_at
          return (
            <TouchableOpacity
              key={tableOrder.id}
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                paddingVertical: 20,
                borderTopWidth: index === 0 ? 0 : 0.5,
                borderColor: Colors.border,
              }}
              onPress={() => {
                // TODO: 実装
              }}
            >
              <Text style={{ flex: 1, color: Colors.secondaryBlack }}>
                {tableOrder.completed_at !== null
                  ? dayjs(tableOrder.completed_at).format('HH:mm')
                  : tableOrder.status === 'canceled' &&
                    dayjs(tableOrder.current_status_changed_at).format('HH:mm')}
              </Text>
              <View
                style={[
                  width < sm
                    ? {}
                    : {
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      },
                ]}
              >
                <View
                  style={[
                    width < sm && {
                      alignItems: 'flex-end',
                    },
                    { marginRight: 'auto' },
                  ]}
                >
                  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <Text style={{ fontWeight: '600' }}>{tableSeat.name}</Text>
                    {tableOrder.recorded_at !== null &&
                      isRecordedAtChanged &&
                      tableOrder.status !== 'canceled' && (
                        <View
                          style={{
                            backgroundColor: Colors.black20,
                            marginLeft: 15,
                            padding: 4,
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: '600',
                              fontSize: 12,
                            }}
                          >
                            {t('計上日: {{time}}', {
                              time: dayjs(tableOrder.recorded_at).format('M/D'),
                            })}
                          </Text>
                        </View>
                      )}
                  </View>
                </View>
                <View
                  style={[
                    width < sm && {
                      marginTop: 8,
                    },
                  ]}
                >
                  <Text
                    style={[
                      { fontWeight: '600', textAlign: 'right' },
                      tableOrder.status === 'canceled' && {
                        color: Colors.caution,
                      },
                    ]}
                  >
                    {t('{{price}}円', {
                      price: numberWithDelimiter(tableOrder.total_amount),
                    })}
                  </Text>
                  <Text
                    style={[
                      { fontSize: 16, textAlign: 'right', marginTop: 6 },
                      tableOrder.status === 'canceled' && {
                        color: Colors.caution,
                      },
                    ]}
                  >
                    {tableOrder.payment_method}
                  </Text>
                </View>
              </View>
            </TouchableOpacity>
          )
        })}
        <View
          style={{
            borderTopWidth: 0.5,
            borderColor: Colors.border,
            alignItems: 'flex-end',
            paddingTop: 24,
            marginBottom: 20,
          }}
        >
          <View style={{ width: width < sm ? '100%' : '60%' }}>
            {sale.total_amount_components.map((component, index) => {
              return (
                <View
                  style={{
                    flexDirection: 'row',
                    marginTop: index === 0 ? 0 : 12,
                  }}
                  key={index}
                >
                  <Text style={{ flex: 2, textAlign: 'right' }}>
                    {component.name}
                  </Text>
                  <Text
                    style={{ flex: width < sm ? 3 : 4, textAlign: 'right' }}
                  >
                    {t('{{price}}円', {
                      price: numberWithDelimiter(component.amount),
                    })}
                  </Text>
                </View>
              )
            })}
            <View style={{ flexDirection: 'row', marginTop: 16 }}>
              <Text
                style={{
                  flex: 2,
                  textAlign: 'right',
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                {t('売上合計')}
              </Text>
              <Text
                style={{
                  flex: width < sm ? 3 : 4,
                  textAlign: 'right',
                  fontSize: 22,
                  fontWeight: '600',
                }}
              >
                {t('{{price}}円', {
                  price: numberWithDelimiter(sale.total_amount),
                })}
              </Text>
            </View>
          </View>
        </View>
      </ShadowBox>
    </View>
  )
}

export default function TableOrdersPastScreen() {
  const { width, sm } = useResponsive()
  const restaurantId = useRestaurantId()

  const [startDate, setStartDate] = useState(initStartDate)
  const [endDate, setEndDate] = useState(initEndDate)

  const [fetchParams, setFetchParams] = useState<{
    startDate: dayjs.Dayjs
    endDate: dayjs.Dayjs
  }>({ startDate, endDate })

  const { sales, setSize, isLoading } = useRestaurantSales(
    restaurantId,
    fetchParams.endDate
  )

  const {
    salesByPaymentMethod,
    setSize: setSalesByPaymentMethodSize,
    isLoading: isSalesByMethodLoading,
  } = useRestaurantSalesByPaymentMethod(restaurantId, fetchParams.endDate)

  const fetchSize = fetchParams.endDate.diff(fetchParams.startDate, 'day') + 1

  useEffect(() => {
    setSize(fetchSize)
    setSalesByPaymentMethodSize(fetchSize)
  }, [fetchSize, setSalesByPaymentMethodSize, setSize])

  const onPress = () => {
    setFetchParams({ startDate, endDate })
  }

  return (
    <ScrollView
      style={{
        flex: 1,
        backgroundColor: Colors.bgBlack,
      }}
      contentContainerStyle={{
        padding: width < sm ? 16 : 32,
      }}
    >
      <View
        style={[
          width >= sm && {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          },
        ]}
      >
        <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
          <DateSelectInput initialDate={startDate} setDate={setStartDate} />
          <Text style={{ marginHorizontal: 24, fontSize: 32 }}>~</Text>
          <DateSelectInput initialDate={endDate} setDate={setEndDate} />
        </View>
        <Button
          style={[
            {
              height: 44,
              borderColor: 'transparent',
              backgroundColor: 'white',
              borderRadius: 24,
              paddingHorizontal: 24,
            },
            width < sm
              ? {
                  marginTop: 24,
                }
              : {
                  marginLeft: 24,
                },
          ]}
          onPress={() => onPress()}
          textStyle={{ color: Colors.primary }}
        >
          {t('表示する')}
        </Button>
      </View>
      <View
        style={{
          marginTop: 32,

          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',

          borderBottomColor: Colors.border,
          borderBottomWidth: 0.5,
          paddingBottom: 24,
        }}
      >
        <Text
          style={{
            fontSize: 24,
            fontWeight: '600',
            color: Colors.black,
          }}
        >
          {getFormatLabel(fetchParams.startDate)} ~{' '}
          {getFormatLabel(fetchParams.endDate)}
        </Text>
        <CSVDownloadButton
          data={
            salesByPaymentMethod !== undefined && !isSalesByMethodLoading
              ? convertDataFormat(salesByPaymentMethod)
              : undefined
          }
          headers={CSVHeaders}
          fileName="table_orders_past"
        >
          {t('支払い別CSVダウンロード')}
        </CSVDownloadButton>
      </View>
      {sales?.map((sale, index) => {
        return (
          <View key={index} style={{ marginTop: index === 0 ? 32 : 40 }}>
            <Sale sale={sale} />
          </View>
        )
      })}

      {isLoading && (
        <View style={{ paddingVertical: 48 }}>
          <Loading />
        </View>
      )}
    </ScrollView>
  )
}
