import React from 'react'
import { useParams } from 'react-router-dom'

import { useNavigate } from 'react-router'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { RestaurantCourseForm } from '@hello-ai/for_r_app/src/components/RestaurantCourses/RestaurantCourseForm'
import { displayToastInfo } from '@hello-ai/for_r_app/src/components/Shared/Toast'
import { getSortedLocales } from '@hello-ai/for_r_app/src/components/Translation'
import {
  RestaurantCourse as RestaurantCourseModel,
  CreateParams,
  UpdateParams,
  createRestaurantCourse,
  restaurantCourseClient,
  updateRestaurantCourse,
  useRestaurantCourse,
} from '@hello-ai/for_r_app/src/models/RestaurantCourse'

import { useNavigation } from 'components/Shared/Navigation/Screen'
import { useToken } from 'models/Auth'
import { restaurantCourseQuestionService } from 'models/RestaurantCourseQuestion'
import { useRestaurantTableMenus } from 'models/TableMenu'
import { createTranslation } from 'models/Translation'
import { callRpc, rpcOptions } from 'modules/rpc'
import { onError } from 'modules/swr'
import { useRestaurantCountryLanguage } from 'modules/useRestaurantCountryLanguage'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function RestaurantCoursesForm() {
  const restaurantId = useRestaurantId()
  const token = useToken()

  const preferredLocale = useRestaurantCountryLanguage()
  const sortedLocales = getSortedLocales(preferredLocale)

  const params = useParams<{ restaurantCourseId?: string }>()

  const restaurantCourseId =
    params.restaurantCourseId === undefined
      ? undefined
      : Number(params.restaurantCourseId)

  const { restaurantCourse, mutate } = useRestaurantCourse(
    restaurantId,
    restaurantCourseId
  )

  const { tableMenus } = useRestaurantTableMenus({ restaurantId })

  const {
    data: { restaurantCourseQuestions } = {
      restaurantCourseQuestions: undefined,
    },
  } = restaurantCourseQuestionService.useList({
    restaurantId,
    page: 1,
    perPage: 999,
  }) // TODO: 分割してfetch

  const navigation = useNavigation()

  const navigate = useNavigate()

  const onCreate = async (params: CreateParams) => {
    if (token == null) return
    const { error } = await createRestaurantCourse(token, restaurantId, params)

    if (error != null) {
      onError(error)
      return
    }

    navigate(`/restaurants/${restaurantId}/courses`)

    displayToastInfo(t('コースを作成しました'))
  }

  const onUpdate = async (params: UpdateParams) => {
    if (token == null) return
    if (restaurantCourseId == null) return
    const { error } = await updateRestaurantCourse(
      token,
      restaurantId,
      restaurantCourseId,
      params
    )

    if (error != null) {
      onError(error)
      return
    }

    navigate(`/restaurants/${restaurantId}/courses`)
    displayToastInfo(t('更新しました'))
    mutate()
  }

  const onArchive = () => {
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: () => archive(),
      },
    ])
  }

  const archive = async () => {
    if (restaurantCourseId === undefined) return
    const { error } = await callRpc(
      restaurantCourseClient.archive(
        {
          restaurantId,
          id: restaurantCourseId,
        },
        rpcOptions({ token })
      )
    )
    if (error != null) {
      onError(error)
      return
    }

    navigate(`/restaurants/${restaurantId}/courses`)
    displayToastInfo(t('アーカイブしました'))
  }

  React.useLayoutEffect(() => {
    if (restaurantCourseId != null) {
      navigation.setOptions({
        title: t('コースを編集'),
      })
    } else {
      navigation.setOptions({
        title: t('コースを作成'),
      })
    }
  }, [navigation, restaurantCourseId])

  if (restaurantCourse === null || tableMenus == null) {
    return <Loading />
  }

  const questions: RestaurantCourseModel['questions'] | undefined =
    restaurantCourseQuestions?.map((question) => ({
      id: question.id,
      category:
        question.category === 0
          ? 'text'
          : question.category === 1
            ? 'checkbox'
            : 'radio',
      description: question.description,
      description_i18n: question.description,
      description_i18n_en: question.descriptionI18NEn,
      description_i18n_ja: question.descriptionI18NJa,
      description_i18n_ko: question.descriptionI18NKo,
      description_i18n_th: '',
      description_i18n_zh_cn: question.descriptionI18NZhCn,
      description_i18n_zh_hk: question.descriptionI18NZhHk,
      description_i18n_zh_tw: question.descriptionI18NZhTw,
      is_required: question.isRequired,
      name: question.name,
      selections: question.selections.map((selection) => ({
        id: selection.id,
        description: selection.description,
        description_i18n: selection.description,
        description_i18n_en: selection.descriptionI18NEn,
        description_i18n_ja: selection.descriptionI18NJa,
        description_i18n_ko: selection.descriptionI18NKo,
        description_i18n_th: '',
        description_i18n_zh_cn: selection.descriptionI18NZhCn,
        description_i18n_zh_hk: selection.descriptionI18NZhHk,
        description_i18n_zh_tw: selection.descriptionI18NZhTw,
        status: selection.status === 0 ? 'published' : 'archived',
      })),
      status: question.status === 0 ? 'published' : 'archived',
    }))

  return (
    <RestaurantCourseForm
      restaurantId={restaurantId}
      restaurantCourseId={restaurantCourseId}
      restaurantCourse={restaurantCourse}
      tableMenus={tableMenus}
      restaurantCourseQuestions={questions}
      onCreate={onCreate}
      onCreateError={(errors) => onError(new Error(errors[0]))}
      onUpdate={onUpdate}
      onUpdateError={(errors) => onError(new Error(errors[0]))}
      onArchive={onArchive}
      sortedLocales={sortedLocales}
      createTranslation={(params) => createTranslation(token!, params)}
      image={restaurantCourse?.image_urls}
    />
  )
}
