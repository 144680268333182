import React, { ComponentProps } from 'react'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import RequestReservationListView from '../../Restaurant/RequestReservation/ListView'

export default function RequestContent({
  restaurantId,
  date,
  onPressRequestRestaurantReservation,
  onPressCustomer,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  onPressRequestRestaurantReservation: ComponentProps<
    typeof RequestReservationListView
  >['onPressRequestRestaurantReservation']
  onPressCustomer: (customerId: string) => void
}) {
  return (
    <RequestReservationListView
      restaurantId={restaurantId}
      date={date}
      onPressRequestRestaurantReservation={onPressRequestRestaurantReservation}
      onPressCustomer={onPressCustomer}
    />
  )
}
