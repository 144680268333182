/* eslint-disable ar-i18n/require-translation-ja */

import React, { useCallback, useState } from 'react'

import { times } from 'lodash'
import { View } from 'react-native'

import { Button } from '@hello-ai/ar_shared/src/components/Button'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { onError } from '@hello-ai/ar_shared/src/modules/swr'
import { MichelinAward } from '@hello-ai/ar_shared/src/types/ForR/MichelinAward'
import { RestaurantMichelinAwardStatus } from '@hello-ai/ar_shared/src/types/ForR/Restaurant'

import { getMichelinAwardLabel } from '../../Restaurant/Information/common'

// 今年度まで
const michelinLatestYear = (() => {
  const now = new Date()
  return now.getMonth() >= 4 ? now.getFullYear() : now.getFullYear() - 1
})()

const getYears = (): Array<SelectItem<number>> => {
  const diffYears = michelinLatestYear - 1926
  return times(diffYears + 1, (i) => {
    return {
      label: `${michelinLatestYear - i}年`,
      value: michelinLatestYear - i,
    }
  })
}

const years = getYears()

const awards: Readonly<MichelinAward['category'][]> = [
  'one_star',
  'two_stars',
  'three_stars',
  'bibgourmand',
] as const

interface MichelinAwardDraftProps {
  value: Partial<RestaurantMichelinAwardStatus>
  onUpdate: (params: Partial<RestaurantMichelinAwardStatus>) => void
}
function MichelinAwardDraft({ value, onUpdate }: MichelinAwardDraftProps) {
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }}
    >
      <FormGroup
        formLabel={<FormLabel value="受賞年度" />}
        style={{ marginTop: 24, flex: 1 }}
        containerStyle={{ flex: 1, flexBasis: 'auto' }}
      >
        <SelectInput
          selectedValue={value.year}
          setValue={(year) => {
            onUpdate({ year })
          }}
          items={[{ label: '選択してください', value: undefined }, ...years]}
          style={{ paddingVertical: 8, flex: 1 }}
        />
      </FormGroup>
      <FormGroup
        formLabel={<FormLabel value="ランク" />}
        style={{ marginTop: 24, marginLeft: 24, flex: 1 }}
        containerStyle={{ flex: 1, flexBasis: 'auto' }}
      >
        <SelectInput
          selectedValue={value.category}
          setValue={(category) => {
            onUpdate({ category })
          }}
          style={{ paddingVertical: 8, flex: 1 }}
          items={[
            { label: '選択してください', value: undefined },
            ...awards.map((award) => ({
              value: award,
              label: getMichelinAwardLabel(award),
            })),
          ]}
        />
      </FormGroup>
    </View>
  )
}

export function MichelinAddForm({
  requestMichelinAwards,
}: {
  requestMichelinAwards: (
    awards: Omit<RestaurantMichelinAwardStatus, 'status'>[]
  ) => Promise<void>
}) {
  const [loading, setLoading] = useState(false)
  const [draftMichelinAwards, setDraftMichelinAwards] = useState<
    Partial<RestaurantMichelinAwardStatus>[]
  >([{ year: undefined, category: undefined }])
  const updateDraftEntry = useCallback(
    (index: number, params: Partial<RestaurantMichelinAwardStatus>) => {
      setDraftMichelinAwards((prev) =>
        prev.map((prev, i) => (i === index ? { ...prev, ...params } : prev))
      )
    },
    []
  )

  return (
    <>
      {draftMichelinAwards.map((award, index) => (
        <MichelinAwardDraft
          value={award}
          key={index}
          onUpdate={(params) => updateDraftEntry(index, params)}
        />
      ))}
      <Button
        mode="contained"
        height={44}
        style={[{ marginTop: 24, width: '100%' }]}
        disabled={loading}
        loading={loading}
        onPress={() => {
          setLoading(true)
          if (
            draftMichelinAwards.some(
              (award) => award.year == null || award.category == null
            )
          ) {
            onError(new Error('未選択の項目があります'))
            setLoading(false)
            return
          }

          requestMichelinAwards(
            draftMichelinAwards as RestaurantMichelinAwardStatus[]
          )
            .catch(console.error)
            .finally(() => setLoading(false))
        }}
      >
        追加を申請する
      </Button>
    </>
  )
}
