import React from 'react'
import { useParams } from 'react-router-dom'

import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash'
import { generatePath } from 'react-router'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  HeaderBack,
  HeaderRightButton,
} from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import { history } from 'modules/history'
import TableProducts from 'pages/Menus/TableProducts'
import TableProductArchived from 'pages/Menus/TableProducts/Archived'
import TableProductForm from 'pages/Menus/TableProducts/Form'

function TableProductRightHeaderButton() {
  const { width, sm } = useResponsive()
  const params = useParams<{ id: string }>()
  return (
    <HeaderRightButton
      icon={
        <FontAwesomeIcon icon={faEyeSlash} size={18} color={Colors.primary} />
      }
      onPress={() => {
        history.push(
          generatePath(`/restaurants/:id/products/archived`, {
            id: params.id!,
          })
        )
      }}
      label={width < sm ? null : t('非表示にした商品')}
    />
  )
}

const routes: StackRoutes = {
  Index: {
    title: t('商品'),
    fullPath: `/restaurants/:id/products`,
    relativePath: `/`,
    exact: true,
    element: <TableProducts />,
    screenOptions: {
      headerRight: <TableProductRightHeaderButton />,
    },
  },
  Show: {
    title: t('商品を追加'),
    fullPath: `/restaurants/:id/products/new`,
    relativePath: `/new`,
    element: <TableProductForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('商品を編集'),
    fullPath: `/restaurants/:id/products/:table_product_id/edit`,
    relativePath: `/:table_product_id/edit`,
    element: <TableProductForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="../.." />,
    },
  },
  Archived: {
    title: t('非表示にした商品'),
    fullPath: `/restaurants/:id/products/archived`,
    relativePath: `/archived`,
    element: <TableProductArchived />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function TableProductsStack() {
  return <Stack routes={routes} />
}
