import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useParams } from 'react-router'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { ReservationWalkinsForm } from '@hello-ai/for_r_app/src/components/Reservation/Walkins/Form'

import { useRestaurantId } from 'modules/useRestaurantId'

const useTime = (value: string | null) => {
  return useMemo(() => {
    if (value == null) return undefined
    const parsed = parseInt(value)
    if (isNaN(parsed)) return undefined
    if (parsed < 0) return undefined
    return parsed
  }, [value])
}

export default function ReservationWalkinsFormPage() {
  const restaurantId = useRestaurantId()
  const { restaurantReservationId } = useParams<{
    restaurantReservationId: string
  }>()

  const [params] = useSearchParams()

  const startTime = useTime(params.get('startTime'))
  const endTime = useTime(params.get('endTime'))
  const selectedSeatIds = params.get('selectedSeatIds')?.split(',')
  const date =
    params.get('date') != null
      ? dayjs(params.get('date'), 'YYYY-MM-DD')
      : undefined

  return (
    <ReservationWalkinsForm
      restaurantId={restaurantId}
      restaurantReservationId={restaurantReservationId}
      date={date}
      startTime={startTime}
      endTime={endTime}
      selectedSeatIds={selectedSeatIds}
    />
  )
}
