import React from 'react'
import { useLocation } from 'react-router-dom'

import { useParams } from 'react-router'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'

import TableProductOptionBoxFormContent from 'components/Menus/TableProductOptionBoxFormContent'
import { useToken } from 'models/Auth'
import {
  createTableProductOptionBox,
  updateTableProductOptionBox,
  useTableProductOptionBox,
} from 'models/TableProductOptionBox'
import { history } from 'modules/history'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableProductOptionBoxFormScreen() {
  const { table_product_option_box_id: tableProductOptionBoxId } = useParams<{
    id: string
    table_product_option_box_id: string
  }>()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const copyTableProductOptionBoxId = queryParams.get(
    'copy_table_product_option_box_id'
  )
  const restaurantId = useRestaurantId()
  const token = useToken()
  const { tableProductOptionBox, isLoading } = useTableProductOptionBox({
    restaurantId,
    tableProductOptionBoxId:
      tableProductOptionBoxId ?? copyTableProductOptionBoxId,
  })

  const isCopy = copyTableProductOptionBoxId != null

  const onPressCopy = (tableProductOptionBoxId: string) => {
    history.push(
      `/restaurants/${restaurantId}/product_option_boxes/new?copy_table_product_option_box_id=${tableProductOptionBoxId}`
    )
  }

  if (isLoading) {
    return <Loading />
  }

  return (
    <TableProductOptionBoxFormContent
      tableProductOptionBox={tableProductOptionBox}
      createTableProductOptionBox={async ({ params }) => {
        if (token == null) {
          return
        }
        await createTableProductOptionBox({
          token,
          restaurantId,
          params,
        })
      }}
      updateTableProductOptionBox={async ({
        tableProductOptionBoxId,
        params,
      }) => {
        if (token == null) {
          return
        }
        await updateTableProductOptionBox({
          token,
          restaurantId,
          productOptionBoxId: tableProductOptionBoxId,
          params,
        })
      }}
      onPressCopy={onPressCopy}
      isCopy={isCopy}
    />
  )
}
