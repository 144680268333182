import React from 'react'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { getLocale } from '@hello-ai/for_r_app/src/modules/locale'

export function MUIDatePickerProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={getLocale()}
    >
      {children}
    </LocalizationProvider>
  )
}
