import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { useParams } from 'react-router'

import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { RequestForm } from '@hello-ai/for_r_app/src/components/Reservation/RequestForm'
import { useRestaurantRequestReservation } from '@hello-ai/for_r_app/src/models/RestaurantRequestReservation'

import { useRestaurantId } from 'modules/useRestaurantId'

const useSkipSteps = (v: string | null) => {
  if (v == null) return []
  return v
    .split(',')
    .map((v) => parseInt(v))
    .filter((v) => !isNaN(v))
}

export default function RequestReservationFormPage() {
  const { restaurantRequestReservationId } = useParams<{
    restaurantRequestReservationId: string
  }>()
  const restaurantId = useRestaurantId()
  const { requestReservation } = useRestaurantRequestReservation(
    restaurantId,
    parseInt(restaurantRequestReservationId!)
  )
  const [params] = useSearchParams()
  const skipSteps = useSkipSteps(params.get('skipSteps'))
  const fromRequestReservation = params.get('mode') === 'request'

  if (requestReservation == null) return <Loading />
  return (
    <RequestForm
      restaurantId={restaurantId}
      data={requestReservation}
      skipSteps={skipSteps}
      fromRequestReservation={fromRequestReservation}
    />
  )
}
