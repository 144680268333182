import React, { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useParams } from 'react-router'

import { ReservationBlocksShow } from '@hello-ai/for_r_app/src/components/Reservation/Blocks/Show'

import { useRestaurantId } from 'modules/useRestaurantId'

import { Redirect } from 'App'

const useTime = (value: string | null) => {
  return useMemo(() => {
    if (value == null) return undefined
    const parsed = parseInt(value)
    if (isNaN(parsed)) return undefined
    if (parsed < 0) return undefined
    return {
      seconds: parsed,
      nanos: 0,
    }
  }, [value])
}

function ReservationBlocksShowPage() {
  const { restaurantReservationBlockId } = useParams<{
    restaurantReservationBlockId: string
  }>()
  const [params] = useSearchParams()
  const startAt = useTime(params.get('startAt'))
  const endAt = useTime(params.get('endAt'))
  const restaurantId = useRestaurantId()

  if (startAt == null || endAt == null) {
    return <Redirect to={`/restaurants/${restaurantId}/reservations?m=chart`} />
  }
  return (
    <ReservationBlocksShow
      restaurantId={restaurantId}
      restaurantReservationBlockId={restaurantReservationBlockId!}
      startAt={startAt}
      endAt={endAt}
    />
  )
}

export default ReservationBlocksShowPage
