import React from 'react'

import { View, ScrollView } from 'react-native'

import UnContractedView from '@hello-ai/ar_shared/src/components/ForR/SiteController/AccountSetting/UnContractedView'
import ExecuteHistoryListView from '@hello-ai/ar_shared/src/components/ForR/SiteController/ExecuteHistoryListView'
import NewSeatSettingFab from '@hello-ai/ar_shared/src/components/ForR/SiteController/SeatSetting/NewSeatSettingFab'
import PromptOutboundView from '@hello-ai/ar_shared/src/components/ForR/SiteController/SeatSetting/PromptOutboundView'
import SeatSettingSync from '@hello-ai/ar_shared/src/components/ForR/SiteController/SeatSetting/SeatSettingSync'
import SharedSeatListView, {
  FilterValue,
} from '@hello-ai/ar_shared/src/components/ForR/SiteController/SeatSetting/SharedSeatListView'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { LatestScrapingStatusChecks } from '@hello-ai/ar_shared/src/types/ForR/SiteController'
import {
  useExecuteHistories,
  useSupportedOutboundSettings,
} from '@hello-ai/for_r_app/src/models/SiteControllerOutboundSettings'

import { useAuthMe } from '../../models/Auth'
import { useImportMailSettings } from '../../models/ImportMailSettings'
import {
  deleteSharedSeats,
  updateSiteControllerParsedSeats,
  useSharedSeats,
} from '../../models/SiteControllerSeatSetting'
import { fetcher } from '../../modules/swr'

import ParsedSeatListView from './NotSharedSeatListView'

const containerStyle = {
  paddingTop: 40,
  paddingHorizontal: 56,
  paddingBottom: 80,
}

interface OutboundSeatViewProps {
  restaurantId: number
  displayToastInfo: (message: string) => void
  displayToastSuccess: (message: string) => void
  onPressEditSharedSeat: (sharedSeatId: string) => void
  onPressAddSharedSeat: () => void
}

const getSharedSeatStatusesFromFilter = (filter: FilterValue) => {
  if (filter === 'all') return ['enabled', 'stop']
  if (filter === 'unassociated') return ['enabled', 'stop']
  return [filter]
}

function OutboundSeatView({
  restaurantId,
  displayToastInfo,
  displayToastSuccess,
  onPressEditSharedSeat,
  onPressAddSharedSeat,
}: OutboundSeatViewProps) {
  const token = useToken()
  const [searchText, setSearchText] = React.useState('')
  const [filter, setFilter] = React.useState<FilterValue>('enabled')

  const sharedSeats = useSharedSeats(restaurantId, {
    name: searchText,
    shared_seat_statuses: getSharedSeatStatusesFromFilter(filter),
    unassociated: filter === 'unassociated' ? true : undefined,
  })

  const executionHistory = useExecuteHistories(restaurantId, {
    event_type_and_actions: [
      {
        event_type: 'shared_seat',
        actions: ['create', 'update', 'delete', 'stop'],
      },
      {
        event_type: 'parsed_seat',
        actions: ['parsed_seat_scraping'],
      },
    ],
  })
  const { actor } = useAuthMe()

  return (
    <>
      <ScrollView contentContainerStyle={[containerStyle, { rowGap: 48 }]}>
        <SeatSettingSync
          onPressSync={async () => {
            if (token == null) return
            if (actor.businessUser?.id == null) return
            displayToastInfo(
              t(
                '取得には数十秒ほどかかります。このままお待ちいただくか、後ほどご確認ください。'
              )
            )
            const { error } = await updateSiteControllerParsedSeats(
              token,
              restaurantId,
              actor.businessUser?.id
            )
            if (error != null) return
            await new Promise((resolve) => {
              const interval = setInterval(async () => {
                const response = await fetcher<LatestScrapingStatusChecks>([
                  token,
                  `/restaurants/${restaurantId}/site_controller/latest_scraping_status_checks`,
                  {
                    event_type: 'parsed_seat',
                  },
                ])
                if (response.result) {
                  clearInterval(interval)
                  resolve(true)
                }
              }, 3000)
            })
            await Promise.all([
              executionHistory.mutate(),
              // TODO: これどうしようかな
              // parsedSeats.mutate()
            ])
            displayToastSuccess(t('取得が完了しました'))
          }}
          latestExecutionHistory={executionHistory.data?.[0]}
        />
        <SharedSeatListView
          searchText={searchText}
          setSearchText={setSearchText}
          filter={filter}
          setFilter={setFilter}
          data={sharedSeats.data}
          paginationProps={{
            currentPage: sharedSeats.pagination.currentPage ?? 1,
            totalPage: sharedSeats.pagination.totalPages ?? 1,
            setPage: sharedSeats.pagination.setPage,
          }}
          onPressDeleteCheckedSeats={async (checkedSeatIds) => {
            if (token == null) return
            const { error } = await deleteSharedSeats(
              token,
              restaurantId,
              checkedSeatIds
            )
            await sharedSeats.mutate()
            if (error != null) return
            displayToastSuccess(t('削除しました'))
          }}
          onPressEditSharedSeat={onPressEditSharedSeat}
          onPressAddSharedSeat={onPressAddSharedSeat}
        />
        <ParsedSeatListView restaurantId={restaurantId} />
        <ExecuteHistoryListView
          histories={executionHistory.data}
          paginationProps={{
            currentPage: executionHistory.pagination.currentPage ?? 1,
            totalPage: executionHistory.pagination.totalPages ?? 1,
            setPage: executionHistory.pagination.setPage,
          }}
        />
      </ScrollView>
      <NewSeatSettingFab onPress={onPressAddSharedSeat} />
    </>
  )
}

interface ContractedViewProps extends OutboundSeatViewProps {
  onPressOutboundSetting: () => void
}

function ContractedView({
  restaurantId,
  onPressOutboundSetting,
  ...rest
}: ContractedViewProps) {
  const { isExistOutboundSettings } = useSupportedOutboundSettings(restaurantId)

  if (isExistOutboundSettings == null) return null

  if (!isExistOutboundSettings)
    return (
      <View style={{ backgroundColor: Colors.bgBlack, height: '100%' }}>
        <View style={[containerStyle]}>
          <PromptOutboundView onPressOutboundSetting={onPressOutboundSetting} />
        </View>
      </View>
    )

  return <OutboundSeatView restaurantId={restaurantId} {...rest} />
}

interface GourmetSiteSeatSettingViewProps extends ContractedViewProps {}

export default function GourmetSiteSeatSettingView({
  restaurantId,
  ...rest
}: GourmetSiteSeatSettingViewProps) {
  const { importMailSettingsData } = useImportMailSettings(restaurantId)

  if (importMailSettingsData == null) return null

  return (
    <>
      {importMailSettingsData.site_controller_enabled ? (
        <ContractedView restaurantId={restaurantId} {...rest} />
      ) : (
        <View style={[containerStyle, { backgroundColor: Colors.bgBlack }]}>
          <UnContractedView />
        </View>
      )}
    </>
  )
}
