import React from 'react'

import invariant from 'tiny-invariant'

import type { Chart } from './Chart'
import type { ChartProps } from './types'

interface ChartScrollResponder {
  setChartRef: (ref: React.ElementRef<typeof Chart> | null) => void
  reset: () => void
  chartProps: Pick<ChartProps, 'onLoadStart' | 'onLoadEnd'>
  requestScroll: (payload: ScrollPayload) => void
}

const ChartState = {
  LOADING: 'LOADING',
  LOADING_AWAIT_SCROLL: 'LOADING_AWAIT_SCROLL',
  LOADED: 'LOADED',
  LOADED_PERFORM_SCROLL: 'LOADED_PERFORM_SCROLL',
  ERROR: 'ERROR',
} as const

type ChartStateKey = keyof typeof ChartState

const ChartEventType = {
  LOAD_START: 'LOAD_START',
  LOAD_END: 'LOAD_END',
  SCROLL_REQUESTED: 'SCROLL_REQUESTED',
  SCROLL_COMPLETED: 'SCROLL_COMPLETED',
} as const

type ChartEventTypeKey = keyof typeof ChartEventType

// 次にスクロールさせたい位置(time)
type ScrollPayload = Promise<{ time: number }> | { time: number }

type ChartEvent =
  | {
      type: typeof ChartEventType.SCROLL_REQUESTED
      payload: ScrollPayload
    }
  | {
      type: Exclude<ChartEventTypeKey, typeof ChartEventType.SCROLL_REQUESTED>
    }

const Transitions: Record<
  ChartStateKey,
  Record<ChartEventTypeKey, ChartStateKey>
> = {
  LOADING: {
    LOAD_START: ChartState.LOADING,
    LOAD_END: ChartState.LOADED,
    SCROLL_REQUESTED: ChartState.LOADING_AWAIT_SCROLL,
    SCROLL_COMPLETED: ChartState.ERROR,
  },
  LOADING_AWAIT_SCROLL: {
    LOAD_START: ChartState.LOADING,
    LOAD_END: ChartState.LOADED_PERFORM_SCROLL,
    SCROLL_REQUESTED: ChartState.LOADING_AWAIT_SCROLL,
    SCROLL_COMPLETED: ChartState.ERROR,
  },
  LOADED: {
    LOAD_START: ChartState.LOADING,
    LOAD_END: ChartState.LOADED,
    SCROLL_REQUESTED: ChartState.LOADED_PERFORM_SCROLL,
    SCROLL_COMPLETED: ChartState.ERROR,
  },
  LOADED_PERFORM_SCROLL: {
    // 副作用のためのstateで、実行し終わるとすぐにLOADEDに戻る
    LOAD_START: ChartState.ERROR,
    LOAD_END: ChartState.ERROR,
    SCROLL_REQUESTED: ChartState.ERROR,
    SCROLL_COMPLETED: ChartState.LOADED,
  },
  ERROR: {
    LOAD_START: ChartState.ERROR,
    LOAD_END: ChartState.ERROR,
    SCROLL_REQUESTED: ChartState.ERROR,
    SCROLL_COMPLETED: ChartState.ERROR,
  },
}

/**
 * Chartのloadingが完了するタイミングで指定した位置までスクロールを行うState Machine
 * 最も基本的な遷移は
 * LOADING -> LOADING_AWAIT_SCROLL -> LOADED_PERFORM_SCROLL -> LOADEDだが、
 * これ以外にもload完了時のスクロールリクエストは即時実行したり、
 * ユーザ操作などの割り込みで途中でLOADINGに戻った場合に待機中のスクロールリクエストを破棄するなどのハンドリングも行っている
 */
export function createChartScrollResponder(): ChartScrollResponder {
  let state: ChartStateKey = 'LOADING'

  let ref: React.ElementRef<typeof Chart> | null = null

  let nextScrollPayload: ScrollPayload | null = null
  let cancelScroll: (() => void) | null = null

  function setChartRef(ref_: React.ElementRef<typeof Chart> | null) {
    ref = ref_
  }

  function dispatchEvent(event: ChartEvent) {
    const prevState = state
    let nextState = null
    if (Transitions[prevState] != null) {
      nextState = Transitions[prevState][event.type]
    }
    invariant(
      nextState != null && nextState !== ChartState.ERROR,
      `Invalid transition: prevState: ${prevState}, event type: ${event.type} `
    )
    if (prevState !== nextState) {
      state = nextState
      performTransitionSideEffects(prevState, nextState, event)
    }
  }
  function performScrollAsync(payload: ScrollPayload) {
    let canceled = false
    async function perform() {
      try {
        payload = await payload
      } catch (ex) {
        return
      }
      if (canceled) return
      ref?.scrollToTime(payload)
    }
    perform()
    return () => {
      canceled = true
    }
  }

  function performTransitionSideEffects(
    prevState: ChartStateKey,
    nextState: ChartStateKey,
    event: ChartEvent
  ) {
    if (
      prevState === ChartState.LOADING_AWAIT_SCROLL &&
      event.type === ChartEventType.LOAD_START
    ) {
      nextScrollPayload = null
      cancelScroll?.()
      cancelScroll = null
    }

    if (
      (prevState === ChartState.LOADING ||
        prevState === ChartState.LOADING_AWAIT_SCROLL) &&
      event.type === ChartEventType.SCROLL_REQUESTED
    ) {
      nextScrollPayload = event.payload
    }

    if (
      prevState === ChartState.LOADING_AWAIT_SCROLL &&
      event.type === ChartEventType.LOAD_END
    ) {
      cancelScroll?.()
      cancelScroll = performScrollAsync(nextScrollPayload!)
      nextScrollPayload = null
      dispatchEvent({ type: ChartEventType.SCROLL_COMPLETED })
    }
    if (
      prevState === ChartState.LOADED &&
      event.type === ChartEventType.SCROLL_REQUESTED
    ) {
      cancelScroll?.()
      cancelScroll = performScrollAsync(event.payload)
      nextScrollPayload = null
      dispatchEvent({ type: ChartEventType.SCROLL_COMPLETED })
    }
  }

  /** unmount時のcleanup処理を行う関数 */
  function reset() {
    cancelScroll?.()
  }

  function onLoadStart() {
    dispatchEvent({ type: ChartEventType.LOAD_START })
  }

  function onLoadEnd() {
    dispatchEvent({ type: ChartEventType.LOAD_END })
  }

  function requestScroll(payload: ScrollPayload) {
    dispatchEvent({
      type: ChartEventType.SCROLL_REQUESTED,
      payload,
    })
  }

  return {
    setChartRef,
    reset,
    chartProps: {
      onLoadStart,
      onLoadEnd,
    },
    requestScroll,
  }
}
