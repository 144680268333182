import React, { useCallback, useEffect, useMemo, useState } from 'react'

import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import {
  type CalendarState,
  CalendarStateProvider,
  ReservationCalendar,
} from '@hello-ai/for_r_app/src/components/Restaurant/Reservation/ReservationCalendarList'

import { useRestaurantCheckReservationRequests } from '../../../models/RestaurantRequestReservation'
import ModalCenter from '../../Shared/ModalCenter'

import { DayComponent, CalendarDatesContext } from './DayComponent'
import { useCalendarBusinessTimes, useCalendarReservationDates } from './hooks'
import { getStartAndEndOfMonth } from './utils'

import type { CalendarPeriods } from './types'
import type { DateData } from 'react-native-calendars/src/types'

export function CalendarModal({
  restaurantId,
  title,
  isModalVisible,
  shouldShowRequestReservationBadge,
  onClose,
  selectedDate,
  onPress,
}: {
  restaurantId: number
  title: string
  isModalVisible: boolean
  shouldShowRequestReservationBadge: boolean
  onClose: () => void
  selectedDate: dayjs.Dayjs
  onPress: (d: dayjs.Dayjs) => void
}) {
  const calendarState: CalendarState = useMemo(() => {
    return { selectedDate }
  }, [selectedDate])

  const onDayPress = useCallback(
    (date: DateData) => onPress(dayjs(date.dateString, 'YYYY-MM-DD')),
    [onPress]
  )

  const [calendarPeriodBaseDate, setCalendarPeriodBaseDate] =
    useState(selectedDate)

  const calendarPeriods = useMemo<CalendarPeriods>(
    () => ({
      current: getStartAndEndOfMonth(calendarPeriodBaseDate.clone()),
    }),
    [calendarPeriodBaseDate]
  )

  const { data } = useRestaurantCheckReservationRequests(
    shouldShowRequestReservationBadge ? restaurantId : undefined,
    {
      startDate: calendarPeriods.current.gte,
      endDate: calendarPeriods.current.lte,
    }
  )

  const reservationDateSets = useCalendarReservationDates(
    restaurantId,
    calendarPeriods
  )
  const businessTimeSets = useCalendarBusinessTimes(
    restaurantId,
    calendarPeriods
  )
  const [calendarDates, isLoading] = useMemo(() => {
    const entries = Object.entries(reservationDateSets)
    const mergedDateSets = entries
      .filter(
        ([period, reservationDateSet]) =>
          reservationDateSet.length > 0 &&
          businessTimeSets[period] != null &&
          businessTimeSets[period].length > 0
      )
      .map(([period, reservationTimeSet]) => {
        const businessTimeSet = businessTimeSets[period]
        return reservationTimeSet.map((reservation, index) => {
          const businessTime = businessTimeSet[index]
          return [
            reservation.date,
            {
              ...reservation,
              businessTimes: businessTime?.businessTimes ?? [],
              hasRequestReservation:
                data?.check_reservations_hash[reservation.date] ?? false,
            },
          ] as const
        })
      })
    return [
      Object.fromEntries(mergedDateSets.flat()),
      entries.length !== mergedDateSets.length || data === undefined,
    ]
  }, [reservationDateSets, businessTimeSets, data])

  const onVisibleMonthsChange = useCallback((dates: DateData[]) => {
    const date = dates[0]
    if (date != null) {
      setCalendarPeriodBaseDate(dayjs(date.dateString, 'YYYY-MM-DD'))
    }
  }, [])
  useEffect(() => {
    if (!isModalVisible) {
      return
    }
    setCalendarPeriodBaseDate(selectedDate)
  }, [isModalVisible, selectedDate])

  return (
    <CalendarStateProvider value={calendarState}>
      <ModalCenter
        title={title}
        width="100%"
        height="100%"
        isModalVisible={isModalVisible}
        onClose={onClose}
      >
        <CalendarDatesContext.Provider value={calendarDates}>
          <ReservationCalendar
            restaurantId={restaurantId}
            selectedDate={selectedDate.format('YYYY-MM-DD')}
            selectedPeriods={
              calendarDates[selectedDate.format('YYYY-MM-DD')]?.periods ?? []
            }
            dayComponent={DayComponent}
            onDayPress={onDayPress}
            onVisibleMonthsChange={onVisibleMonthsChange}
            visibleMonth={calendarPeriodBaseDate.format('YYYY-MM')}
            displayLoadingIndicator={isLoading}
            onClose={onClose}
          />
        </CalendarDatesContext.Provider>
      </ModalCenter>
    </CalendarStateProvider>
  )
}
