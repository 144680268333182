import React from 'react'

import RestaurantCourseEditOrderView from '@hello-ai/for_r_app/src/components/RestaurantCourses/RestaurantCourseEditOrder'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function RestaurantCourseEditOrder() {
  const restaurantId = useRestaurantId()
  return <RestaurantCourseEditOrderView restaurantId={restaurantId} />
}
