import React from 'react'

import RestaurantCourseArchivedView from '@hello-ai/for_r_app/src/components/RestaurantCourses/ArchivedListView'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function RestaurantCoursesArchived() {
  const restaurantId = useRestaurantId()

  if (restaurantId == null) return null

  return <RestaurantCourseArchivedView restaurantId={restaurantId} />
}
