import React, { useRef } from 'react'

import composeRefs from '@seznam/compose-react-refs'
import { useHover } from 'react-native-web-hooks'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'

import { createLinkComponent, InjectedProps } from './Link'

type LinkTextProps = React.ComponentPropsWithoutRef<typeof Text> & InjectedProps

const TextLink = createLinkComponent<Text, LinkTextProps>(Text)

const LinkText = React.forwardRef<Text, LinkTextProps>(
  ({ style, ...props }: LinkTextProps, hostRef) => {
    const ref = useRef<Text>(null)
    const isHovered = useHover(ref)
    return (
      <TextLink
        ref={composeRefs(ref, hostRef)}
        style={[
          {
            cursor: 'pointer',
            color: Colors.black,
            fontSize: 14,
            textDecorationLine: 'none',
          },
          isHovered && {
            textDecorationLine: 'underline',
          },
          style,
        ]}
        {...props}
      />
    )
  }
)

export default LinkText
