import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import { CandidatesRestaurant } from '@hello-ai/ar_shared/src/types/ForR/CandidatesRestaurant'

import axios, { wrapResponse, setHeader } from 'modules/axios'
import useSWR, { fetcher, onError, swrKey } from 'modules/swr'

interface Query {
  phone_number?: string
  url?: string
}

export function useRestaurantOwnershipCandidates(query?: Query) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    CandidatesRestaurant[],
    any,
    ReturnType<typeof swrKey> | null
  >(
    query != null
      ? swrKey(token, '/owner/restaurant_ownership_verifications/candidates', {
          query,
        })
      : null,
    ([token, url]) => fetcher<CandidatesRestaurant[]>([token, url, { query }])
  )

  return {
    data,
    error,
    mutate,
  }
}

export function useRestaurantOwnershipCandidatesSearch(keyword: string) {
  const token = useToken()
  const { data, error, mutate } = useSWR<
    CandidatesRestaurant[],
    any,
    ReturnType<typeof swrKey> | null
  >(
    keyword !== ''
      ? swrKey(
          token,
          '/owner/restaurant_ownership_verifications/candidates/search',
          { keyword }
        )
      : null,
    ([token, url]) => fetcher<CandidatesRestaurant[]>([token, url, { keyword }])
  )

  const filteredData = data?.filter((item) => {
    if (item.has_owner == null) return true
    return !item.has_owner
  })

  return {
    data: filteredData,
    error,
    mutate,
  }
}

export async function requestVerificationCode(
  token: string,
  params: {
    verification_request: {
      restaurant_id: number
    }
    'g-recaptcha-response': string
  }
) {
  setHeader({ token })
  const { response, error } = await wrapResponse(
    axios.post<{ verification_request_id: string }>(
      `/owner/restaurant_ownership_verifications/request_with_code`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    verification_request_id: response?.data.verification_request_id,
    error,
  }
}

export async function verifyCodeForRestaurantOwnership(
  token: string,
  requestVerifyId: string,
  params: {
    verification_request: {
      verification_code: string
    }
  }
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.post(
      `/owner/restaurant_ownership_verification_requests/${requestVerifyId}/verify_with_code`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }
  return {
    error,
  }
}

export async function requestWithBusinessLicenses(
  token: string,
  params: {
    verification_requests: {
      restaurant_id: number
      business_licenses: { file: string }[]
    }[]
  }
) {
  setHeader({ token })
  const { error } = await wrapResponse(
    axios.post<{ verification_request_id: string }>(
      `/owner/restaurant_ownership_verifications/request_with_business_licenses`,
      params
    )
  )

  if (error != null) {
    onError(error)
  }

  return {
    error,
  }
}
