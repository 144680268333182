import React from 'react'

import { View } from 'react-native'
import { useNavigate } from 'react-router'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import GourmetSiteCourseSettingView from '@hello-ai/for_r_app/src/components/GourmetSiteCourse/GourmetSiteCourseSettingView'
import {
  displayToastInfo,
  displayToastSuccess,
} from '@hello-ai/for_r_app/src/components/Shared/Toast'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function GourmetSiteCourseSetting() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      <GourmetSiteCourseSettingView
        restaurantId={restaurantId}
        onPressPromptOutboundSetting={() => {
          navigate(`/restaurants/${restaurantId}/gourmet_site/account_settings`)
        }}
        displayToastInfo={displayToastInfo}
        displayToastSuccess={displayToastSuccess}
      />
    </View>
  )
}
