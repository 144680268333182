import axios, { AxiosResponse } from 'axios'
import Constants from 'expo-constants'
import Qs from 'qs'
import { Platform } from 'react-native'

import { AspidaResponse } from '@hello-ai/api_table'
import { getLocale } from '@hello-ai/ar_shared/src/modules/locale'

import Config from './config'
import { navigationRef } from './rootNavigation'

type ResponseWrapper<T> = Promise<{
  response?: AxiosResponse<T>
  error?: unknown
}>

export const headers = {
  Accept: 'application/vnd.autoreserve.com+json; version=1',
  'Content-Type': 'application/json',

  ...(Platform.OS === 'web'
    ? {
        'X-ForR-Platform': 'web',
      }
    : {
        'X-ForR-Version': Constants.nativeAppVersion,
        'X-ForR-Update-Version':
          (process.env.UPDATE_VERSION ?? '') || '9999999999999',
      }),
}
const instance = axios.create({
  baseURL: Config.apiUrl,
  headers,
  params: {
    locale: getLocale(),
  },
})

Config.subscribe((config) => {
  instance.defaults.baseURL = config.apiUrl
})

instance.interceptors.request.use((config) => {
  config.paramsSerializer = {
    encode(value) {
      return Qs.parse(value)
    },
    serialize(params) {
      return Qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: true,
        skipNulls: true,
      })
    },
  }

  if (Platform.OS !== 'web') {
    // リクエスト時に画面名をヘッダーに付与する。
    // 目的は、APIのログから画面名を特定できるようにすること。
    const currentRouteName =
      navigationRef.current?.getCurrentRoute()?.name ?? ''
    if (currentRouteName !== '') {
      config.headers['X-AR-SCREEN'] = currentRouteName
    } else {
      delete config.headers['X-AR-SCREEN']
    }
  }

  return config
})

export function setHeader(auth: { token: string | null }) {
  if (auth.token != null) {
    instance.defaults.headers.common.Authorization = `Token token=${auth.token}`
    instance.defaults.headers.common['X-AR-Token'] = auth.token
  } else {
    delete instance.defaults.headers.common.Authorization
    delete instance.defaults.headers.common['X-AR-Token']
  }
}

export async function wrapAspidaResponse<T>(
  promise: Promise<AspidaResponse<T>>
): ResponseWrapper<T> {
  return promise
    .then((response) => {
      return {
        response: {
          data: response.body,
          status: response.status,
          statusText: response.status.toString(),
          headers: response.headers,
        },
      } as Awaited<ResponseWrapper<T>>
    })
    .catch((error) => ({ error }))
}

export async function wrapResponse<T>(
  promise: Promise<AxiosResponse<T>>
): ResponseWrapper<T> {
  return await promise
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export const { isAxiosError } = axios

export default instance
