import React from 'react'

import { StyleSheet, Text, View } from 'react-native'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import type { UseNeedConfirmReservations } from './hooks'

type Props = {
  reservationAmounts: UseNeedConfirmReservations
  isOpenAccordion: boolean
}
export function NeedConfirmReservationItem({
  reservationAmounts: {
    requestReservationAmount,
    smartPaymentAmount,
    unallocatedSeatAmount,
  },
  isOpenAccordion,
}: Props) {
  const { width, sm } = useResponsive()

  const renderItem = (label: string, count: number) => (
    <View style={styles.item}>
      <Text style={styles.itemLabel}>{label}</Text>
      <Text style={styles.itemCount}>
        <Text style={styles.itemCountUnit}>
          {count}
          {t('件')}
        </Text>
      </Text>
    </View>
  )

  return (
    <View
      style={width > sm ? styles.itemsContainer : mobileStyles.itemsContainer}
    >
      {isOpenAccordion ? (
        <>
          {requestReservationAmount > 0 &&
            renderItem(t('リクエスト承認待ち'), requestReservationAmount)}
          {smartPaymentAmount > 0 &&
            renderItem(t('スマート決済あり'), smartPaymentAmount)}
          {unallocatedSeatAmount > 0 &&
            renderItem(t('座席指定なし'), unallocatedSeatAmount)}
        </>
      ) : (
        <>
          {requestReservationAmount > 0
            ? renderItem(t('リクエスト承認待ち'), requestReservationAmount)
            : smartPaymentAmount > 0
              ? renderItem(t('スマート決済あり'), smartPaymentAmount)
              : unallocatedSeatAmount > 0
                ? renderItem(t('座席指定なし'), unallocatedSeatAmount)
                : null}
        </>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  itemsContainer: {
    flexDirection: 'row',
    marginLeft: 16,
    gap: 16,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemLabel: {
    fontSize: 14,
    color: Colors.black,
    marginRight: 4,
  },
  itemCount: {
    fontSize: 14,
    fontWeight: '600',
  },
  itemCountUnit: {
    fontSize: 14,
    color: Colors.success,
    fontWeight: '600',
    marginLeft: 1,
  },
})

const mobileStyles = StyleSheet.create({
  itemsContainer: {
    ...styles.itemsContainer,
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 0,
    gap: 6,
    flexDirection: 'column',
  },
})
