import React, { useMemo, useState, useEffect, useRef } from 'react'

import { ScrollView } from 'react-native'

import {
  ReservationStepper,
  ReservationStepperNextButton,
  ReservationStepperSkipButton,
} from '@hello-ai/ar_shared/src/components/ReservationStepper'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import { useEffectEvent } from '@hello-ai/ar_shared/src/modules/useEffectEvent'

import { useRestaurantBusinessTimesByDate } from '../../../../models/RestaurantBusinessTime'
import { getFormatTime } from '../../../../modules/time'
import { displayToastError } from '../../../Shared/Toast'
import { RESERVATION_STEPPERS } from '../../FormCommon/Steppers'
import { getDefaultDuration } from '../SelectSeats'
import { ReservationFormState, ReservationFormStepProps } from '../types'

import { DateTimeAndPartySizeForm, validate } from './Form'

export function DateTimeAndPartySize({
  restaurantId,
  currentStep,
  skipSteps,
  state,
  onPressGoBack,
  onPressNext,
  onPressSkip,
  onLoad,
}: ReservationFormStepProps & { restaurantId: number; onLoad?: () => void }) {
  const [dateTime, setDateTime] = useState(
    dayjs(`${state.dateString} ${getFormatTime(state.startTime)}`)
  )
  const [adultPartySize, setAdultPartySize] = useState(
    `${state.adultPartySize}`
  )
  const [childPartySize, setChildPartySize] = useState(
    `${state.childPartySize}`
  )

  const {
    restaurantBusinessTimes,
    isLoading: isRestaurantBusinessTimesLoading,
  } = useRestaurantBusinessTimesByDate(restaurantId, {
    date: dateTime.format('YYYY-MM-DD'),
  })

  const { disabled, errors } = useMemo(
    () =>
      validate({
        dateTime,
        adultPartySize,
        childPartySize,
        restaurantBusinessTimes,
        isRestaurantBusinessTimesLoading,
      }),
    [
      adultPartySize,
      childPartySize,
      dateTime,
      restaurantBusinessTimes,
      isRestaurantBusinessTimesLoading,
    ]
  )

  useEffect(() => {
    errors.length > 0 &&
      errors.forEach((error) => {
        displayToastError(error)
      })
  }, [errors])

  const handleOnLoad = useEffectEvent(() => {
    onLoad?.()
  })
  useEffect(() => {
    handleOnLoad?.()
  }, [handleOnLoad])

  // 画面を開いた時、デフォルトの時間の位置までスクロールする
  const hourScrollViewRef = useRef<ScrollView>(null)
  const isInitialRender = useRef(true)
  useEffect(() => {
    if (isInitialRender.current && hourScrollViewRef.current) {
      const scrollToIndex = dateTime.hour() - 5
      if (scrollToIndex >= 0) {
        setTimeout(() => {
          hourScrollViewRef.current?.scrollTo({
            y: scrollToIndex * 60,
            animated: false,
          })
        }, 100)
      }
      isInitialRender.current = false
    }
  }, [dateTime])

  const handleOnPress = (
    onPress?: (newParams: Partial<ReservationFormState>) => void
  ) => {
    const dateString = dateTime.format('YYYY-MM-DD')
    const startTime = dateTime.hour() * 3600 + dateTime.minute() * 60
    const defaultEndTime =
      startTime + getDefaultDuration({ startTime, restaurantBusinessTimes })

    // 日付や時間を変更した場合、既に選択されていた時刻や席が選択できない状態になる場合があるため、終了時刻および選択している席の情報をリセットする
    const shouldResetSeatInfo =
      dateString === state.dateString && startTime === state.startTime
    const endTime = shouldResetSeatInfo ? state.endTime : defaultEndTime
    const selectedSeatIds = shouldResetSeatInfo ? state.selectedSeatIds : []

    if (onPress) {
      onPress({
        dateString,
        startTime,
        endTime,
        selectedSeatIds,
        adultPartySize: Number(adultPartySize || '0'),
        childPartySize: Number(childPartySize || '0'),
      })
    }
  }

  const onPressNextWithParams = () => {
    handleOnPress(onPressNext)
  }

  const onPressSkipWithParams = () => {
    handleOnPress(onPressSkip)
  }

  const onPressNextButton = (
    <ReservationStepperNextButton
      onPressNext={onPressNextWithParams}
      text={t('席を選択する')}
    />
  )

  const onPressSkipButton = (
    <ReservationStepperSkipButton
      onPressSkip={onPressSkipWithParams}
      text={t('席の選択をスキップする')}
    />
  )

  return (
    <ReservationStepper
      steps={RESERVATION_STEPPERS}
      currentStepNumber={currentStep == null ? undefined : currentStep}
      skipStepNumbers={skipSteps}
      errors={[]}
      onPressGoBack={onPressGoBack}
      onPressNext={onPressNextWithParams}
      onPressNextButton={onPressNextButton}
      onPressSkipButton={onPressSkipButton}
      isDisabledNextPress={disabled}
    >
      <DateTimeAndPartySizeForm
        restaurantId={restaurantId}
        dateTime={dateTime}
        setDateTime={setDateTime}
        adultPartySize={adultPartySize}
        setAdultPartySize={setAdultPartySize}
        childPartySize={childPartySize}
        setChildPartySize={setChildPartySize}
      />
    </ReservationStepper>
  )
}
