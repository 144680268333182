import React, { useContext } from 'react'

import { View } from 'react-native'
import invariant from 'tiny-invariant'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'

import CanceledContent from 'components/Restaurant/Reservation/ListView/CanceledContent'
import RequestContent from 'components/Restaurant/Reservation/ListView/RequestContent'
import ReservedContent from 'components/Restaurant/Reservation/ListView/ReservedContent'

const ContentTypes = ['reserved', 'request', 'canceled'] as const
type ContentType = (typeof ContentTypes)[number]

const DateStateContext = React.createContext<
  [dayjs.Dayjs, (date: dayjs.Dayjs) => void] | undefined
>(undefined)

export const DateStateProvider = DateStateContext.Provider

export function useDateState() {
  const value = useContext(DateStateContext)
  invariant(
    value !== undefined,
    'useDateState() must be used within <DateStateProvider />'
  )

  return value
}

export default function RestaurantReservationsIndex({
  type,
}: {
  type: ContentType
}) {
  const [date] = useDateState()

  function getContent() {
    switch (type) {
      case 'reserved':
        return <ReservedContent date={date} />
      case 'request':
        return <RequestContent date={date} />
      case 'canceled':
        return <CanceledContent date={date} />
      default:
        return null
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: Colors.bgBlack }}>
      {getContent()}
    </View>
  )
}
