import React from 'react'

import { useParams } from 'react-router'

import { ReservationChangeSeat } from '@hello-ai/for_r_app/src/components/Reservation/ChangeSeat'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function ReservationChangeSeatPage() {
  const restaurantId = useRestaurantId()
  const { restaurantReservationId } = useParams<{
    restaurantReservationId: string
  }>()
  return (
    <ReservationChangeSeat
      restaurantId={restaurantId}
      restaurantReservationId={restaurantReservationId!}
    />
  )
}
