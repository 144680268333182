import React from 'react'

import { View } from 'react-native'

import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { Header } from './Header'
import { Modal } from './Modal'

export interface ModalCenterProps {
  title: string | React.ReactNode
  width?: string
  height?: string | null
  avoidKeyboard?: boolean
  isModalVisible: boolean
  onClose: () => void
  children: React.ReactNode
  headerLeft?: () => React.ReactNode
  headerRight?: () => React.ReactNode
}

export default function ModalCenter({
  title,
  width: modalWidth = '50%',
  height = null,
  avoidKeyboard,
  isModalVisible,
  onClose,
  headerLeft,
  headerRight,
  children,
}: ModalCenterProps) {
  const { width, sm } = useResponsive()

  return (
    <Modal
      style={[
        width < sm && {
          margin: 0,
        },
      ]}
      isVisible={isModalVisible}
      onBackdropPress={onClose}
      avoidKeyboard={avoidKeyboard}
    >
      <View
        style={[
          {
            backgroundColor: 'white',
          },
          width < sm
            ? {
                width: '100%',
                height: '100%',
              }
            : {
                width: modalWidth,
                alignSelf: 'center',
                borderRadius: 8,
                minHeight: 300,
                overflow: 'hidden',
              },
          width >= sm && height != null && { height },
        ]}
      >
        <Header
          title={title}
          onClose={onClose}
          headerLeft={headerLeft}
          headerRight={headerRight}
        />
        {children}
      </View>
    </Modal>
  )
}
