import invariant from 'tiny-invariant'

import { TableSeat } from '@hello-ai/ar_shared/src/types/ForR/TableSeat'
import { TableSeatResource_Status } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_resource'
import {
  GetRequest,
  ListRequest,
} from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_service'
import { TableSeatClient } from '@hello-ai/proto/src/gen/auto_reserve/restaurants/table_seat/table_seat_service.client'

import { createRpcService, rpcOptions } from 'modules/rpc'
import useSWR, { fetcher, swrKey } from 'modules/swr'

import { useToken } from './Auth'

export * from '@hello-ai/ar_shared/src/types/ForR/TableSeat'

export const tableSeatService = createRpcService(TableSeatClient)
export const tableSeatClient = tableSeatService.client

export function useTableSeats(
  restaurantId: number,
  status: TableSeatResource_Status
) {
  const token = useToken()
  const params: ListRequest = { restaurantId, status }

  const { data, mutate, error } = useSWR(
    swrKey(token, 'TableSeat/List'),
    () => tableSeatClient.list(params, rpcOptions({ token })).response
  )

  return {
    tableSeats: data?.tableSeats,
    mutate,
    error,
  }
}

export function useTableSeat(params: GetRequest | undefined) {
  const token = useToken()

  const { data, mutate, error } = useSWR(
    params === undefined ? null : swrKey(token, 'TableSeat/Get'),
    () => {
      invariant(params !== undefined, 'params cannot be undefined')
      return tableSeatClient.get(params, rpcOptions({ token })).response
    }
  )

  return {
    tableSeat: data,
    mutate,
    error,
  }
}

export function useTableSeatsBulk(
  restaurantId: number,
  params: {
    ids: string[]
  }
) {
  const token = useToken()

  const {
    data: tableSeats,
    error,
    mutate,
  } = useSWR<TableSeat[], any, ReturnType<typeof swrKey>>(
    swrKey(token, `/restaurants/${restaurantId}/seats/bulk`, params),
    ([token, url]) => fetcher<TableSeat[]>([token, url, params])
  )

  return {
    tableSeats,
    error,
    mutate,
  }
}
