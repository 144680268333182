import React from 'react'
import { useParams } from 'react-router-dom'

import { faDownload } from '@fortawesome/pro-solid-svg-icons/faDownload'
import { generatePath } from 'react-router'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import {
  HeaderBack,
  HeaderRightButton,
} from 'components/Shared/Navigation/Header'
import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import { history } from 'modules/history'
import TableMenus from 'pages/Menus/TableMenus'
import TableMenusForm from 'pages/Menus/TableMenus/Form'
import ImportCSV from 'pages/Menus/TableMenus/ImportCSV'

function TableMenuRightHeaderButton() {
  const { width, sm } = useResponsive()
  const params = useParams<{ id: string }>()
  return (
    <HeaderRightButton
      icon={
        <FontAwesomeIcon icon={faDownload} size={18} color={Colors.primary} />
      }
      label={width < sm ? null : t('CSVインポート')}
      onPress={() => {
        history.push(
          generatePath(`/restaurants/:id/menus/import_csv`, {
            id: params.id!,
          })
        )
      }}
    />
  )
}

const routes: StackRoutes = {
  Index: {
    title: t('メニュー'),
    fullPath: `/restaurants/:id/menus`,
    relativePath: `/`,
    exact: true,
    element: <TableMenus />,
    screenOptions: {
      headerRight: <TableMenuRightHeaderButton />,
    },
  },
  Show: {
    title: t('メニューを追加'),
    fullPath: `/restaurants/:id/menus/new`,
    relativePath: `/new`,
    element: <TableMenusForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
  Edit: {
    title: t('メニューを編集'),
    fullPath: `/restaurants/:id/menus/:table_menu_id/edit`,
    relativePath: `/:table_menu_id/edit`,
    element: <TableMenusForm />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="../.." />,
    },
  },
  ImportCSV: {
    title: t('CSVインポート'),
    fullPath: `/restaurants/:id/menus/import_csv`,
    relativePath: `/import_csv`,
    element: <ImportCSV />,
    screenOptions: {
      headerLeft: <HeaderBack fallback="." />,
    },
  },
}

export default function TableMenusStack() {
  return <Stack routes={routes} />
}
