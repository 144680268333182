import React from 'react'

import { TakeoutUnavailableView } from '@hello-ai/for_r_app/src/components/Restaurant/Takeout/TakeoutUnavailableView'

import { useRestaurant } from 'models/Restaurant'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function TakeoutView({
  children,
}: {
  children?: React.ReactNode
}) {
  const restaurantId = useRestaurantId()
  const { restaurant } = useRestaurant(restaurantId)

  console.log('restaurant', restaurant)

  if (!restaurant?.has_current_takeout_contract) {
    return <TakeoutUnavailableView />
  }
  return <>{children}</>
}
