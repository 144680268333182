import React from 'react'

import { View } from 'react-native'
import { useNavigate } from 'react-router'

import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import GourmetSiteAccountSettingView from '@hello-ai/for_r_app/src/components/GourmetSiteAccount/GourmetSiteAccountSettingView'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function GourmetSiteAccountSetting() {
  const restaurantId = useRestaurantId()
  const navigate = useNavigate()

  return (
    <View style={{ backgroundColor: Colors.bgBlack, flex: 1 }}>
      <GourmetSiteAccountSettingView
        restaurantId={restaurantId}
        onPressItem={(source) => {
          navigate(
            `/restaurants/${restaurantId}/gourmet_site/account_settings/${source}`
          )
        }}
      />
    </View>
  )
}
