import React from 'react'

import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'

import { useImportMailSettings } from 'models/ImportMailSettings'
import { useRestaurantId } from 'modules/useRestaurantId'

export default function StatusLabel() {
  const restaurantId = useRestaurantId()
  const { importMailSettingsData } = useImportMailSettings(restaurantId)

  return (
    <Text
      style={[
        {
          padding: 8,
          fontSize: 14,
          fontWeight: '600',
          lineHeight: 22,
        },
        importMailSettingsData?.site_controller_enabled
          ? {
              color: Colors.success,
              backgroundColor: Colors.accentBg,
            }
          : {
              color: Colors.black60,
              backgroundColor: Colors.black6,
            },
      ]}
    >
      {importMailSettingsData?.site_controller_enabled
        ? t('契約中')
        : t('未契約')}
    </Text>
  )
}
