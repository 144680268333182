import React from 'react'

import { Stack, StackRoutes } from 'components/Shared/Navigation/Stack'
import SignUp from 'pages/SignUp'

const routes: StackRoutes = {
  Index: {
    title: '',
    fullPath: '/sign_up',
    relativePath: '/',
    exact: true,
    element: <SignUp />,
    screenOptions: {
      headerShown: false,
    },
  },
}

export default function SignUpStack() {
  return <Stack routes={routes} />
}
