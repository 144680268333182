import React, { useState } from 'react'

import { faDiagramCells } from '@fortawesome/pro-regular-svg-icons/faDiagramCells'
import { faList } from '@fortawesome/pro-regular-svg-icons/faList'
import { faChair } from '@fortawesome/pro-solid-svg-icons/faChair'
import { ViewProps } from 'react-native'
import Reanimated, {
  AnimateProps,
  FlipInEasyY,
  FlipOutEasyY,
} from 'react-native-reanimated'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'

import { HeaderRightButton } from '../Shared/HeaderRightButton'
import { SegmentedControl } from '../Shared/SegmentedControl'

export type HeaderViewModeType = 'chart' | 'list' | 'map'

export function HeaderViewModeButton({
  viewMode,
  onPress,
}: {
  viewMode: HeaderViewModeType
  onPress: () => void
}) {
  const [entering, setEntering] = useState<
    AnimateProps<ViewProps>['entering'] | undefined
  >(undefined)
  const { width, sm } = useResponsive()

  return (
    <HeaderRightButton
      style={{
        paddingRight: 0,
      }}
      icon={
        <Reanimated.View
          key={viewMode}
          entering={entering}
          exiting={FlipOutEasyY}
        >
          <FontAwesomeIcon
            icon={viewMode === 'list' ? faList : faDiagramCells}
            color={Colors.primary}
            size={width < sm ? 21 : 32}
          />
        </Reanimated.View>
      }
      onPress={() => {
        setEntering(() => FlipInEasyY)
        onPress()
      }}
    />
  )
}

export function HeaderViewModeSegmentedControl({
  viewMode,
  onPress,
}: {
  viewMode: HeaderViewModeType
  onPress: (value: HeaderViewModeType) => void
}) {
  const { width, sm } = useResponsive()

  return (
    <SegmentedControl
      style={{ width: 216, height: 40 }}
      tintColor={Colors.primary}
      buttons={[
        {
          onPress: () => {
            onPress('map')
          },
          selected: viewMode === 'map',
          Component: (
            <FontAwesomeIcon
              icon={faChair}
              color={viewMode === 'map' ? Colors.white : Colors.primary}
              size={width < sm ? 16 : 24}
            />
          ),
        },
        {
          onPress: () => {
            onPress('chart')
          },
          selected: viewMode === 'chart',
          Component: (
            <FontAwesomeIcon
              icon={faDiagramCells}
              color={viewMode === 'chart' ? Colors.white : Colors.primary}
              size={width < sm ? 16 : 24}
            />
          ),
        },
        {
          onPress: () => {
            onPress('list')
          },
          selected: viewMode === 'list',
          Component: (
            <FontAwesomeIcon
              icon={faList}
              color={viewMode === 'list' ? Colors.white : Colors.primary}
              size={width < sm ? 16 : 24}
            />
          ),
        },
      ]}
    />
  )
}
