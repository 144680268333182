import React from 'react'

import ArchivedListView from '@hello-ai/for_r_app/src/components/Restaurant/TableMenu/ArchivedListView'

import { useRestaurantId } from 'modules/useRestaurantId'

export default function TableProductsScreen() {
  const restaurantId = useRestaurantId()

  return <ArchivedListView restaurantId={restaurantId} />
}
