import React, { ComponentProps, useCallback, useState } from 'react'

import { faChevronDown } from '@fortawesome/pro-solid-svg-icons/faChevronDown'
import { View } from 'react-native'

import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { Pagination } from '@hello-ai/ar_shared/src/components/Pagination'
import { RefreshControl } from '@hello-ai/ar_shared/src/components/RefreshControl'
import {
  SelectItem,
  SelectInput,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { useToken } from '@hello-ai/ar_shared/src/modules/auth'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'

import { useRestaurant } from '../../../models/Restaurant'
import {
  updateRestaurantReservation,
  useRestaurantReservations,
} from '../../../models/RestaurantReservation'
import { ContentScrollView } from '../../Restaurant/Reservation/ListView/Content'
import { displayToastSuccess } from '../../Shared/Toast'
import { IrregularNotification } from '../IrregularNotification'

const RESERVED_TYPES: SelectItem<
  'all' | 'reservation' | 'future_only' | 'walkin'
>[] = [
  {
    label: t('全て'),
    value: 'all',
  },
  {
    label: t('予約'),
    value: 'reservation',
  },
  {
    label: t('来店前の予約'),
    value: 'future_only',
  },
  {
    label: t('ウォークイン'),
    value: 'walkin',
  },
]

export default function Reserved({
  restaurantId,
  date,
  onPressRestaurantReservation,
  onPressCustomer,
}: {
  restaurantId: number
  date: dayjs.Dayjs
  onPressRestaurantReservation: ComponentProps<
    typeof ContentScrollView
  >['onPressRestaurantReservation']
  onPressCustomer: ComponentProps<typeof ContentScrollView>['onPressCustomer']
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [reservedType, setReservedType] =
    useState<(typeof RESERVED_TYPES)[number]['value']>('all')
  const { data: restaurant } = useRestaurant(restaurantId)

  const { restaurantReservations, headerData, mutate } =
    useRestaurantReservations(restaurantId, {
      date: date.format('YYYY-MM-DD'),
      future_only: reservedType === 'future_only',
      page: currentPage,
      per_page: 10,
    })

  const [isRefreshing, setIsRefreshing] = useState(false)

  const onRefresh = useCallback(async () => {
    setIsRefreshing(true)
    try {
      await mutate()
    } finally {
      setIsRefreshing(false)
    }
  }, [mutate])
  const token = useToken()

  if (restaurant == null) return null

  return (
    <ContentScrollView
      restaurant={restaurant}
      restaurantReservations={restaurantReservations}
      headerContent={
        <View style={{ flex: 1 }}>
          <IrregularNotification
            restaurantId={restaurantId}
            date={date}
            style={{ marginBottom: 24 }}
          />
          <SelectInput
            selectedValue={reservedType}
            setValue={setReservedType}
            items={RESERVED_TYPES}
            labelStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            style={{ backgroundColor: 'white', maxWidth: '50%' }}
            indicatorIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size={10}
                color={Colors.black}
              />
            }
          />
          {/* {width > sm && (
            <Button
              mode="text"
              disabled={Platform.OS !== 'web'}
              roundness={24}
              style={{
                marginLeft: 24,
                backgroundColor: 'white',
                flexDirection: 'row',
              }}
            >
              <FontAwesomeIcon
                icon={faPrint}
                size={20}
                color={Colors.primary}
                style={{ marginRight: 10 }}
              />
              <Text style={{ color: Colors.primary, fontWeight: '600' }}>
                {t('印刷する')}
              </Text>
            </Button>
          )} */}
        </View>
      }
      headerContentSm={
        <View>
          <IrregularNotification
            restaurantId={restaurantId}
            date={date}
            style={{ marginTop: 24, marginBottom: 8 }}
          />
          <SelectInput
            selectedValue={reservedType}
            setValue={setReservedType}
            items={RESERVED_TYPES}
            labelStyle={{
              fontSize: 16,
              fontWeight: '300',
            }}
            style={{ backgroundColor: 'white', marginTop: 16 }}
            indicatorIcon={
              <FontAwesomeIcon
                icon={faChevronDown}
                size={10}
                color={Colors.black}
              />
            }
          />
        </View>
      }
      onPressRestaurantReservation={onPressRestaurantReservation}
      onPressCustomer={onPressCustomer}
      onUpdateVisitStatus={async (reservationId, visitStatus) => {
        if (token != null) {
          const { error } = await updateRestaurantReservation(
            token,
            restaurantId,
            reservationId.toString(),
            {
              visit_status: visitStatus,
            }
          )
          if (error !== undefined) return
          await mutate()
          displayToastSuccess(t('来店ステータスを更新しました'))
        }
      }}
      onIssueSmartPaymentReceipt={() => mutate()}
      refreshControl={
        <RefreshControl refreshing={isRefreshing} onRefresh={onRefresh} />
      }
      pagination={
        <Pagination
          currentPage={currentPage}
          totalPage={headerData?.totalPages ?? 0}
          setPage={(page) => setCurrentPage(page)}
        />
      }
    />
  )
}
