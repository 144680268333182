import React, { useMemo, useRef } from 'react'

import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons/faTrashAlt'
import produce from 'immer'
import { range, times } from 'lodash'
import { View, Platform } from 'react-native'

import { toDoubleDigits } from '@hello-ai/ar_shared/src//modules/number'
import {
  Alert,
  AlertMethods,
  AlertProvider,
} from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormGroup, FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { KeyboardAwareScrollView } from '@hello-ai/ar_shared/src/components/KeyboardAwareScrollView'
import { RadioIcon } from '@hello-ai/ar_shared/src/components/Radio'
import {
  SelectInput,
  SelectItem,
} from '@hello-ai/ar_shared/src/components/SelectInput'
import { ShadowBox } from '@hello-ai/ar_shared/src/components/ShadowBox'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import { TouchableOpacity } from '@hello-ai/ar_shared/src/components/Touchables'
import { Wrap } from '@hello-ai/ar_shared/src/components/Wrap'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import dayjs from '@hello-ai/ar_shared/src/modules/dayjs'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/for_r'
import {
  supportedLocales,
  SupportedLocale,
} from '@hello-ai/ar_shared/src/modules/locale'
import {
  getFormatTime,
  toHoursAndMinutes,
  toSeconds,
} from '@hello-ai/ar_shared/src/modules/time'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import { RestaurantBusinessTime as RestaurantBusinessTimeModel } from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTime'
import {
  RestaurantBusinessTimeReservationPeriodPolicySinceType,
  RestaurantBusinessTimeReservationPeriodPolicyUntilType,
} from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTimeReservationPeriodPolicy'
import { RestaurantBusinessTimeReservationTime } from '@hello-ai/ar_shared/src/types/ForR/RestaurantBusinessTimeReservationTime'

import {
  LocaleValue,
  confirmSaveIncompleteChangesAsync,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
  TranslationFormItem,
  TranslationFormList,
} from '../Translation'

const formRadioWidth = 200

const maxEndHours = 30

const hours = times(maxEndHours, (i) => {
  return {
    label: toDoubleDigits(i),
    value: i,
  }
})

const mins = times(4, (i) => {
  const min = i * 15
  return {
    label: toDoubleDigits(min),
    value: min,
  }
})

const stayingHours = times(6, (i) => {
  return {
    label: t('{{text}}時間', {
      text: i,
    }),
    value: i,
  }
})

const stayingMins = times(4, (i) => {
  const min = i * 15
  return {
    label: t('{{text}}分', {
      text: min,
    }),
    value: min,
  }
})

const sinceMonthBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('ヶ月前'),
    value: undefined,
  },
  ...range(0, 36 + 1).map((value) => ({
    label: t('{{month}}ヶ月前', {
      month: value,
    }),
    value,
  })),
]

const sinceDayBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('日前'),
    value: undefined,
  },
  ...range(0, 30 + 1).map((value) => ({
    // 31日は1ヶ月で設定できるので、日付の最大は30日で足りる
    label: t('{{text}}日前', {
      text: value,
    }),
    value,
  })),
]

const sinceSpecificDayItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('日'),
    value: undefined,
  },
  ...range(0, 30 + 1).map((value) => ({
    // 31日は1ヶ月で設定できるので、日付の最大は30日で足りる
    label: t('{{text}}日', {
      text: value,
    }),
    value,
  })),
]

const sinceTimeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

const sinceSpecificTimeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

const untilDayBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('日前'),
    value: undefined,
  },
  ...range(0, 180 + 1).map((value) => {
    return value === 0
      ? { label: t('当日'), value }
      : value === 1
        ? { label: t('前日'), value }
        : {
            label: t('{{text}}日前', {
              text: value,
            }),
            value,
          }
  }),
]

function getTimeBeforeLabel(hours: number, mins: number) {
  let str = ''

  if (hours > 0) {
    str += t('{{text}}時間', {
      text: hours,
    })
  }
  if (mins > 0 || (hours === 0 && mins === 0)) {
    str += t('{{text}}分', {
      text: mins,
    })
  }
  str += t('前')
  return str
}

const untilTimeBeforeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時間前'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getTimeBeforeLabel(hours, mins),
      value: toSeconds(hours, mins),
    }))
  ),
]

const untilSpecificTimeItems: Array<SelectItem<number | undefined>> = [
  {
    label: t('時'),
    value: undefined,
  },
  ...range(0, 23 + 1).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

const reservationTimeItems: Array<SelectItem<number>> = [
  ...range(0, maxEndHours).flatMap((hours) =>
    range(0, 60, 15).map((mins) => ({
      label: getFormatTime(toSeconds(hours, mins)),
      value: toSeconds(hours, mins),
    }))
  ),
]

export function RestaurantBusinessTimeForm({
  restaurantBusinessTime,
  onCreateOrUpdate,
  onDestroy,
  children,
  sortedLocales,
  createTranslation,
}: {
  restaurantBusinessTime?: RestaurantBusinessTimeModel
  onCreateOrUpdate: (params: any) => Promise<void>
  onDestroy: () => void
  children?: React.ReactNode
  sortedLocales: SupportedLocale[]
  createTranslation: (params: {
    text: string
    source_lang: string
    target_langs: string[]
  }) => Promise<{
    data?: {
      translations: Record<SupportedLocale, string>
    }
    error?: unknown
  }>
}) {
  const { width, sm } = useResponsive()
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const alertRef = useRef<AlertMethods>(null)

  const defaultName: { [key in SupportedLocale]: string } = useMemo(
    () => ({
      ja:
        restaurantBusinessTime?.name_i18n_ja ??
        restaurantBusinessTime?.name ??
        '',
      en: restaurantBusinessTime?.name_i18n_en ?? '',
      ko: restaurantBusinessTime?.name_i18n_ko ?? '',
      'zh-CN': restaurantBusinessTime?.name_i18n_zh_cn ?? '',
      'zh-TW': restaurantBusinessTime?.name_i18n_zh_tw ?? '',
      'zh-HK': restaurantBusinessTime?.name_i18n_zh_hk ?? '',
      th: restaurantBusinessTime?.name_i18n_th ?? '',
    }),
    [
      restaurantBusinessTime?.name,
      restaurantBusinessTime?.name_i18n_ja,
      restaurantBusinessTime?.name_i18n_en,
      restaurantBusinessTime?.name_i18n_ko,
      restaurantBusinessTime?.name_i18n_zh_cn,
      restaurantBusinessTime?.name_i18n_zh_tw,
      restaurantBusinessTime?.name_i18n_zh_hk,
      restaurantBusinessTime?.name_i18n_th,
    ]
  )

  const [name, setName] = useFormState<LocaleValue>(defaultName)

  const [startHour, setStartHour] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.start_time).hours
      : 12
  )
  const [startMin, setStartMin] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.start_time).minutes
      : 0
  )
  const [lastOrderHour, setLastOrderHour] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.last_order_time).hours
      : 12
  )
  const [lastOrderMin, setLastOrderMin] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.last_order_time).minutes
      : 0
  )
  const [endHour, setEndHour] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.end_time).hours
      : 12
  )
  const [endMin, setEndMin] = useFormState(
    restaurantBusinessTime
      ? toHoursAndMinutes(restaurantBusinessTime.end_time).minutes
      : 0
  )
  const [stayingHour, setStayingHour] = useFormState(
    restaurantBusinessTime?.staying_time != null
      ? toHoursAndMinutes(restaurantBusinessTime.staying_time).hours
      : 2
  )
  const [stayingMin, setStayingMin] = useFormState(
    restaurantBusinessTime?.staying_time != null
      ? toHoursAndMinutes(restaurantBusinessTime.staying_time).minutes
      : 0
  )
  const [wdays, setWdays] = useFormState<number[]>(
    restaurantBusinessTime
      ? restaurantBusinessTime.wdays
      : [0, 1, 2, 3, 4, 5, 6, 7]
  )

  // restaurant_business_time_reservation_period_policy

  const [reservable, setReservable] = useFormState(
    restaurantBusinessTime?.reservable ?? true
  )

  const [sinceType, setSinceType] =
    useFormState<RestaurantBusinessTimeReservationPeriodPolicySinceType>(
      restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
        ?.since_type ?? 'each_day'
    )

  const [sinceDayBefore, setSinceDayBefore] = useFormState<number | undefined>(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_day_before ?? undefined
  )
  const [sinceMonthBefore, setSinceMonthBefore] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_month_before ?? undefined
  )
  const [sinceSpecificDay, setSinceSpecificDay] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_specific_day ?? undefined
  )
  const [sinceSpecificTime, setSinceSpecificTime] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.since_specific_time ?? undefined
  )

  const [untilType, setUntilType] =
    useFormState<RestaurantBusinessTimeReservationPeriodPolicyUntilType>(
      restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
        ?.until_type ?? 'offset'
    )

  const [untilDayBefore, setUntilDayBefore] = useFormState<number | undefined>(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.until_day_before ?? undefined
  )

  const [untilTimeBefore, setUntilTimeBefore] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.until_time_before ?? undefined
  )

  const [untilSpecificTime, setUntilSpecificTime] = useFormState<
    number | undefined
  >(
    restaurantBusinessTime?.restaurant_business_time_reservation_period_policy
      ?.until_specific_time ?? undefined
  )

  // 回転（予約可能時間帯）
  const [
    restaurantBusinessTimeReservationTimes,
    setRestaurantBusinessTimeReservationTimes,
  ] = useFormState<
    Array<{
      id?: RestaurantBusinessTimeReservationTime['id']
      start_time: RestaurantBusinessTimeReservationTime['start_time']
      end_time: RestaurantBusinessTimeReservationTime['start_time']
      _destroy?: boolean
    }>
  >(restaurantBusinessTime?.restaurant_business_time_reservation_times ?? [])

  const onChangeWdays = (wday: number, value: boolean) => {
    if (value) {
      if (!wdays.includes(wday)) {
        setWdays((wdays) => [...wdays, wday])
      }
    } else {
      setWdays((wdays) => wdays.filter((wd) => wd !== wday))
    }
  }

  const onChangeReservationTimeStartTime = (index: number, value: number) => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft[index].start_time = value
      })
    )
  }

  const onChangeReservationTimeEndTime = (index: number, value: number) => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft[index].end_time = value
      })
    )
  }

  const onPressRemoveReservationTime = (index: number) => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft[index]._destroy = true
      })
    )
  }

  const onPressAddReservationTime = () => {
    setRestaurantBusinessTimeReservationTimes(
      produce(restaurantBusinessTimeReservationTimes, (draft) => {
        draft.push({
          start_time: toSeconds(startHour, startMin),
          end_time: toSeconds(lastOrderHour, lastOrderMin),
        })
      })
    )
  }

  const onPress = async () => {
    const alert = Platform.select({
      web: alertRef.current?.alert,
      default: Alert.alert,
    })
    const startTime = toSeconds(startHour, startMin)
    const lastOrderTime = toSeconds(lastOrderHour, lastOrderMin)
    const endTime = toSeconds(endHour, endMin)
    const stayingTime =
      stayingHour != null && stayingMin != null
        ? toSeconds(stayingHour, stayingMin)
        : null

    let periodPolicyParams: any = {}

    if (
      sinceType === 'each_day' &&
      [sinceMonthBefore, sinceDayBefore, sinceSpecificTime].some(
        (value) => value !== undefined
      )
    ) {
      periodPolicyParams = {
        since_type: sinceType,
        since_month_before: sinceMonthBefore,
        since_day_before: sinceDayBefore,

        since_specific_time: sinceSpecificTime,
      }
    } else if (
      sinceType === 'bulk' &&
      [sinceMonthBefore, sinceSpecificDay, sinceSpecificTime].some(
        (value) => value !== undefined
      )
    ) {
      periodPolicyParams = {
        since_type: sinceType,
        since_month_before: sinceMonthBefore,
        since_specific_day: sinceSpecificDay,
        since_specific_time: sinceSpecificTime,
      }
    }

    if (
      untilType === 'offset' &&
      [untilDayBefore, untilTimeBefore].some((value) => value !== undefined)
    ) {
      periodPolicyParams = {
        ...periodPolicyParams,
        until_type: untilType,
        until_day_before: untilDayBefore,
        until_time_before: untilTimeBefore,
      }
    } else if (
      untilType === 'specific' &&
      [untilDayBefore, untilSpecificTime].some((value) => value !== undefined)
    ) {
      periodPolicyParams = {
        ...periodPolicyParams,
        until_type: untilType,
        until_day_before: untilDayBefore,
        until_specific_time: untilSpecificTime,
      }
    }

    const params = {
      name: name.ja,
      ...Object.fromEntries(
        supportedLocales.map((locale) => {
          return [
            `name_i18n_${locale.replaceAll('-', '_').toLowerCase()}`,
            name[locale],
          ]
        })
      ),
      start_time: startTime,
      last_order_time: lastOrderTime,
      end_time: endTime,
      staying_time: stayingTime,
      wdays,
      reservable,
      ...(Object.keys(periodPolicyParams).length > 0
        ? {
            restaurant_business_time_reservation_period_policy:
              periodPolicyParams,
          }
        : {}),
      restaurant_business_time_reservation_times:
        restaurantBusinessTimeReservationTimes,
    }

    if (restaurantBusinessTime) {
      if (hasIncompleteChanges(name, defaultName)) {
        if (!(await confirmSaveIncompleteChangesAsync(alert))) {
          return
        }
      }
    }

    return await onCreateOrUpdate(params)
  }

  const space = width < sm ? 24 : 32

  return (
    <View
      style={{
        flex: 1,
        paddingBottom: 106,
      }}
    >
      <KeyboardAwareScrollView
        contentContainerStyle={{
          paddingVertical: 48,
          paddingHorizontal: 32,
        }}
        style={{ backgroundColor: Colors.bgBlack }}
      >
        <View>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>
            {t('基本項目')}
          </Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <TranslationFormList
              formLabel={<FormLabel value={t('名前')} required />}
              showsFormDescription
              sortedLocales={sortedLocales}
              values={name}
              onChangeValues={setName}
              createTranslation={(params) => createTranslation(params)}
            >
              {({ locale }) => (
                <TranslationFormItem
                  key={locale}
                  formLabel={
                    <FormLabel value={getTranslationLocaleLabel(locale)} />
                  }
                >
                  <TextInput
                    placeholder={''}
                    value={name[locale]}
                    onChangeText={(text) =>
                      setName((value) => ({
                        ...value,
                        [locale]: text,
                      }))
                    }
                    autoCapitalize="none"
                  />
                </TranslationFormItem>
              )}
            </TranslationFormList>
            <Divider
              style={{
                marginVertical: 24,
              }}
            />
            <View
              style={[
                { marginTop: space },
                width >= sm && { flexDirection: 'row' },
              ]}
            >
              <View
                style={[
                  width >= sm && {
                    flex: 1,
                    marginRight: 12,
                  },
                ]}
              >
                <Text style={{ fontWeight: '600' }}>{t('開始時間')}</Text>
                <View
                  style={{
                    marginTop: 16,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SelectInput
                    selectedValue={startHour}
                    setValue={setStartHour}
                    items={hours}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                  <Text style={{ marginHorizontal: 8 }}>:</Text>
                  <SelectInput
                    selectedValue={startMin}
                    setValue={setStartMin}
                    items={mins}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                </View>
              </View>
              <View
                style={[
                  width < sm
                    ? {
                        marginTop: space,
                      }
                    : {
                        flex: 1,
                        marginLeft: 12,
                      },
                ]}
              >
                <Text style={{ fontWeight: '600' }}>
                  {t('ラストオーダー時間')}
                </Text>
                <View
                  style={{
                    marginTop: 16,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SelectInput
                    selectedValue={lastOrderHour}
                    setValue={setLastOrderHour}
                    items={hours}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                  <Text style={{ marginHorizontal: 8 }}>:</Text>
                  <SelectInput
                    selectedValue={lastOrderMin}
                    setValue={setLastOrderMin}
                    items={mins}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                </View>
              </View>
              <View
                style={[
                  width < sm
                    ? {
                        marginTop: space,
                      }
                    : {
                        flex: 1,
                        marginLeft: 12,
                      },
                ]}
              >
                <Text style={{ fontWeight: '600' }}>{t('終了時間')}</Text>
                <View
                  style={{
                    marginTop: 16,
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <SelectInput
                    selectedValue={endHour}
                    setValue={setEndHour}
                    items={hours}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                  <Text style={{ marginHorizontal: 8 }}>:</Text>
                  <SelectInput
                    selectedValue={endMin}
                    setValue={setEndMin}
                    items={mins}
                    style={{ flex: 1 }}
                    containerStyle={{ height: 64 }}
                  />
                </View>
              </View>
            </View>
            <View style={{ marginTop: space }}>
              <Text style={{ fontWeight: '600' }}>{t('滞在時間')}</Text>
              <View
                style={{
                  marginTop: 16,
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <SelectInput
                  selectedValue={stayingHour}
                  setValue={setStayingHour}
                  items={stayingHours}
                  style={{ flex: 1 }}
                  containerStyle={{ height: 64 }}
                />
                <Text style={{ marginHorizontal: 8 }}>:</Text>
                <SelectInput
                  selectedValue={stayingMin}
                  setValue={setStayingMin}
                  items={stayingMins}
                  style={{ flex: 1 }}
                  containerStyle={{ height: 64 }}
                />
              </View>
            </View>
            <View style={{ marginTop: space }}>
              <Text style={{ fontWeight: '600' }}>{t('曜日')}</Text>
              <View style={{ marginTop: 16 }}>
                <Wrap verticalGap={12} horizontalGap={width < sm ? 12 : 16}>
                  {dayjs
                    .weekdays()
                    .concat(t('祝日'))
                    .map((label, wday) => (
                      <Checkbox
                        key={wday.toString()}
                        checked={wdays.includes(wday)}
                        onChange={(value) => {
                          onChangeWdays(wday, value)
                        }}
                        checkboxLabel={<CheckboxLabel value={label} />}
                      />
                    ))}
                </Wrap>
              </View>
              <Text style={{ marginTop: 16, color: Colors.secondaryBlack }}>
                {t('休日が祝日の場合は休日の設定が優先されます')}
              </Text>
            </View>
          </ShadowBox>
        </View>

        <View style={{ marginTop: 48 }}>
          <Text style={{ fontSize: 22, fontWeight: '600' }}>{t('予約')}</Text>
          <ShadowBox style={{ marginTop: 24 }}>
            <FormGroup
              mode="inline"
              formLabel={
                <View>
                  <FormLabel value={t('予約可')} />
                  <Text
                    style={{
                      marginTop: 8,
                    }}
                  >
                    {t('この営業時間内で予約可能になります')}
                  </Text>
                </View>
              }
              containerStyle={{
                justifyContent: 'center',
                flex: 1,
              }}
              formLabelContainerStyle={{ flex: 1 }}
            >
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <Switch value={reservable} onValueChange={setReservable} />
              </View>
            </FormGroup>
            <FormGroup
              mode={width < sm ? 'vertical' : 'inline-expanded'}
              style={{ marginTop: 24 }}
              formLabel={<FormLabel value={t('予約可能時間帯')} />}
            >
              {restaurantBusinessTimeReservationTimes.map(
                (reservationTime, index) => {
                  if (reservationTime._destroy) return null

                  return (
                    <View
                      key={`${index}`}
                      style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: index === 0 ? 0 : 24,
                      }}
                    >
                      <SelectInput
                        items={reservationTimeItems}
                        selectedValue={reservationTime.start_time}
                        setValue={(value) => {
                          onChangeReservationTimeStartTime(index, value)
                        }}
                      />
                      <Text
                        style={{
                          marginHorizontal: 8,
                        }}
                      >
                        〜
                      </Text>
                      <SelectInput
                        items={reservationTimeItems}
                        selectedValue={reservationTime.end_time}
                        setValue={(value) => {
                          onChangeReservationTimeEndTime(index, value)
                        }}
                      />
                      <TouchableOpacity
                        style={[{ marginLeft: 60 }]}
                        onPress={() => {
                          onPressRemoveReservationTime(index)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrashAlt}
                          size={24}
                          color={Colors.secondaryBlack}
                        />
                      </TouchableOpacity>
                    </View>
                  )
                }
              )}

              <Text
                style={{
                  marginTop: 8,
                  color: Colors.black60,
                  fontSize: 14,
                }}
              >
                {t(
                  '追加しない場合は、営業時間（ラストオーダーまで）が予約可能時間帯として設定されます'
                )}
              </Text>
              <Button
                style={{ marginTop: 24 }}
                mode="outline"
                height={48}
                onPress={onPressAddReservationTime}
              >
                {t('予約可能時間帯を追加')}
              </Button>
            </FormGroup>
            <FormGroup
              mode="vertical"
              style={{ marginTop: 24 }}
              formLabel={<FormLabel value={t('予約受付開始')} />}
            >
              <View
                style={
                  width >= sm && {
                    flexDirection: 'row',
                    alignItems: 'center',
                  }
                }
              >
                <TouchableOpacity
                  onPress={() => {
                    setSinceType('each_day')
                  }}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: formRadioWidth,
                  }}
                >
                  <RadioIcon selected={sinceType === 'each_day'} />
                  <Text style={{ marginLeft: 10 }}>{t('毎日予約')}</Text>
                </TouchableOpacity>
                <SelectInput
                  items={sinceMonthBeforeItems}
                  selectedValue={sinceMonthBefore}
                  setValue={setSinceMonthBefore}
                  labelStyle={[
                    sinceMonthBefore === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                  style={
                    width < sm && {
                      marginTop: 16,
                    }
                  }
                />
                <SelectInput
                  style={
                    width < sm
                      ? {
                          marginTop: 16,
                        }
                      : {
                          marginLeft: 24,
                        }
                  }
                  items={sinceDayBeforeItems}
                  selectedValue={sinceDayBefore}
                  setValue={setSinceDayBefore}
                  labelStyle={[
                    sinceDayBefore === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                />
                <SelectInput
                  style={
                    width < sm
                      ? {
                          marginTop: 16,
                        }
                      : {
                          marginLeft: 24,
                        }
                  }
                  items={sinceTimeItems}
                  selectedValue={sinceSpecificTime}
                  setValue={setSinceSpecificTime}
                  labelStyle={[
                    sinceSpecificTime === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                />
              </View>
              <Text
                style={{
                  marginLeft: width < sm ? 0 : formRadioWidth,
                  marginTop: 8,
                  color: Colors.black60,
                  fontSize: 14,
                }}
              >
                {t(
                  '例：1ヶ月前に設定した場合、1月10日 10:00時点で、2月9日10:00までの予約ができます'
                )}
              </Text>
              <View
                style={[
                  {
                    marginTop: 24,
                  },
                  width >= sm && {
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <TouchableOpacity
                  onPress={() => {
                    setSinceType('bulk')
                  }}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: formRadioWidth,
                  }}
                >
                  <RadioIcon selected={sinceType === 'bulk'} />
                  <Text style={{ marginLeft: 10 }}>{t('一斉予約')}</Text>
                </TouchableOpacity>

                <SelectInput
                  items={sinceMonthBeforeItems}
                  selectedValue={sinceMonthBefore}
                  setValue={setSinceMonthBefore}
                  labelStyle={[
                    sinceMonthBefore === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                  style={
                    width < sm && {
                      marginTop: 16,
                    }
                  }
                />
                <SelectInput
                  style={
                    width < sm
                      ? {
                          marginTop: 16,
                        }
                      : {
                          marginLeft: 24,
                        }
                  }
                  items={sinceSpecificDayItems}
                  selectedValue={sinceSpecificDay}
                  setValue={setSinceSpecificDay}
                  labelStyle={[
                    sinceSpecificDay === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                />
                <SelectInput
                  style={
                    width < sm
                      ? {
                          marginTop: 16,
                        }
                      : {
                          marginLeft: 24,
                        }
                  }
                  items={sinceSpecificTimeItems}
                  selectedValue={sinceSpecificTime}
                  setValue={setSinceSpecificTime}
                  labelStyle={[
                    sinceSpecificTime === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                />
              </View>
              <Text
                style={{
                  marginLeft: width < sm ? 0 : formRadioWidth,
                  marginTop: 8,
                  color: Colors.black60,
                  fontSize: 14,
                }}
              >
                {t(
                  '例：2ヶ月前 1日10:00に設定した場合、1月の予約枠が、2ヶ月前の1日10時（11月1日10:00）に開放されます'
                )}
              </Text>
            </FormGroup>
            <FormGroup
              mode="vertical"
              style={{ marginTop: 24 }}
              formLabel={<FormLabel value={t('予約受付終了')} />}
            >
              <View
                style={
                  width >= sm && {
                    flexDirection: 'row',
                    alignItems: 'center',
                  }
                }
              >
                <TouchableOpacity
                  onPress={() => {
                    setUntilType('offset')
                  }}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: formRadioWidth,
                  }}
                >
                  <RadioIcon selected={untilType === 'offset'} />
                  <Text style={{ marginLeft: 10 }}>{t('〜前')}</Text>
                </TouchableOpacity>
                <SelectInput
                  items={untilDayBeforeItems}
                  selectedValue={untilDayBefore}
                  setValue={setUntilDayBefore}
                  labelStyle={[
                    untilDayBefore === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                  style={
                    width < sm && {
                      marginTop: 16,
                    }
                  }
                />
                <SelectInput
                  style={
                    width < sm
                      ? {
                          marginTop: 16,
                        }
                      : {
                          marginLeft: 24,
                        }
                  }
                  items={untilTimeBeforeItems}
                  selectedValue={untilTimeBefore}
                  setValue={setUntilTimeBefore}
                  labelStyle={[
                    untilTimeBefore === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                />
              </View>
              <View>
                <Text
                  style={{
                    marginLeft: width < sm ? 0 : formRadioWidth,
                    marginTop: 8,
                    color: Colors.black60,
                    fontSize: 14,
                  }}
                >
                  {t(
                    '例：前日1時間前に設定した場合、1月10日 10:00の予約を、1月9日09:00に締め切ります'
                  )}
                </Text>
              </View>
              <View
                style={[
                  {
                    marginTop: 24,
                  },
                  width >= sm && {
                    flexDirection: 'row',
                    alignItems: 'center',
                  },
                ]}
              >
                <TouchableOpacity
                  onPress={() => {
                    setUntilType('specific')
                  }}
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    width: formRadioWidth,
                  }}
                >
                  <RadioIcon selected={untilType === 'specific'} />
                  <Text style={{ marginLeft: 10 }}>{t('〜日前〜時')}</Text>
                </TouchableOpacity>
                <SelectInput
                  items={untilDayBeforeItems}
                  selectedValue={untilDayBefore}
                  setValue={setUntilDayBefore}
                  labelStyle={[
                    untilDayBefore === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                  style={
                    width < sm && {
                      marginTop: 16,
                    }
                  }
                />
                <SelectInput
                  style={
                    width < sm
                      ? {
                          marginTop: 16,
                        }
                      : {
                          marginLeft: 24,
                        }
                  }
                  items={untilSpecificTimeItems}
                  selectedValue={untilSpecificTime}
                  setValue={setUntilSpecificTime}
                  labelStyle={[
                    untilSpecificTime === undefined && {
                      color: Colors.black60,
                    },
                  ]}
                />
              </View>
              <View>
                <Text
                  style={{
                    marginLeft: width < sm ? 0 : formRadioWidth,
                    marginTop: 8,
                    color: Colors.black60,
                    fontSize: 14,
                  }}
                >
                  {t(
                    '例：前日18:00に設定した場合、1月10日の予約枠を、1月9日18:00に締め切ります'
                  )}
                </Text>
              </View>
            </FormGroup>
          </ShadowBox>
        </View>

        {children}
      </KeyboardAwareScrollView>
      <View
        style={{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          paddingVertical: width < sm ? 16 : 12,
          paddingHorizontal: width < sm ? 16 : 24,
          paddingBottom: width < sm ? 40 : 58,
          backgroundColor: Colors.white,
          flexDirection: 'row',
          justifyContent: 'center',
          gap: width > sm ? 16 : 12,
          borderTopWidth: 1,
          borderColor: Colors.black10,
        }}
      >
        {restaurantBusinessTime != null && (
          <Button
            mode="outline"
            variant="danger-secondary"
            onPress={() => onDestroy()}
            style={{
              borderColor: Colors.caution,
              backgroundColor: 'transparent',
              maxWidth: width > sm ? 296 : '',
              paddingLeft: width > sm ? 0 : 12,
              paddingRight: width > sm ? 0 : 12,
              flex: width > sm ? 1 : 0,
            }}
            height={48}
          >
            {width > sm ? (
              t('削除する')
            ) : (
              <FontAwesomeIcon
                icon={faTrashAlt}
                size={20}
                color={Colors.caution}
              />
            )}
          </Button>
        )}
        <Button
          onPress={() => {
            setIsSubmitting(true)
            onPress().finally(() => setIsSubmitting(false))
          }}
          disabled={isSubmitting}
          loading={isSubmitting}
          style={{
            marginLeft: 20,
            width: 280,
            height: 48,
          }}
        >
          {restaurantBusinessTime != null ? t('更新する') : t('作成する')}
        </Button>
      </View>
      <View
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        pointerEvents="box-none"
      >
        <AlertProvider ref={alertRef} />
      </View>
    </View>
  )
}
