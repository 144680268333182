import React, { useMemo, useState } from 'react'

import { faGripLines } from '@fortawesome/pro-solid-svg-icons/faGripLines'
import {
  View,
  ScrollView,
  TouchableOpacity as RNTouchableOpacity,
  FlatList,
} from 'react-native'

import { Alert } from '@hello-ai/ar_shared/src/components/Alert'
import { Button } from '@hello-ai/ar_shared/src/components/Button'
import {
  Checkbox,
  CheckboxGroup,
  CheckboxLabel,
} from '@hello-ai/ar_shared/src/components/Checkbox'
import { Divider } from '@hello-ai/ar_shared/src/components/Divider'
import { FontAwesomeIcon } from '@hello-ai/ar_shared/src/components/FontAwesomeIcon'
import { FormLabel } from '@hello-ai/ar_shared/src/components/Form'
import { Loading } from '@hello-ai/ar_shared/src/components/Loading'
import { Switch } from '@hello-ai/ar_shared/src/components/Switch'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { TextInput } from '@hello-ai/ar_shared/src/components/TextInput'
import {
  ToggleGroup,
  ToggleItem,
} from '@hello-ai/ar_shared/src/components/Toggle'
import { Colors } from '@hello-ai/ar_shared/src/constants/Colors'
import { t } from '@hello-ai/ar_shared/src/modules/i18n'
import { supportedLocales } from '@hello-ai/ar_shared/src/modules/locale'
import { useFormState } from '@hello-ai/ar_shared/src/modules/useFormState'
import { useResponsive } from '@hello-ai/ar_shared/src/modules/useResponsive'
import {
  confirmSaveIncompleteChangesAsync,
  getSortedLocales,
  getTranslationLocaleLabel,
  hasIncompleteChanges,
  LocaleValue,
  TranslationFormItem,
  TranslationFormList,
} from '@hello-ai/for_r_app/src/components/Translation'

import { SelectedImage, SelectImage } from 'components/Shared/SelectImage'
import { useToken } from 'models/Auth'
import { TableMenu } from 'models/TableMenu'
import { TableProduct as TableProductModel } from 'models/TableProduct'
import { TableUnlimitedOrderPlan } from 'models/TableUnlimitedOrderPlan'
import { createTranslation } from 'models/Translation'
import { goBack } from 'modules/history'
import { useRestaurantCountryLanguage } from 'modules/useRestaurantCountryLanguage'

const space = 40

function TableProduct({
  tableProduct,
  index,
  drag,
  onPress,
  isActive,
  isSortable,
}: {
  tableProduct?: TableProductModel
  index?: number
  drag: () => void
  onPress: () => void
  isActive: boolean
  isSortable: boolean
}) {
  if (tableProduct == null) {
    return null
  }

  return (
    <RNTouchableOpacity
      style={{
        paddingVertical: 24,
        borderTopWidth: index === 0 ? 0 : 0.5,
        paddingLeft: 0,
        marginLeft: 0,
        paddingRight: 0,
        borderColor: Colors.border,
        backgroundColor: isActive ? Colors.primaryBg : 'white',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      onPress={!isSortable ? onPress : undefined}
      onPressIn={isSortable ? drag : undefined}
    >
      <View style={{ flex: 1 }}>
        <Text>{tableProduct.name}</Text>
      </View>
      {isSortable && (
        <FontAwesomeIcon
          icon={faGripLines}
          size={20}
          color={Colors.disabledBlack}
        />
      )}
    </RNTouchableOpacity>
  )
}

export default function TableUnlimitedOrderPlanFormContent({
  tableUnlimitedOrderPlan,
  tableMenus,
  isLoadingTableMenus,
  createUnlimitedOrderPlan,
  updateUnlimitedOrderPlan,
  onPressProduct,
}: {
  tableUnlimitedOrderPlan?: TableUnlimitedOrderPlan
  tableMenus: TableMenu[]
  isLoadingTableMenus: boolean
  createUnlimitedOrderPlan: ({
    token,
    params,
  }: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  updateUnlimitedOrderPlan: ({
    token,
    params,
  }: {
    token: string
    params: Record<string, any>
  }) => Promise<void>
  onPressProduct?: (productId: string) => void
}) {
  const { width, sm } = useResponsive()
  const token = useToken()

  const tableUnlimitedOrderPlanId = tableUnlimitedOrderPlan?.id

  const preferredLocale = useRestaurantCountryLanguage()
  const sortedLocales = getSortedLocales(preferredLocale)

  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [removeImage, setRemoveImage] = useState(false)
  const defaultName = useMemo(() => {
    const name: Partial<LocaleValue> = {}
    supportedLocales.forEach((locale) => {
      name[locale] =
        tableUnlimitedOrderPlan?.[
          `name_${locale.replaceAll('-', '_').toLowerCase()}`
        ] ?? ''
    })

    return name as LocaleValue
  }, [tableUnlimitedOrderPlan])
  const [name, setName] = useFormState<LocaleValue>(defaultName)

  const defaultDescription = useMemo(() => {
    const description: Partial<LocaleValue> = {}
    supportedLocales.forEach((locale) => {
      description[locale] =
        tableUnlimitedOrderPlan?.[
          `description_${locale.replaceAll('-', '_').toLowerCase()}`
        ] ?? ''
    })

    return description as LocaleValue
  }, [tableUnlimitedOrderPlan])

  const [description, setDescription] =
    useFormState<LocaleValue>(defaultDescription)
  const [taxIncluded, setTaxIncluded] = useFormState(
    tableUnlimitedOrderPlan?.tax_included ?? true
  )
  const [price, setPrice] = useFormState(
    String(tableUnlimitedOrderPlan?.price ?? '')
  )
  // サーバー側では秒単位だがクライアント側では分単位で入出力する
  const [duration, setDuration] = useFormState(
    tableUnlimitedOrderPlan?.duration != null
      ? String(Math.round(tableUnlimitedOrderPlan?.duration / 60))
      : ''
  )
  const [menus, setMenus] = useFormState(
    tableUnlimitedOrderPlan
      ? tableUnlimitedOrderPlan.table_menus.map((menu) => menu.id)
      : []
  )
  const [status, setStatus] = useFormState(
    tableUnlimitedOrderPlan?.status ?? 'published'
  )

  const onPress = async () => {
    if (name[preferredLocale] === '') {
      Alert.alert(t('放題プラン名を入力してください'))
      return
    }
    if (price === '' || price == null) {
      Alert.alert(t('価格を入力してください'))
      return
    }

    const params = {
      ...Object.fromEntries(
        supportedLocales.map((locale) => {
          return [
            `name_${locale.replaceAll('-', '_').toLowerCase()}`,
            name[locale],
          ]
        })
      ),
      ...Object.fromEntries(
        supportedLocales.map((locale) => {
          return [
            `description_${locale.replaceAll('-', '_').toLowerCase()}`,
            description[locale],
          ]
        })
      ),
      tax_included: taxIncluded,
      price,
      duration: Number(duration) * 60,
      table_menu_ids: menus,
      status,
    } as any

    if (selectedImage !== null) {
      params.image = selectedImage
    } else {
      if (removeImage) {
        params.image = null
      }
    }

    if (token === null) {
      return
    }

    if (tableUnlimitedOrderPlanId !== undefined) {
      if (
        hasIncompleteChanges(name, defaultName) ||
        hasIncompleteChanges(description, defaultDescription)
      ) {
        if (!(await confirmSaveIncompleteChangesAsync(Alert.alert))) {
          return
        }
      }
      await updateUnlimitedOrderPlan({
        token,
        params,
      })
      goBack('..')
    } else {
      await createUnlimitedOrderPlan({
        token,
        params,
      })
      goBack('..')
    }
  }

  const alertArchive = () => {
    const params = {
      status: 'archived',
    }
    Alert.alert(t('本当にアーカイブしますか？'), '', [
      {
        text: t('いいえ'),
        onPress: () => console.log('Cancel Pressed'),
        style: 'cancel',
      },
      {
        text: t('はい'),
        onPress: async () => {
          if (token === null) {
            return
          }
          await updateUnlimitedOrderPlan({
            token,
            params,
          })
          goBack('..')
        },
      },
    ])
  }

  return (
    <ScrollView
      contentContainerStyle={[
        width < sm
          ? {
              paddingHorizontal: 16,
              paddingVertical: 20,
            }
          : {
              paddingHorizontal: 36,
              paddingVertical: space,
            },
      ]}
      style={{ backgroundColor: 'white' }}
    >
      <TranslationFormList
        formLabel={<FormLabel value={t('放題プラン名')} required />}
        showsFormDescription
        sortedLocales={sortedLocales}
        values={name}
        onChangeValues={setName}
        createTranslation={(params) => createTranslation(token!, params)}
      >
        {({ locale }) => (
          <TranslationFormItem
            key={locale}
            formLabel={<FormLabel value={getTranslationLocaleLabel(locale)} />}
          >
            <TextInput
              placeholder={''}
              value={name[locale]}
              onChangeText={(text) =>
                setName((value) => ({
                  ...value,
                  [locale]: text,
                }))
              }
              autoCapitalize="none"
            />
          </TranslationFormItem>
        )}
      </TranslationFormList>
      <Divider
        style={{
          marginVertical: 24,
        }}
      />
      <TranslationFormList
        formLabel={<FormLabel value={t('説明')} />}
        showsFormDescription
        sortedLocales={sortedLocales}
        values={description}
        onChangeValues={setDescription}
        createTranslation={(params) => createTranslation(token!, params)}
      >
        {({ locale }) => (
          <TranslationFormItem
            key={locale}
            formLabel={<FormLabel value={getTranslationLocaleLabel(locale)} />}
          >
            <TextInput
              placeholder={''}
              value={description[locale]}
              onChangeText={(text) =>
                setDescription((value) => ({
                  ...value,
                  [locale]: text,
                }))
              }
              autoCapitalize="none"
            />
          </TranslationFormItem>
        )}
      </TranslationFormList>
      <Divider style={{ marginVertical: 24 }} />
      <View
        style={[
          width >= sm && {
            flexDirection: 'row',
          },
        ]}
      >
        <View
          style={{
            alignSelf: 'flex-start',
          }}
        >
          {(tableUnlimitedOrderPlan?.image != null && !removeImage) ||
          selectedImage != null ? (
            <SelectedImage
              source={{
                uri: (selectedImage ?? '') || tableUnlimitedOrderPlan?.image.sm,
              }}
              onRemovePress={() => {
                setSelectedImage(null)
                setRemoveImage(true)
              }}
            />
          ) : (
            <SelectImage
              onSelectImage={(image) => {
                setSelectedImage(image)
                setRemoveImage(false)
              }}
            />
          )}
        </View>
        <View
          style={[
            { flex: 1 },
            width < sm
              ? {
                  marginTop: 16,
                }
              : { marginLeft: 32 },
          ]}
        >
          <View
            style={[
              width >= sm && {
                flexDirection: 'row',
              },
            ]}
          >
            <ToggleGroup
              style={[width >= sm && { height: '100%' }]}
              value={taxIncluded}
              onValueChange={(value) => setTaxIncluded(value)}
            >
              <ToggleItem style={{ width: 110 }} value text={t('税込')} />
              <ToggleItem
                style={{ width: 110 }}
                value={false}
                text={t('税抜')}
              />
            </ToggleGroup>
            <TextInput
              placeholder={t('価格')}
              value={price}
              onChangeText={(text) => setPrice(text)}
              autoCapitalize="none"
              keyboardType="number-pad"
              style={[
                { flex: 1 },
                width < sm
                  ? {
                      marginTop: 16,
                    }
                  : {
                      marginLeft: space,
                    },
              ]}
            />
          </View>
          <TextInput
            placeholder={t('ラストオーダーまでの時間（分）')}
            value={duration}
            onChangeText={(text) => setDuration(text)}
            autoCapitalize="none"
            keyboardType="number-pad"
            style={{ marginTop: 20 }}
          />
        </View>
      </View>
      <View style={{ marginTop: space }}>
        <Text style={{ fontWeight: '600' }}>{t('メニュー')}</Text>
        <View style={{ marginTop: 20 }}>
          {isLoadingTableMenus ? (
            <Loading />
          ) : (
            <CheckboxGroup value={menus} onChange={setMenus}>
              {tableMenus?.map(({ id, name }) => {
                return (
                  <Checkbox
                    key={id}
                    value={id}
                    checkboxLabel={<CheckboxLabel value={name} />}
                  />
                )
              })}
            </CheckboxGroup>
          )}
        </View>
      </View>
      <View style={{ marginTop: space }}>
        <Text style={{ fontWeight: '600' }}>{t('商品リスト')}</Text>
      </View>
      <FlatList
        style={{ backgroundColor: 'white', paddingLeft: 0, marginLeft: 0 }}
        data={tableUnlimitedOrderPlan?.table_products}
        keyExtractor={(item) => String(item.id)}
        renderItem={({ item, index }) => (
          <TableProduct
            tableProduct={item}
            index={index}
            drag={() => {}}
            onPress={() => {
              if (!onPressProduct) {
                return
              }
              onPressProduct(item.id)
            }}
            isActive={false}
            isSortable={false}
          />
        )}
      />
      <View
        style={{
          borderTopWidth: 1,
          borderColor: Colors.border,
          marginTop: space,
          paddingTop: space,
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Text style={{ marginRight: 8, marginTop: 2 }}>{t('公開する')}</Text>
          <Switch
            onValueChange={() => {
              setStatus(status === 'published' ? 'unpublished' : 'published')
            }}
            value={status === 'published'}
          />
        </View>
      </View>

      <View style={{ flexDirection: 'row', marginTop: 40 }}>
        {tableUnlimitedOrderPlanId !== undefined && (
          <Button
            height={56}
            mode="outline"
            variant="danger-secondary"
            style={{
              flex: 1,
              marginRight: 32,
            }}
            onPress={() => alertArchive()}
          >
            {t('アーカイブ')}
          </Button>
        )}
        <Button
          height={56}
          style={{
            flex: 1,
          }}
          onPress={() => onPress()}
        >
          {tableUnlimitedOrderPlanId !== undefined
            ? t('更新する')
            : t('追加する')}
        </Button>
      </View>
    </ScrollView>
  )
}
